import Block from '_core_ext/core/common/Block';
import util from '_core_ext/util';

export default class ViewModeBase extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.searchQueryUrl = this.config.searchQueryUrl;
        this.storageInfo = this.config.storageInfo;
        this.currentViewMode = this.config.preSelectedViewMode;

        this.event('click', '.js-view-control', this.onControlViewClick.bind(this));
    }

    onControlViewClick(el) {
        var selectedViewMode = $(el).data('view-mode');
        
        // Changes made to stop click event on active Grid Switcher incase of Mixed Template
        if(this.currentViewMode === selectedViewMode && !$('.c-product-list--condensed.mixed-template').length){
            return;
        }
        this.storeViewMode(selectedViewMode);
        this.setViewMode(selectedViewMode);

        var queryParams = {
            url: util.updateOrAppendUrlParams(this.searchQueryUrl, this.getViewModeUrlParams())
        };
        this.emitter.emit(`${this.id}.mode.changed`, queryParams);
    }

    storeViewMode(selectedViewMode) {
        var key = this.getLocalStorageItemKey();
        this.setLocalStorageItem(key, selectedViewMode);
    }

    getStoredViewMode() {
        var key = this.getLocalStorageItemKey();
        return this.getLocalStorageItem(key);
    }

    getLocalStorageItemKey() {
        return this.storageInfo.id;
    }

    setLocalStorageItem(key, value) {
        localStorage.setItem(key, value);
    }

    getLocalStorageItem(key) {
        return localStorage.getItem(key);
    }

    setViewMode(selectedViewMode) {
        this.currentViewMode = selectedViewMode;
    }

    getViewMode() {
        return this.currentViewMode;
    }

    changeViewMode() {
        throw new Error('changeViewMode method must be implemented on children components');
    }

    getViewModeUrlParams() {
        throw new Error('getViewModeUrlParams method must be implemented on children components');
    }

    updateQueryUrls(paginationParams) {
        this.searchQueryUrl = util.updateOrAppendUrlParams(this.searchQueryUrl, paginationParams);
    }
}