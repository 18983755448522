import util from '_core_ext/util';

export default {
    getHistoryUrl: function (gridUrl, infinitePagination) {
        var linkUri = util.getUri(gridUrl);
        var historyUrl = linkUri.url;
        var query = this.getUrlQueryParams(gridUrl, infinitePagination);

        return util.removeParamFromURL(util.appendParamsToUrl(historyUrl, query), 'format');
    },

    getUrlQueryParams: function (gridUrl, infinitePagination) {
        var linkUri = util.getUri(gridUrl);
        var query = util.getQueryStringParams(window.location.search);
        var linkQuery = linkUri.queryParams;

        for (var key in linkQuery) {
            if (Object.prototype.hasOwnProperty.call(linkQuery, key)) {
                query[key] = linkQuery[key];
            }
        }

        query.sz = infinitePagination ? parseInt(query.start, 10) + parseInt(query.sz, 10) : parseInt(query.sz, 10);
        query.start = infinitePagination ? 0 : parseInt(query.start, 10);

        return query;
    },

    getPaginationParams: function (gridUrl, infinitePagination) {
        var queryParams = this.getUrlQueryParams(gridUrl, infinitePagination);

        return {
            sz: queryParams.sz,
            start: queryParams.start
        };
    },

    setHistoryUrl: function(gridUrl, infinitePagination) {
        history.pushState({}, document.title, this.getHistoryUrl(gridUrl, infinitePagination), gridUrl);
    }
};