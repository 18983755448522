'use strict';

const dialog = require('_core_ext/dialog');
const util = require('_core_ext/util');
const ajax = require('_core_ext/ajax');
const cookie = require('_core_ext/cookie');
const $cache = {};
const options = {
    cookiemsg: '.js-cookies-message'
};

import eventMgr from '_core_ext/core/eventMgr';
const emitter = eventMgr.getEmitter('gdpr');

/**
 * @function getConsent Used to display/control of the modal containing the consent management message
 **/

function initializeCache() {
    $cache.document = $(document);
    $cache.cookiemsg = $cache.document.find(options.cookiemsg);
}

function enablePrivacyCookies() {
    var cookieName = $cache.cookiemsg.data('cookieName');
    if (document.cookie.indexOf('dw=1') < 0) {
        cookie.set('dw', '1', getCookiesExpire());
    }

    if (document.cookie.indexOf('dw_cookies_accepted') < 0) {
        cookie.set(cookieName, '1', getCookiesExpire());
    }
}

function showPrivacyDialog() {
    if (SitePreferences.COOKIE_HINT === true && document.cookie.indexOf('dw_cookies_accepted') < 0) {
        $cache.cookiemsg.addClass('is--shown');
        $cache.document.trigger('cookieMessage.toggle');
        emitter.emit('shown');
        $cache.cookiemsg.on('click', '.js-acceptbtn', (e) => {
            e.preventDefault();

            $cache.cookiemsg.removeClass('is--shown');
            $cache.document.trigger('cookieMessage.toggle');
            $cache.cookiemsg.addClass('is--accepted');
            emitter.emit('closed');
            $cache.document.trigger('header.recalculateHeight');
            enablePrivacyCookies();
        });
    } else {
        // Otherwise, we don't need to show the asset, just enable the cookies
        enablePrivacyCookies();
    }
}

function applyConsentChoice(choice) {
    ajax({
        method: 'GET',
        url: util.appendParamToURL(Urls.consentTrackingSetSession, 'consentTracking', choice)
    });
    showPrivacyDialog();
}

function getCookiesExpire() {
    var date = new Date();
    date.setTime(date.getTime() + (365 * 86400000));
    return date.toGMTString();
}

function getConsent() {
    dialog.open({
        url: Urls.consentTracking,
        options: {
            closeOnEscape: false,
            dialogClass: 'no-close tracking-consent-dialog',
            buttons: [{
                text: Resources.TRACKING_NO_CONSENT,
                class: 'tracking-consent-button-no',
                id: 'tracking-consent-button-disagree',
                click: function () {
                    $(this).dialog('close');
                    applyConsentChoice(false);
                }
            }, {
                text: Resources.TRACKING_CONSENT,
                class: 'tracking-consent-button-yes',
                id: 'tracking-consent-button-agree',
                click: function () {
                    $(this).dialog('close');
                    applyConsentChoice(true);
                    cookie.set('consentDialogShown', 'true', getCookiesExpire());
                }
            }],
            close: function () {
                $(this).dialog('destroy');
                applyConsentChoice(false);
            }
        }
    });
}

function initConsentTrackingHandler() {
    if (SitePreferences.CONSENT_TRACKING_HINT &&
        pageContext.currentPage !== 'countryselector' &&
        document.cookie.indexOf('consentDialogShown') < 0) {
        if (pageContext.consentTracking) {
            showPrivacyDialog();
        } else {
            getConsent();
        }
    } else {
        showPrivacyDialog();
    }
}

var gdpr = {
    init: function () {
        initializeCache();
        initConsentTrackingHandler();
    }
};
module.exports = gdpr;
