import ViewModeBase from '../Base';

export default class ProductView extends ViewModeBase {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.currentCategoryId = this.config.currentCategoryId;
    }

    getLocalStorageItemKey() {
        return location.pathname + '_' + this.storageInfo.id;
    }

    getViewModeUrlParams() {
        return {
            productViewMode: this.currentViewMode
        };
    }
}