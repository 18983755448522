const searchsuggest = require('./searchsuggest'),
    util = require('./../../../app_storefront_core_ext/cartridge/js/util');
var googleRecaptcha = require('./../../../app_storefront_core_ext/cartridge/js/googleRecaptcha');

/**
 * @description Moved from SG app.js
 */
function initializeEvents() {
    var controlKeys = ['8', '13', '46', '45', '36', '35', '38', '37', '40', '39'];
    var phonePrefixes = {
        "US": "+1",
        "GB": "+44",
        "MX": "+52",
        "ES": "+34",
        "FR": "+33",
        "PT": "+351",
        "DE": "+49",
        "BE": "+32",
        "CH": "+41",
        "MC": "+377",
        "IT": "+39",
        "IE": "+353",
        "LU": "+352",
        "NL": "+31",
        "SE": "+46",
        "AT": "+43",
        "NO": "+47",
        "DK": "+45",
        "FI": "+358",
        "CZ": "+420",
        "EN": "+44",
        "DEFAULT": "+44",
        "UNDEFINED": "+44"
    };
    var selectedCountry = $('input[name="locale"]').length && $('input[name="locale"]').val() !== 'null' ? $('input[name="locale"]').val().toUpperCase() : 'GB';
    var phonePrefix = phonePrefixes[selectedCountry];
    if ($('.phone_prefix').length) {
        $('.phone_prefix').val(phonePrefix);
    }

    $('body')
        .on('keydown', 'textarea[data-character-limit]', function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on('change keyup mouseup', 'textarea[data-character-limit]', function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });


    // initialize search suggestions
    var $searchContainer = $('#header .header__search');
    searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);

    // subscribe email box
    var $subscribeEmail = $('.subscribe-email');
    if ($subscribeEmail.length > 0) {
        $subscribeEmail.focus(function () {
            var val = $(this.val());
            if (val.length > 0 && val !== Resources.SUBSCRIBE_EMAIL_DEFAULT) {
                return; // do not animate when contains non-default value
            }

            $(this).animate({color: '#999999'}, 500, 'linear', function () {
                $(this).val('').css('color', '#333333');
            });
        }).blur(function () {
            var val = $.trim($(this.val()));
            if (val.length > 0) {
                return; // do not animate when contains value
            }
            $(this).val(Resources.SUBSCRIBE_EMAIL_DEFAULT)
                .css('color', '#999999')
                .animate({color: '#333333'}, 500, 'linear');
        });
    }

    // subscribe retail store dob date
    var $subscribeDOB_D = $(".c-retail-subscribe-form.c-subscribe-page-content__form .dob_d");
    var $subscribeDOB_Dlabel = $subscribeDOB_D.siblings(".form-row_label").find(".form-row_value");
    $subscribeDOB_D.focus(function () {
        $subscribeDOB_Dlabel.hide();
    }).blur(function () {
        if ($subscribeDOB_D.val() === '') {
            $subscribeDOB_Dlabel.show();
        }
    });

    // subscribe retail store dob month
    var $subscribeDOB_M = $(".c-retail-subscribe-form.c-subscribe-page-content__form .dob_m");
    var $subscribeDOB_Mlabel = $subscribeDOB_M.siblings(".form-row_label").find(".form-row_value");
    $subscribeDOB_M.focus(function () {
        $subscribeDOB_Mlabel.hide();
    }).blur(function () {
        if ($subscribeDOB_M.val() === '') {
            $subscribeDOB_Mlabel.show();
        }
    });

    // subscribe retail store dob year
    var $subscribeDOB_Y = $(".c-retail-subscribe-form.c-subscribe-page-content__form .dob_y");
    var $subscribeDOB_Ylabel = $subscribeDOB_Y.siblings(".form-row_label").find(".form-row_value");
    $subscribeDOB_Y.focus(function () {
        $subscribeDOB_Ylabel.hide();
    }).blur(function () {
        if ($subscribeDOB_Y.val() === '') {
            $subscribeDOB_Ylabel.show();
        }
    });

    // subscribe retail store phone label
    var $subscribePhone = $('.c-retail-subscribe-form.c-subscribe-page-content__form .js-newsletter-phone input');
    var $subscribePhonelabel = $subscribePhone.siblings(".form-row_label").find(".form-row_value");
    
    $subscribePhone.focus(function () {
        $subscribePhonelabel.hide();
    }).blur(function () {
        if ($subscribePhone.val() == '') {
            $subscribePhonelabel.show();
        }
        errorChange();
    });
    
    function errorChange() {
        var $newsletterPhone = $('.c-retail-subscribe-form.c-subscribe-page-content__form .js-newsletter-phone')
        if ($newsletterPhone.find('input.error').length > 0) {
            $newsletterPhone.siblings('.js-newsletter-phone-prefix').addClass("phone-error");
        } else {
            $newsletterPhone.siblings('.js-newsletter-phone-prefix').removeClass("phone-error");
        }
    }

    // subscribe retail store accept policy click
    var $subscribeFormInputCb = $(".c-retail-subscribe-form.c-subscribe-page-content__form .input-checkbox");
    var $subscribeOmetriaFormInputCb = $(".c-subscribe-ometria-form-hidden .accept_policy_cb");
    $subscribeFormInputCb.click(function () {
        $subscribeOmetriaFormInputCb.click();
    });

    function updateOmetriaForm() {
        // subscribe retail date combining dd, mm, yy
        var dateElem = $(".c-retail-subscribe-form .c-subscribe-form__birthday-form");
        var resultDate;
        if (dateElem.find(".dob_y") !== '' && dateElem.find(".dob_m") !== '' && dateElem.find(".dob_d") !== '') {
            resultDate = dateElem.find(".dob_y").val() + "-" + dateElem.find(".dob_m").val() + "-" + dateElem.find(".dob_d").val();
            $(".c-subscribe-ometria-form-hidden input[type='date']")[0].value = resultDate;
        }

        // subscribe retail store email and text input
        var $subscribeOmetriaFormInput = $(".c-subscribe-ometria-form-hidden .js-input_field");
        var $subscribeFormInput = $(".c-retail-subscribe-form.c-subscribe-page-content__form .js-input_field");
        if ($subscribeOmetriaFormInput.length > 0 && $subscribeFormInput.length  > 0) {
            $subscribeOmetriaFormInput[0].value = $subscribeFormInput[0].value;
            $subscribeOmetriaFormInput[1].value = $subscribeFormInput[1].value;
            $subscribeOmetriaFormInput[2].value = $subscribeFormInput[2].value;
        }

        // subscribe retail store tel input
        if (($(".c-retail-subscribe-form.c-subscribe-page-content__form .js-input_field[type='tel']")[0].value) != '') {
            $(".c-subscribe-ometria-form-hidden .js-input_field[type='tel']")[0].value = $('.phone_prefix option:selected').val() + $(".c-retail-subscribe-form.c-subscribe-page-content__form .js-input_field[type='tel']")[0].value;
        }

        // subscribe retail store language selection based on dropdown
        var $ometriaCbs = $(".c-subscribe-ometria-form-hidden input[type=radio]");
        if ($ometriaCbs.length) {
            var $selectedVal = $('.c-retail-subscribe-form .js-newsletter-language option:selected')[0].value;
            if ($selectedVal === 'EN') {
                $ometriaCbs[0].click();
            } else if ($selectedVal === 'DE') {
                $ometriaCbs[3].click();
            } else if ($selectedVal === 'ES') {
                $ometriaCbs[1].click();
            } else if ($selectedVal === 'FR') {
                $ometriaCbs[2].click();
            }
        }
    }
    if ($('.recaptcha-verify').length) {
        googleRecaptcha.buildRecaptchaV2();
    }
    function isCaptchaValid() {
        if ($('.g-recaptcha-response').val() !== '' || grecaptcha.enterprise.getResponse() !== '') {
            return true;
        } else {
            if ($('.g-recaptcha-response').length) {
                grecaptcha.enterprise.reset();
            }
            return false;
        }
    }

    // subscribe retail store submit button
    var $subscribeFormBtn = $(".c-retail-subscribe-form.c-subscribe-page-content__form .c-subscribe-form__btn.c-retail-form-btn");
    var $subscribeForm = $(".c-subscribe-form--footer button, .c-subscribe-form button");
    var $subscribeOmetriaFormBtn = $('.c-subscribe-ometria-form-hidden input[type=\'submit\']');
    var directClick = true;

    $subscribeFormBtn.click(function (e) {
        e.preventDefault();
        directClick = false;
        if ($('.recaptcha-verify').length && !isCaptchaValid()) {
            googleRecaptcha.buildRecaptchaV2();
            return;
        }
        updateOmetriaForm();
        // Call the helper to generate and verify customer token
        googleRecaptcha.getRecaptchaResponse().then(
            function (value) {
                if ($('.c-retail-subscribe-form').valid()) {
                    $subscribeOmetriaFormBtn.click();
                } else {
                    var dirtyState = $('.c-retail-subscribe-form .js-newsletter-language');
                    var dirstyStateLang = dirtyState.hasClass('state-dirty');
                    var langError = $('.c-retail-subscribe-form #storesubscribe_language-error');
                    if (dirtyState.length && langError.length) {
                        if (!dirstyStateLang) {
                            langError.addClass('error');
                            langError.text('This field is required.');
                            langError.css('display', 'inline-block');
                            return;
                        } else {
                            langError.removeClass('error');
                            langError.css('display', 'none');
                        }
                    }
                }
            });
    });

    $subscribeForm.click(function (e) {
        if ($('.recaptcha-verify').length) {
            if (!directClick) {
                return;
            }
            e.preventDefault();
            $subscribeForm.parents("form").valid();
            googleRecaptcha.getRecaptchaResponse().then(
                function (value) {
                    if ($subscribeForm.parents("form").valid()) {
                        $subscribeForm.parents("form").submit();
                    } else {
                        googleRecaptcha.buildRecaptchaV2();
                        return;
                    }
                }
            );
        }
    });
}

/**
 * @description Moved from SG app.js
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting
    $('html').addClass('js');
    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $('html').addClass('infinite-scroll');
    }
    // load js specific styles
    util.limitCharacters();

    if (document.cookie.length === 0) {
        $('<div/>').addClass('browser-compatibility-alert').append($('<p/>').addClass('c-browser-error')
            .html(Resources.COOKIES_DISABLED)).prependTo('#browser-check');
    }
    // add data-attribute for ContentSquare - Masking personal information for screen capture
    $('.js-personal-data').attr('data-cs-mask', '');

    // subscribe retail store radio focus
    var langError = $('.c-retail-subscribe-form #storesubscribe_language-error');
    var jsLangElement = $('.c-retail-subscribe-form .js-newsletter-language');
    if (jsLangElement.length > 0) {
        jsLangElement.on('change', function () {
            var val = $(this).find('option:selected').val();
            if (val == '') {
                langError.addClass('error');
                langError.css('display', 'inline-block');
            } else {
                langError.removeClass('error');
                langError.css('display', 'none');
            }
        });
    }
}


function returnSecondaryLink() {
    $('.c-orderdetails__trackingnumber').on('click', '#returnTrackingSecondaryLink', function (e) {
        var trackingURL = $("#returnTrackingPrimaryLink").attr("href");
        $(".return-btn2").attr('href', trackingURL);
    });
}

function updatePhoneDropLabel() {
    var countryCodeLabel = $('.selectric-phone_prefix').find('.selectric .label');
    if (countryCodeLabel.length > 0) {
        var countryRegExp = countryCodeLabel.text().match(/\+\d+/)[0];
        countryCodeLabel.text(countryRegExp);
    }
    var phonePref = $('.c-retail-subscribe-form .js-newsletter-phone-prefix');
    phonePref.on('change', function () {
        var countryLabel = $(this).find('.selectric .label');
        if (countryLabel.length > 0) {
            countryLabel.text(countryLabel.text().match(/\+\d+/)[0]);
        }
    });
}

module.exports = {
    'init': function () {
        initializeDom();
        initializeEvents();
        returnSecondaryLink();
        $(document).ready(function() {
            updatePhoneDropLabel();
        });
    }
};