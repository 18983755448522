import Component from '_core_ext/core/Component';

export default class LiveChat extends Component {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.initEvents();
        this.loadScript();
    }

    loadScript() {
        var script = document.createElement('script');
        script.src = 'https://cdn.kustomerapp.com/chat-web/widget.js';
        script.setAttribute('defer', '');
        script.setAttribute('data-kustomer-api-key', this.config.API_KEY);
        window.document.body.appendChild(script);
    }

    initEvents() {
        window.addEventListener('kustomerLoaded', () => {
            window.Kustomer.start({
                brandId: this.config.brandId
            });
        });
    }
}