import Block from '_core_ext/core/common/Block';

export default class LocationsPicker extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.locationsList = {};
        this.locationsMap = {};

        this.getById('locationsList', (locationsList) => {
            this.locationsList = locationsList;
        });
        this.getById('locationsMap', (locationsMap) => {
            this.locationsMap = locationsMap;
        });

        this.onChild('locationsList', 'locationsList.location.selected', this.onLocationSelected.bind(this));
        this.onChild('locationsMap', 'locationsMap.location.selected', this.onLocationSelected.bind(this));
        this.onChild('locationsList', 'locationsList.location.pin.click', this.onLocationPinClick.bind(this));

        this.event('click', '.js-select-locations-list', this.toggleListMap.bind(this, false));
        this.event('click', '.js-select-locations-map', this.toggleListMap.bind(this, true));
    }

    toggleListMap (hide) {
        this.locationsList.toggle(hide);
        this.locationsMap.toggle(!hide);
        this.$el.find('.js-select-locations-list, .js-select-locations-map').toggleClass('js-selected');
    }

    onLocationSelected (locationId, locationInfo) {
        var selectedLocationHtml = this.locationsList.getLocationListTile(locationId);
        this.emitter.emit('locationsPicker.location.selected', locationId, locationInfo, selectedLocationHtml);
    }

    onLocationPinClick (locationId) {
        this.toggleListMap(true);
        this.locationsMap.showPinLocation(locationId);
    }
}