import eventMgr from '_core_ext/core/eventMgr';
import ajax from '_core_ext/ajax';

var initialized = false;

function getProductTileContent(params) {
    return new Promise((res, rej) => {
        ajax({
            url: params.url,
            type: 'POST',
            contentType: 'text/html',
            callback: (tileContent) => {
                if (tileContent === null) {
                    return rej('Couldn\'t get product tile section.');
                }
                res(tileContent);
            }
        });
    });
}

module.exports.init = function () {
    if (initialized) {
        return;
    }
    eventMgr.registerAction('get.product.tile.content', getProductTileContent);
    initialized = true;
};