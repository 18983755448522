'use strict';
var layout, ajax , util, emitter, referencesLoaded;
import eventMgr from '_core_ext/core/eventMgr';
import imagesLoaded from 'imagesloaded';
function getReferences() {
    layout = require('_core_ext/layout');

    if (!('ui' in $)) {
        require('jquery-ui-dist/jquery-ui');
    }
    if (!('focusable' in $.ui)) {
        require('jquery-ui/ui/focusable');
    }
    if (!('tabbable' in $.ui)) {
        require('jquery-ui/ui/tabbable');
    }
    if (!('data' in $.ui)) {
        require('jquery-ui/ui/data');
    }
    if (!('position' in $.ui)) {
        require('jquery-ui/ui/position');
    }
    if (!('widget' in $.ui)) {
        require('jquery-ui/ui/widget');
    }
    if (!('unique-id' in $.ui)) {
        require('jquery-ui/ui/unique-id');
    }
    if (!('button' in $.ui)) {
        require('jquery-ui/ui/widgets/button');
    }
    if (!('dialog' in $.ui)) {
        require('jquery-ui/ui/widgets/dialog');
    }
    if (!('safe-active-element' in $.ui)) {
        require('jquery-ui/ui/safe-active-element');
    }

    ajax = require('./ajax');
    util = require('./util');
    emitter = eventMgr.getEmitter('dialog');

    referencesLoaded = true;
}


function onContentUpdate() {
    if (this.$container) {
        this.$container.dialog('option', 'position', this.getSettings().position);
    }
}

var dialog = {
    /**
     * @function
     * @description Appends a dialog to a given container (target)
     * @param {Object} params  params.target can be an id selector or an jquery object
     */
    create: function (params = {}) {
        if (!referencesLoaded) {
            getReferences();
        }

        var $target;
        var settings = $.extend(true, {}, this.getSettings(), params.options);
        if ($.type(settings.target) === 'string') {
            if (settings.target.charAt(0) === '#') {
                $target = $(settings.target);
            } else {
                $target = $('#' + settings.target);
            }
        } else if (settings.target instanceof jQuery) {
            $target = settings.target;
        } else {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            $target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
        }

        this.$container = $target;
        if (!this.$container.data('dialogEventAttached')) {
            // Dialog action listener to emit component's event
            this.$container.on('dialogopen', () => {
                emitter.emit('open');
            }).on('dialogclose', () => {
                emitter.emit('close');
            }).on('dialogopen', () => {
                var $dialogContainer = this.$container.parents('.ui-dialog');

                if (!$dialogContainer.parent().is('body')) {
                    $dialogContainer.appendTo('body');
                }

                this.$dialogContainer = $dialogContainer;

            });
            this.$container.data('dialogEventAttached', true);
            eventMgr.on('window.resize', onContentUpdate.bind(this));
            eventMgr.on('window.modechanged', onContentUpdate.bind(this));
        }

        if (settings.closeOnOutsideClick) {
            this.$container.one('dialogopen', function () {
                var $container = $(this),
                    $overlay = $container.data('ui-dialog').overlay || '';
                if (!$overlay.length) {
                    return;
                }
                $overlay.one('click', function () {
                    $(this).data('dialog').dialog('close');
                }).data('dialog', $container);
            });
        }

        this.$container.on('dialogclose', () => {
            $('html').removeClass('is--dialog-opened');
        });

        if (settings.emptyOnClose) {
            this.$container.on('dialogclose', function () {
                $(this).empty();
            });
        }
        if (!settings.autofocusOnFirst) {
            this.$container.one('dialogopen', function () {
                $(this).find(':tabbable:first').blur();
            });
        }

        // create the dialog
        this.$container.dialog(settings);
    },
    /**
     * @function
     * @description Opens a dialog using the given url (params.url) or html (params.html)
     * @param {Object} params
     * @param {Object} params.url should contain the url
     * @param {String} params.html contains the html of the dialog content
     */
    open: function (params, closePrevious = true) {
        if (closePrevious === true) {
            // close any open dialog
            this.close();
        }
        this.create(params);
        this.replace(params);

        this.$container.on('dialogopen', () => {
            $('html').addClass('is--dialog-opened');
            this.preventScrollingOnSafari();
        });

        this.$container.parent().on('scroll', () => {
            $(document).trigger('dialog.scroll').delay(50);
        });

        $(document).trigger('dialog.opened', [this.$container]);
    },
    /**
     * @description populate the dialog with html content, then open it
     **/
    openWithContent: function (params) {
        var content, position, callback;

        if (!this.$container) { return; }
        content = params.content || params.html;
        if (!content) { return; }
        this.$container.empty().html(content);
        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
        }

        if (params.options) {
            position = params.options.position;
        }
        if (!position) {
            position = this.getSettings().position;
        }
        imagesLoaded(this.$container).on('done', () => {
            this.$container.dialog('option', 'position', position);
        });

        callback = (typeof params.callback === 'function') ? params.callback : function () {};
        callback();
    },
    /**
     * @description Replace the content of current dialog
     * @param {object} params
     * @param {string} params.url - If the url property is provided, an ajax call is performed to get the content to replace
     * @param {string} params.html - If no url property is provided, use html provided to replace
     */
    replace: function (params) {
        if (!this.$container) {
            return;
        }
        if (params.url) {
            params.url = util.appendParamToURL(params.url, 'format', 'ajax');
            ajax.load({
                url: params.url,
                type: 'GET',
                data: params.data,
                callback: function (response) {
                    params.content = response;
                    this.openWithContent(params);
                }.bind(this)
            });
        } else if (params.html || params.content) {
            this.openWithContent(params);
        }
    },
    /**
     * @function
     * @description Closes the dialog
     */
    close: function () {
        if (!this.$container) {
            return;
        }
        this.$container.dialog('close');
    },
    restore: function ($oldInstance) {
        this.close();
        this.$container = $oldInstance;
        if (!this.$container.dialog('isOpen')) {
            this.$container.dialog('open');
            this.$container.dialog('option', 'position', this.getSettings().position);
        }
        emitter.emit('restore');
    },
    exists: function () {
        return this.$container && (this.$container.length > 0);
    },
    getCurrent: function () {
        return this.$container;
    },
    isActive: function () {
        return this.exists() && (this.$container.children.length > 0);
    },
    isOpen: function($instance) {
        if ($instance) {
            return $instance.dialog('instance') && $instance.dialog('isOpen');
        }
        return this.$container && this.$container.dialog('isOpen');
    },

    /* eslint-disable */
    /**
     * preventScrollingOnSafari fix source:
     * https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi
    */
    /* eslint-enable */

    preventScrollingOnSafari: function() {
        var _overlay = document.getElementsByClassName('ui-widget-overlay')[0];
        var _clientY = null;

        function isOverlayTotallyScrolled() {
            return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
        }

        function disableRubberBand(event) {
            var clientY = event.targetTouches[0].clientY - _clientY;

            if (_overlay.scrollTop === 0 && clientY > 0) {
                event.preventDefault();
            }

            if (isOverlayTotallyScrolled() && clientY < 0) {
                event.preventDefault();
            }
        }

        _overlay.addEventListener('touchstart', (event) => {
            if (event.targetTouches.length === 1) {
                _clientY = event.targetTouches[0].clientY;
            }
        }, false);

        _overlay.addEventListener('touchmove', (event) => {
            if (event.targetTouches.length === 1) {
                disableRubberBand(event);
            }
        }, false);
    },
    getSettings: function() {
        return {
            autoOpen: false,
            height: 'auto',
            modal: true,
            overlay: {
                opacity: 0.5,
                background: 'black'
            },
            resizable: false,
            responsive: true,
            title: null,
            width: 'auto',
            close: function () {
                $(this).dialog('close');
            },
            position: {
                my: 'center',
                at: 'center',
                of: window,
                collision: layout.isDesktop() ? 'fit' : 'flipfit'
            },
            emptyOnClose: true,
            closeOnOutsideClick: true,
            autofocusOnFirst: false
        };
    }
};

module.exports = dialog;
