import eventMgr from '_core_ext/core/eventMgr';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';
import page from '_core_ext/page';

var initialized = false;

function getQuickShopContent (params) {
    var {url, ajaxParams, loaderLocation} = params;
    url = url ? url : Urls.quickShop;

    return new Promise((res, rej) => {
        ajax({
            type: 'POST',
            url: url,
            contentType: 'text/html',
            data: JSON.stringify(ajaxParams),
            callback: (data) => {
                if (data === null) {
                    page.refresh();
                    return rej('Couldn\'t get quick shop content.');
                }
                res(data);
            }
        });
    });
}

module.exports.init = function () {
    if (initialized) {
        return;
    }
    eventMgr.registerAction('get.quick.shop.content', getQuickShopContent);
    initialized = true;
};