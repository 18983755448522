module.exports={
    "global": [
        "quickView",
        "promoClick",
        "productClick",
        "selectItem",
        "quickShopAddToCartCarousel",
        "quickShopAddItemsToCartCarousel",
        "filterClick",
        "varientClick",
        "colourClick",
        "lengthClick",
        "menuClick",
        "navigationClick",
        "menuBannerClick",
        "categoryClick",
        "searchCategoryClick",
        "searchTermClick",
        "searchProductClick",
        "socialClick",
        "findStoreClick",
        "subscribeFooter",
        "sliderClick",
        "viewBag",
        "viewCart",
        "proceedCheckout",
        "homeBannerClick",
        "removeFromCart",
        "removeItemsFromCart",
        "login",
        "addToCart",
        "addItemsToCart",
        "register",
        "previewBag",
        "previewMiniBag",
        "closePreviewBag",
        "closeMiniPreviewBag",
        "productContextMenuOpen",
        "beginCheckout",
        "addShippingInfo",
        "loginPage",
        "registerPage",
        "subscribeFooterSection",
        "subscribeCheckboxSection",
        "subscribePDPNotifyPopup",
        "formValidationErrors",
        "findStore",
        "subscribeFooterError",
        "viewItemList",
        "searchSelectItem",
        "viewCartBag",
        "filtersClick",
        "genreClick",
        "landingClick",
        "internalSearch",
        "internalSearchCategoryClick",
        "searchResultsClick",
        "navBannerClick",
        "clpBannerClick",
        "navHktBannerClick",
        "addtobagProductTile",
        "socialFooterClick",
        "landingBannerClick"
    ],
    "productSet": [
        "sizeGuideClick",
        "colorClickPDP",
        "shareClick",
        "impressions"
    ],
    "product": [
        "sizeGuideClick",
        "colorClickPDP",
        "shareClick",
        "impressions",
        "emailMeWhenAvailable"
    ],
    "cart": [
        "impressions",
        "coupon",
        "couponCode"
    ],
    "checkout": [
        "shippingSubmit",
        "shippingMethodChange",
        "goPayment",
        "editBag",
        "placeOrder",
        "addPaymentInfo",
        "editDelivery",
        "coupon",
        "couponCode",
        "formErrors",
        "globale",
        "paymentError",
        "paymentPageError",
        "formCehckoutValidationErrors",
        "formBillingValidationErrors",
        "checkoutGoPayment",
        "checkoutPlaceOrder"
    ],
    "billing": [
        "billingSubmit",
        "coupon",
        "couponCode",
        "paymentError",
        "paymentPageError",
        "formBillingValidationErrors"
    ],
    "category": [
        "colorClick",
        "impressions",
        "quickShopAddToCart",
        "quickShopAddItemsToCart",
        "gridViewModeClick",
        "productViewModeClick"
    ],
    "search": [
        "colorClick",
        "impressions",
        "quickShopAddToCart",
        "quickShopAddItemsToCart",
        "viewSearchResults"
    ],
    "clp": [
        "colorClick",
        "impressions",
        "genreBannerClick"
    ],
    "plp": [
        "colorClick",
        "impressions"
    ],
     "account": [
        "createAccount",
        "loginError",
        "registrationError",
        "createAccountPage",
        "loginPageError",
        "registerPageError",
        "myAccountFormErrors",
        "myAccountFormPopupErrors"

    ],
    "checkoutlogin": [
        "checkoutGuest",
        "loginError",
        "checkoutGuestLogin",
        "loginPageError"
    ],
    "orderconfirmation": [
        "globale"
    ]
}