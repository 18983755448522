/* eslint-disable */
export default class MapPopUp extends google.maps.OverlayView {
/* eslint-enable */
    constructor(position, $content, id) {
        super();
        var $pixelOffset;

        this.position = position;

        $content.addClass('c-popup__content');

        $pixelOffset = $('<div></div>');
        $pixelOffset.addClass('c-popup__anchor js-popup-bubble-content js-popup-' + id);
        $pixelOffset.append($content);

        this.$anchor = $('<div></div>');
        this.$anchor.addClass('c-popup__anchor');
        this.$anchor.append($pixelOffset);
        this.stopEventPropagation();
    }

    onAdd () {
        $(this.getPanes().floatPane).append(this.$anchor);
    }

    draw () {
        var divPosition = this.getProjection().fromLatLngToDivPixel(this.position);
        var display = Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ? 'block' : 'none';

        if (display === 'block') {
            this.$anchor.css('left', divPosition.x + 'px');
            this.$anchor.css('top', divPosition.y + 'px');
        }
        if (this.$anchor.css('display') !== display) {
            this.$anchor.css('display', display);
        }
    }

    stopEventPropagation () {
        var $anchor = this.$anchor;

        ['click', 'dblclick', 'contextmenu', 'wheel', 'mousedown', 'touchstart', 'pointerdown'].forEach((event) => {
            $anchor.on(event, (e) => {
                if ($(e.target).hasClass('js-close-popup') || $(e.target).hasClass('js-select-location')) {
                    return;
                }

                e.stopPropagation();
            });
        });
    }
}