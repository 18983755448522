import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';
import commonUtils from '_core_ext/components/checkout/utils/commonUtils';
import util from '_core_ext/util';
import checkoutSummaryServices from './utils/checkoutSummaryServices';
import page from '_core_ext/page';
import layout from '_core_ext/layout';

export default class CheckoutSummary extends Block {
    init () {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.checkingCoupon = false;
        this.checkoutStep = pageContext.pipelineName.indexOf('COShipping') > -1 ? '2' : '3';
        this.showErrorMessage = commonUtils.showPostalCodeRestrictionsErrorMessage.bind(this);
        this.clearErrorMessage = commonUtils.clearPostalCodeRestrictionsErrorMessage.bind(this);
        this.isCustomerLogged = this.config.isCustomerLogged;

        checkoutSummaryServices.init();

        this.event('click', '.js-submit-checkout', this.onProceedToPayment.bind(this));
        this.event('click', '.js-billing-submit', this.onPlaceOrder.bind(this));
        this.event('click', '.js-add-coupon', this.onSubmitCoupon.bind(this));
        this.event('click', '.js-item-remove', this.onRemoveCoupon.bind(this));
        this.event('click', '.js-toggle-coupon-field', this.onToggleCouponInputField.bind(this));
        this.event('click', '.js-checkout-edit-bag', this.onEditBag.bind(this));
        this.event('click', '.js-edit-delivery', this.onEditDelivery.bind(this));

        eventMgr.registerAction('CheckoutSummary.update.summary', this.handleUpdateSummary.bind(this));
        eventMgr.registerAction('CheckoutSummary.handle.error.message', this.handleErrorMessage.bind(this));
        eventMgr.registerAction('CheckoutSummary.toggle.sticky.proceed.payment.button', this.handleToggleStickyButton.bind(this));

        if (layout.isSmallView() || layout.isMediumView()) {
            this.$el.one('click', '.js-view-all-items', this.onViewAllItems.bind(this));
        }
    }

    getCheckoutSummary() {
        return eventMgr.execute('get.checkout.summary');
    }

    updateSummary (summary) {
        this.$el.find('.js-checkout-summary-wrapper').empty().html(summary);
        return eventMgr.execute('components.update', this.$el);
    }

    handleUpdateSummary (isShippingFormVisible) {
        return new Promise((res) => {
            this.getCheckoutSummary().then((summary) => {
                return this.updateSummary(summary);
            }).then(() => {
                this.$el.fadeIn('fast');
                this.$el.find('.checkout-mini-cart .minishipment .header a').hide();
                this.$el.find('.order-totals-table .order-shipping .label a').hide();
                this.hideProceedToPaymentStikyButton(isShippingFormVisible);
                res();
            });
        });
    }

    hideProceedToPaymentStikyButton (isShippingFormVisible) {
        if (!isShippingFormVisible) {
            this.$el.find('.js-ordersummary-sticky .js-submit-checkout').addClass('is--hidden');
        }
    }

    handleErrorMessage (show) {
        return new Promise((res) => {
            if (show) {
                this.showErrorMessage();
            } else {
                this.clearErrorMessage();
            }
            res();
        });
    }

    onProceedToPayment (el, event) {
        event.preventDefault();
        eventMgr.execute('Shipping.submit.delivery.form');
    }

    onPlaceOrder (el, event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        $(document).find('.js-checkout-form .js-billing-submit').trigger('click');
    }

    onSubmitCoupon (el, event) {
        event.preventDefault();

        var $couponCode = this.$el.find('input[name$="_couponCode"]');
        var code = $couponCode.val();

        if (code.length === 0) {
            var $error = this.$el.find('.js-coupon-error');
            var $couponField = this.$el.find('.js-coupon-field');
            $error.html(Resources.COUPON_CODE_MISSING);
            $couponField.addClass('error');
            return;
        }

        var action = $(el).attr('name');
        this.handleCouponAction(action);
    }

    onViewAllItems (event) {
        event.preventDefault();
        $(event.currentTarget).hide();
        this.$el.find('.js-line-item-row').removeClass('is--hidden@md-and-down');
    }

    handleCouponAction (action) {
        if (this.checkingCoupon) {
            return;
        }
        this.checkingCoupon = true;
        var data = action + '=' + action;
        var params = {
            viewType: pageContext.ns,
            format: 'ajax',
            checkoutStep: this.checkoutStep
        };
        var url = util.getUrlToValidateCouponForPaymentCreditCard(Urls.submitCartForm, params);
        data += '&' + this.$el.find('.js-summary-wrapper').serialize();
        var isZeroCheckout = $('.js-zero-checkout').length;
        eventMgr.execute('check.if.coupon.is.applicable.for.credit.card', {
            url: url,
            cb: function() {
                this.checkingCoupon = false;
                var isCouponError = !!this.$el.find('.js-coupon-error').children().length;
                if (this.checkoutStep === '2') {
                    eventMgr.execute('Shipping.update.shipping.selector.after.coupon.submit', isCouponError);
                }
                if (isZeroCheckout) {
                    window.location.reload();
                } 
                if($('.js-coupon-field').hasClass('error')){
                    $('.js-coupon-field').removeClass('is--hidden')
                }
            }.bind(this),
            data: data
        });
    }

    onRemoveCoupon (el, event) {
        event.preventDefault();
        var action = $(el).attr('name');
        this.handleCouponAction(action);
    }

    onToggleCouponInputField () {
        this.$el.find('.js-coupon-field').toggleClass('is--hidden');
    }

    onKeyBoardSubmit (el, event) {
        event.preventDefault();
        if (event.which !== 13 || !$(el).val().length) {
            return;
        }

        var action = this.$el.find('.js-add-coupon').attr('name');
        this.handleCouponAction(action);
    }

    onEditBag (el, event) {
        event.preventDefault();
        if (this.checkoutStep === '2') {
            this.fromShippingSaveCustomerData();
        } else {
            this.fromBillingSaveCustomerData(Urls.cartShow);
        }
    }

    onEditDelivery (el, event) {
        event.preventDefault();
        if (this.checkoutStep === '2') {
            eventMgr.execute('Shipping.scroll.to.delivery.form');
        } else {
            this.fromBillingSaveCustomerData(Urls.shippingStart + '#shipping-form');
        }
    }

    saveCustomerData (params) {
        return eventMgr.execute('save.customer.data', params);
    }

    fromShippingSaveCustomerData () {
        if (!this.isCustomerLogged) {
            return page.redirect(Urls.cartShow);
        }

        return eventMgr.execute('Shipping.get.stored.delivery.locations.info')
            .then((storedDeliveryLocationsInfo) => {
                var {validToSaveData} = storedDeliveryLocationsInfo;
                var params = Object.assign({}, storedDeliveryLocationsInfo, {checkoutStep: this.checkoutStep});
                if (!validToSaveData) {
                    return;
                }
                return this.saveCustomerData(params);
            })
            .then(() => {
                page.redirect(Urls.cartShow);
            });
    }

    fromBillingSaveCustomerData (redirectTo) {
        if (!this.isCustomerLogged) {
            return page.redirect(redirectTo);
        }

        return eventMgr.execute('Billing.get.billing.address.info')
            .then((billingInfo) => {
                var params = Object.assign({}, billingInfo, {checkoutStep: this.checkoutStep});
                return this.saveCustomerData(params);
            }).then(() => {
                page.redirect(redirectTo);
            });
    }

    handleToggleStickyButton (hide) {
        return new Promise((res) => {
            this.$el.find('.js-ordersummary-sticky .js-submit-checkout').toggleClass('is--hidden', hide);
            res();
        });
    }
}