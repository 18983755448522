import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';
import shippingServices from '_core_ext/components/checkout/shipping/utils/shippingServices';

export default class Shipping extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.shipByRegionEnabled = SitePreferences.SHIP_BY_REGION_ENABLED;
        this.selectedRegionInfo = {};
        this.selectedShippingMethodInfo = {};
        this.shippingMethodsSelector = {};
        this.shippingRegionsSelector = {};
        this.shippingFormsContainer = {};
        this.checkoutSummary = {};
        this.isCustomerLogged = this.config.isCustomerLogged;
        this.deliveryAddressesData = {};

        shippingServices.init();

        this.getById('shippingMethodsSelector', (shippingMethodsSelector) => {
            this.shippingMethodsSelector = shippingMethodsSelector;
        });
        this.getById('shippingRegionsSelector', (shippingRegionsSelector) => {
            this.shippingRegionsSelector = shippingRegionsSelector;
        });
        this.getById('shippingFormsContainer', (shippingFormsContainer) => {
            this.shippingFormsContainer = shippingFormsContainer;
        });
        /* eslint-disable */
        this.onChild('shippingRegionsSelector', 'shippingRegionsSelector.on.region.change', this.handleRegionSelection.bind(this));
        this.onChild('shippingMethodsSelector', 'shippingMethodsSelector.on.shipping.method.selected', this.handleShippingMethodSelection.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.delivery.form.submit', this.handleDeliveryFormSubmit.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.home.address.selection', this.handleHomeAddressSelection.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.home.add.new.address', this.handleClearErrorMsgs.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.home.edited.address.save', this.handleHomeAddressSelection.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.access.point.selected', this.handlePickUpLocationSelected.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.store.selected', this.handlePickUpLocationSelected.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.change.pick.up.location', this.handleChangePickUpLocation.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.find.pick.up.locations', this.handleFindPickUpLocations.bind(this));
        this.onChild('shippingFormsContainer', 'shippingFormsContainer.on.find.pick.up.locations.current.location', this.handleFindPickUpLocationsCurrentLocation.bind(this));

        eventMgr.registerAction('Shipping.submit.delivery.form', this.handleDeliveryFormSubmitCheckoutSummary.bind(this));
        eventMgr.registerAction('Shipping.update.shipping.selector.after.coupon.submit', this.updateShippingSelectorAfterCouponSubmit.bind(this));
        eventMgr.registerAction('Shipping.scroll.to.delivery.form', this.handleScrollToDeliveryForm.bind(this));
        eventMgr.registerAction('Shipping.get.stored.delivery.locations.info', this.getStoredDeliveryLocationsInfo.bind(this));
        /* eslint-enable */
        this.setSelectedShippingMethodInfo(this.shippingMethodsSelector.getCurrentSelectedShippingMethodInfo());
        this.setDeliveryAddressesData(this.selectedShippingMethodInfo.shippingMethodDeliveryOption,
            this.shippingFormsContainer.getDeliveryAddressesData());

        if (this.shipByRegionEnabled) {
            this.setSelectedRegionInfo(this.shippingRegionsSelector.getCurrentSelectedRegionInfo());
            this.shippingFormsContainer
                .changeHeader(this.selectedShippingMethodInfo, this.selectedRegionInfo, this.shipByRegionEnabled);
        } else {
            this.shippingFormsContainer
                .changeHeader(this.selectedShippingMethodInfo, this.selectedRegionInfo, this.shipByRegionEnabled);
        }

        if (this.isCustomerLogged) {
            this.validateShippingMethodAvailability();
        }

        // Initialize the new checkout guest login functionality
        this.newCheckoutGuestLogin();
        this.guestLogin();
}

    setSelectedRegionInfo (regionInfo) {
        this.selectedRegionInfo = regionInfo;
    }

    setSelectedShippingMethodInfo (shippingMethodInfo) {
        this.selectedShippingMethodInfo = shippingMethodInfo;
    }

    handleShippingMethodSelection (shippingMethodInfo) {
        var currentShippingMethodId = this.selectedShippingMethodInfo.shippingMethodId;
        var isCurrentShippingMethodSelected = currentShippingMethodId === shippingMethodInfo.shippingMethodId;
        if (isCurrentShippingMethodSelected) {
            return;
        }
        var deliveryOptionBeforeChange = this.selectedShippingMethodInfo.shippingMethodDeliveryOption;
        this.setSelectedShippingMethodInfo(shippingMethodInfo);
        var regionId = this.shipByRegionEnabled ? this.selectedRegionInfo.regionId : null;
        var deliveryAddressInfo = this.shippingFormsContainer.getDeliveryAddressInfo();
        var shopperInfo = this.shippingFormsContainer.getShopperInfo();
        var isDeliveryFormActive = this.shippingFormsContainer.isDeliveryFormActive();

        this.setDeliveryAddressesData(deliveryOptionBeforeChange, this.shippingFormsContainer.getDeliveryAddressesData());

        this.shippingMethodsSelector
            .selectShippingMethod(this.selectedShippingMethodInfo, regionId, deliveryAddressInfo)
            .then(() => {
                return eventMgr.execute('CheckoutSummary.update.summary', isDeliveryFormActive);
            })
            .then(() => {
                this.handleClearErrorMsgs();
                return this.shippingFormsContainer
                    .handleShippingFormChange(this.selectedShippingMethodInfo, this.selectedRegionInfo, this.shipByRegionEnabled);
            })
            .then(() => {
                if (this.shipByRegionEnabled) {
                    this.shippingRegionsSelector.hideUnselectedRegions();
                }
                var selectedDeliveryOption = this.selectedShippingMethodInfo.shippingMethodDeliveryOption;
                var deliveryAddressInfo = this.deliveryAddressesData[selectedDeliveryOption]
                    ? this.deliveryAddressesData[selectedDeliveryOption] : {};
                this.shippingFormsContainer
                    .updateDeliveryForm(deliveryAddressInfo, shopperInfo, this.isCustomerLogged);
                this.validateShippingMethodAvailability();
                // eslint-disable-next-line max-len
                var savedAddressLength = $('.c-checkout__content .shippingMethodSelector .c-shipping-address__list').find('address.js-personal-data').length;
                if (savedAddressLength === 0) {
                    // eslint-disable-next-line max-len
                    $('.c-checkout__content').find('.shippingMethodSelector.deliveryAddress').css({'padding': '0', 'margin': '0'});
                }
            });
    }

        // Function for handling guest login during checkout
        newCheckoutGuestLogin() {
            $(document).on('click', '.checkout-login a', function (e) {
    
                $(".checkout-login-form").removeClass("d-none");
                if((".checkout-login-form:visible").length) {
                    $(".addressForm").addClass("d-none");
                    $(".checkout-login").addClass("d-none");
                    $(".c-order-summary__button").attr('disabled', true);
                }else {
                    $(".addressForm").removeClass("d-none");
                    $(".checkout-login").removeClass("d-none");
                    $(".c-order-summary__button").removeAttr('disabled');
                }
            });
            $(document).on('click', '.password .show-icon', function (e) {
                var $input = $(this).parents(".form-row_field").find("input[type='password']")
                if ($input.attr("type") == "password") {
                    $input.attr("type", "text");
                } 
                $input.siblings('.show-icon').hide();
                $input.siblings('.hide-icon').show();
            });
            $(document).on('click', '.password .hide-icon', function (e) {
                var $input = $(this).parents(".form-row_field").find("input[type='text']");
                if ($input.attr("type") == "text") {
                    $input.attr("type", "password");
                } 
                $input.siblings('.show-icon').show();
                $input.siblings('.hide-icon').hide();
            });
        }
        guestLogin(){
            $(document).on('click', '.link-quick-buy-guest', function (e) {
                e.preventDefault();
                var url = new URL(window.location)
                url.search = ''
                window.location.href = url.toString();
        })
        }

    handleRegionSelection (regionInfo) {
        var isCurrentRegionSelected = this.selectedRegionInfo.regionId === regionInfo.regionId;
        if (isCurrentRegionSelected) {
            return;
        }
        var shopperInfo = this.shippingFormsContainer.getShopperInfo();
        this.setSelectedRegionInfo(regionInfo);
        this.shippingMethodsSelector.handleShippingMethodsUpdate(this.selectedRegionInfo.regionId)
            .then((newSelectedShippingMethodInfo) => {
                this.setSelectedShippingMethodInfo(newSelectedShippingMethodInfo);
                this.handleClearErrorMsgs();
                return this.shippingFormsContainer
                    .handleShippingFormChange(this.selectedShippingMethodInfo, this.selectedRegionInfo, this.shipByRegionEnabled);
            })
            .then(() => {
                this.clearDeliveryAddressesData();
                var selectedDeliveryOption = this.selectedShippingMethodInfo.shippingMethodDeliveryOption;
                var deliveryAddressInfo = this.deliveryAddressesData[selectedDeliveryOption]
                    ? this.deliveryAddressesData[selectedDeliveryOption] : {};
                this.shippingFormsContainer
                    .updateDeliveryForm(deliveryAddressInfo, shopperInfo, this.isCustomerLogged);
                this.validateShippingMethodAvailability();
                // eslint-disable-next-line max-len
                var savedAddressLength = $('.c-checkout__content .shippingMethodSelector .c-shipping-address__list').find('address.js-personal-data').length;
                if (savedAddressLength === 0) {
                    // eslint-disable-next-line max-len
                    $('.c-checkout__content').find('.shippingMethodSelector.deliveryAddress').css({'padding': '0', 'margin': '0'});
                }
            });
    }

    handleDeliveryFormSubmit (deliveryAddressInfo, deliveryOption) {
        this.validateShippingMethodAvailability(deliveryAddressInfo, deliveryOption)
            .then((isShippingMethodValid) => {
                if (!isShippingMethodValid) {
                    return;
                }
                this.shippingFormsContainer.submitDeliveryForm(this.selectedShippingMethodInfo.shippingMethodId);
            });
    }

    validateShippingMethodAvailability (deliveryAddressInfo, deliveryOption) {
        deliveryAddressInfo = deliveryAddressInfo || this.shippingFormsContainer.getDeliveryAddressInfo();
        deliveryOption = deliveryOption || this.selectedShippingMethodInfo.shippingMethodDeliveryOption;
        var regionId = this.shipByRegionEnabled ? this.selectedRegionInfo.regionId : null;
        var shippingMethodId = this.selectedShippingMethodInfo.shippingMethodId;
        var storeId = deliveryOption === 'IN_STORE' ? this.shippingFormsContainer.getSelectedStoreId() : '';

        return this.shippingMethodsSelector
            .validateSelectedShippingMethod(deliveryAddressInfo, deliveryOption, regionId, storeId, shippingMethodId)
            .then((isShippingMethodValid) => {
                if (!isShippingMethodValid) {
                    this.handleShowErrorMsgs();
                } else {
                    this.handleClearErrorMsgs();
                }

                return isShippingMethodValid;
            });
    }

    handleDeliveryFormSubmitCheckoutSummary () {
        return new Promise((res) => {
            if (this.shippingFormsContainer.beforeSubmitCheck()) {
                this.handleDeliveryFormSubmit();
            }
            res();
        });
    }

    updateShippingSelectorAfterCouponSubmit (isCouponError) {
        var regionId = this.shipByRegionEnabled ? this.selectedRegionInfo.regionId : null;
        var deliveryAddressInfo = this.shippingFormsContainer.getDeliveryAddressInfo();
        var deliveryOption = this.selectedShippingMethodInfo.shippingMethodDeliveryOption;
        return new Promise((res) => {
            this.shippingMethodsSelector
                .updateShippingMethodsAfterCouponSubmit(deliveryAddressInfo, deliveryOption, regionId, isCouponError);
            res();
        });
    }

    handleScrollToDeliveryForm () {
        return new Promise((res) => {
            this.shippingFormsContainer.scrollToForm();
            res();
        });
    }

    handleClearErrorMsgs () {
        eventMgr.execute('CheckoutSummary.handle.error.message', false);
        this.shippingFormsContainer.clearErrorMessage();
    }

    handleShowErrorMsgs () {
        eventMgr.execute('CheckoutSummary.handle.error.message', true);
        this.shippingFormsContainer.showErrorMessage();
    }

    handleHomeAddressSelection (deliveryAddressInfo, selectedStoredAddessId) {
        this.setDeliveryAddressesData('HOME', {
            'addressId': selectedStoredAddessId
        });
        this.validateShippingMethodAvailability(deliveryAddressInfo);
    }

    getStoredDeliveryLocationsInfo () {
        return new Promise((res) => {
            var deliveryOption = this.selectedShippingMethodInfo.shippingMethodDeliveryOption;
            var addressId = ('HOME' in this.deliveryAddressesData) && this.deliveryAddressesData.HOME.addressId;
            var storeId = ('IN_STORE' in this.deliveryAddressesData) && this.deliveryAddressesData.IN_STORE.locationId;
            var validToSaveData = !!(deliveryOption === 'HOME' && addressId) || !!(deliveryOption === 'IN_STORE' && storeId);
            res({
                deliveryOption: deliveryOption,
                addressId: addressId || '',
                storeId: storeId || '',
                validToSaveData: validToSaveData
            });
        });
    }

    handlePickUpLocationSelected (deliveryOption, pickUpLocationData) {
        this.handleClearErrorMsgs();
        this.setDeliveryAddressesData(deliveryOption, pickUpLocationData);
        eventMgr.execute('CheckoutSummary.toggle.sticky.proceed.payment.button', false);
    }

    handleChangePickUpLocation () {
        eventMgr.execute('CheckoutSummary.toggle.sticky.proceed.payment.button', true);
    }

    handleFindPickUpLocations () {
        this.shippingFormsContainer.findPickUpLocations(this.selectedShippingMethodInfo.shippingMethodId);
    }

    handleFindPickUpLocationsCurrentLocation () {
        this.shippingFormsContainer.findPickUpLocationsCurrentLocation(this.selectedShippingMethodInfo.shippingMethodId);
    }

    clearDeliveryAddressesData() {
        for (var deliveryOption in this.deliveryAddressesData) {
            if (!this.isCustomerLogged && deliveryOption === 'HOME') {
                continue;
            }
            for (var field in this.deliveryAddressesData[deliveryOption]) {
                this.deliveryAddressesData[deliveryOption][field] = '';
            }
        }
    }

    setDeliveryAddressesData(deliveryOption, deliveryData) {
        var fieldValue;
        var field;
        for (field in deliveryData) {
            fieldValue = deliveryData[field];
            if (typeof fieldValue === 'object' && fieldValue !== null) {
                if (!Object.keys(fieldValue).length) {
                    return;
                }
            } else if (!fieldValue) {
                return;
            }
        }

        for (field in deliveryData) {
            if (!(deliveryOption in this.deliveryAddressesData)) {
                this.deliveryAddressesData[deliveryOption] = {};
            }
            this.deliveryAddressesData[deliveryOption][field] = deliveryData[field];
        }
    }
}