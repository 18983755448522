import Block from '_core_ext/core/common/Block';
export default class ShippingRegionsSelector extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.$expandRegionsButton = this.$el.find('.js-region-selector-expand-btn');
        this.currentRegionId = this.$el.find('input[name="selected_region"]:checked').val();
        this.currentRegionInfo = {};

        this.eachChild((shippingRegion) => {
            shippingRegion.on('shippingRegion.selected', this.handleRegionChange.bind(this));
        });

        this.getById(`region-${this.currentRegionId}`, (region) => {
            this.setRegion(region.getRegionInfo());
        });
    }

    handleRegionChange (regionInfo, $selectedRegion) {
        this.setRegion(regionInfo);
        this.selectRegion($selectedRegion);
        this.hideUnselectedRegions();
        this.emitter.emit('shippingRegionsSelector.on.region.change', regionInfo);
    }

    setRegion (regionInfo) {
        var {regionId} = regionInfo;
        this.currentregionId = regionId;
        this.currentRegionInfo = regionInfo;
    }

    selectRegion ($selectedRegion) {
        this.$el.find('input[name="selected_region"]:checked').prop('checked', '');
        $selectedRegion.find('input').prop('checked', 'checked');
    }


    hideUnselectedRegions () {
        if (!this.$expandRegionsButton.find('span').hasClass('js-show-regions')) {
            return;
        }

        this.$el.find('.js-region-selector-list').removeClass('expanded');
        this.$expandRegionsButton.find('span').removeClass('icon-small-arrow-up js-show-regions')
            .addClass('icon-small-arrow-down');
        this.$el.find('input[name="selected_region"]:unchecked').parents('.js-region-selector__item')
            .addClass('is--hidden');
    }

    showUnselectedRegions () {
        if (this.$expandRegionsButton.find('span').hasClass('js-show-regions')) {
            return;
        }

        this.$el.find('.js-region-selector-list').addClass('expanded');
        this.$expandRegionsButton.find('span').removeClass('icon-small-arrow-down')
            .addClass('icon-small-arrow-up js-show-regions');
        this.$el.find('input[name="selected_region"]:unchecked').parents('.js-region-selector__item')
            .removeClass('is--hidden');
    }

    handleShownRegions() {
        var $notSelectedVisibleRegions = this.$el.find('input[name="selected_region"]:unchecked:visible');

        if ($notSelectedVisibleRegions.length) {
            this.hideUnselectedRegions();
        } else {
            this.showUnselectedRegions();
        }
    }

    getCurrentSelectedRegionInfo () {
        return this.currentRegionInfo;
    }
}