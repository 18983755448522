'use strict';
/* eslint-disable */
var ajax = require('./../../ajax'),
    util = require('./../../util'),
    page = require('./../../page'),
    cookie = require('./../../cookie'),
    progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
    $cache = {},
    cssConstants = {
        HIDDEN_CLASS: 'is--hidden',
        IGNORE_CLASS: 'js-ignore'
    },
    checkingCoupon = false;

// ADYEN v22.1.0 ADDITIONAL CODE START
var adyenCheckout = require('./../../adyen-checkout');


/**
* @function
* @description Changes the payment type or issuerId of the selected payment method
* @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
*/
function updatePaymentType(selectedPayType, issuerType) {
    if(issuerType){
      $('#dwfrm_adyPaydata_issuer').val(selectedPayType);
    }
    else{
      $('input[name="brandCode"]').removeAttr('checked');
      $('input[value=' + selectedPayType + ']').prop('checked', 'checked');
    }
    // if the payment type has hidden fields reveal it
    $('#component_' + selectedPayType).show();
    formPrepare.validateForm();
    }
// ADYEN v22.1.0 ADDITIONAL CODE END
    
/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    var $creditCard = $('[data-method="CREDIT_CARD"]');
    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger('change');
    $creditCard.find('select[name$="_type"]').val(data.type).trigger('change');
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger('change');
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger('change');
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger('change');
    $creditCard.find('input[name$="_cvn"]').val('').trigger('change');

    // ADYEN v19.2.2 ADDITIONAL CODE START
    $creditCard.find('[name$="creditCard_selectedCardID"]').val(data.selectedCardID).trigger('change');
    // ADYEN v19.2.2 ADDITIONAL CODE END
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, 'creditCardUUID', cardID);
    ajax.getJson({
        url: url,
        callback: function (data) {
            if (!data) {
            	page.redirect(Urls.showCart);
                return;
            }
            setCCFields(data);
        }
    });
}

function initializeDOM() {
    $('.js-applepay-button-shield').addClass('active');

    $(document).ready(function () {
        // Enable a custom date-picker for browsers not supported type="date" e.x. desktop Safari
        if ( $('[type="date"]').prop('type') !== 'date' ) {
            $('[type="date"]')
                .datepicker({ dateFormat: "yy-mm-dd"})
                .attr("placeholder", "yyyy-mm-dd");
        }
    });
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 */
function updatePaymentMethod(paymentMethodID, existedPaymentMethods) {
    /* @TODO: Refactor
     *
     * We need to refactor this method to make manipulations with the submit button depending on with payment method is selected more flexible.
     * Also, if no selected payment method found, we are trying to get the DOM element by [data-method="Custom"] which does not exist.
     */
    var $paymentMethods = $('.payment-method');
    var $selectedPaymentMethod = $paymentMethods.filter('[data-method="' + paymentMethodID + '"]');
    $cache.selectedPaymentMethod = paymentMethodID;

    $paymentMethods.removeClass('payment-method-expanded').addClass(cssConstants.IGNORE_CLASS);

    if ($selectedPaymentMethod.length === 0) {
        $selectedPaymentMethod = $('[data-method="Custom"]');
    }
    $selectedPaymentMethod.addClass('payment-method-expanded').removeClass(cssConstants.IGNORE_CLASS);

    //Remove 'disabled' class from submit button in case when payment isn't credit card
    if (paymentMethodID !== 'CREDIT_CARD' || !existedPaymentMethods) {
        $('.js-applepay-button-shield').removeClass('active');
    }

    if (paymentMethodID === 'DW_APPLE_PAY') {
        $('.js-billing-button-wrapper').addClass('is--hidden');
        $('.js-applepay-button-wrapper').removeClass('is--hidden');
    } else {
        $('.js-billing-button-wrapper').removeClass('is--hidden');
        $('.js-applepay-button-wrapper').addClass('is--hidden');
    }

    // ensure checkbox of payment method is checked
    $('input[name$="_selectedPaymentMethodID"]').removeAttr('checked');
    $('input[value=' + paymentMethodID + ']').prop('checked', 'checked');

    var placeBtnText = $selectedPaymentMethod.data('placebtnText');
    if ((paymentMethodID === 'AdyenComponent' || paymentMethodID === 'Klarna') && $('input[name="brandCode"]').closest('.js-pay-type-container').length) {
        placeBtnText = $('input[name="brandCode"]').closest('.js-pay-type-container').data('placebtnText');
    }

    $('.js-billing-submit span').text(placeBtnText);
    $('.js-billing-submit').toggleClass('js-paypal-sbmt', paymentMethodID === 'PayPal');
}

// ADYEN v19.2.2 ADDITIONAL CODE START
/**
* @function
* @description Changes the payment type or issuerId of the selected payment method
* @param {String, Boolean} value of payment type or issuerId and a test value to see which one it is, to which the payment type or issuerId should be changed to
*/
// function updatePaymentType(selectedPayType, issuerType) {
//     if (issuerType) {
//         $('[name="issuer"]').val(selectedPayType);
//     } else {
//         $('input[name="brandCode"]').removeAttr('checked');
//         $('input[name="brandCode"]#' + selectedPayType).prop('checked', 'checked');
//     }
// }
// ADYEN v19.2.2 ADDITIONAL CODE END

// Check the validity of Adyen checkout components [IDEAL, KLARNA, AFTERPAY, RATEPAY] (method based on adyen-checkout.js)
function componentDetailsValid(selectedMethod){
    //set data from components
    switch(selectedMethod) {
        case "ideal":
            var idealComponent = AdyenWebComponents['idealComponent'];
            if (idealComponent.componentRef.state.isValid) {
                $('#dwfrm_adyPaydata_issuer').val(idealComponent.componentRef.state.data.issuer);
            }
            return idealComponent.componentRef.state.isValid;
            break;
        case "klarna":
            var klarnaComponent = AdyenWebComponents['klarnaComponent'];
            if (klarnaComponent) {
                if (klarnaComponent.componentRef.state.isValid) {
                    adyenCheckout.parseOpenInvoiceComponentData(klarnaComponent.componentRef.state);
                    if($('#ssnValue')){
                        $('#dwfrm_adyPaydata_socialSecurityNumber').val($('#ssnValue').val());
                    }
                }
                return klarnaComponent.componentRef.state.isValid;
            } else {
                //New Klarna integration is without component
                return true;
            }
            break;
        case "afterpay_default":
            var afterpayComponent = AdyenWebComponents['afterpayComponent'];
            if (afterpayComponent.componentRef.state.isValid) {
                adyenCheckout.parseOpenInvoiceComponentData(afterpayComponent.componentRef.state);
            }
            return afterpayComponent.componentRef.state.isValid;
            break;
        case "ratepay":
            $('#dwfrm_adyPaydata_dateOfBirth').val($("#ratepay_dob").val());
            $('#dwfrm_adyPaydata_gender').val($("#ratepay_gender").val());
            return true;
        case "dotpay":
            var dotpayComponent = AdyenWebComponents['dotpayComponent'];
            if (!dotpayComponent) {
                return true;
            }
            if (dotpayComponent.componentRef.state.isValid) {
                $('#dwfrm_adyPaydata_issuer').val(dotpayComponent.componentRef.state.data.issuer);
            }
            return dotpayComponent.componentRef.state.isValid;
            break;
        case "giropay":
            var giropayComponent = AdyenWebComponents['giropayComponent'];
            if (!giropayComponent) {
                return true;
            }
            if (giropayComponent.componentRef.state.isValid) {
                $('#dwfrm_adyPaydata_bic').val(giropayComponent.componentRef.state.data.bic);
            }
            return giropayComponent.componentRef.state.isValid;
            break;
        default:
            return true;
    }
}

// Check the validity of Adyen checkout components (method based on adyen-checkout.js)
function adyenBillingValidation(paymentMethod) {
    if (paymentMethod == 'CREDIT_CARD') {

        if (!window.CardValid) {
            if ($cache.isOneClick) {
                window.AdyenOneClick.showValidation();
            } else {
                window.AdyenCard.showValidation();
            }
            util.scrollTo($('.js-payment-form-wrp').offset().top - 100, 600);
            return false;
        }
        adyenCheckout.clearCardData();
        var oneClickCard = window.AdyenOneClick;
        if ($cache.isOneClick) {
            $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID').val($('#adyenCreditCardList option:selected').attr('id'));
            $('#dwfrm_billing_paymentMethods_creditCard_type').val($('#adyenCreditCardList option:selected').val());
            $('#dwfrm_billing_paymentMethods_creditCard_adyenEncryptedSecurityCode').val(oneClickCard.state.data.encryptedSecurityCode);
        } else {
            $('#dwfrm_billing_paymentMethods_creditCard_selectedCardID').val("");
            adyenCheckout.copyCardData(window.AdyenCard);
        }
    } else if (paymentMethod == 'Adyen' || paymentMethod == 'Klarna'){
        var selectedMethod = $('[name="brandCode"]:checked');
        var componentIsValid = componentDetailsValid(selectedMethod.val());
        if (!componentIsValid) {
            util.scrollTo(selectedMethod.offset().top - 100, 600);
        }
        return componentIsValid;
    }

    $('.form-data-error').html('');

    return true;
}

function checkForm(e) {
    var isZeroCheckOut = !!$cache.document.find('.js-zero-checkout').length;
    if ((
        SitePreferences.ADYEN_SF_ENABLED &&
        $cache.selectedPaymentMethod === 'CREDIT_CARD' ||
        $cache.selectedPaymentMethod === 'AdyenComponent' ||
        $cache.selectedPaymentMethod === 'Klarna')
        && !isZeroCheckOut
    ) {
        if(!adyenBillingValidation($cache.selectedPaymentMethod)) {
            return false;
        }
    }

    if (!$cache.checkoutForm.validate().form()) {
        if ($cache.addressFields.is(':hidden')) {
            $('.js-global-accordion-header').trigger('accordion.open', 0);
        }
        let $invalidAddressFields = $cache.checkoutForm.find('.js-address-form:not(.' + cssConstants.IGNORE_CLASS + ') span.error');
        if ($invalidAddressFields.length) {
            let $addrForm = $invalidAddressFields.closest('.js-address-form.is--hidden');
            $addrForm.removeClass('is--hidden');
            $cache.document.trigger('showInvalidFields', [$addrForm]);
        }

        if (e) {
            e.preventDefault();
        }

        return false;
    }

    return true;
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.sameAsShippingToggle = $cache.document.find('.js-shipping-address-toggle');
    $cache.existingShippingAddress = $cache.document.find('.js-existing-shipping-address');
    $cache.selectedAddress = $cache.document.find('.js-selected-address-wrapper');
    $cache.billingAddressForm = $cache.document.find('.js-address-form:not(.js-invoice-form)');
    $cache.addressFields = $cache.document.find('.js-address-fields');
    $cache.checkoutForm = $cache.document.find('.js-checkout-form');
    $cache.addressList = $cache.document.find('.js-checkout-address-list');
    $cache.addNewAddress = $cache.document.find('.js-add-new-address');
    $cache.billingSubmit = $cache.checkoutForm.find('.js-billing-submit');
    $cache.storePickupData = $('[data-store-address]').data('storeAddress');
    // set default payment method to 'CREDIT_CARD' if not selected
    $cache.selectedPaymentMethod = 'CREDIT_CARD';
    $cache.isOneClick = false;
    $cache.payType = $cache.document.find('[name="brandCode"]');
    $cache.issuer = $cache.document.find('.issuer');
}

function fillFormWithAddress(selectedAddress) {
    if (!selectedAddress) {
        return;
    }
    util.fillAddressFields(selectedAddress, $cache.billingAddressForm);
}

/**
 * Switch between two modes: new address form or collapsed block with selected shipping address
 * @param {Boolean} show
 */
function toggleNewAddressForm(show) {
    if (show) {
        $cache.billingAddressForm.find('input[type="text"], input[type="tel"]').val('');
        $cache.billingAddressForm.find('select').val('');
        $cache.billingAddressForm.find('select').selectric('refresh');
        $cache.billingAddressForm.find('select[name$="_country"]').trigger('change', [$cache.billingAddressForm, '']);
    } else {
        fillFormWithAddress($cache.existingShippingAddress.data('address'));
    }
    $cache.selectedAddress.toggleClass(cssConstants.HIDDEN_CLASS, show);
    $cache.billingAddressForm.toggleClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS, !show);
    $cache.addNewAddress.toggleClass(cssConstants.HIDDEN_CLASS, show);
}

function resetAdyenPaymentForm() {
    $('#creditCard_owner').removeAttr("disabled").val('');
    $('#dwfrm_billing_paymentMethods_creditCard_type').removeAttr("disabled").val($("#dwfrm_billing_paymentMethods_creditCard_type option:first").val());
    $('#creditCard_number').removeAttr("disabled").val('');
    $('#creditCard_expiration_month').val('').selectric('refresh');
    $('#creditCard_expiration_year').val('').selectric('refresh');
    $('#creditCard_cvn').val('');
    $('#dwfrm_billing_paymentMethods_creditCard_encrypteddata').val('');
    // show the save card input and label because it is a new card
    $('#dwfrm_billing_paymentMethods_creditCard_saveCard').show();
    $('label[for="dwfrm_billing_paymentMethods_creditCard_saveCard"]').show();
    $cache.document.find('.js-payment-method-image').removeClass().addClass('js-payment-method-image c-creditcard__type c-creditcard__input-type');
    $cache.document.trigger('selectric.reinit', [$('.js-payment-form')]);
    handlePlaceOrderButtonState();
    util.scrollTo($('.js-payment-form-wrp').offset().top - 100, 600);
}

function handlePlaceOrderButtonState() {
    var isFormClosed = $cache.billingAddressForm.hasClass(cssConstants.HIDDEN_CLASS);
    var $allRequiredPaymentFields = $('.js-payment-form-wrp').find('input.required:not(.js-creditcard-type):visible, select.required:visible');
    var $allRequiredAddressFields = $('.js-address-form:not(.js-invoice-form)').find(!isFormClosed ? 'input.required, select.required' : 'input.required:visible, select.required:visible');
    var isBillingAddressUsed = $cache.document.find('.js-billing-element:visible').length;
    var isEmptyPaymentFields = !!$allRequiredPaymentFields.filter((index, item) => { return !item.value; }).length;
    var isEmptyAddressFields = !!$allRequiredAddressFields.filter((index, item) => { return !item.value; }).length;
    var isCreditCardComponentValid = true;

    if (SitePreferences.ADYEN_SF_ENABLED && $cache.selectedPaymentMethod === 'CREDIT_CARD') {
        isCreditCardComponentValid = window.CardValid;
    }

    if (!isBillingAddressUsed || (!isEmptyPaymentFields && !isEmptyAddressFields && isCreditCardComponentValid && checkForm())) {
        $('.js-applepay-button-shield').removeClass('active');
    } else {
        $('.js-applepay-button-shield').addClass('active');
    }
}

function cleanBillingForm() {
    $('[id$="billingAddress_addressFields_postal"]').val('');
    $('#checkout-input-firstname').val('');
    $('#checkout-input-lastname').val('');
    $('[id$="billingAddress_addressFields_address1"]').val('');
    $('[id$="billingAddress_addressFields_address2"]').val('');
    $('[id$="billingAddress_addressFields_city"]').val('');
    $('#checkout-input-phone').val('');
    $('[id$="billingAddress_addressFields_states_state"]').val('');
    $('[id$="billingAddress_addressFields_country"]').val('');
    $('[id$="billingAddress_addressFields_phone"]').val('');
}

function preventSubmitDuplicate(e) {
	var $submitButton = $('.js-billing-submit');
    if ($submitButton.hasClass('is--clicked')) {
        e.preventDefault();
        return false;
    } else {
        $submitButton.addClass('is--clicked');
    }
    return true;
}

function prefillBillingAddressFromStore() {
    if ($cache.storePickupData) {
        $cache.billingAddressForm.find('[name$="_country"]').val($cache.storePickupData.country).trigger('change');
        $cache.billingAddressForm.find('[name$="_state"]').val($cache.storePickupData.stateCode).selectric('refresh');
        $cache.billingAddressForm.find('[name$="_title"]').val($cache.storePickupData.titleCode);
        $cache.billingAddressForm.find('[name$="_city"]').val($cache.storePickupData.city.substr(0, 40));
        $cache.billingAddressForm.find('[name$="_address1"]').val(unescape($cache.storePickupData.address1.substr(0, 60)));
        $cache.billingAddressForm.find('[name$="_address2"]').val(unescape($cache.storePickupData.address2.substr(0, 40)));
        $cache.billingAddressForm.find('[name$="_postal"]').val($cache.storePickupData.postal.substr(0, 10));
        $cache.billingAddressForm.find('[name$="_phone"]').val($cache.storePickupData.phone.substr(0, 30));
        $cache.billingAddressForm.find('[name$="_firstName"]').val(unescape($cache.storePickupData.firstName.substr(0, 40)));
        $cache.billingAddressForm.find('[name$="_lastName"]').val(unescape($cache.storePickupData.lastName.substr(0, 40)));
        $cache.billingAddressForm.validate();
    }
}

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
function initializeEvents() {
    var $selectPaymentMethod = $('.payment-method-options');
    var selectedPaymentMethod = $selectPaymentMethod.find('.js-payment-method:checked').val();
    var $saveCardWrapper = $cache.document.find('.js-save-card');
    var $allRequiredPaymentFields = $('.js-payment-form-wrp').find('input.required, select.required');
    var $allRequiredBillingAddressFields = $('.js-address-form').find('input.required, select.required:not([name$="_country"])');
    var $acceptPolicies = $('.js-accept-policies').find('input.required.input-checkbox');
    var $payType = $('[name="brandCode"]');
    var $issuer = $('.issuer');
    var selectedPayType = $payType.find(':checked').val();
    selectedPaymentMethod = selectedPaymentMethod ? selectedPaymentMethod : $cache.selectedPaymentMethod;

    // ADYEN v19.2.2 ADDITIONAL CODE START
    var selectedPayType = $cache.payType.find(':checked').val();
    // ADYEN v19.2.2 ADDITIONAL CODE END

    updatePaymentMethod(selectedPaymentMethod, $selectPaymentMethod.length);

    if (!$saveCardWrapper.data('creditCardExists')) {
        $saveCardWrapper.removeClass('is--hidden');
    }

    if (cookie.get('isReturnedFromExternalPaymentPage') === 'true') {
        window.history.pushState('forward', null, '#forward');
        window.history.back();
        $cache.window.on('popstate', function() {
            if (window.location.hash.indexOf('#forward') > -1) {
                window.history.back();
                $cache.billingSubmit.trigger('click');
            }
        });
    }

    $('.js-payment-form-wrp').on('adyenWebComponentChange', handlePlaceOrderButtonState);
    $('.js-payment-form-wrp').on('adyenCardComponentOnLoad', function () {
        progress.hide('.js-payment-form-wrp');
    });
    $allRequiredPaymentFields.on('change', handlePlaceOrderButtonState);
    $allRequiredBillingAddressFields.on('change', util.debounce(handlePlaceOrderButtonState, 400));
    $acceptPolicies.on('change', handlePlaceOrderButtonState);
    $cache.checkoutForm.on('submit', function(e) {
        var cardHolderName = $(".adyen-checkout__card__holderName"); 
        var cardHolderNameInput = $(".adyen-checkout__card__holderName__input");
        if(($cache.selectedPaymentMethod === 'AdyenComponent') && $(".paymentMethod input[type='radio']:checked").attr("id") === 'rb_scheme'){
            if(cardHolderName.length > 0) { 
                if(!cardHolderNameInput.val().length){
                    cardHolderNameInput.prop('required',true);
                    return false;
                }  
            }
        } else {
            cardHolderNameInput.prop('required',false);
        }
        if (checkForm(e)) {
            progress.show();
            preventSubmitDuplicate(e);
        } else {
            return false;
        }
    });

    $cache.checkoutForm.on('change', '.js-cc-month', function() {
        $('.js-cc-month-hidden').val($(this).val());
        //simulate action for firing validation rules
        $('.js-creditcard-date').focus().blur();
    });

    $cache.checkoutForm.on('change', '.js-cc-year', function() {
        $('.js-cc-year-hidden').val($(this).val());
        //simulate action for firing validation rules
        $('.js-creditcard-date').focus().blur();
    });

    $selectPaymentMethod.on('click', '.js-payment-method', function () {
        $cache.issuer.addClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
        $('.js-hpp-issuer').val("");

        updatePaymentMethod($(this).val());

        // ADYEN v22.1.0 ADDITIONAL CODE START
        if ($(this).val() == 'Adyen' && $payType.length > 0) {
            // set payment type of Adyen to the first one
            updatePaymentType((selectedPayType) ? selectedPayType : $payType[0].value, false);
         } else {
            $payType.removeAttr('checked');
         }
        // ADYEN v22.1.0 ADDITIONAL CODE END

        $cache.document.find('.js-billing-element').show();
    });

    // ADYEN v22.1.0 ADDITIONAL CODE START
    $cache.issuer.on('change', function () {
        updatePaymentType($(this).val(), true);
    });
    // ADYEN v22.1.0 ADDITIONAL CODE END

    // select credit card from list
    $('#creditCardList').on('change', function () {
        var cardUUID = $(this).val();
        if (!cardUUID) { return; }
        populateCreditCardForm(cardUUID);

        // remove server side error
        $('.required.error').removeClass('error');
        $('.error-message').remove();
    });

    // ADYEN v19.2.2 ADDITIONAL CODE START
    if (SitePreferences.ADYEN_SF_ENABLED) {
        adyenCheckout.initBilling();
    }

    $cache.payType.on('change', function() {
        const $this = $(this);
        const methodType = $this.data('methodType') || 'Adyen';
        let $currentPayTypeIssuers = $this.closest('.js-pay-type-container').find(".js-issuers-container");
        $('.js-hpp-issuer').val("");
        $cache.issuer.addClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
        $('.checkoutComponent').addClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
        $('#component_' + $this.val()).removeClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS).show();
        switch ($this.val()) {
            case 'klarna':
            case 'afterpay_default':
            case 'ratepay':
                $('.js-openInvoiceFields').removeClass(cssConstants.IGNORE_CLASS);
                break;
            default:
                $('.js-openInvoiceFields').addClass(cssConstants.IGNORE_CLASS);
                break;
        }

        if ($currentPayTypeIssuers.length > 0) {
            $('.js-hpp-issuer').val($currentPayTypeIssuers.val());
            $currentPayTypeIssuers.removeClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
        }

        updatePaymentMethod(methodType);
        $('.payment-method-options .js-payment-method#is-' + methodType).trigger('change');
    });
    // ADYEN v19.2.2 ADDITIONAL CODE END

    $cache.document.on('summary.billing.updated', () => {
        var $paymentMethodOptions = $('.payment-method-options');
        var $checkedPaymentMethod = $paymentMethodOptions.find('input:checked.js-payment-method');
        var selectedPaymentMethod;

        $cache.checkoutSummaryButton = $cache.document.find('.js-order-summary .js-billing-submit');
        $cache.checkoutSummaryButtonText = $cache.document.find('.js-order-summary .js-billing-submit span');

        if ($checkedPaymentMethod.length) {
            selectedPaymentMethod = $checkedPaymentMethod[0].value;
            if (selectedPaymentMethod === 'AdyenComponent' && ($('#paymentMethodsList').find('.paymentMethod.active').find('input#rb_paywithgoogle').length || $('#paymentMethodsList').find('.paymentMethod.active').find('input#rb_mbway').length)) {
                $('.checkout-button').attr('disabled',true);
            }
            if (selectedPaymentMethod == 'PayPal') {
                $cache.selectedPaymentMethodText = $('[data-method="' + $paymentMethodOptions.find('input:checked.js-payment-method')[0].value + '"]')[0];
                $cache.checkoutSummaryButtonText.text($cache.selectedPaymentMethodText.dataset.placebtnText);
                $cache.checkoutSummaryButton.addClass('js-paypal-sbmt');
                $('.checkout-button').attr('disabled',false);
            }
            if (selectedPaymentMethod == 'DW_APPLE_PAY') {
                $('.js-billing-button-wrapper').addClass('is--hidden');
                $('.js-applepay-button-wrapper').removeClass('is--hidden');
            }
            handlePlaceOrderButtonState();
        } else {
            $cache.checkoutBillingButtonText = $cache.document.find('.js-checkout-form .js-billing-submit span');
            if ($cache.checkoutBillingButtonText.length && $cache.checkoutSummaryButtonText.length) {
                $cache.checkoutSummaryButtonText.text($cache.checkoutBillingButtonText.html());
                
            }
        }
    });

    $cache.document.on('ready',function(){
        setTimeout(() => {            
            if($('.paymentMethod').find('input[value = applepay]').is(":checked")){
                $('.js-billing-button-wrapper').addClass('is--hidden');
                $('.js-applepay-button-wrapper').removeClass('is--hidden')
            }
        }, 3000);
    })


    $cache.document.on('click', '.paymentMethod input:radio', function(){
        $('.paymentMethod').removeClass('active');
        $(this).parent().addClass('active');
    })

    $cache.document.on('click', '.paymentMethod', function(){
        if($(this).find('input[value = applepay]').is(":checked")){
            $('.js-billing-button-wrapper').addClass('is--hidden');
            $('.js-applepay-button-wrapper').removeClass('is--hidden')
        }
        else {
            $('.js-billing-button-wrapper').removeClass('is--hidden');
            $('.js-applepay-button-wrapper').addClass('is--hidden')
        }
    })

    $cache.document.on('click', '.dw-apple-pay-button', function(e) {
        $.ajax({
            type: 'POST',
            url: util.appendParamToURL(util.ajaxUrl(Urls.saveBillingAddress)),
            data: $cache.checkoutForm.serialize()
        });
    });

    $('#adyenCreditCardList').on('change', function () {
        var selectedCard = $('#adyenCreditCardList').val();
        if (selectedCard !== "") {
            $cache.isOneClick = true;
            $('#newCard').addClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
            $('#selectedCard').removeClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
        } else {
            $cache.isOneClick = false;
            $('#newCard').removeClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
            $('#selectedCard').addClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS);
        }
    });

}

function checkCreditCardApplicableCoupons(callback) {
    if (checkingCoupon) {
        checkingCoupon.abort();
    }
        
    checkingCoupon = util.makeRequestForCheckingIfCouponIsApplicableForCreditCard(
        util.getUrlToValidateCouponForPaymentCreditCard(Urls.updateSummary), 
        'GET',
        callback
    );
}

function initGlobalEvents() {
    $('.payment-method-options').on('change', '.js-payment-method', function () {
        checkCreditCardApplicableCoupons();

        cleanBillingForm();
        if ($cache.sameAsShippingToggle.length) {
            $cache.sameAsShippingToggle.trigger('change');
        } else {
            if (!!$cache.existingShippingAddress.data('customer') && $cache.addressList.length && $cache.billingAddressForm.hasClass(cssConstants.HIDDEN_CLASS)) {
                fillFormWithAddress($cache.selectedAddress.data('address'));
                let previousAddressId = $cache.selectedAddress.data('aid');
                let $previouslySelectedAddress = $cache.addressList.find(`input[data-value="${previousAddressId}"]`);
                if ($previouslySelectedAddress.length) {
                    $previouslySelectedAddress.trigger('click');
                } else {
                    $cache.billingAddressForm.removeClass(cssConstants.IGNORE_CLASS);
                    $cache.document.trigger('addresslist.open');
                    $cache.billingAddressForm.find('input[type="text"], input[type="tel"], select').val('');
                }
            }
        }
    });

    $cache.document.on("address.region.updated", function () {
        if ($cache.storePickupData) {
            $('[id$="billingAddress_addressFields_states_state"]').val($cache.storePickupData.stateCode);
        }
        handlePlaceOrderButtonState();
    });

    $cache.document.on("billing.update", function () {
        initializeCache();
        initializeEvents();
        $cache.document.trigger('selectric.reinit', [$('.js-payment-form')]);
        $cache.document.trigger('address.init', ['address']);
        $cache.document.trigger('summary.billing.updated');
    });

    // Custom code to add class on apple pay button to hide it
    setTimeout(() => {       
        if($('.paymentMethod').find('input[value = "applepay"]')){
            $('input[value = "applepay"]').parent().addClass('ios-new-button-new') 
        }
    }, 2000);

    var $issuerId = $('[name="issuerId"]');
    var $saveCardWrapper = $cache.document.find('.js-save-card');

    $cache.document
        .on('change', '.js-shipping-address-toggle', function () {
            var sameAsShipping = $cache.sameAsShippingToggle.prop('checked'),
                isReturnedCustomer = !!$cache.existingShippingAddress.data('customer');
            if (isReturnedCustomer) {
                if (sameAsShipping) {
                    $cache.document.trigger('addresslist.close');
                    $cache.selectedAddress.find('.address').html($cache.existingShippingAddress.find('.address').html());
                    $cache.selectedAddress.data('address', $cache.existingShippingAddress.data('address'));
                    toggleNewAddressForm(false);
                } else if ($cache.addressList.length && $cache.billingAddressForm.hasClass(cssConstants.HIDDEN_CLASS)) {
                    fillFormWithAddress($cache.selectedAddress.data('address'));
                    let previousAddressId = $cache.selectedAddress.data('aid');
                    let $previouslySelectedAddress = $cache.addressList.find(`input[data-value="${previousAddressId}"]`);
                    if ($previouslySelectedAddress.length) {
                        $previouslySelectedAddress.trigger('click');
                    } else {
                        $cache.billingAddressForm.removeClass(cssConstants.IGNORE_CLASS);
                        $cache.document.trigger('addresslist.open');
                        $cache.billingAddressForm.find('input[type="text"], input[type="tel"], select').val('');
                    }
                } else {
                    toggleNewAddressForm(true);
                }
        } else {
                if (sameAsShipping) {
                    $cache.selectedAddress.find('.address').html($cache.existingShippingAddress.find('.address').html());
                }
                toggleNewAddressForm(!sameAsShipping);
            }
        })
        .on('click', '.js-add-new-address', function () {
            $cache.sameAsShippingToggle.prop('checked', false).trigger('change');
        })
        .on('ready', function(){
            setTimeout(function(){
                $('.adyen-checkout__mb-way').append(`<button class="duplicate-mbway-button" type="button">Confirmar compra</button>`);
            }, 2000)
        })
        .on('click', '.duplicate-mbway-button', function(){
            $('.c-address-form').removeClass('js-ignore');
            if($('#dwfrm_billing').valid() == true){
                $('.adyen-checkout__button.adyen-checkout__button--pay').click();
            }
        })
        .on('change', '.js-send-invoice', function () {
            let sendInvoice = $(this).prop('checked');
            $('.js-invoice-form').toggleClass(cssConstants.HIDDEN_CLASS + ' ' + cssConstants.IGNORE_CLASS, !sendInvoice);
            $('.js-invoice-country-select').trigger('change');
            if (!sendInvoice) {
                handlePlaceOrderButtonState();
            }
        })
        .on('change', '.js-invoice-form select[name$="country"]', function () {
            $('.js-invoice-country').text($(this).find(":selected").text());
        })
        .on('click', '.js-add-new-card', function () {
            var $this = $(this),
                $paymentForm = $this.closest('form');

            resetAdyenPaymentForm();

            $cache.document.find('#is-CREDIT_CARD').trigger('click');

            $paymentForm.find('.js-saved-card-block').hide();
            $saveCardWrapper.removeClass('is--hidden');
            $paymentForm.find('.js-back-to-saved-card').removeClass('is--hidden');
            $paymentForm.find('.js-payment-method-title').removeClass('is--hidden');
            $('#adyenCreditCardList').prop('selectedIndex', 0).trigger('change');

            window.CardValid = window.AdyenCard.isValid;

            $paymentForm.closest('form').find('.js-payment-form').removeClass('is--hidden');
            $this.hide();
            handlePlaceOrderButtonState();
            checkCreditCardApplicableCoupons();
        })
        .on('click', '.js-back-to-saved-card', function () {
            var $this = $(this),
                $paymentForm = $this.closest('form');

            resetAdyenPaymentForm();
            $paymentForm.find('.js-credit-card-tile.active').click();
            $saveCardWrapper.addClass('is--hidden');
            $paymentForm.find('.js-saved-card-block').show();
            $paymentForm.find('.js-payment-method-title').addClass('is--hidden');
            $paymentForm.closest('form').find('.js-payment-form').addClass('is--hidden');
            $paymentForm.find('.js-add-new-card').show();
            $this.addClass('is--hidden');
            handlePlaceOrderButtonState();
        })
        .on('click', '.js-credit-card-tile', function () {
            progress.show();
            var $this = $(this),
                url = $this.data('url');

            ajax({
                url: url
            }).then(function () {
                $cache.document.trigger('selectric.reinit', [$this.closest('form')]);

                $this.closest('.c-creditcard__item').siblings().removeClass('active');
                $this.closest('.c-creditcard__item').addClass('active');

                //trigger adyen checkout logic for saved credit cards
                var selectedOption = $('#adyenCreditCardList option#' + $this.data('cardUuid'));
                var selectedOptionIndex = 0;
                if (selectedOption.length) {
                    selectedOptionIndex = selectedOption.prop('index');
                }
                $('#adyenCreditCardList').prop('selectedIndex', selectedOptionIndex).trigger('change');

                checkCreditCardApplicableCoupons(() => {
                    progress.hide();
                });
            });
        })
        .on('check.addressform summary.updated', checkForm)
        .on('click', '.js-applepay-button-shield', checkForm);
    if ($('.js-credit-card-tile').length) {
        $('.js-credit-card-tile.active').click();
    }
}

var billing = {
    init: function (params) {
        initializeDOM();
        initializeCache();
        initializeEvents(params);
        initGlobalEvents();
    }
};

module.exports = billing;
