import eventMgr from '_core_ext/core/eventMgr';
import Block from '_core_ext/core/common/Block';

export default class productTileCmp extends Block {
    init() {
        super.init();
        var self = this;
        eventMgr.execute('components.update', self.$el);
        $(document).on('producttiles.loaded', function(event, selector) {
            return eventMgr.execute('components.update', self.$el)
            .then(() => {
                var newProductTiles = self.getProductTileCmpsFromResponse(selector);
                self.subscribeToChildrenEvents(newProductTiles);
            });
        });
        $(document).on('producttiles.childevents', function(event, selector) {
            self.subscribeToChildrenEvents();
        });
    }

    getProductTileCmpsFromResponse (html) {
        html = $(html);
        var producTiles = [];
        var productTileId = '';
        var self = this;
        $(html).find('.product-tile').each((i, el) => {
            productTileId = $(el).data('json-config').cmpId;
            self.getById(productTileId, (cmp) => {
                producTiles.push(cmp);
            });
        });

        return producTiles;
    }

    
    subscribeToChildrenEvents (children) {
        children = children || this.items;
        var self = this;

        children.forEach((productTile) => {
            productTile.on('produtTile.quickShop.hideSiblings', () => {
                this.items.forEach((ProductTile) => {
                    ProductTile.hideQuickShop();
                });
            });
        });
    }
};
