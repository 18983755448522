import FormGeneric from '_core_ext/components/forms/FormGeneric';
import ajax from '_core_ext/ajax';
import page from '_core_ext/page';

export default class AccountPaymentForm extends FormGeneric {
    init() {
        super.init();
        this.deleteButton = this.$el.find('.js-delete');
    }

    onSubmit() {
        event.preventDefault();

        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_CREDITCARD))) {
            $('<input/>').attr({
                type: 'hidden',
                name: this.deleteButton.attr('name'),
                value: this.deleteButton.attr('value') || 'delete card'
            }).appendTo(this.$el);

            ajax({
                type: 'POST',
                url: this.$el.attr('action'),
                data: this.$el.serialize() + '&' + this.deleteButton.attr('name') + '=x',
                callback: function() {
                    page.redirect(Urls.paymentsList);
                }
            });
        }
    }
}
