import InputGeneric from './InputGeneric';

export default class InputText extends InputGeneric {
    init() {
        super.init();
        this.event('keyup', '.js-input_field', this.onKeyUp);

        if (this.config.regexp) {
            this.event('blur', this.$el, this.validateInput);
        }
    }

    onKeyUp() {
        this.emitter.emit('keyup', this);
    }

    validateInput() {
        var inputValue = this.$el.val();

        if (inputValue) {
            var regEx = new RegExp(this.config.regexp);
            var isValid = regEx.test($.trim(inputValue));

            if (!isValid) {
                this.$el.addClass('error');
            } else {
                this.$el.removeClass('error');
            }
        }

        return;
    }
}