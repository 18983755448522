'use strict';

// Global variable used by GTM
window.dataLayer = window.dataLayer || [];

var eventsLib = require('./events'),
    // mappings = require('./mappings'),
    pageDataCollectors = require('./page-data-collectors'),
    pageTagsConfig = require('./tagsconfig.json'),
    pageEventConfig = require('./eventsconfig.json'),
    utils = require('./utils');

var analyticsData, currentPage;

/* eslint-disable */
function initGlobalVariables() {
    pageContext.analytics = utils.updateObjectValues(pageContext.analytics, 'undefined', undefined);
    analyticsData = pageContext.analytics || {};
    currentPage = pageContext.currentPage || pageContext.ns;
}

function initEvents() {
    var eventsToInit = $.merge($(pageEventConfig.global).slice() || [], pageEventConfig[currentPage] || []);

    utils.initFunctionsFromObject(eventsToInit, eventsLib, [analyticsData, currentPage]);
}

function pushPageData() {
    var tagsToPush = $.merge($.merge([], pageTagsConfig.global || []), pageTagsConfig[currentPage] || []);

    utils.initFunctionsFromObject(tagsToPush, pageDataCollectors, [analyticsData, currentPage]);
}

module.exports = {
    init: function () {
        if (!SitePreferences.GTM_ENABLED) {
            return;
        }

        initGlobalVariables();
        pushPageData();
        initEvents();
    }
};
