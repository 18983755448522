'use strict';
import device from 'current-device';
import eventMgr from '_core_ext/core/eventMgr';
const emitter = eventMgr.getEmitter('window');

var util = require('_core_ext/util'),
    layout,
    initialized = false,
    viewportWidth = {
        'small': {'maxWidth': 767, 'minWidth': 320},
        'medium': {'maxWidth': 1023, 'minWidth': 768},
        'large': {'maxWidth': 1439, 'minWidth': 1024}
    },
    $cache = {};

function toggleBodyLocking() {
    var $this = $(this);

    if ($this.css('overflow') === 'auto') {
        $this.trigger('layout.lock');
    } else {
        $this.trigger('layout.unlock');
    }
}

function bodyLock() {
    $(this).css({overflow: 'hidden'});
}

function bodyUnlock() {
    $(this).css({overflow: 'auto'});
}

function initializeCache() {
    $cache = {
        $html: $('html')
    };
}

function initializeDOM() {
    for (var key in device) {
        if (key in device && device[key] && typeof device[key] !== 'function') {
            $cache.$html.addClass(device[key]);
        }
    }
}

function detectApplePaySupport() {
    if (window.ApplePaySession) {
        $cache.$html.addClass('apple-pay-supported');
    }
}

function initializeEvents() {
    var viewMode = layout.getMode();

    $(window).on('resize', util.eventDelay(() => {
        $(document).trigger('window.resize');
        emitter.emit('resize');
        var layoutView = layout.getMode();

        if (viewMode !== layoutView) {
            viewMode = layoutView;
            $(document).trigger('window.modechanged', {mode: viewMode});
            emitter.emit('modechanged', {mode: viewMode});
        }
    }, 500));

    $(window).on('scroll', util.eventDelay(() => {
        $(document).trigger('window.scroll', {scrollTop: $(this).scrollTop()});
        emitter.emit('scroll');
    }, 300));

    $(document)
        .on('layout.togglelock', 'body', toggleBodyLocking)
        .on('layout.lock', 'body', bodyLock)
        .on('layout.unlock', 'body', bodyUnlock);
}

layout = {
    getMode: function () {
        var windowWidth = window.innerWidth;

        if (windowWidth <= viewportWidth.small.maxWidth) {
            return 'small';
        } else if (windowWidth <= viewportWidth.medium.maxWidth) {
            return 'medium';
        } else if (windowWidth <= viewportWidth.large.maxWidth) {
            return 'large';
        } else {
            return 'extra-large';
        }
    },
    getCurrentBreakpointWidth: function() {
        return viewportWidth[this.getMode()] && viewportWidth[this.getMode()].maxWidth;
    },
    isSmallView: function () {
        return this.getMode() === 'small';
    },
    isMediumView: function () {
        return this.getMode() === 'medium';
    },
    isLargeView: function () {
        return this.getMode() === 'large';
    },
    isExtraLargeView: function () {
        return this.getMode() === 'extra-large';
    },
    isMobileView: function() {
        return this.isSmallView() || this.isMediumView();
    },
    init: function () {
        if (initialized) {
            return;
        }

        initializeCache();
        initializeDOM();
        detectApplePaySupport();
        initializeEvents();
        initialized = true;
    },
    isMobile: function () {
        return typeof device.mobile === 'function' && device.mobile();
    },
    isTablet: function () {
        return typeof device.tablet === 'function' && device.tablet();
    },
    isTelevision: function () {
        return typeof device.television === 'function' && device.television();
    },
    isDesktop: function () {
        return typeof device.desktop === 'function' && device.desktop();
    },
    isIOS: function () {
        return typeof device.ios === 'function' && device.ios();
    },
    isAndroid: function () {
        return typeof device.android === 'function' && device.android();
    },
    isLandscape: function () {
        return typeof device.landscape === 'function' && device.landscape();
    },
    isPortrait: function () {
        return typeof device.portrait === 'function' && device.portrait();
    }
};

module.exports = layout;
