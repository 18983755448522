var pnum = (/[+-]?(?:\d*\.|)\d+(?:[eE][+-]?\d+|)/).source,
    rnumnonpx = new RegExp('^(' + pnum + ')(?!px)[a-z%]+$', 'i'),
    rmargin = (/^margin/),
    getStyles = function(elem) {
        return elem.ownerDocument.defaultView.getComputedStyle(elem, null);
    },
    cssExpand = ['Top', 'Right', 'Bottom', 'Left'],
    support = {};

var boxSizingReliableVal,
    docElem = document.documentElement,
    container = document.createElement('div'),
    div = document.createElement('div');

if (div.style) {
    div.style.backgroundClip = 'content-box';
    div.cloneNode(true).style.backgroundClip = '';
    support.clearCloneStyle = div.style.backgroundClip === 'content-box';

    container.style.cssText = 'border:0;width:0;height:0;top:0;left:-9999px;margin-top:1px;' +
        'position:absolute';
    container.appendChild(div);

    // Executing both pixelPosition & boxSizingReliable tests require only one layout
    // so they're executed at the same time to save the second computation.
}

function computePixelPositionAndBoxSizingReliable() {
    div.style.cssText =
        // Support: Firefox<29, Android 2.3
        // Vendor-prefix box-sizing
        '-webkit-box-sizing:border-box;-moz-box-sizing:border-box;' +
        'box-sizing:border-box;display:block;margin-top:1%;top:1%;' +
        'border:1px;padding:1px;width:4px;position:absolute';
    div.innerHTML = '';
    docElem.appendChild(container);

    var divStyle = window.getComputedStyle(div, null);
    boxSizingReliableVal = divStyle.width === '4px';

    docElem.removeChild(container);
}

function boxSizingReliable() {
    if (boxSizingReliableVal === null) {
        computePixelPositionAndBoxSizingReliable();
    }
    return boxSizingReliableVal;
}

function augmentWidthOrHeight(elem, name, extra, isBorderBox, styles) {
    var i = extra === (isBorderBox ? 'border' : 'content') ?
        // If we already have the right measurement, avoid augmentation
            4 :
        // Otherwise initialize for horizontal or vertical properties
            name === 'width' ? 1 : 0,

        val = 0;

    for (; i < 4; i += 2) {
        // both box models exclude margin, so add it if we want it
        if (extra === 'margin') {
            val += $.css(elem, extra + cssExpand[i], true, styles);
        }

        if (isBorderBox) {
            // border-box includes padding, so remove it if we want content
            if (extra === 'content') {
                val -= $.css(elem, 'padding' + cssExpand[i], true, styles);
            }

            // at this point, extra isn't border nor margin, so remove border
            if (extra !== 'margin') {
                val -= $.css(elem, 'border' + cssExpand[i] + 'Width', true, styles);
            }
        } else {
            // at this point, extra isn't content, so add padding
            val += $.css(elem, 'padding' + cssExpand[i], true, styles);

            // at this point, extra isn't content nor padding, so add border
            if (extra !== 'padding') {
                val += $.css(elem, 'border' + cssExpand[i] + 'Width', true, styles);
            }
        }
    }

    return val;
}

function curCSS(elem, name, computed) {
    var width, minWidth, maxWidth, ret,
        style = elem.style;

    computed = computed || getStyles(elem);

    // Support: IE9
    // getPropertyValue is only needed for .css('filter') in IE9, see #12537
    if (computed) {
        ret = computed.getPropertyValue(name) || computed[name];
    }

    if (computed) {

        if (ret === '' && !$.contains(elem.ownerDocument, elem)) {
            ret = $.style(elem, name);
        }

        // Support: iOS < 6
        // A tribute to the "awesome hack by Dean Edwards"
        // iOS < 6 (at least) returns percentage for a larger set of values, but width seems to be reliably pixels
        // this is against the CSSOM draft spec: http://dev.w3.org/csswg/cssom/#resolved-values
        if (rnumnonpx.test(ret) && rmargin.test(name)) {

            // Remember the original values
            width = style.width;
            minWidth = style.minWidth;
            maxWidth = style.maxWidth;

            // Put in the new values to get a computed value out
            style.minWidth = style.maxWidth = style.width = ret;
            ret = computed.width;

            // Revert the changed values
            style.width = width;
            style.minWidth = minWidth;
            style.maxWidth = maxWidth;
        }
    }

    return ret !== undefined ?
        // Support: IE
        // IE returns zIndex value as an integer.
        ret + '' :
        ret;
}

function getWidthOrHeight(elem, name, extra) {
    // Start with offset property, which is equivalent to the border-box value
    var val,
        valueIsBorderBox = true,
        styles = getStyles(elem),
        isBorderBox = $.css(elem, 'boxSizing', false, styles) === 'border-box';

    // Support: IE <=11 only
    // Running getBoundingClientRect on a disconnected node
    // in IE throws an error.
    if (elem.getClientRects().length) {
        val = elem.getBoundingClientRect()[name];
    }

    // some non-html elements return undefined for offsetWidth, so check for null/undefined
    // svg - https://bugzilla.mozilla.org/show_bug.cgi?id=649285
    // MathML - https://bugzilla.mozilla.org/show_bug.cgi?id=491668
    if (val <= 0 || val === null) {
        // Fall back to computed then uncomputed css if necessary
        val = curCSS(elem, name, styles);
        if (val < 0 || val === null) {
            val = elem.style[name];
        }

        // Computed unit is not pixels. Stop here and return.
        if (rnumnonpx.test(val)) {
            return val;
        }

        // we need the check for style in case a browser which returns unreliable values
        // for getComputedStyle silently falls back to the reliable elem.style
        valueIsBorderBox = isBorderBox &&
            (boxSizingReliable() || val === elem.style[name]);

        // Normalize "", auto, and prepare for extra
        val = parseFloat(val) || 0;
    }

    // use the active box-sizing model to add/subtract irrelevant styles
    return (val +
        augmentWidthOrHeight(
            elem,
            name,
            extra || (isBorderBox ? 'border' : 'content'),
            valueIsBorderBox,
            styles
        )
    ) + 'px';
}

module.exports.getWidthOrHeight = getWidthOrHeight;