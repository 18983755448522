import ShippingFormBase from './Base';
import locationsPickerServices from '_core_ext/components/locationspicker/utils/locationsPickerServices';
import eventMgr from '_core_ext/core/eventMgr';

const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLE_MAPS_API_KEY +
    '&libraries=places';
var google;
var flag = false;
export default class PickUpShippingForm extends ShippingFormBase {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.locatorForm = this.items.find((form) => {
            return form.config.type === 'locatorForm';
        });
        this.wasPickUpLocationSelected = this.config.wasPickUpLocationSelected;
        this.getLocationsUrl = this.config.getLocationsUrl;
        this.locationsPicker = null;

        locationsPickerServices.init();
        this.subscribeToChildrenEvents();

        if (this.wasPickUpLocationSelected) {
            this.toggleLocatorForm(true);
            this.toggleDeliveryForm(false);
        }

        if ('google' in window) {
            google = window.google;
            this.initializeCmpEvents();
        } else {
            $.getScript(GOOGLE_MAPS_URL)
                .done(() => {
                    google = window.google;
                    this.initializeCmpEvents();
                });
        }
    }

    subscribeToChildrenEvents () {
        throw new Error('subscribeToChildrenEvents method must be implemented on children classes');
    }

    initializeCmpEvents () {
        this.geocoder = new google.maps.Geocoder();
        this.initializedAutoComplete();
        this.event('click', '.js-find-location', this.onFindLocationsClick.bind(this));
        this.event('click', '.js-current-location', this.onCurrentLocationClick.bind(this));
        this.event('click', '.js-change-location', this.onChageLocationClick.bind(this));
        this.event('click', '.js-show-more', this.onSelectedlocationShowMoreInfo.bind(this));
        this.event('click', '.js-close-more', this.onSelectedlocationCloseInfo.bind(this));
        if($(window).width() < 1025){
            this.event('click', '.js-show-button', this.onClickStoreTiminigs.bind(this));
        }
        else {            
            this.event('mouseover', '.js-show-button', this.onMouseOverStoreTiminigs.bind(this));
            this.event('mouseout', '.js-show-button', this.onMouseOutStoreTiminigs.bind(this));
        }
    }
    onMouseOverStoreTiminigs(el, event){
        event.preventDefault();
        $(el).siblings('.js-schedule-table').removeClass('is--hidden')
    }

    onMouseOutStoreTiminigs(el, event){
        event.preventDefault();
        $(el).siblings('.js-schedule-table').addClass('is--hidden')
    }

    onClickStoreTiminigs(el, event){
        event.preventDefault();
        if(flag == false){
            $(el).siblings('.js-schedule-table').removeClass('is--hidden');
            flag = true;
        }
        else{
            $(el).siblings('.js-schedule-table').addClass('is--hidden');
            flag = false;
        }
    }

    isLocatorFormActive () {
        return this.locatorForm.$el.is(':visible');
    }

    toggleLocatorForm (hide) {
        this.locatorForm.$el.closest('.js-locator-form').toggleClass('is--hidden', hide);
    }

    updateDeliveryForm () {
        throw new Error('updateDeliveryForm method must be implemented on children classes');
    }

    getLocationsPicker (action, formData, coordinates, shippingMethodId) {
        return eventMgr.execute('get.locations.picker', {
            url: this.getLocationsUrl,
            action: action,
            formData: formData,
            coordinates: coordinates || null,
            shippingMethodId: shippingMethodId || ''
        });
    }

    renderLocationsPicker (locationsPicker) {
        this.$el.find('.js-locations-picker').empty().html(locationsPicker);
        return eventMgr.execute('components.update', this.$el);
    }

    handleGetLocationsPicker (action, formData, coordinates, shippingMethodId) {
        return this.getLocationsPicker(action, formData, coordinates, shippingMethodId)
            .then((locationsPicker) => {
                return this.renderLocationsPicker(locationsPicker);
            })
            .then(() => {
                this.toggleLocatorForm(false);
                this.toggleDeliveryForm(true);
                this.subscribeToChildrenEvents();
                this.getById('locationsPicker', (locationsPicker) => {
                    this.locationsPicker = locationsPicker;
                });
            });
    }

    getLocationCoordinates (location) {
        return new Promise((res, rej) => {
            this.geocoder.geocode({
                address: location
            }, (results, status) => {
                if (status !== google.maps.GeocoderStatus.OK || !results.length || !('geometry' in results[0])) {
                    return rej('Couldn\'t get coordinates');
                }
                var location = results[0].geometry.location;
                var coordinates = {
                    lat: Number(location.lat().toFixed(6)),
                    lng: Number(location.lng().toFixed(6))
                };
                res(coordinates);
            });
        });
    }

    getCurrentPositionCoordinates () {
        return new Promise((res, rej) => {
            if (!('geolocation' in navigator)) {
                return rej('Couldn\'t get current location coordinates');
            }
            navigator.geolocation.getCurrentPosition((position) => {
                var currentCoordinates = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                res(currentCoordinates);
            });
        });
    }

    onCurrentLocationClick () {
        throw new Error('onCurrentLocationClick method must be implemented on children classes');
    }

    findPickUpLocationsOncurrentlocation (shippingMethodId) {
        var $locatorForm = this.locatorForm.$el;
        var formData = $locatorForm.serializeArray();
        var action = $locatorForm.find('.js-find-location').attr('name');
        var searchInputFieldName = $locatorForm.find('.js-get-address-suggestions').attr('name');

        formData.forEach((item) => {
            if (item.name === searchInputFieldName) {
                item.value = 'currentlocation';
            }
        });

        this.getCurrentPositionCoordinates()
            .then((coordinates) => {
                this.handleGetLocationsPicker(action, formData, coordinates, shippingMethodId);
            });
    }

    initializedAutoComplete () {
        var $input = this.locatorForm.$el.find('.js-get-address-suggestions');
        var options = {};
        var autocomplete = new google.maps.places.Autocomplete($input.get(0), options);
        if (pageContext && pageContext.currentCountry) {
            autocomplete.setComponentRestrictions({'country': pageContext.currentCountry.trim().toLowerCase()});
        }
    }

    onFindLocationsClick () {
        throw new Error('onFindLocationsClick method must be implemented on children classes');
    }

    findPickUpLocations (shippingMethodId) {
        var $locatorForm = this.locatorForm.$el;
        var address = $locatorForm.find('.js-get-address-suggestions').val();
        if (!address) {
            return;
        }
        var formData = $locatorForm.serializeArray();
        var action = $locatorForm.find('.js-find-location').attr('name');
        this.getLocationCoordinates(address)
            .then((coordinates) => {
                this.handleGetLocationsPicker(action, formData, coordinates, shippingMethodId);
            });
    }

    onChageLocationClick (el, event) {
        event.preventDefault();
        if (this.locationsPicker) {
            this.locationsPicker.show();
        }
        this.toggleLocatorForm(false);
        this.toggleDeliveryForm(true);
        this.$el.find('.js-selected-location-info').hide();
        this.emitter.emit('pickUpBaseShippingForm.change.pick.up.location');
    }

    setSelectedLocation (selectedLocationHtml) {
        this.$el.find('.js-selected-location-info').show();
        this.deliveryForm.$el.find('.js-selected-location-info').html(selectedLocationHtml);
    }

    fillDeliveryFormWithLocationtInfo () {
        throw new Error('fillDeliveryFormWithLocationtInfo method must be implemented on childrend classess');
    }

    onLocationSelected (locationId, locationInfo, selectedLocationHtml) {
        if (this.locationsPicker) {
            this.locationsPicker.hide();
        }
        this.toggleLocatorForm(true);
        this.toggleDeliveryForm(false);
        this.fillDeliveryFormWithLocationtInfo(locationId, locationInfo);
        this.setSelectedLocation(selectedLocationHtml);
    }

    onSelectedlocationShowMoreInfo (el, event) {
        event.preventDefault();
        $(el).addClass('is--hidden');
        $(el).siblings().removeClass('is--hidden');
    }

    onSelectedlocationCloseInfo (el, event) {
        event.preventDefault();
        $(el).addClass('is--hidden');
        $(el).siblings('.js-schedule-table').addClass('is--hidden');
        $(el).siblings('.js-show-more').removeClass('is--hidden');
    }
}