'use strict';

require('selectric')($);

let $cache = {},
    initialized = false,
    options = {
        sourceSelector: '.js-video',
        volumeControl: '.js-volume-control',
        isMutedClass: 'is--muted',
        isPausedClass: 'is--paused',
        smallVideo: '.video-small',
        bigVideo: '.video-big'
    };

function clickVideoHandler() {
    var $element = $(this),
        $videoEl = $element.find('video');

    $videoEl.on('click', function() {
        if ($(this).get(0).paused) {
            $(this).get(0).play();
            $(this).removeClass(options.isPausedClass);
        } else {
            $(this).get(0).pause();
            $(this).addClass(options.isPausedClass);
        }
    });
}

function volumeVideoHandler() {
    var $element = $(this),
        $volumeControlEl = $element.find(options.volumeControl),
        $videoEL = $element.find('video').get(0);

    $volumeControlEl.addClass(options.isMutedClass);
    $volumeControlEl.on('click', function(event) {
        event.preventDefault();
        if ($videoEL.muted) {
            $volumeControlEl.removeClass(options.isMutedClass);
            $videoEL.muted = false;
        } else {
            $volumeControlEl.addClass(options.isMutedClass);
            $videoEL.muted = true;
        }
    });
}

function scrollVideoHandler() {
    var $element = $(this),
        $videoEl = $element.find('video');

    if (!$videoEl.length) {
        return;
    }

    var videoOffset = $videoEl.offset() || 0,
        videoCenterPosition = videoOffset.top + ($videoEl.outerHeight() / 2),
        windowTopPosition = $cache.window.scrollTop(),
        windowTopBottomPosition = $cache.window.scrollTop() + $cache.window.height();

    if (windowTopBottomPosition > videoCenterPosition && windowTopPosition < videoCenterPosition && !$videoEl.hasClass(options.isPausedClass)) {
        $videoEl.get(0).play();
    } else {
        $videoEl.get(0).pause();
    }
}

function ajaxVideoHandler() {
    var $element = $(this),
        $videoEl = $element.find('video'),
        $videoElSrc = $videoEl.children()[0].src;

    if (!$videoEl.length) {
        return;
    }

    $videoEl.children()[0].src = $videoElSrc;
}

function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
}

function initializeParams(params) {
    $.extend(true, options, params);
}

function initializeVideoHandler() {
    var $videoElements = $cache.document.find(options.sourceSelector);
    $videoElements.each(volumeVideoHandler);
    $videoElements.each(clickVideoHandler);
    $videoElements.each(scrollVideoHandler);
}

function initializeEvents() {
    var $videoElements;
    var $videoSmall = $cache.document.find(options.smallVideo);
    var $videoBig = $cache.document.find(options.bigVideo);

    initializeVideoHandler();

    $cache.document
        .on('window.scroll', () => {
            $videoElements = $cache.document.find(options.sourceSelector);
            $videoElements.each(scrollVideoHandler);
        })
        .on('search.update', () => {
            $videoElements = $cache.document.find(options.sourceSelector);
            $videoElements.each(ajaxVideoHandler);
            initializeVideoHandler();
        })
        .on('window.resize', () => {
            if ($('.video-small').css('display') === 'none') {
                $videoSmall.each(function() {
                    $(this).get(0).pause();
                });
            }
            if ($('.video-big').css('display') === 'none') {
                $videoBig.each(function() {
                    $(this).get(0).pause();
                });
            }
        });
}


module.exports = {
    init: function (params) {
        if (initialized) {
            return;
        }

        initializeParams(params);
        initializeCache();
        initializeEvents();

        initialized = true;
    }
};
