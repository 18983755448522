/**
 *    (c) 2009-2014 Demandware Inc.
 *    Subject to standard usage terms and conditions
 *    For all details and documentation:
 *    https://bitbucket.com/demandware/sitegenesis
 */

'use strict';

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement('script');
    s.setAttribute('src', 'https://ajax.googleapis.com/ajax/libs/jquery/2.2.1/jquery.min.js');
    s.setAttribute('type', 'text/javascript');
    document.getElementsByTagName('head')[0].appendChild(s);
}

require('./../../../app_storefront_core_ext/cartridge/js/js-ext')();
require('./../../../app_storefront_core_ext/cartridge/js/jquery-ext')();

var app = window.app = {
    init: function () {
        this.components.extendConfig(require('./components-config')).initAll();
    },
    'page' : require('./../../../app_storefront_core_ext/cartridge/js/page'),
    'components' : require('./../../../app_storefront_core_ext/cartridge/js/components')
};
//to close virtual selling chat tooltip
$(window).on('load', function () {
      setTimeout(function () {
          if ($('.hero-launcher-avatar-container').length) {
            hero('show');
            hero('hide');
          }
        }, 5000);
  });
// initialize app
$(document).ready(function () {
    app.init();

    //Video Lazy Load Script 
    var lazyvideo = document.querySelectorAll('.lazy-video');
    let lazyVideoObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                if (entry.target.querySelector('source').getAttribute('data-src') !== null) {
                const source = entry.target.querySelector('source').getAttribute('data-src')
                entry.target.setAttribute('src', source);
                }
                observer.unobserve(entry.target);
            }
        });
    });

    lazyvideo.forEach(video => {
        lazyVideoObserver.observe(video);
    });

    //Image Lazy Load Script 
    /** First we get all the non-loaded image elements **/
    var lazyImages = [].slice.call(document.querySelectorAll(".lazyloadimg"));
    /** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element **/
    let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let lazyImg = entry.target;
                lazyImg.src = lazyImage.dataset.src;
                lazyImg.classList.remove("lazy");
                lazyImageObserver.unobserve(lazyImg);
            }
        });
    });
    /** Now observe all the non-loaded images using the observer we have setup above **/
    lazyImages.forEach(function(lazyImg) {
        lazyImageObserver.observe(lazyImg);
    });

    /**
     * @function 
     * @description Lazy Load Script for Picture tag add class lazypicture in picture tag
     */         
      

    var lazyPictures =[].slice.call(document.querySelectorAll(".lazypicture > source"));
    if ("IntersectionObserver" in window && 'IntersectionObserverEntry' in window) {
       let lazyPicObserver = 
        new IntersectionObserver(function(entries, observer) {
           entries.forEach(function(entry) {
            if (entry.isIntersecting) {      
               let lazyImage = entry.target;
               lazyImage.srcset = lazyImage.dataset.srcset;
               lazyImage.nextElementSibling.srcset = lazyImage.dataset.srcset;
               lazyImage.nextElementSibling.classList.add('fade-in');
               lazyImage.parentElement.classList.remove("lazypicture");
               lazyPicObserver.unobserve(lazyImage);
             }
          });
         });
  
       lazyPictures.forEach(function(lazyImage) {
        lazyPicObserver.observe(lazyImage);
       });
    } else {
      
      // Not supported, load all images immediately
      let active = false;
   
      const lazyLoad = function() {
        if (active === false) {
          active = true;
          setTimeout(function() {
            lazyImages.forEach(function(lazyImage) {
              if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                lazyImage.srcset = lazyImage.dataset.srcset;
                lazyImage.nextElementSibling.src = lazyImage.dataset.srcset;
                lazyImage.nextElementSibling.classList.add('fade-in');
                lazyImage.parentElement.classList.remove("lazypicture");
                
                lazyImages = lazyImages.filter(function(image) {
                  return image !== lazyImage;
                });
    
                if (lazyPictures.length === 0) {
                  document.removeEventListener("scroll", lazyLoad);
                  window.removeEventListener("resize", lazyLoad);
                  window.removeEventListener("orientationchange", lazyLoad);
                }
              }
            });
    
            active = false;
          }, 200);
        }
      };
    
      document.addEventListener("scroll", lazyLoad);
      window.addEventListener("resize", lazyLoad);
      window.addEventListener("orientationchange", lazyLoad);
     }
});