'use strict';

/* eslint-disable */
var progress = require('./../../../app_storefront_core/cartridge/js/progress'),
    inScroll = false;

/** Error message constants. */
var FUNC_ERROR_TEXT = 'Expected a function';

var util = {
    /**
     * @function
     * @description Scrolls a browser window to a given x point or DOM object
     * @param {String} x coordinate or {Object} to which to scroll
     * @param {Number} duration of animation in milliseconds
     * @param {Function} callback after animation is complete
     */
    scrollTo: function (topOffsetOrElement, duration, cb) {
        var $scrollElement,
            scrollToElement = typeof topOffsetOrElement === 'object',
            duration = duration || 10,
            topOffset;

        if (inScroll) {
            return;
        }

        inScroll = true;

        if (scrollToElement) {
            topOffset = topOffsetOrElement.offset().top;
        } else {
            topOffset = topOffsetOrElement;
        }

        $scrollElement = $('html, body');

        $scrollElement.animate({
            scrollTop: topOffset
        }, duration, () => {
                if (typeof cb === 'function') {
                    cb();
                }

                inScroll = false;
            }
        );
    },
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url.indexOf(name + '=') !== -1) {
            return url;
        }
        var separator = url.indexOf('?') !== -1 ? '&' : '?';
        return url + separator + name + '=' + encodeURIComponent(value);
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf('?') === -1 || url.indexOf(name + '=') === -1) {
            return url;
        }
        var hash;
        var params;
        var domain = url.split('?')[0];
        var paramUrl = url.split('?')[1];
        var newParams = [];
        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf('#') > -1) {
            hash = paramUrl.split('#')[1] || '';
            paramUrl = paramUrl.split('#')[0];
        }
        params = paramUrl.split('&');
        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split('=')[0] !== name) {
                newParams.push(params[i]);
            }
        }
        return domain + '?' + newParams.join('&') + (hash ? '#' + hash : '');
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;
        for (var key in params) {
            _url = this.appendParamToURL(_url, key, params[key]);
        }
        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;
        if (typeof url !== 'string') { return; }
        var a = document.createElement('a');
        a.href = url;
        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }
        return qs;
    },

    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof (offsetToTop) !== 'undefined') {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === 'CSS1Compat') {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, 'format', 'ajax');
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url.indexOf('http') !== 0 && url.charAt(0) !== '/') {
            url = '/' + url;
        }
        return url;
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i, len = urls.length;
        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $('<link/>').appendTo($('head')).attr({
            type: 'text/css',
            rel: 'stylesheet'
        }).attr('href', url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;
        while (0 > i--) {
            $(this.loadedCssFiles[i]).remove();
        }
        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }
        var params = {};
        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        qs.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'),
            function ($0, $1, $2, $3) {
                params[$1] = decodeURIComponent($3).replace(/\+/g, ' ');
            }
        );
        return params;
    },

    getParameterValueFromUrl: function (parameterName, url) {
        var currentQueryString = url || window.location.search;
        var currentQueryStringParams = this.getQueryStringParams(currentQueryString);

        return currentQueryStringParams[parameterName];
    },

    /**
     * @function
     * @description copy the query string from URL from one URL to another
     * @param {String} sourceUrl the source URL for getting query
     * @param {String} destinationUrl the source URL for getting query
     **/
     copyQueryString: function (sourceUrl, destinationUrl) {
        var targetUrl;
        if (typeof sourceUrl !== 'string') { return; }
        if (typeof destinationUrl !== 'string') { return; }
        
        let sourceQueryParams = this.getQueryStringParams(this.getQueryString(sourceUrl));
        targetUrl = this.updateOrAppendUrlParams(destinationUrl, sourceQueryParams);
        return targetUrl;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === 'UUID' || field === 'key') {
                continue;
            }
            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace('Code', '') + '"]').val(address[field]);

            if (field === 'title') {
                $form.find('[name$="title"]').selectric('refresh');
            }

            // update the state fields
            if (field === 'countryCode') {
                $form.find('[name$="country"]').trigger('change', [$form, address.stateCode]);
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode).selectric('refresh');
            }
        }
    },

    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var characterLimit = $(this).data('character-limit');
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + '</span>',
                '<span class="char-allowed-count">' + characterLimit + '</span>');
            var charCountContainer = $(this).next('div.char-count');
            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }
            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },
    checkLimitMessage: function () {
        $('form').find('textarea[data-character-limit]').each(function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data('character-limit'),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next('div.char-count').find('.char-remain-count').html(charsRemain);
        });
    },
    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        var utils = this,
            dialog = require('./dialog'),
            dialogURL = utils.ajaxUrl(Urls.pageShow),
            confirmResult = false;

        $(container).on('click', '.delete', function (event) {
            var $this = $(this),
                $dialog;

            if (!confirmResult) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                return;
            }

            dialog.open({
                'url': utils.appendParamToURL(dialogURL, 'cid', Resources.CONFIRMATION_DIALOG_CID),
                'options': {
                    'width': 400
                },
                'callback': function () {
                    $('#delete-cancel')
                        .on('click', closeDialogCallback);

                    $('#delete-confirm')
                        .on('click', closeDialogCallback)
                        .on('click', function (event) {
                            confirmResult = true;
                            $this[0].click();
                        });

                    function closeDialogCallback(event) {
                        event.preventDefault();
                        $dialog.dialog('isOpen') && $dialog.dialog('close');
                    }
                }
            });

            $dialog = dialog.$container;
            return false;
        });

    },

    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     */
    scrollBrowser: function (xLocation, duration) {
        var duration = typeof (duration) !== 'undefined' ? duration : 500;
        $('html, body').animate({ scrollTop: xLocation }, duration);
    },

    setWindowScrollTop: function (offset) {
        if (!offset) {
            return;
        }

        var fixedHeaderHeight = $('.js-sticky_header').height();
        var positionToScroll = offset - fixedHeaderHeight;
        if (positionToScroll <= 0) {
            return;
        }

        $(window).scrollTop(positionToScroll);
    },

    /**
     * @description funtion that check storage Availability on window object
     * @param {String} storage type(name)
     * @return {Boolean}
     */
    storageAvailable: function(type) {
        var storage = [];
        try {
            storage = window[type];
            var test = '__storage_test__';
            storage.setItem(test, test);
            storage.removeItem(test);
            return true;
        } catch (e) {
            //ignore storage error
        }
    },

    eventDelay: function (callback, threshhold, scope, skipInitialCall) {
        threshhold || (threshhold = 250);
        var last,
            deferTimer;

        /**
         * @todo  Add description
         */
        return function () {
            var context = scope || this,
                now = (new Date()).getTime(),
                args = arguments;

            if (last && now < last + threshhold) {
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    callback.apply(context, args);
                }, threshhold);
            } else {
                last = now;

                if (!skipInitialCall) {
                    callback.apply(context, args);
                }
            }
        };
    },

    /**
     * Throttling Function Calls
     *
     * @see http://www.nczonline.net/blog/2007/11/30/the-throttle-function/
     * @param {Function} callback Callback function to call
     * @param {Number} delay Delay before callback fire
     * @param {Object} scope The context to for callback fire
     */
    throttle: function (callback, delay, scope) {
        clearTimeout(callback._tId);
        callback._tId = setTimeout(function () {
            callback.call(scope);
        }, delay || 100);
    },

    getTimer: function () {
        return {
            id: null,
            clear: function () {
                if (this.id) {
                    window.clearTimeout(this.id);
                    delete this.id;
                }
            },
            start: function (duration, callback) {
                this.id = setTimeout(callback, duration);
            }
        };
    },

    getInterval: function () {
        return {
            id: null,
            clear: function () {
                if (this.id) {
                    window.clearInterval(this.id);
                    delete this.id;
                }
            },
            start: function (duration, callback) {
                this.id = setInterval(callback, duration);
            }
        };
    },

    /**
     * Add/remove value from array depending on its existing in array
     * @param inArr
     * @param value
     * @returns {Array}
     */
    toggleFromArray: function (inArr, value) {
        var arr = inArr.slice();
        var valueIndex = arr.indexOf(value);
        if (valueIndex >= 0) {
            arr.splice(valueIndex, 1);
        } else {
            arr.push(value);
        }

        return arr;
    },

    /**
     * Helper function.
     * Checks if two arrays have the same values. It doesn't depend from order
     * @param arr1
     * @param arr2
     * @returns {Boolean}
     */
    arraysEqualByValues: function (arr1, arr2) {
        if (!arr1 || !arr2) {
            return arr1 === arr2;
        }

        var len1 = arr1.length,
            len2 = arr2.length;

        if (len1 !== len2) {
            return false;
        }
        for (var i = 0; i < len1; i++) {
            if (arr2.indexOf(arr1[i]) < 0) {
                return false;
            }
        }

        return true;
    },

    /**
     * Helper function.
     * Construct :nth-child selector with given element and start position
     * @param {String} elem
     * @param {Number} from
     * @returns {String}
     */
    fromNthSelector: function (elem, from) {
        var selector = elem + ':nth-child(n+';
        selector += (from + 1);
        selector += ')';

        return selector;
    },

    getKeyByValue: function (HashMapCollection, value) {
        for (var prop in HashMapCollection) {
            if (HashMapCollection.hasOwnProperty(prop)) {
                if (HashMapCollection[prop] === value) { return prop; }
            }
        }
    },

    /**
     * [getUri description]
     * @param  {[type]} o [description]
     * @return {[type]}   [description]
     */
    getUri: function (o) {
        var a;

        if (o.tagName && $(o).attr('href')) {
            a = o;
        } else if (typeof o === 'string') {
            a = document.createElement("a");
            a.href = o;
        } else {
            return null;
        }

        // overcome some stupid ie behaviour
        if (a.host === '') {
            a.href = a.href;
        }

        // all actual version of IE not so smart to correctly process
        // protocol independent locations, so wee need to help them
        if (a.protocol === ':') {
            a.protocol = window.location.protocol;
        }

        // fix for some IE browsers
        if (a.pathname.indexOf('/') !== 0) {
            a.pathname = '/' + a.pathname;
        }

        return Object.create({
            'protocol': a.protocol, //http:
            'host': a.host, //www.myexample.com
            'hostname': a.hostname, //www.myexample.com'
            'port': a.port, //:80
            'path': a.pathname, // /sub1/sub2
            'query': a.search, // ?param1=val1&param2=val2
            'queryParams': a.search.length > 1 ? this.getQueryStringParams(a.search.substr(1)) : {},
            'hash': a.hash, // #OU812,5150
            'url': a.protocol + '//' + a.host + a.pathname,
            'toString': function () {
                return this.protocol + '//' + this.host + this.port + this.pathname + this.search + this.hash;
            }
        });
    },

    progressShow: function ($container) {
        $container.addClass('js-loading');
        progress.show($container);
    },

    progressHide: function ($container) {
        $container.removeClass('js-loading');
        progress.hide();
    },

    contentShow: function (target) {
        var dialog = require('./dialog');
        var contentId = $(target).data('contentid');

        if (!contentId) {
            return;
        }

        $.ajax({
            url: Urls.pageShow,
            data: {
                format: 'ajax',
                cid: contentId,
                type: 'POST'
            }
        }).done(function (response) {
            dialog.open({
                html: response
            });
        });
    },

    /**
     * Handles state of one selectbox acoording to state another
     *
     * @param {any} $select
     * @param {any} $options
     */
    handleSelectChange: function ($select, $options) {
        $options = $options || $select.children('option');

        if ($options.length > 1) {
            $select.closest('.js-custom-select').removeClass('custom-select--static');
        } else {
            $select.closest('.js-custom-select').addClass('custom-select--static');
        }
    },
    
    /**
     * Genereal wrapper for JSON.parse(...) with error catching
     * @result {Object}
     */
    jsonParse : function (stringified) {
        var parsed = {};

        if (!stringified) {
            return  parsed;
        }

        try {
            parsed = JSON.parse(stringified);
        } catch (e) {
            return parsed;
        }

        return parsed;
    },

    /**
     * @description Tests if localStorage is available, useful in Safari where localStorage can't be used when browsing privately.
     * @returns {boolean}
     */
    canAccessSessionStorage : function () {
        var item = 'test';
        try {
            sessionStorage.setItem(item, item);
            sessionStorage.removeItem(item);
            return true;
        } catch (e) {
            return false;
        }
    },
    isObject: function (value) {
        var type = typeof value;
        return value != null && (type == 'object' || type == 'function');
    },
    toNumber: function (value) {
        /** Used as references for various `Number` constants. */
        var NAN = 0 / 0;

        /** Used to match leading and trailing whitespace. */
        var reTrim = /^\s+|\s+$/g;

        /** Used to detect bad signed hexadecimal string values. */
        var reIsBadHex = /^[-+]0x[0-9a-f]+$/i;

        /** Used to detect binary string values. */
        var reIsBinary = /^0b[01]+$/i;

        /** Used to detect octal string values. */
        var reIsOctal = /^0o[0-7]+$/i;

        if (typeof value == 'number') {
            return value;
        }
        if (typeof value === 'symbol') {
            return NAN;
        }
        if (this.isObject(value)) {
            var other = typeof value.valueOf == 'function' ? value.valueOf() : value;
            value = this.isObject(other) ? (other + '') : other;
        }
        if (typeof value != 'string') {
            return value === 0 ? value : +value;
        }
        value = value.replace(reTrim, '');
        var isBinary = reIsBinary.test(value);
        return (isBinary || reIsOctal.test(value))
            ? parseInt(value.slice(2), isBinary ? 2 : 8)
            : (reIsBadHex.test(value) ? NAN : +value);
    },

    debounce: function (func, wait, options) {
        var lastArgs,
            lastThis,
            maxWait,
            result,
            timerId,
            lastCallTime,
            lastInvokeTime = 0,
            leading = false,
            maxing = false,
            trailing = true;

        if (typeof func != 'function') {
            throw new TypeError(FUNC_ERROR_TEXT);
        }

        wait = this.toNumber(wait) || 0;

        if (this.isObject(options)) {
            leading = !!options.leading;
            maxing = 'maxWait' in options;
            maxWait = maxing ? Math.max(toNumber(options.maxWait) || 0, wait) : maxWait;
            trailing = 'trailing' in options ? !!options.trailing : trailing;
        }

        function invokeFunc (time) {
            var args = lastArgs,
                thisArg = lastThis;

            lastArgs = lastThis = undefined;
            lastInvokeTime = time;
            result = func.apply(thisArg, args);
            return result;
        }

        function leadingEdge(time) {
            // Reset any `maxWait` timer.
            lastInvokeTime = time;
            // Start the timer for the trailing edge.
            timerId = setTimeout(timerExpired, wait);
            // Invoke the leading edge.
            return leading ? invokeFunc(time) : result;
        }

        function remainingWait(time) {
            var timeSinceLastCall = time - lastCallTime,
                timeSinceLastInvoke = time - lastInvokeTime,
                timeWaiting = wait - timeSinceLastCall;

            return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;
        }

        function shouldInvoke(time) {
            var timeSinceLastCall = time - lastCallTime,
                timeSinceLastInvoke = time - lastInvokeTime;

            // Either this is the first call, activity has stopped and we're at the
            // trailing edge, the system time has gone backwards and we're treating
            // it as the trailing edge, or we've hit the `maxWait` limit.
            return (lastCallTime === undefined || (timeSinceLastCall >= wait) ||
                (timeSinceLastCall < 0) || (maxing && timeSinceLastInvoke >= maxWait));
        }

        function timerExpired() {
            var time = Date.now();
            if (shouldInvoke(time)) {
                return trailingEdge(time);
            }
            // Restart the timer.
            timerId = setTimeout(timerExpired, remainingWait(time));
        }

        function trailingEdge(time) {
            timerId = undefined;

            // Only invoke if we have `lastArgs` which means `func` has been
            // debounced at least once.
            if (trailing && lastArgs) {
              return invokeFunc(time);
            }
            lastArgs = lastThis = undefined;
            return result;
        }

        function cancel() {
            if (timerId !== undefined) {
                clearTimeout(timerId);
            }
            lastInvokeTime = 0;
            lastArgs = lastCallTime = lastThis = timerId = undefined;
        }

        function flush() {
            return timerId === undefined ? result : trailingEdge(Date.now());
        }

        function debounced() {
            var time = Date.now(),
                isInvoking = shouldInvoke(time);

            lastArgs = arguments;
            lastThis = this;
            lastCallTime = time;

            if (isInvoking) {
                if (timerId === undefined) {
                    return leadingEdge(lastCallTime);
                }
                if (maxing) {
                    // Handle invocations in a tight loop.
                    timerId = setTimeout(timerExpired, wait);
                    return invokeFunc(lastCallTime);
                }
            }

            if (timerId === undefined) {
                timerId = setTimeout(timerExpired, wait);
            }
            return result;
        }

        debounced.cancel = cancel;
        debounced.flush = flush;
        return debounced;
    },

    makeRequestForCheckingIfCouponIsApplicableForCreditCard: function(url, type, cb = () => {}, data = {}) {
        var $cachedDocument = $(document);

        return $.ajax({
            url,
            type,
            data
        }).done((response) => {
            if (response) {
                var $response = $('<div/>').append(response),
                    $summaryContent = $response.find('.js-order-summary'),
                    $billingContent = $response.find('.js-checkout-form');

                if ($summaryContent.length) {
                    $cachedDocument.find('.js-order-summary').empty().html(
                        $response.find('.js-order-summary').remove()
                    );
                }

                if ($billingContent.length) {
                    $cachedDocument.find('.js-checkout-form').remove();
                    $cachedDocument.find('.js-checkout__content').append($response);
                    $cachedDocument.trigger('billing.update');
                } else if ($summaryContent.length) {
                    $cachedDocument.trigger('summary.billing.updated');
                }

                var isCouponAdded = $(response).find('.js-item-remove.js-coupon').length > 0;
                if (isCouponAdded) {
                    $cachedDocument.trigger('coupon.added', [response]);
                }
            }

            if (cb) {
                cb();
            }
        });
    },

    getUrlToValidateCouponForPaymentCreditCard: function(url, params = {}) {
        var selectedPaymentMethod = [].filter.call(
            $('input[name$="_selectedPaymentMethodID"]'),
            (e) => $(e).prop('checked')
        ).pop();

        if (selectedPaymentMethod) {
            params.paymentMethod = $(selectedPaymentMethod).val();
            if (params.paymentMethod === 'CREDIT_CARD') {
                
                var isOneClick = false;
                if ($('#adyenCreditCardList').length) {
                    var selectedCard = $('#adyenCreditCardList').val();
                    if (selectedCard !== "") {
                        isOneClick = true;
                    }
                }
                
                if (!isOneClick) {
                    if (window.CardBrand) {
                        params.creditCardType = window.CardBrand;
                    }
                } else {
                    var creditCartType = $(selectedPaymentMethod)
                        .closest('.js-payment-method-item-container')
                        .find('.js-credit-card-tile.active .js-credit-card-type');

                    if (creditCartType.length && creditCartType.data('value')) {
                        params.creditCardType = creditCartType.data('value')
                    }
                }
            }
        }

        return this.appendParamsToUrl(url, params);
    },

    updateOrAppendUrlParams: function(url, params = {}) {
        var linkUri = util.getUri(url);
        var linkUriUrl = linkUri.url;
        var linkUriQueryParams = linkUri.queryParams;

        for (var key in params) {
            linkUriQueryParams[key] = params[key];
        }

        return util.appendParamsToUrl(linkUriUrl, linkUriQueryParams);
    }
};

module.exports = util;
