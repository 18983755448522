export default {
    getCenter: function (data) {
        var reducer = (sum, n) => sum + n;
        var lat = data.map((obj) => obj.lat).reduce(reducer) / data.length;
        var lng = data.map((obj) => obj.lng).reduce(reducer) / data.length;

        return {
            lat: lat,
            lng: lng
        };
    }
};