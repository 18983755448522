import Component from '_core_ext/core/Component';

export default class TooltipContent extends Component {
    init () {
        super.init();
        this.initConfig(this.settings);
        this.$tooltipContent = this.$el.find('.js-tooltip-content');
        this.initializeEvents();
    }

    checkWidthToEnableMultiRow() {
        if (this.$tooltipContent.width() > 300 && !this.$tooltipContent.hasClass('multi-row')) {
            this.$tooltipContent.addClass('multi-row');
        }
    }

    showContent() {
        this.$tooltipContent.addClass('show');
    }

    hideContent() {
        this.$tooltipContent.removeClass('show');
    }

    initializeEvents() {
        ['mouseenter', 'focus'].forEach((event) => {
            this.event(event, '.js-tooltip-trigger', this.checkWidthToEnableMultiRow.bind(this));
            this.event(event, '.js-tooltip-trigger', this.showContent.bind(this));
        });

        ['mouseleave', 'blur'].forEach((event) => {
            this.event(event, '.js-tooltip-trigger', this.hideContent.bind(this));
        });
    }
}
