import $ from 'jquery';
import EventEmitter from 'events';
import eventMgr from './eventMgr';
import {getMode as viewType} from '_core_ext/layout';

const tryFn = (fn, failFn) => {
    try {
        return fn();
    } catch (e) {
        if (failFn) {
            return failFn(e);
        }
    }
};

export default class Component {
    /**
     * By default components will be selected by selector `[data-cmp="${cmpName}"]`
     * @see js/components.js method selectAndCreateComponents
     *
     * if need use some custome selector, it can be redefined in child classes by redeclaring
     * static "selector" method
     * @example
     * <pre><code>
     * static selector() {
     *     return '.js-component-selector';
     * }
     * </code></pre>
     */
    static get selector() {
        return null;
    }
    /**
     * for correct work, must be redeclared at extended class (if default code level config required)
     *
     * @example
     *
     * <pre><code>
     * get configDefault() {
     *     return {
     *         'attr1' : 'val1',
     *         'attrN' : 'valN'
     *     };
     * }
     * </code></pre>
     */
    get configDefault() {
        return {
            initOnDevice: ['small', 'medium', 'large', 'extra-large']
        };
    }
    constructor (el, config = {}) {
        this.$el = el instanceof $ ? el : $(el);
        this.initConfig(config);
        this.listeners = [];
        this.eventMgrListeners = [];
        this.emitter = new EventEmitter();
        this.items = [];
        if (this.config.cmpId) {
            this.id = this.config.cmpId;
        } else {
            let idAttr = this.$el.attr('id');
            if (idAttr) {
                this.id = idAttr;
            }
            if (!this.id && this.config.id) {
                this.id = this.config.id;
            }
        }
    }
    initConfig(config = {}) {
        var jsonConfigAttr = this.$el.attr('data-json-config'),
            jsonConfig = {};

        if (jsonConfigAttr) {
            tryFn(() => {
                jsonConfig = $.parseJSON(jsonConfigAttr);
                jsonConfig = jsonConfig[viewType()] ? $.extend(true, {}, jsonConfig, jsonConfig[viewType()]) : jsonConfig;
            }, (error) => {
                if (console && console.warn) {
                    console.warn('Error while loading component config', this.$el, error);
                }
            });
        }
        this.config = $.extend(true, {}, this.configDefault, config, jsonConfig, this.$el.data());
    }
    init () {}
    destroy () {
        var i, item, event, $element;

        while ((event = this.listeners.pop())) {
            $element = event.shift();
            $element.off.apply($element, event);
        }
        while ((event = this.eventMgrListeners.pop())) {
            eventMgr.off.apply(eventMgr, event);
        }
        for (i = 0; i < this.items.length; ++i) {
            item = this.items[i];
            if (item) {
                if (typeof item.destroy === 'function') {
                    item.destroy();
                }
                delete this.items[i];
            }
        }
        delete this.$el;
        this.emitter.removeAllListeners();
    }
    isBindedToDom () {
        return this.$el && ((this.$el.parents('html').length > 0) || this.$el.is('html'));
    }
    eligibleOnDevice() {
        return this.config.initOnDevice.indexOf(viewType()) > -1;
    }
    event (eventName, selector, cb, $element) {
        var self = this,
            fn = function () {
                return cb.apply(self, [this].concat(Array.prototype.slice.call(arguments)));
            };

        if ($.isFunction(selector)) {
            $element = cb || self.$el;
            cb = selector;
            $element.on(eventName, fn);
            self.listeners.push([$element, eventName, fn]);
        } else {
            $element = $element || self.$el;
            $element.on(eventName, selector, fn);
            self.listeners.push([$element, eventName, selector, fn]);
        }
        return self;
    }
    on (eventName, cb) {
        return this.emitter.on(eventName, cb);
    }
    off (eventName, cb) {
        return this.emitter.off(eventName, cb);
    }
    once (eventName, cb) {
        return this.emitter.once(eventName, cb);
    }
    eventMgr (event, handler) {
        handler = handler.bind(this);
        eventMgr.on(event, handler);
        this.eventMgrListeners.push([event, handler]);
    }
    callFnForId (id, name, ...args) {
        this.getById(id, (cmp) => {
            cmp[name](...args);
        });
    }
    getById (id, cb) {
        for (var c = 0; c < this.items.length; ++c) {
            if (this.items[c]) {
                if (this.items[c].id === id) {
                    cb.call(this, this.items[c]);
                } else {
                    this.items[c].getById(id, cb);
                }
            }
        }
    }
}