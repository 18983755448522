import Block from '_core_ext/core/common/Block';
import layout from '_core_ext/layout';

export default class Refinement extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.selectedRefinements = this.getSelectedValuesSet();
        this.activeViewMode = 'desktop';
        this.onWindowModeChanged({mode: layout.getMode()});

        this.event('click', '.js-refinement-subitem a', this.onRefinementSelected.bind(this));
        this.event('click', '.js-filters-title', this.onRefinementTitleClick.bind(this));
        this.event('click', '.js-button-back', this.onRefinementClose.bind(this));
        this.eventMgr('window.modechanged', this.onWindowModeChanged.bind(this));
    }

    onWindowModeChanged(modeData) {
        if (!modeData) {
            return;
        }

        var {mode} = modeData;
        if (mode === 'medium' || mode === 'small') {
            this.activeViewMode = 'mobile';
        } else {
            this.activeViewMode = 'desktop';
        }
    }

    getType() {
        return ['attribute', 'price', 'category'].indexOf(this.config.type) < 0 ? 'attribute' : this.config.type;
    }

    getSelectedValues() {
        return [...this.selectedRefinements];
    }

    getSelectedValuesSet() {
        var selectedRefinements = new Set();
        this.$el.find('.js-refinement-subitem.selected').each((i, item) => {
            switch (this.getType()) {
                case 'price':
                    selectedRefinements.add({
                        min: $(item).find('a').data('refinement-price-min'),
                        max: $(item).find('a').data('refinement-price-max')
                    });
                    break;
                default:
                    selectedRefinements.add($(item).find('a').data('refinement-value'));
                    break;
            }
        });

        return selectedRefinements;
    }

    toggleSelectedValue(value) {
        if (this.selectedRefinements.has(value)) {
            this.selectedRefinements.delete(value);
        } else {
            if (!this.config.multiOption) {
                this.selectedRefinements.clear();
            }
            this.selectedRefinements.add(value);
        }
    }

    updateSelectedRefinementsList() {
        var html = '';

        switch (this.getType()) {
            case 'price':
                this.getSelectedValues().forEach((v) => {
                    // eslint-disable-next-line max-len
                    var $selectedValueElement = this.$el.find('.js-refinement-subitem a[data-refinement-price-min="' + v.min + '"][data-refinement-price-max="' + v.max + '"]');
                    if ($selectedValueElement.length) {
                        html += '<div class="c-refinements__subitem--selected">' + $selectedValueElement.html() + '</div>';
                    }
                });
                break;
            default:
                this.getSelectedValues().forEach((v) => {
                    var $selectedValueElement = this.$el.find('.js-refinement-subitem a[data-refinement-value="' + v + '"]');
                    if ($selectedValueElement.length) {
                        html += '<div class="c-refinements__subitem--selected">' + $selectedValueElement.html() + '</div>';
                    }
                });
                break;
        }

        this.$el.find('.js-filters-title .js-selected-refinements-list').html(html);
    }

    onRefinementSelected(el, event) {
        var $el = $(el);
        if ($el.parent().hasClass('unselectable')) {
            return;
        }
        event.preventDefault();

        var refinementValue;
        switch (this.getType()) {
            case 'price':
                refinementValue = {
                    min: $el.data('refinement-price-min'),
                    max: $el.data('refinement-price-max')
                };
                break;
            default:
                refinementValue = $el.data('refinement-value');
                break;
        }

        // Check if refinement supports multiple options applied at the same time
        if (this.config.multiOption) {
            // Toggle selected state indicators of the option
            $el.parent().toggleClass('c-apply--true c-apply--false');
        } else {
            // If not, un-check selected options
            this.$el.find('.js-refinement-subitem.selected').addClass('c-apply--false');
            this.$el.find('.js-refinement-subitem.c-apply--true').removeClass('c-apply--true');
            $el.parent().addClass('c-apply--true');
        }

        this.toggleSelectedValue(refinementValue);
        this.updateSelectedRefinementsList();

        var url = $el.attr('href');
        this.emitter.emit(`${this.id}.refinement.selected`, {
            searchQueryUrl: url,
            refinementId: this.id,
            refinementData: {
                type: this.getType(),
                selectedValues: this.getSelectedValues()
            }
        });
    }

    onRefinementTitleClick(el) {
        if (this.activeViewMode === 'mobile') {
            $(el).closest('.js-filter').addClass('is--expanded');
        } else {
            var $refinementBlock = $(el).closest('.js-filter');
            var params = {
                refinementId: this.id
            };

            $refinementBlock.toggleClass('is--expanded-desktop');

            if ($refinementBlock.hasClass('is--expanded-desktop')) {
                params.isExpanded = true;
            } else {
                params.isExpanded = false;
            }

            this.emitter.emit(`${this.id}.refinement.display.change`, params);
        }
    }

    onRefinementClose(el) {
        $(el).closest('.js-filter').removeClass('is--expanded');
    }

    clearSelectedValues() {
        this.selectedRefinements.clear();
        this.$el.find('.js-refinement-subitem.c-apply--true').removeClass('c-apply--true');
        this.$el.find('.js-filters-title .js-selected-refinements-list').html('');
    }
}