import Block from '_core_ext/core/common/Block';
import layout from '_core_ext/layout';
import util from '_core_ext/util';

export default class HeaderSearch extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.active = false;
        this.isTransparentEnabled = $('.homePageTransparentHeader').val() === 'true';

        this.event('submit', '.js-header-search-form', (el) => {
            let currentQuery = $(el).find('input[name="q"]').val();
            if (!$.trim(currentQuery).length) {
                return false;
            }
        });

        this.event('focus', '.js-simple-search-input', this.onSearchBarFocus.bind(this));
        this.event('blur', '.js-simple-search-input', this.onSearchBarBlur.bind(this));
    }

    isActive() {
        return this.active;
    }

    hideSearchForm() {
        this.active = false;
        this.$el.find('.js-simple-search-input').blur();
        this.emitter.emit('headerSearch.hide');
    }

    showSearchForm(focus) {
        this.active = true;

        if (focus) {
            if (layout.isMobileView()) {
                let addFocusClass = new Promise((resolve) => {
                    util.scrollBrowser(0, 0);
                    resolve();
                });

                addFocusClass.then(() => {
                    this.$el.find('.js-simple-search-input').focus();
                });
            } else {
                this.$el.find('.js-simple-search-input').focus();
            }
        }

        this.emitter.emit('headerSearch.show');
    }

    toggleSearchForm(focus) {
        if (!this.isActive()) {
            this.showSearchForm(focus);
            var currentScroll = $(window).scrollTop();
            if((!currentScroll > 0) && this.isTransparentEnabled) {
                $('.header').removeClass('transparent');
            }
        } else {
            this.hideSearchForm();
            var currentScroll = $(window).scrollTop();
            if((!currentScroll > 0) && this.isTransparentEnabled) {
                $('.header').addClass('transparent');
            }
        }
    }

    onSearchBarFocus() {
        this.emitter.emit('headerSearch.search.bar.focus');
    }

    onSearchBarBlur() {
        this.emitter.emit('headerSearch.search.bar.blur');
    }
}