'use strict';

var ajax = require('./../../ajax'),
    util = require('./../../util');

/**
 * @description loads product's navigation
 **/
module.exports = function () {
    var $pidInput = $('.pdpForm input[name="pid"]').last(),
        $navContainer = $('.js-product-nav-container');
    // if no hash exists, or no pid exists, or nav container does not exist, return
    if (window.location.hash.length <= 1 || $pidInput.length === 0 || $navContainer.length === 0) {
        return;
    }

    var pid = $pidInput.val(),
        hash = window.location.hash.substr(1),
        url = util.appendParamToURL(Urls.productNav + '?' + hash, 'pid', pid);
    if (window.location.search.indexOf('cgid') > -1) {
        var cgid = util.getParameterValueFromUrl('cgid', window.location.search);
        url = util.appendParamToURL(url, 'cgid', cgid);
    }

    ajax.load({
        url: url,
        target: $navContainer
    });
};
