import eventMgr from '_core_ext/core/eventMgr';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';
import util from '_core_ext/util';

var initialized = false;

function submitForm (params) {
    var {url, formData} = params;

    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            type: 'POST',
            url: url,
            data: formData,
            callback: (response) => {
                progress.hide();
                if (response === null) {
                    return rej('Couldnt submit notify me form.');
                }
                res(util.jsonParse(response).message);
            }
        });
    });
}

module.exports.init = function () {
    if (initialized) {
        return;
    }
    eventMgr.registerAction('submit.notify.me.form', submitForm);
    initialized = true;
};