import FormGeneric from '_core_ext/components/forms/FormGeneric';
import ajax from '_core_ext/ajax';

export default class PasswordResetForm extends FormGeneric {
    init() {
        super.init();
        this.submitButton = this.$el.find('[name$="_requestpassword_send"]');
    }

    onSubmit() {
        let selector = this.config.selector ? $(this.config.selector) : this.$el.closest('#dialog-container');

        ajax({
            type: 'POST',
            url: this.$el.attr('action'),
            data: this.$el.serialize() + '&' + this.submitButton.attr('name') + '=',
            target: selector
        });
    }
}
