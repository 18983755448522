'use strict';

var util = require('./../../../app_storefront_core/cartridge/js/util');

function redirect(newURL) {
    setTimeout(function () {
        window.location.href = newURL;
    }, 0);
}

function refresh() {
    setTimeout(function () {
        window.location.assign(window.location.href);
    }, 500);
}

function getPageContextFromDOM($domElement) {
    /**
    * Add data from remote includes to pageContext
    *
    * Example of setting data attribute in remote include rendering template:
    *     <div class="js-page-context"
    *         data-page-context='{any : data}'
    *    '>
    *    </div>
    */
    var $pageContextContainer;

    if ($domElement) {
        $pageContextContainer = $domElement.find('.js-page-context').add($domElement.siblings('.js-page-context'));
    } else {
        $pageContextContainer = $('.js-page-context');
    }

    $pageContextContainer.each(function () {
        var data = $(this).data('pageContext');

        if (data) {
            $.extend(true, window.pageContext, data);
        }
    });
}

function init() {
    getPageContextFromDOM();
}

module.exports = $.extend({
    title: '',
    type: ''
}, window.pageContext, {
    'params': util.getQueryStringParams(window.location.search.substr(1)),
    'redirect': redirect,
    'refresh': refresh,
    'init': init
});