'use strict';

function paymentMethodButtons(){
    // setTimeout(function(){
    //     var paymentMethodWrapper = $('.payment-button');
    //     var checkedID = $('.payment-method-options').find('.js-payment-method-item-container').find('.c-payment-method__radio').find(':checked').attr('id');
    //     var adyenCheckedID = $('.payment-method-options').find('.js-adyen-payment-methods:last').find('.js-adyen-payment-methods-container').find('.c-payment-method__radio').find(':checked').attr('id');
    //     var allInActive = true;
    //     paymentMethodWrapper.each(function(){
    //         if($(this).attr('data-paymenttype') == checkedID || $(this).attr('data-paymenttype') == adyenCheckedID){
    //             $(this).addClass('active');
    //             allInActive = false;
    //         }
    //         if($('.c-applepay__payment-mark').hasClass('active')){
    //             $('.payment-method-options').addClass('is--hidden');
    //         }
    //         if($('.paypal').hasClass('active')){
    //             $('.payment-method-options').addClass('is--hidden');
    //             $('.klarna-account-text').addClass('is--hidden');
    //             $('.paypal-text').removeClass('is--hidden');
    //         }
    //         if($('.button__klarna_account').hasClass('active') || $('.button__klarna_paynow').hasClass('active')){
    //             $('.payment-method-options').addClass('is--hidden');
    //             $('.klarna-account-text').addClass('is--hidden');
    //             $('.paypal-text').addClass('is--hidden');
    //         }
    //         if($('.button__klarna').hasClass('active')){
    //             $('.klarna-account-text').addClass('is--hidden');
    //             $('.paypal-text').addClass('is--hidden');
    //         }
    //     })
    //     if(allInActive == true){
    //         $('.payment-method-options').addClass('is--hidden');
    //     }
    //     $('.adyen-checkout__field--expiryDate').find('.adyen-checkout__label__text').text('CVV *'); 
    // }, 300)
    $('.billingAddress__un-registered-user').prop("checked", true).trigger('click').trigger('change');
    $(document).on('click', '.payment-button', function(e) {
        e.preventDefault();
        $('.payment-button').removeClass('active');
        var $selectPaymentMethod = $('.payment-method-options');
        $selectPaymentMethod.find(':checked').prop("checked", false).trigger('click').trigger('change');
        var selector = '#' + $(this).attr('data-paymenttype');
        $(selector).prop("checked", true).trigger('click').trigger('change');
        $(this).addClass('active');
        if(selector == "#is-PayPal"){
            $('.payment-method-options').addClass('is--hidden');
            $('.klarna-account-text').addClass('is--hidden');
            $('.paypal-text').removeClass('is--hidden');
        }
        else if(selector == "#is-DW_APPLE_PAY"){
            $('.payment-method-options').addClass('is--hidden');
            $('.klarna-account-text').addClass('is--hidden');
            $('.paypal-text').addClass('is--hidden');
        }
        else if(selector == "#klarna_account" || selector == "#klarna_paynow"){
            $('.payment-method-options').addClass('is--hidden');
            $('.klarna-account-text').removeClass('is--hidden');
            $('.paypal-text').addClass('is--hidden');
        }
        else if(selector == "#klarna"){
            $('.c-payment-method.payment-method-options').find('.c-checkout__heading').empty().text('Personal details');
            $('.payment-method-options').removeClass('is--hidden');
            $('.klarna-account-text').addClass('is--hidden');
            $('.paypal-text').addClass('is--hidden');
        }
        else{
            $('.payment-method-options').removeClass('is--hidden');
            $('.klarna-account-text').addClass('is--hidden');
            $('.paypal-text').addClass('is--hidden');
            $('.c-payment-method.payment-method-options').find('.c-checkout__heading').empty().text('PAYMENT DETAILS')
        }
    });
}
function handleCouponCTAStatus(){
    $(document).on('keyup', 'input[name$="_couponCode"]', function (e) {
        e.preventDefault();
        var $couponCode = $('input[name$="_cart_couponCode"]');
        var $couponCodeCTA = $('.js-add-coupon');
        $couponCode.val() === '' ? $couponCodeCTA.prop('disabled', true) : $couponCodeCTA.prop('disabled', false);
    })
}
module.exports = {
    init:  function(){
        paymentMethodButtons();
        handleCouponCTAStatus();
    }
}