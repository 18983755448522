/* eslint-disable no-console */
import EventEmitter from 'events';
import {Promise as Promise} from 'es6-promise-polyfill';

var emitter = new EventEmitter(),
    emitters = [],
    actions = {};

emitter.setMaxListeners(3000);

const eventMgr = {
    getEmitter: (namespace) => {
        if (emitters.indexOf(namespace) > -1) {
            if (window.console) {
                window.console.log('EventMgr: Given namespace "' + namespace +
                    '" already exists. Event emitter creation failed.');
            }
            return {
                emit: () => false
            };
        }
        emitters.push(namespace);
        return {
            emit: function (eventName) {
                try {
                    arguments[0] = namespace + '.' + eventName;
                    return emitter.emit(...arguments);
                } catch (e) {
                    if (window.console) {
                        window.console.error(e);
                    }
                }
            }
        };
    },
    on: (eventName, cb) => emitter.on(eventName, cb),
    off: (eventName, cb) => emitter.removeListener(eventName, cb),
    once: (eventName, cb) => emitter.once(eventName, cb),
    execute: (actionName, params) => {
        if (actions[actionName]) {
            return actions[actionName](params).then((data) => {
                return data;
            }, (reason) => {
                return new Promise((res, rej) => {
                    rej(reason);
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                reject(new Error(`Action is not found for "${actionName}"`));
            });
        }
    },
    registerAction: (actionName, handler) => {
        if (actions[actionName] && window.console) {
            window.console.warn(`Overriding "${actionName}"!`);
        }
        actions[actionName] = handler;
    }
};

// make it global
window.eventMgr = eventMgr;

export default eventMgr;
