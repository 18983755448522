'use strict';

var $cache = {},
    layout = require('./layout');


function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.html = $('html');
    $cache.body = $('body');
    $cache.background = $('[data-background]');
}

function initializeListeners() {
    $cache.document
        .on('quickview.opened', () => {
            require('./components').initPage('product', 'quickview');
        })
        .on('search.updated recommendations.loaded recommendations.initPredictiveRecommendations', () => {
            require('picturefill')();
        })
        .on('click', '.js-search-icon', () => {
            if (!$.trim($('.js-header-search-input').val()).length) {
                return false;
            }
        })
        .on('click', '.js-simplesearch-button', (e) => {
            if (!$('.js-simplesearch-value').val().length) {
                e.preventDefault();
            }
        })
        .on('click', '.recommendation-carousel .js-swiper-button-next', (e) => {
            $(e.target).closest('.js-swiper').addClass('swiper-next-clicked');
        })
        .on('click', '.recommendation-carousel .js-swiper-button-prev', (e) => {
            $(e.target).closest('.js-swiper').removeClass('swiper-next-clicked');
        })
        // Add class for disabling focus styles during mouse use
        .on('keyup', (e) => {
            if (e.keyCode === 9) { // check on Tab press key
                $cache.html.removeClass('intent-mouse');
            }
        })
        .on('mousedown', () => {
            $cache.html.addClass('intent-mouse');
        })
        // Imulate click event for Enter key press
        .on('keyup', '[role="button"]', function(e) {
            if (e.keyCode === 13) {
                $(this).click();
            }
        })
        .on('focus', '.js-input--has-arrow input', () => {
            $('.js-input--has-arrow').removeClass('o-input--has-arrow');
        })
        .on('window.resize', () => {
            if (layout.isDesktop() && $cache.body.find('.ui-dialog').length) {
                $cache.body.find('#dialog-container').dialog('option', 'position', {my: 'center', at: 'center', of: window});
            }
        });
    $cache.window
        .bind('pageshow', (event) => {
            if (event.originalEvent.persisted) {
                $(document).trigger('recentlyviewed.reload');
            }
        });
    if (layout.isMobileView()) {
        $cache.document
            .on('focus', '.pt_checkout .c-checkout__input-row input', () => {
                var $ordersumsticky = $cache.body.find('.c-ordersummary-sticky');

                if ($ordersumsticky.length) {
                    $ordersumsticky.hide();
                }
            })
            .on('blur', '.pt_checkout .c-checkout__input-row input', () => {
                var $ordersumsticky = $cache.body.find('.c-ordersummary-sticky');

                if ($ordersumsticky.length) {
                    $ordersumsticky.removeAttr('style');
                }
            });
    }
}

/**
 * @description Allows color and image used in the full width background heading strip to be read and set from the content asset
 * @example <h1 data-background data-background-color='white' data-background-image='//example.com/example.jpg' />
 */
function setBackgroundStrip() {
    var $el = $cache.background;

    if (!$el.length) {
        return;
    }

    var backgroundColor = $el.data('background-color') ? $el.data('background-color') : '',
        backgroundImage = $el.data('background-color') ? 'url("' + $el.data('background-image') + '")' : '';

    $('.js-full-width-background').css({
        backgroundColor: backgroundColor,
        backgroundImage: backgroundImage
    });
}

function initializeDOM() {
    setBackgroundStrip();
}

module.exports = {
    init: function () {
        initializeCache();
        initializeDOM();
        initializeListeners();
    }
};
