'use strict';

var isTransparentEnabled = $('.homePageTransparentHeader').val() === 'true';

function stickyHeaderFcn() {
    const body = document.body;
    const scrollUp = "scroll-up";
    const scrollDown = "scroll-down";
    const header = $('.header');
    let lastScroll = 0;

    window.addEventListener("scroll", () => {
        const currentScroll = window.pageYOffset;
        if (currentScroll <= 0 || header.hasClass('suggestion-show')) {
            if (isTransparentEnabled) {
                header.addClass('transparent');
                if ($('html').hasClass('is--header-search-show')) {
                    header.removeClass('transparent');
                }
            }
            body.classList.remove(scrollUp);
            return;
        } else if(isTransparentEnabled) {
            header.removeClass('transparent');
        }

        if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) { // down
            body.classList.remove(scrollUp);
            body.classList.add(scrollDown);
        } else if (currentScroll < lastScroll && body.classList.contains(scrollDown)) { // up
            body.classList.remove(scrollDown);
            body.classList.add(scrollUp);
        }
        lastScroll = currentScroll;
    });
}

function calcHeaderHeight() {
    let header = $('.header');
    if (header.length > 0) {
        let headerHeight = header.outerHeight();
        if ($('div.c-product-result__wrapper').length > 0) {
            $('div.c-product-result__wrapper').css('margin-top', headerHeight + 12);
        } else {
            $('#main').css('margin-top', headerHeight);
        }
    }
}

module.exports = {
    init: function() {
        var currentScroll = $(window).scrollTop();
        if (!(currentScroll > 0) && isTransparentEnabled) {
            $('.header').addClass('transparent');
        } else {
            $('.header').removeClass('transparent');
        }
        stickyHeaderFcn();
        $(window).on('load', function() {
            calcHeaderHeight();
        });
    }
}
