import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';
const emitter = eventMgr.getEmitter('address');

export default class AddressList extends Block {
    init () {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.checkoutStep = pageContext.pipelineName.indexOf('COShipping') > -1 ? '2' : '3';
        this.$document = $(document);
        this.$list = this.$el.find('.js-checkout-address-list');
        this.$addNewAddress = this.$el.find('.js-add-new-address');
        this.$addressFields = this.$el.find('.js-address-fields');
        this.$addressActions = this.$el.find('.js-show-less-addresses, .js-add-new-address span');
        this.$miniaddresses = this.$el.find('.js-minicheckoutaddress');
        this.$selectedAddress = this.$el.find('.js-selected-address-wrapper');
        this.$showAllAddresses = this.$el.find('.js-show-addresses');
        this.$showLessAddresses = this.$el.find('.js-show-less-addresses');

        this.event('click', '.js-add-new-address', this.onAddNewAddress.bind(this));
        this.event('click', '.js-select-stored-address', this.onAddressSelection.bind(this));
        this.event('click', '.js-edit-stored-address', this.onSelectedAddressEdit.bind(this));
        this.event('click', '.js-selected-address-wrapper', this.onSelectedAddressClick.bind(this));

        if (this.$list.length) {
            this.event('addresslist.close',() => {
                this.toggleAddressListView(false);
                this.$miniaddresses.removeClass('is--selected').find('input[type="radio"]').prop('checked', false);
            });
            this.event('addresslist.open', () => this.toggleAddressListView(true));
            this.event('click', '.js-show-addresses', () => {
                this.toggleAddressListView(true);
                emitter.emit('addresslist.show');
            });
            this.event('click', '.js-show-less-addresses', () => {
                this.toggleAddressListView(false);
                emitter.emit('addresslist.less');
            });
        }
    }

    toggleAddressListView(expandList) {
        this.$showLessAddresses.toggleClass('is--hidden', !expandList);
        this.$list.toggleClass('is--hidden', !expandList);
        this.$showAllAddresses.toggleClass('is--hidden', expandList);
        this.$selectedAddress.toggleClass('is--hidden', expandList);
    }

    getMiniAddress (addressId) {
        var miniAddressId;
        return this.$miniaddresses.filter((i, el) => {
            miniAddressId = $(el).find('input').data('value');
            return miniAddressId === addressId;
        });
    }

    onAddNewAddress () {
        this.toggleAddressListView(!this.$selectedAddress.data('address'));
        this.$addNewAddress.addClass('is--hidden');
        this.$miniaddresses.removeClass('is--selected');
        this.emitter.emit('addressList.add.new.address');
        emitter.emit('addressform.toggle');
    }

    onAddressSelection (el) {
        var $el = $(el);
        var selectedAddress = $el.data('address');
        if (!selectedAddress) {
            return;
        }

        var deliveryAddressInfo = this.getDeliveryAddressInfo(selectedAddress);
        var $miniaddress = this.getMiniAddress(selectedAddress.ID);

        selectedAddress.searchaddress = $el.data('value');
        this.selectMiniAddress($miniaddress, selectedAddress);
        this.toggleAddressListView(false);
        this.$addNewAddress.removeClass('is--hidden');
        this.emitter.emit('addressList.address.selected', deliveryAddressInfo, selectedAddress);
    }

    selectMiniAddress ($miniAddress, selectedAddressInfo) {
        this.$miniaddresses.removeClass('is--selected');
        $miniAddress.addClass('is--selected');
        this.$miniaddresses.find('.js-select-stored-address').removeClass('js-is-address-selected');
        $miniAddress.find('.js-select-stored-address').addClass('js-is-address-selected');
        this.$selectedAddress.data('address', selectedAddressInfo);
        this.$selectedAddress.data('aid', selectedAddressInfo.ID);
        this.$selectedAddress.find('.address').html($miniAddress.find('.js-personal-data').html());
    }

    getDeliveryAddressInfo (selectedAddress) {
        var deliveryAddressInfo = {};
        [
            'address1',
            'address2',
            'countryCode',
            'stateCode',
            'postalCode',
            'city'
        ].forEach((field) => {
            if (field === 'countryCode') {
                deliveryAddressInfo.country = selectedAddress[field];
            }
            deliveryAddressInfo[field] = selectedAddress[field];
        });

        return deliveryAddressInfo;
    }

    onSelectedAddressClick (el) {
        var $el = $(el);
        var selectedAddressId = $el.data('aid');
        var selectedAddress = $el.data('address');

        if (!selectedAddress) {
            return;
        }
        this.$addNewAddress.removeClass('is--hidden');
        this.getMiniAddress(selectedAddressId).addClass('is--selected');
        this.emitter.emit('addressList.selected.address.click', selectedAddress);
    }

    onSelectedAddressEdit (el) {
        var $el = $(el);
        var selectedAddress = $el.data('address');

        if (!selectedAddress) {
            return;
        }

        this.emitter.emit('addressList.selected.address.edit', selectedAddress);
    }

    toggle (disable) {
        this.$el.toggleClass('is--hidden', disable);
    }

    updateStoredAddressInfo ($miniAddress, newAddressInfo) {
        $miniAddress.find('.js-select-stored-address').data('address', newAddressInfo);
        $miniAddress.find('.js-edit-stored-address').data('address', newAddressInfo);
        var $field;
        for (var field in newAddressInfo) {
            $field = $miniAddress.find(`.js-personal-data .js-minishipping-address-${field}`);
            if ($field.length) {
                $field.text(newAddressInfo[field]);
            }
        }
    }

    changeAddress (addressId) {
        var $miniAddress = this.getMiniAddress(addressId);
        var newAddressInfo = $miniAddress.find('.js-select-stored-address').data('address');
        this.selectMiniAddress($miniAddress, newAddressInfo);
    }

    handleStoredAddressEdition (addressId, newAddressInfo) {
        var $miniAddress = this.getMiniAddress(addressId);
        this.updateStoredAddressInfo($miniAddress, newAddressInfo);
        this.selectMiniAddress($miniAddress, newAddressInfo);
        this.toggleAddressListView(false);
        this.$addNewAddress.removeClass('is--hidden');
    }

    handleCancelAddressEdition () {
        this.toggleAddressListView(false);
        this.$addNewAddress.removeClass('is--hidden');
    }

    getCurrentSelectedAddressInfo () {
        return this.$miniaddresses.find('.js-select-stored-address.js-is-address-selected').data('address');
    }
}
