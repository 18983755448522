import Account from '_core_ext/components/forms/Account';
import page from '_core_ext/page';

export default class ChangeLogin extends Account {
    init() {
        super.init();
        this.event('click', '.js-cancel-change-login', this.cancel.bind(this));
    }

    isNewLoginEqual(val) {
        return val === $('.js-login-email').val();
    }

    extendValidatorMethods() {
        super.extendValidatorMethods();

        $.validator.addMethod('isNewLoginEqual', this.isNewLoginEqual.bind(this) , Resources.VALIDATE_NEW_LOGIN_CONFIRM);
        $.validator.addClassRules({
            'js-login-email-confirm': {
                isNewLoginEqual: true
            }
        });
    }

    onSubmitSuccess(response) {
        page.redirect(response.redirectUrl);
    }

    cancel(el, event) {
        event.preventDefault();
        page.redirect(this.config.cancelRedirectUrl);
    }
}