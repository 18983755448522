'use strict';

var $cache = {},
    options = {
        sourceSelector: '.js-dropdown',
        triggerButton: '.js-dropdown-button',
        expandedClass: 'is--expanded',
        disableTransitionClass: 'js-no-transition',
        closeOnClickOutside: true
    },
    dropdownArray = [];


function dropdownHandler(index, item) {
    var $element = $(item),
        dropdownOptions = $.extend(true, {}, options, $element.data('dropdownOptions') || {});

    // toggle 'is--expanded' class for dropdown component. Visibility implemented via css
    $element.find(dropdownOptions.triggerButton)
        .off('click')
        .click(function(e) {
            e.preventDefault();
            $(this).parent().toggleClass(dropdownOptions.expandedClass);
        });

    /**
    * @description Check if close on Outside click enabled (Enabled by default)
    * @example <div class="js-dropdown o-dropdown" data-dropdown-options='{ "closeOnClickOutside" : false }> ...
    */
    if (dropdownOptions.closeOnClickOutside) {
        dropdownArray.push({
            $element: $element,
            dropdownOptions: dropdownOptions
        });
    }

    /**
    * @description Check if dropdown should have a listener for triggered open/close events
    * @example <div class="js-dropdown o-dropdown" data-dropdown-options='{ "enableToggleEvents": true }> ...
    */
    if (dropdownOptions.enableToggleEvents) {
        $element
            .off('dropdown.open dropdown.close')
            .on('dropdown.open dropdown.close', function(e, disableTransition) {
                e.stopPropagation();
                $(this).toggleClass(dropdownOptions.expandedClass, e.namespace === 'open');
                if (disableTransition) {
                    setTimeout(() => $cache.body.removeClass(options.disableTransitionClass), 0);
                }
            });
    }
}

function initializeCache() {
    $cache.document = $(document);
    $cache.body = $('body');
}

function initializeParams(params) {
    $.extend(true, options, params);
}

function initializeEvents() {
    var $dropdownElements = $cache.document.find(options.sourceSelector);
    dropdownArray = [];
    $dropdownElements.each(dropdownHandler);
    $cache.document
        .off('click.outside')
        .on('click.outside', (e) => {
            dropdownArray.forEach((item) => {
                if (!item.$element.is(e.target) && item.$element.has(e.target).length === 0) {
                    item.$element.removeClass(item.dropdownOptions.expandedClass);
                }
            });
        });
}

function initDropdown() {
    initializeCache();
    initializeEvents();
}

function initializeGlobalEvents() {
    if (options.globalEvents) {
        $cache.document
            .off(options.globalEvents)
            .on(options.globalEvents, initDropdown);
    }
}

module.exports = {
    init: function (params) {
        initializeParams(params);
        initializeCache();
        initializeGlobalEvents();
        initializeEvents();
    }
};
