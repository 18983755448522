import InputGeneric from './InputGeneric';

export default class Button extends InputGeneric {
    init () {
        //super.init();
        if (!this.id) {
            this.id = this.$el.data('id');
        }

        this.event('click', this.onClick);

        this.shown = true;
        this.$field = this.$el;
    }
    onClick (el, event) {
        if (!this.$el.prop('disabled')) {
            if (!this.config.dontPrevent) {
                event.preventDefault();
            }
            this.emitter.emit('click', this);
        }
    }
    getValue () {
        return this.$el.text();
    }
    setValue (val = '') {
        this.setError();
        return this.$el.text(val);
    }
    setError (err = '') {
        if (err) {
            this.$el.addClass('m-error');
            this.$el.text(err);
        } else {
            this.$el.removeClass('m-error');
            this.$el.text('');
        }
    }
    isValid () {
        return true;
    }
    validate () {
        return true;
    }
    click () {
        this.$el.trigger('click');
    }
    skipSubmission () {
        return true;
    }
}