import eventMgr from '_core_ext/core/eventMgr';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';
import util from '_core_ext/util';


function getLocationsPicker (params) {
    var {url, action, formData, coordinates, shippingMethodId} = params;
    url = util.appendParamToURL(url, 'shippingMethodId', shippingMethodId);

    if (coordinates) {
        url = util.appendParamsToUrl(url, coordinates);
    }

    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            url: url,
            type: 'POST',
            data: action + '=' + action + '&' + $.param(formData),
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t get locations picker');
                }
                res(data);
            }
        });
    });
}

function getStoreMiniaddress (params) {
    var {storeId} = params;
    var url = util.appendParamToURL(Urls.getStoreMiniaddress, 'sid', storeId);

    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Could\'t store mini address');
                }
                res(data);
            }
        });
    });
}

module.exports.init = function () {
    eventMgr.registerAction('get.locations.picker', getLocationsPicker);
    eventMgr.registerAction('get.store.mini.address', getStoreMiniaddress);
};