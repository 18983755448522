import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';

export default class ShippingMethodsSelector extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.currentShippingMethodId = this.$el.find('input[name="selected_shipping_method"]:checked').val();
        this.currentShippingMethodInfo = {};

        this.subscribeToChildrenEvents();
        this.getById(`shippingMethod-${this.currentShippingMethodId}`, (shippingMethod) => {
            this.setShippingMethod(shippingMethod.getShippingMethodInfo());
        });
    }

    subscribeToChildrenEvents () {
        this.eachChild((shippingMethod) => {
            shippingMethod.on('shippingMethod.selected', this.handleShippingMethodChange.bind(this));
        });
    }

    handleShippingMethodChange (shippingMethodInfo, $shippingMethod) {
        this.setShippingMethod(shippingMethodInfo);
        this.changeShippingMethod($shippingMethod);
        this.emitter.emit('shippingMethodsSelector.on.shipping.method.selected', shippingMethodInfo);
    }

    setShippingMethod (shippingMethodInfo) {
        var {shippingMethodId} = shippingMethodInfo;
        this.currentShippingMethodId = shippingMethodId;
        this.currentShippingMethodInfo = shippingMethodInfo;
    }

    selectShippingMethod (shippingMethodInfo, regionId, deliveryAddressInfo) {
        var {shippingMethodId} = shippingMethodInfo;
        return eventMgr.execute('select.shipping.method', {
            deliveryAddressInfo: deliveryAddressInfo,
            shippingMethodId: shippingMethodId,
            regionId: regionId
        });
    }

    getShippingMethods (regionId) {
        return eventMgr.execute('get.shipping.methods.by.region', {
            regionId: regionId
        });
    }

    updateShippingMethods (shippingMethods) {
        this.$el.find('.js-available-shipping-methods-list').empty().html(shippingMethods);
        $('.js-shipping-method__item').each(function(){
            if($(this).find('input').is(':checked')){
                $(this).parents('.shippingMethods').addClass('active');
            }
        })
        return eventMgr.execute('components.update', this.$el);
    }

    handleShippingMethodsUpdate (regionId) {
        return this.getShippingMethods(regionId)
            .then((shippingMethods) => {
                return this.updateShippingMethods(shippingMethods);
            })
            .then(() => {
                this.currentShippingMethodId = this.$el.find('input[name="selected_shipping_method"]:checked').val();
                this.subscribeToChildrenEvents();
                this.getById(`shippingMethod-${this.currentShippingMethodId}`, (shippingMethod) => {
                    this.setShippingMethod(shippingMethod.getShippingMethodInfo());
                });
                return this.currentShippingMethodInfo;
            });
    }

    getCurrentSelectedShippingMethodInfo () {
        return this.currentShippingMethodInfo;
    }

    changeShippingMethod ($shippingMethod) {
        this.$el.find('input[name="selected_shipping_method"]:checked').prop('checked', '');
        $shippingMethod.find('input').prop('checked', 'checked');
    }

    getAvailableShippingMethodsInfo (deliveryAddressInfo, deliveryOption, regionId, checkCostNeeded) {
        return eventMgr.execute('get.available.shipping.methods.info', {
            deliveryAddressInfo: deliveryAddressInfo,
            deliveryOption: deliveryOption,
            regionId: regionId,
            checkCostNeeded: checkCostNeeded || false
        });
    }

    validateSelectedShippingMethod (deliveryAddressInfo, deliveryOption, regionId, storeId, shippingMethodId) {
        return eventMgr.execute('validate.selected.shipping.method', {
            deliveryAddressInfo: deliveryAddressInfo,
            deliveryOption: deliveryOption,
            regionId: regionId,
            storeId: storeId,
            shippingMethodId: shippingMethodId
        });
    }

    getAvailableShippingMethodsCostInfo (deliveryAddressInfo, deliveryOption, regionId, storeId) {
        return eventMgr.execute('get.available.shipping.methods.cost.info', {
            deliveryAddressInfo: deliveryAddressInfo,
            deliveryOption: deliveryOption,
            regionId: regionId,
            storeId: storeId,
        });
    }

    updateShippingMethodsAfterCouponSubmit (deliveryAddressInfo, deliveryOption, regionId, storeId, isCouponError) {
        this.getAvailableShippingMethodsCostInfo(deliveryAddressInfo, deliveryOption, regionId, storeId)
            .then((availableShippingMethodsInfo) => {
                var shippingMethodIDs = Object.keys(availableShippingMethodsInfo);
                var shippingCostData = {};
                if (!shippingMethodIDs.length) {
                    return;
                }
                shippingMethodIDs.forEach((shippingMethodId) => {
                    this.getById(`shippingMethod-${shippingMethodId}`, (shippingMethod) => {
                        shippingCostData = availableShippingMethodsInfo[shippingMethodId];
                        if (!Object.keys(shippingCostData).length) {
                            return;
                        }
                        shippingMethod.updatefterCouponSubmit(shippingCostData, isCouponError);
                    });
                });
            });
    }
}