import Component from '_core_ext/core/Component';

export default class Tooltip extends Component {
    init () {
        new global.Tooltip(this.$el, {
            placement: this.config.placement || 'top',
            title: this.config.title || 'Tooltip title default'
        });
    }
}
