import FormGeneric from '_core_ext/components/forms/FormGeneric';
import ajax from '_core_ext/ajax';

export default class LoginWithLoyaltyCheck extends FormGeneric {

    init() {
        super.init();

        this.selector = '[type$="email"]';
        this.event('blur', this.selector, this.checkOcapiLoyaltyUser);
    }

    checkOcapiLoyaltyUser() {
        var email = this.$el.find(this.selector).val();

        ajax({
            url: this.config.url,
            type: 'POST',
            data: {
                email: email
            }
        }).then((response) => {
            if (response) {
                $('.c-login').replaceWith(response);
            }
        });
    }
}