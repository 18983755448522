'use strict';

const cookie = require('./cookie');

/**
 * Checks if customer is visiting top-level category and sets cookie with this category ID (site-specific)
 * otherwise same value is retrieved from cookie and logo link URL is updated with parameter 'cgid'
 */
function setVisitedCategory() {
    if (pageContext.topLevelCategory) {
        cookie.set(Resources.COOKIE_VISITED_CATEGORY, pageContext.topLevelCategory);
    }
}

/**
 * @description prevent scrolling
 */

function preventScrollingOnSafari() {
    var _overlay = document.getElementsByClassName('js-overlay-nav')[0];
    var _clientY = null;

    function isOverlayTotallyScrolled() {
        return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
    }

    function disableRubberBand(event) {
        var clientY = event.targetTouches[0].clientY - _clientY;

        if (_overlay.scrollTop === 0 && clientY > 0) {
            event.preventDefault();
        }

        if (isOverlayTotallyScrolled() && clientY < 0) {
            event.preventDefault();
        }
    }

    _overlay.addEventListener('touchstart', (event) => {
        if (event.targetTouches.length === 1) {
            _clientY = event.targetTouches[0].clientY;
        }
    }, false);

    _overlay.addEventListener('touchmove', (event) => {
        if (event.targetTouches.length === 1) {
            disableRubberBand(event);
        }
    }, false);
};

/**
 * @function
 * @description init header elements
 */
function init() {
    if (SitePreferences.CATEGORY_REDIRECT_ENABLED) {
        setVisitedCategory();
    }
}

module.exports = {
    init: function(params) {
        init(params);
        //Image Lazy Load Script 
        /** First we get all the non-loaded image elements **/
        var lazyImages = [].slice.call(document.querySelectorAll(".lazyload"));
        /** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element **/
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.classList.remove("lazy");
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });
        /** Now observe all the non-loaded images using the observer we have setup above **/
        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    }
};
