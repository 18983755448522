import Component from '_core_ext/core/Component';
import util from '_core_ext/util';
import imagesLoaded from 'imagesloaded';
import eventMgr from '_core_ext/core/eventMgr';
import ajax from '_core_ext/ajax';
import {getMode as viewType} from '_core_ext/layout';

const emitter = eventMgr.getEmitter('dialog');

export default class Dialog extends Component {
    init() {
        this.initConfig(this.settings);

        if (this.eligibleOnDevice()) {
            if (!this.config.staticContent) {
                this.constructDialogURL();
            }

            if (!this.referencesLoaded) {
                this.getReferences();
            }

            this.initializeCache();
            this.initializeEvents();

            if (this.config.autoOpen) {
                this.open();
            }
        }
    }

    constructDialogURL() {
        this.dialogUrl = util.appendParamToURL((this.$el.attr('href') || this.config.url), 'format', 'ajax');

        if (this.config.URLParams) {
            this.dialogUrl = util.appendParamsToUrl(this.dialogUrl, this.config.URLParams);
        }
    }

    initializeCache() {
        this.$cache = {
            contentContainer: this.$el.siblings(this.config.contentContainer)
        };
    }

    initializeEvents() {
        this.event('click', this.onClick);
        this.eventMgr('ajax.contentUpdated', this.onContentUpdate);
        this.eventMgr('window.modechanged', this.onContentUpdate);
        this.eventMgr('window.resize', this.onContentUpdate);
    }

    onContentUpdate() {
        if (this.$container) {
            this.$container.dialog('option', 'position', this.config.position);
        }
    }

    onClick(el, event) {
        event.preventDefault();
        this.open();
    }

    getReferences() {
        if (!('ui' in $)) {
            require('jquery-ui-dist/jquery-ui');
        }
        if (!('focusable' in $.ui)) {
            require('jquery-ui/ui/focusable');
        }
        if (!('tabbable' in $.ui)) {
            require('jquery-ui/ui/tabbable');
        }
        if (!('data' in $.ui)) {
            require('jquery-ui/ui/data');
        }
        if (!('position' in $.ui)) {
            require('jquery-ui/ui/position');
        }
        if (!('widget' in $.ui)) {
            require('jquery-ui/ui/widget');
        }
        if (!('unique-id' in $.ui)) {
            require('jquery-ui/ui/unique-id');
        }
        if (!('button' in $.ui)) {
            require('jquery-ui/ui/widgets/button');
        }
        if (!('dialog' in $.ui)) {
            require('jquery-ui/ui/widgets/dialog');
        }
        if (!('safe-active-element' in $.ui)) {
            require('jquery-ui/ui/safe-active-element');
        }

        this.referencesLoaded = true;
    }

    create() {
        var $target;
        if (typeof this.config.target === 'string') {
            $target = $(this.config.target);
        } else if (this.config.target instanceof jQuery) {
            $target = this.config.target;
        } else {
            $target = $('#dialog-container');
        }

        // if no element found, create one
        if ($target.length === 0) {
            $target = $('<div>').attr('id', 'dialog-container').addClass('dialog-content').appendTo('body');
        }

        if (this.config.additionalClasses) {
            $target.addClass(this.config.additionalClasses);
        }

        this.$container = $target;
        this.attachContainerEvents();
        this.$container.dialog(this.config);
    }

    onDialogOpen() {
        if (this.config.closeOnOutsideClick) {
            var $overlay = this.$container.data('ui-dialog').overlay || '';
            if (!$overlay.length) {
                return;
            }
            $overlay.one('click', () => {
                this.close();
            }).data('dialog', this.$container);
        }

        if (!this.config.autofocusOnFirst) {
            this.$container.find(':tabbable:first').blur();
        }

        $('html').addClass('is--dialog-opened');
        this.preventScrollingOnSafari();

        emitter.emit('open');
    }

    onDialogClose() {
        $('html').removeClass('is--dialog-opened');

        if (this.config.emptyOnClose) {
            this.$container.empty();
        }

        emitter.emit('close');
        this.$container.off('dialogopen');
        this.$container.off('dialogclose');
        this.$container.parent().off('scroll');
    }

    attachContainerEvents() {
        this.$container.on('dialogopen', this.onDialogOpen.bind(this));
        this.$container.on('dialogclose', this.onDialogClose.bind(this));
    }

    open() {
        this.close();
        this.create();
        this.replace();
        this.attachDialogOpenEvents();

        if (this.config.delayForAlignPosition) {
            setTimeout(() => {
                this.onContentUpdate();
            },500);
        }
    }

    attachDialogOpenEvents() {
        this.$container.parent().on('scroll', () => {
            $(document).trigger('dialog.scroll').delay(50);
        });

        if (this.config.closeButton) {
            this.event('click', this.config.closeButton, () => {
                this.close();
            }, this.$container);
        }

        this.event('dialog.close', () => {
            this.close();
        }, this.$container);
    }

    replace() {
        if (!this.$container) {
            return;
        }

        const jsonConfig = this.$el.data().jsonConfig;

        if (
            jsonConfig &&
            jsonConfig.disableLazyLoad &&
            this.$cache.contentContainer.length
        ) {
            this.config.content = this.$cache.contentContainer.html();
            this.openWithContent();
        } else if (this.dialogUrl) {
            ajax.load({
                url: this.dialogUrl,
                type: 'GET',
                data: this.config.data,
                callback: function (response) {
                    this.config.content = response;
                    this.openWithContent();
                }.bind(this)
            });
        } else if (this.config.html || this.config.content) {
            this.openWithContent();
        }
    }

    openWithContent() {
        if (!this.$container) {
            return;
        }

        var content = this.config.content || this.config.html;

        if (!content) {
            return;
        }

        if (this.$el.data().jsonConfig) {
            Object.keys(this.$el.data().jsonConfig.replacePlaceholders || {}).forEach((fieldName) => {
                content = content.replace('{' + fieldName + '}', this.config.replacePlaceholders[fieldName]);
            });
        }

        this.$container.empty().html(content);

        if (!this.isOpen()) {
            this.$container.dialog('open');
        }

        imagesLoaded(this.$container).on('done', () => {
            this.$container.dialog('option', 'position', this.config.position);
        });

        if (this.config.callback) {
            this.config.callback();
        }
    }

    close() {
        if (!this.$container) {
            return;
        }
        this.$container.dialog('close');
    }

    isOpen() {
        return this.$container && this.$container.dialog('isOpen');
    }

    /* eslint-disable */
    /**
     * preventScrollingOnSafari fix source:
     * https://stackoverflow.com/questions/41594997/ios-10-safari-prevent-scrolling-behind-a-fixed-overlay-and-maintain-scroll-posi
    */
    /* eslint-enable */

    preventScrollingOnSafari() {
        var _overlay = document.getElementsByClassName('ui-widget-overlay')[0];
        var _clientY = null;

        function isOverlayTotallyScrolled() {
            return _overlay.scrollHeight - _overlay.scrollTop <= _overlay.clientHeight;
        }

        function disableRubberBand(event) {
            var clientY = event.targetTouches[0].clientY - _clientY;

            if (_overlay.scrollTop === 0 && clientY > 0) {
                event.preventDefault();
            }

            if (isOverlayTotallyScrolled() && clientY < 0) {
                event.preventDefault();
            }
        }

        _overlay.addEventListener('touchstart', (event) => {
            if (event.targetTouches.length === 1) {
                _clientY = event.targetTouches[0].clientY;
            }
        }, false);

        _overlay.addEventListener('touchmove', (event) => {
            if (event.targetTouches.length === 1) {
                disableRubberBand(event);
            }
        }, false);
    }

    get settings() {
        return {
            autoOpen: false,
            height: 'auto',
            modal: true,
            overlay: {
                opacity: 0.5,
                background: 'black'
            },
            closeText: '',
            resizable: false,
            responsive: true,
            title: null,
            width: 'auto',
            close: this.close.bind(this),
            position: {
                my: 'center',
                at: 'center',
                of: window,
                collision: viewType() === 'desktop' ? 'fit' : 'flipfit'
            },
            emptyOnClose: true,
            closeOnOutsideClick: true,
            autofocusOnFirst: false,
            contentContainer: '.js-content-container'
        };
    }
}
