import Block from '_core_ext/core/common/Block';

export default class PdpVideos extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
    }

    update(data) {
        var updateHtml = $('<div>');
        for (var i = 0; i < data.length; i++) {
            var cmpBlock = $('<div>');
            cmpBlock.attr('data-cmp', 'iframeVideo');
            cmpBlock.attr('data-json-config', JSON.stringify({
                "id": "iframeVideo_" + i,
                "width": data[i].width,
                "height": data[i].height,
                "src": data[i].src || '',
                "lazyLoad": true,
                "autoScale": true
            }));
            updateHtml.append(cmpBlock);
        }

        this.$el.find('.js-product-videos-wrap').html(updateHtml.html());
    }
}