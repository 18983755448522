import FormGeneric from '_core_ext/components/forms/FormGeneric';
import util from '_core_ext/util';
import ajax from '_core_ext/ajax';
import page from '_core_ext/page';

export default class AddressForm extends FormGeneric {
    init() {
        super.init();

        if (this.eligibleOnDevice()) {
            this.initializeAddressEvents();
            this.$el.find('select[name$="_country"]').trigger('change', [this.$el, this.$el.find('[name$="state"]').val()]);
        }
    }

    initializeAddressEvents() {
        this.$el.on('click', '.js-edit-address-delete', this.onDelete.bind(this));
    }

    onSubmit() {
        var applyName = this.$el.find('.js-edit-address-submit').attr('name');

        ajax({
            url: this.$el.attr('action'),
            data: this.$el.serialize() + '&' + applyName + '=' + applyName,
            dataType: 'json',
            type: 'POST',
            callback: this.onSubmitSuccess.bind(this)
        });
    }

    onSubmitSuccess(data) {
        if (typeof(data) !== 'string') {
            if (data.success) {
                page.refresh();
            } else if (data.error) {
                page.redirect(Urls.csrffailed);
            } else {
                window.alert(data.message);
                return false;
            }
        } else {
            $('#dialog-container').html(data);
            $(document).trigger('tooltip.init', [$('#dialog-container')]);
        }
    }

    onDelete() {
        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            this.skipValidation = true;
            var url = util.appendParamsToUrl(Urls.deleteAddress, {
                AddressID: this.$el.find('#addressid').val(),
                format: 'ajax'
            });

            ajax({
                url: url,
                method: 'POST',
                dataType: 'json',
                callback: function (data) {
                    if (data.message.length > 0) {
                        window.alert(data.message);
                        return false;
                    } else {
                        page.refresh();
                    }
                }
            });
        }
    }
}
