import Block from '_core_ext/core/common/Block';
import ajax from '_core_ext/ajax';
import page from '_core_ext/page';

export default class loginForm extends Block {
    init() {
        this.initializeAddressEvents();
    }

    initializeAddressEvents() {
        this.event('click', '.js-address-delete', this.onDelete);
    }

    onDelete(deleteLink, event) {
        event.preventDefault();

        if (window.confirm(String.format(Resources.CONFIRM_DELETE, Resources.TITLE_ADDRESS))) {
            ajax({
                url: $(deleteLink).attr('href'),
                dataType: 'json',
                callback: this.onSuccessDeletion
            });
        }
    }

    onSuccessDeletion(data) {
        if (data.status.toLowerCase() === 'ok') {
            page.redirect(Urls.addressesList);
        } else if (data.message.length > 0) {
            window.alert(data.message);
        } else {
            page.refresh();
        }
    }
}
