/* eslint-disable prefer-arrow-callback */
'use strict';

/* eslint no-unused-vars: 0 */
var creditCardValidator = require('./../../lib/credit-card-validator');
var util = require('./../../util');

var $cache = {};
var checkingCoupon = false;

function initializeCache() {
    $cache.document = $(document);
    $cache.cardTypesMapping = {
        visa: 'visa',
        visaelectron: 'visaelectron',
        amex: 'amex',
        mastercard: 'mastercard',
        maestro: 'maestro',
        discover: 'discover'
    };
}

function checkIfSelectedCardIsDiscounted() {
    if (checkingCoupon) {
        checkingCoupon.abort();
    }

    var url = util.getUrlToValidateCouponForPaymentCreditCard(Urls.updateSummary);

    checkingCoupon = util.makeRequestForCheckingIfCouponIsApplicableForCreditCard(
        url,
        'GET'
    );
}

function initializeEvents() {
    $cache.document
        .on('adyenCardComponentOnBrand', '.js-payment-form-wrp', function () {
            checkIfSelectedCardIsDiscounted();
        })
        .on('keyup', '.js-creditcard-number', function () {
            var paymentClass = '',
                $this = $(this),
                prevPaymentClass = $this.data('prevPaymentClass'),
                $creditcardLogo = $cache.document.find('.js-payment-method-image'),
                $creditcardType = $cache.document.find('.js-creditcard-type');

            $(this).validateCreditCard(function (result) {
                paymentClass = result.card_type === null ? '' : result.card_type.name;
                if (prevPaymentClass || paymentClass.length) {
                    $creditcardLogo.removeClass(prevPaymentClass).addClass(paymentClass);
                    $(this).data('prevPaymentClass', paymentClass);
                    $creditcardType.val($cache.cardTypesMapping[paymentClass]);

                    checkIfSelectedCardIsDiscounted();
                } else {
                    $(this).removeData('prevPaymentClass');
                }
            });
        })
        .on('creditcard-selected', function (e, type) {
            var $creditcard = $('.js-creditcard-number'),
                $this = $(this),
                prevPaymentClass = $this.data('prevPaymentClass'),
                $creditcardLogo = $cache.document.find('.js-payment-method-image'),
                $creditcardType = $cache.document.find('.js-creditcard-type'),
                paymentClass = type.toLowerCase(),
                $creditCard = $('[data-method="CREDIT_CARD"]');

            if (prevPaymentClass) {
                $creditcardLogo.removeClass(prevPaymentClass);
            }

            $creditcardLogo.addClass(paymentClass);
            $creditcard.data('prevPaymentClass', paymentClass);
            $creditcardType.val($cache.cardTypesMapping[paymentClass]);
            $creditCard.find('div.saveCard').hide();
            $creditCard.find('input[name$="_saveCard"]').prop('checked', false);

            checkIfSelectedCardIsDiscounted();
        })
        .on('change', '.js-creditcard-number input', () => {
            var $creditCard = $('[data-method="CREDIT_CARD"]');

            $creditCard.find('div.saveCard').show();
        });
}

module.exports.init = function () {
    initializeCache();
    initializeEvents();
};
