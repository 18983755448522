import InputSelect from '../forms/InputSelect';
import layout from '../../layout';
import eventMgr from '../../core/eventMgr';
import selectric from 'selectric';

selectric($);

const emitter = eventMgr.getEmitter('customSelect');

export default class CustomSelect extends InputSelect {
    get configDefault() {
        return {
            disableOn: [],
            maxHeight: 200
        };
    }
    init() {
        super.init();

        this.device = layout.getMode();

        if (this.config.jsonConfig && this.config.jsonConfig.disableOn) {
            this.disableOn = this.config.jsonConfig.disableOn;
        } else {
            this.disableOn = this.config.disableOn;
        }

        if (!~this.disableOn.indexOf(this.device)) {
            this.$el.selectric(this.config);

            emitter.emit('inited');
        }

        this.$el.on('change', this.onChange.bind(this));
    }

    onChange() {
        this.$el.trigger('focusout');
        this.$el.selectric('refresh');
    }

    update() {
        this.$el.selectric('refresh');

        emitter.emit('updated');
    }
}
