import Block from '_core_ext/core/common/Block';
import layout from '_core_ext/layout';
import cookie from '_core_ext/cookie';

const CHILDREN_COMPONENTS_IDS = [
    'headerNavigationContainer',
    'headerSearchContainer'
];

export default class Header extends Block {
    init() {
        super.init();
        this.settings = {};
        this.activeViewMode = 'desktop';
        this.isTablet = layout.isTablet();
        this.isTransparentEnabled = $('.homePageTransparentHeader').val() === 'true';
        this.initConfig(this.settings);
        this.updateChildrenReferences();
        this.subscribeToChildrenEvents();
        this.onWindowModeChanged({mode: layout.getMode()});
        this.subscribeToGlobalEvents();

        this.initTransparency();
        this.headerNavigationContainer.initNavigationStyles();
        if (this.isTablet) {
            this.headerNavigationContainer.setDevice('tablet');
        }
        this.setMobileHeaderPositionTop();

        this.event('click', '.js-header-nav-toggle', this.toggleNavigation.bind(this));
        this.event('click', '.js-overlay-nav', this.onOverlayClick.bind(this));
        this.event('click', '.js-toggle-search', this.toggleSearchForm.bind(this));
        this.event('click', '.js-search-reset', this.resetSearchForm.bind(this));
        this.event('click', '.js-banner-close', this.onBannerClose.bind(this));
        this.event('click', '.header__search-form-close', this.headerSearchBarClose.bind(this));
        this.event('click', '.header__search-form', this.headerSearchBarExpand.bind(this));
        this.event('focusout', '.header__search-input', this.headerSearchBarClose.bind(this));

        $('.show-icon').on("click",function (){
            var $input = $(this).parents(".form-row_field").find("input[type='password']")
            if ($input.attr("type") == "password") {
                $input.attr("type", "text");
            } 
            $input.siblings('.show-icon').hide();
            $input.siblings('.hide-icon').show();
        });

        $('.hide-icon').on("click",function (){
            var $input = $(this).parents(".form-row_field").find("input[type='text']");
            if ($input.attr("type") == "text") {
                $input.attr("type", "password");
            } 
            $input.siblings('.show-icon').show();
            $input.siblings('.hide-icon').hide();
        });

        $(document).on('click', function(e) {
            if ($(document.documentElement).hasClass('is--header-search-show')) {
                $("#header-search-input").focus();
                if (!$(e.target).parents().hasClass('header')) {
                    $('.js-search-reset').trigger('click');
                }
            }
        });
    }

    onWindowModeChanged(modeData) {
        if (!modeData) {
            return;
        }

        var {mode} = modeData;
        if (mode === 'medium' || mode === 'small') {
            this.activeViewMode = 'mobile';
        } else {
            this.activeViewMode = 'desktop';
        }

        this.headerNavigationContainer.setViewMode(this.activeViewMode);

        if (this.activeViewMode === 'mobile') {
            this.headerNavigationContainer.rebuildMenuLinks();
        }
        this.headerNavigationContainer.initNavigationStyles();

        this.setMobileHeaderPositionTop();
        this.initTransparency();

        if (this.isTablet) {
            if (layout.isLandscape()) {
                // close navigation menu
                this.headerNavigationContainer.deactivate();
            }

            if (layout.isPortrait() && this.$el.find('.js-header').hasClass('is--touched')) {
                this.$el.find('.js-header').removeClass('is--touched');
                this.$el.find('.js-overlay-nav').removeClass('is--expanded');
            }
        }
    }

    updateChildrenReferences() {
        CHILDREN_COMPONENTS_IDS.forEach((childComponentId) => {
            this.getById(childComponentId, (childComponent) => {
                this[childComponentId] = childComponent;
            });
        });
    }

    subscribeToChildrenEvents() {
        this.onChild('headerNavigationContainer', 'headerNavigation.state.activated', this.onHeaderNavigationActivated.bind(this));
        this.onChild('headerNavigationContainer', 'headerNavigation.state.deactivated', this.onHeaderNavigationDeactivated.bind(this));
        this.onChild('headerNavigationContainer', 'headerNavigation.link.touched', this.onLinkTouched.bind(this));
        this.onChild('headerNavigationContainer', 'headerNavigation.link.touched.largeView', this.onLargeViewLinkTouched.bind(this));
        this.onChild('headerNavigationContainer', 'headerNavigation.menuTab.mouseEntered', this.onMenuTabMouseEntered.bind(this));
        this.onChild('headerNavigationContainer', 'headerNavigation.menuTab.mouseLeft', this.onMenuTabMouseLeft.bind(this));

        this.onChild('headerSearchContainer', 'headerSearch.show', this.onHeaderSearchShow.bind(this));
        this.onChild('headerSearchContainer', 'headerSearch.hide', this.onHeaderSearchHide.bind(this));
        this.onChild('headerSearchContainer', 'headerSearch.search.bar.focus', this.onHeaderSearchFocus.bind(this));
        this.onChild('headerSearchContainer', 'headerSearch.search.bar.blur', this.onHeaderSearchBlur.bind(this));
    }

    subscribeToGlobalEvents() {
        this.eventMgr('window.modechanged', this.onWindowModeChanged.bind(this));
        this.eventMgr('miniCart.toggled', () => {
            this.headerNavigationContainer.deactivate();
        });
        this.eventMgr('window.resize', this.setMobileHeaderPositionTop);
        this.eventMgr('gdpr.closed', this.setMobileHeaderPositionTop);
        this.eventMgr('miniCart.show', this.setMobileHeaderPositionTop);
        this.eventMgr('searchSuggest.show', this.setMobileHeaderPositionTop);
    }

    initTransparency() {
        let screenConfig = this.$el.find('.js-header').data('screenConfig');
        let viewType = layout.getMode();
        let currentScreenConfig = (screenConfig && window.pageContext.type && screenConfig[window.pageContext.type]) ||
                            (screenConfig && window.pageContext.currentPage && screenConfig[window.pageContext.currentPage]) ||
                            (screenConfig && window.pageContext.ns && screenConfig[window.pageContext.ns]);
        let availableScreens = currentScreenConfig ? currentScreenConfig.screens.split(',') : [];

        if (availableScreens.indexOf(viewType) > -1) {
            $(document).find('#wrapper').addClass('is--transparent');
        } else {
            $(document).find('#wrapper').removeClass('is--transparent');
        }
    }

    setMobileHeaderPositionTop() {
        let headerHeight = this.$el.find('.js-header').find('.js-header-inner').height();
        let isTopBannerHidden = this.$el.find('.js-top-banner').hasClass('c-header-top-banner--hidden');
        // eslint-disable-next-line max-len
        let topBannerHeight = this.$el.find('.js-top-banner').length && !isTopBannerHidden && this.$el.find('.js-top-banner').height();
        // eslint-disable-next-line max-len
        let cookieMsgHeight = this.$el.find('.js-cookies-message').is(':visible') && this.$el.find('.js-cookies-message').height();
        let navTop = topBannerHeight + headerHeight + cookieMsgHeight;
        let headerTop = topBannerHeight + cookieMsgHeight;
        let mHeader = $('.js-header-inner');
        let mHeaderBanner = $('#header-top-banner:visible');
        let headerMHeight = mHeader.outerHeight() + mHeaderBanner.outerHeight();
        let isMiniCartActive = $(document.documentElement).hasClass('is--mini-cart-show');
        let isSuggestionShown = $('body').hasClass('is--suggestion-show');
        let isMenuActive = $(document.documentElement).hasClass('is--menu-active');

        this.$el.find('.js-current-nav-wrapper').css('top', headerMHeight);

        if (!isMenuActive) {
            this.$el.find('.js-header').find('.js-header-inner').css('top', 0);
        }

        if (this.activeViewMode === 'mobile' && !(this.isTablet && layout.isLandscape())) {
            if (isMenuActive || isMiniCartActive || isSuggestionShown) {
                this.$el.find('.js-header').find('.js-header-inner').css('top', headerTop);
            }
        }
    }

    toggleNavigation() {
        this.headerNavigationContainer.toggleActiveState();
        $(document).trigger('minicart.close');
    }

    onOverlayClick() {
        this.headerNavigationContainer.deactivate();
    }

    /**
     * @description toggle simple search form
     */
    toggleSearchForm() {
        this.headerSearchContainer.toggleSearchForm(false);
        $('.header').removeClass('transparent');
        $("#header-search-input").focus();
        if(layout.isMobileView()){
            this.$el.find('.js-header').addClass('search-bar-open');
        }
    }

    toggleTransparentHeader(removeTransparent) {
        var currentScroll = $(window).scrollTop();
        if((!currentScroll > 0) && this.isTransparentEnabled) {
            if(removeTransparent) {
                $('.header').removeClass('transparent');
            } else {
                $('.header').addClass('transparent');
            }
        }
    }

    resetSearchForm() {
        this.hideSearchForm();
        if(layout.isMobileView()){
            this.$el.find('.js-header').removeClass('search-bar-open')
        }
        this.toggleTransparentHeader(false);
    }

    onBannerClose(el, e) {
        e.preventDefault();
        cookie.set(Resources.COOKIE_BANNER_CLOSED, 'true');
        this.$el.find('.js-top-banner').addClass('c-header-top-banner--hidden');
        this.setMobileHeaderPositionTop();
    }

    headerSearchBarExpand(){
        if(!layout.isSmallView()){
            this.$el.find('#header').addClass('header--suggestion-show');
            this.$el.find('.header__search-input').attr('placeholder', Resources.SERACH);
        }
        $('.header').removeClass('transparent');
    }

    headerSearchBarClose(){
        if(!layout.isSmallView()){
            this.$el.find('.js-header').removeClass('header--suggestion-show');
            this.$el.find('.header__search-input').attr('placeholder', Resources.SERACH);
            this.$el.find('.header__search-input').val('');
            this.$el.find('.js-overlay-nav ').removeClass('is--expanded');
        }
    }

    onHeaderNavigationActivated() {
        $(document.documentElement).addClass('is--menu-active');
        this.$el.find('.js-overlay-nav').addClass('is--mobile-expanded');
        this.hideSearchForm();
        this.setMobileHeaderPositionTop();
        this.$el.find('.js-header').removeClass('is--touched');
        this.toggleTransparentHeader(true);
    }

    onHeaderNavigationDeactivated() {
        $(document.documentElement).removeClass('is--menu-active');
        this.$el.find('.js-overlay-nav').removeClass('is--mobile-expanded');
        this.hideOverlay();
        this.setMobileHeaderPositionTop();
        this.$el.find('.js-header').removeClass('is--touched');
        this.toggleTransparentHeader(false);
    }

    onLinkTouched() {
        this.$el.find('.js-overlay-nav').addClass('is--expanded');
        this.$el.find('.js-header').addClass('is--touched');

        $(document).trigger('minicart.close');
    }

    onLargeViewLinkTouched() {
        this.hideSearchForm();
    }

    onMenuTabMouseEntered() {
        this.toggleTransparentHeader(true);
        this.$el.find('.js-overlay-nav').addClass('is--expanded');
        this.hideSearchForm();
        $(document).trigger('minicart.close', $('.js-mini-cart-content').hasClass('is--hidden') || $('.js-mini-cart-content').hasClass('hide-minicart'));
        $('.header').removeClass('transparent');
    }

    onMenuTabMouseLeft() {
        this.toggleTransparentHeader(false);
        this.$el.find('.js-overlay-nav').addClass('is--expanded');
        this.hideSearchForm();
        $(document).trigger('minicart.close', $('.js-mini-cart-content').hasClass('is--hidden') || $('.js-mini-cart-content').hasClass('hide-minicart'));
    }

    onHeaderSearchShow() {
        $(document).trigger('minicart.close', true);
        this.headerNavigationContainer.deactivate();
        this.$el.find('.js-overlay-nav').addClass('is--expanded');
        $(document.documentElement).addClass('is--header-search-show');
        $('.header').removeClass('transparent');
        $("#header-search-input").focus();
    }

    onHeaderSearchHide() {
        this.hideOverlay();
        $(document.documentElement).removeClass('is--header-search-show');
        $('.header__search-form-close').removeClass('search-close-icon-btn');
        $('#search-suggestions').css('display','none');
        this.isTransparentEnabled ? $('.header').addClass('transparent') : '';
    }

    hideOverlay() {
        this.$el.find('.js-overlay-nav').removeClass('is--mobile-expanded');
        this.$el.find('.js-overlay-nav').removeClass('is--expanded');
        $('.header__search-form-close').removeClass('search-close-icon-btn');
    }

    hideSearchForm() {
        this.headerSearchContainer.hideSearchForm();
        $('.header__search-form-close').removeClass('search-close-icon-btn');
    }

    onHeaderSearchFocus() {
        $(document).trigger('minicart.close');

        // add focused state class to component container
        this.$el.addClass('is--search-input-focused');
        $('.header__search-form-close').addClass('search-close-icon-btn');

        if (!layout.isMobileView() || !this.headerNavigationContainer.isActive()) {
            return;
        }
        this.headerNavigationContainer.deactivate();
    }

    onHeaderSearchBlur() {
        // remove focused state class to component container
        this.$el.removeClass('is--search-input-focused');
        $('.header__search-form-close').removeClass('search-close-icon-btn');
    }
}