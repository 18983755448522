import Refinement from '_core_ext/components/plp/refinements/Refinement';
import layout from '_core_ext/layout';

export default class CustomRefinement extends Refinement {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.selectedRefinements = this.getSelectedValuesSet();
        this.activeViewMode = 'desktop';
        this.onWindowModeChanged({mode: layout.getMode()});
        this.toggleRefinements();
        this.toggleSortbyOnHover();
    }

    onRefinementTitleClick(el) {
        $(el).closest('.js-filter').toggleClass('is--expanded');
        $(el).closest('.js-filter').siblings().removeClass('is--expanded');
    }

    toggleRefinements() {
        $('.js-filter').on('mouseenter', function(e) {
            e.preventDefault();
            var $this = $(this);
            $this.addClass('is--expanded-desktop');
            $this.siblings().removeClass('is--expanded-desktop');
            $this.parents('.js-filters-dropdown').addClass('is--expanded-desktop');
        });

        $('.js-filter').on('mouseleave', function(e) {
            e.preventDefault();
            $(this).removeClass('is--expanded-desktop');
            $(this).parents('.js-filters-dropdown').removeClass('is--expanded-desktop');
        });
    }

    toggleSortbyOnHover() {
        if (layout.isMobileView()) {
            $('.js-sort-dropdown').on('touchstart', function(event) {
                setTimeout(function() {
                    $(this).addClass('is--expanded');
                }, 400);
            });
        } else {            
            $('.js-sort-dropdown').on('mouseenter', function() {
                $(this).addClass('is--expanded');
            });

            $('.js-sort-dropdown').on('mouseleave', function() {
                $(this).removeClass('is--expanded');
            });
        }
    }
}