'use strict';

import page from './page';
import util from './util';
import eventMgr from '_core_ext/core/eventMgr';

const emitter = eventMgr.getEmitter('ajax');
let currentRequests = [];

function handleJsonRedirect(response) {
    response = response || {};
    if (response.redirectTo) {
        page.redirect(response.redirectTo);
    }
}

function makeHash(url, data) {
    var params = '';

    if (data) {
        params = JSON.stringify(data);
    }
    return url + params;
}

/**
 * @function
 * @description Ajax request to get json response
 * @param {Boolean} async  Asynchronous or not
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 */
var getJson = function (options) {
    options.url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url) {
        return $.Deferred().reject();
    }
    if (currentRequests[makeHash(options.url, options.data)]) {
        return currentRequests[makeHash(options.url, options.data)];
    }

    // make the server call
    var deffered = $.ajax({
        dataType: options.dataType || 'json',
        type: options.type || 'GET',
        contentType: options.contentType || 'application/json',
        url: options.url,
        async: (typeof options.async === 'undefined' || options.async === null) ? true : options.async,
        data: options.data || {},
        statusCode: {
            401: (response) => {
                handleJsonRedirect(response.responseJSON);
            }
        }
    })
        // success
        .done((response) => {
            if (options.callback) {
                options.callback(response);
            }
        })
        // failed
        .fail((xhr, textStatus) => {
            if (textStatus === 'parsererror') {
                /* eslint-disable */
                window.alert(Resources.BAD_RESPONSE);
                /* eslint-enable */
            }
            if (options.callback) {
                options.callback(null);
            }
        })
        // executed on success or fail
        .always(() => {
            // remove current request from hash
            if (currentRequests[makeHash(options.url, options.data)]) {
                delete currentRequests[makeHash(options.url, options.data)];
            }
        });

    currentRequests[makeHash(options.url, options.data)] = deffered;
    return deffered;
};
/**
 * @function
 * @description ajax request to load html response in a given container
 * @param {String} url URI for the request
 * @param {Object} data Name/Value pair data request
 * @param {Function} callback  Callback function to be called
 * @param {Object} target Selector or element that will receive content
 */
var load = function (options) {
    options.url = util.toAbsoluteUrl(options.url);
    // return if no url exists or url matches a current request
    if (!options.url) {
        return $.Deferred().reject();
    }
    if (currentRequests[makeHash(options.url, options.data)]) {
        return currentRequests[makeHash(options.url, options.data)];
    }

    let callParams = {
        type: options.type || 'GET',
        dataType: options.dataType || 'html',
        url: util.appendParamToURL(options.url, 'format', options.format || 'ajax'),
        data: options.data,
        xhrFields: {
            withCredentials: true
        },
        statusCode: {
            401: (response) => {
                handleJsonRedirect(response.responseJSON);
            }
        }
    };

    if (options.contentType) {
        callParams.contentType = options.contentType;
    }

    // make the server call
    var deffered = $.ajax(callParams)
        .done((response, textStatus, xhr) => {
            // success
            if (options.target) {
                $(options.target).empty().html(response);
            }

            if (options.callback) {
                options.callback(response, textStatus, xhr);
            }

            emitter.emit('contentUpdated');
        })
        .fail((xhr, textStatus) => {
            // failed
            if (textStatus === 'parsererror') {
                /* eslint-disable */
                window.alert(Resources.BAD_RESPONSE);
                /* eslint-enable */
            }
            if (options.callback) {
                options.callback(null, textStatus, xhr);
            }
        })
        .always(() => {
            // remove current request from hash
            if (currentRequests[makeHash(options.url, options.data)]) {
                delete currentRequests[makeHash(options.url, options.data)];
            }
        });

    currentRequests[makeHash(options.url, options.data)] = deffered;
    return deffered;
};

module.exports = load;
module.exports.getJson = getJson;
module.exports.load = load;
