import Registration from '_core_ext/components/forms/Registration';
import ajax from '_core_ext/ajax';

export default class RegisterWithLoyaltyCheck extends Registration {

    init() {
        super.init();

        var loyaltyFileds = '[id$="_customer_title_title"], [id$="_customer_firstname"], [id$="_customer_lastname"], [id$="_customer_gender"], [id$="_customer_email"]';
        var loyaltySelectric = '[id$="_customer_title_title"], [id$="_customer_gender"]';

        this.$el.on('blur', loyaltyFileds, () => {
            this.checkFieldsReady(loyaltyFileds);
        });

        this.$el.on('change', loyaltySelectric, () => {
            this.checkFieldsReady(loyaltyFileds);
        });
    }

    checkFieldsReady(loyaltyFileds) {
        var dataList = loyaltyFileds.split(',');
        var ready = true;

        for (let i = 0; i < dataList.length; i++) {
            if (!this.$el.find(dataList[i]).val() || this.$el.find(dataList[i]).hasClass('error')) {
                ready = false;
            }
        }

        if (ready) {
            this.onRequiredFieldsReady(dataList);
        }

    }

    onRequiredFieldsReady() {
        var fieldData = {
            title: this.$el.find('[id$="_customer_title_title"]').val(),
            gender: this.$el.find('[id$="_customer_gender"]').val(),
            firstname: this.$el.find('[id$="_customer_firstname"]').val(),
            lastname: this.$el.find('[id$="_customer_lastname"]').val(),
            email: this.$el.find('[id$="_customer_email"]').val()
        };

        ajax({
            url: this.config.url,
            type: 'POST',
            data: fieldData
        }).then((response) => {
            if (response) {
                $('#RegistrationFormContainer').replaceWith(response);
            }
        });
    }
}
