import Block from '_core_ext/core/common/Block';
import paginationUtils from '_core_ext/components/plp/utils/pagination';

export default class SearchControls extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.event('click', '.js-pagination-link', this.onPaginationLinkClick.bind(this));
    }


    onPaginationLinkClick(el, event) {
        event.preventDefault();
        var searchQueryUrl = $(el).attr('href');
        if (!searchQueryUrl) {
            return;
        }

        paginationUtils.setHistoryUrl(searchQueryUrl, false);
        this.emitter.emit('searchControls.pagination.link.clicked', {url: searchQueryUrl});
    }
}