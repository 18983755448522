'use strict';
const Slick = require('slick-carousel/slick/slick');
var slickSlider = $('.js-slick-slider');
import layout from '_core_ext/layout';
import productTileCmp from './productTileCmp';

function init(){
    var productTileCmpObj = new productTileCmp();
    productTileCmpObj.init();
    $(document).trigger('producttiles.childevents');
    $(document).on('recommendations.loaded lastvisited.loaded recommendations.initPredictiveRecommendations', function(event, selector) {
        $(document).trigger('producttiles.loaded', '.pdp-slots');
    });

    // Slot ID: recommendations-explicit-pdp || Slick Config JSON data: {"slidesToShow":3,"slidesToScroll":1,"infinite":false,"responsive":[{"breakpoint":1900,"settings":{"slidesToShow":2.5,"slidesToScroll":1}},{"breakpoint":992,"settings":{"slidesToShow":3,"slidesToScroll":1}},{"breakpoint":576,"settings":"unslick"}]}
    var arrowConfig = {"appendArrows": "#slick-recommendation-arrow",
    "nextArrow": "<span role='button' tabindex='0' class='jcarousel-nav js-swiper-button-next recommendation-section__carousel-nav-item'><i class='icon-arrow-right'><span class='visually-hidden'>Next</span></i></span>",
    "prevArrow": "<span role='button' tabindex='0' class='jcarousel-nav js-swiper-button-prev recommendation-section__carousel-nav-item'><i class='icon-arrow-left'><span class='visually-hidden'>Prev</span></i></span>",
    };
    $(document).on('recommendations.loaded', function(event, selector) {
        if(slickSlider && slickSlider.find('li.grid-tile').length >=3) {
            $('#shopthelook .recommendations-carousel').find('.primary-img-container').addClass('slick-added');
        }
        if(slickSlider && slickSlider.find('li.grid-tile').length > 0) {
            $('.js-recommendation-section').removeClass('is--hidden');
            // Need to update the below logic for updating the 'Complete the look' primary image
            $('.recommendation-primary-img').attr('src', $('#product-images .shopthelook-img .component-picture img').attr('srcset'));
            if (slickSlider.find('li.grid-tile').length > 2) {
                $(slickSlider).slick(arrowConfig).on('afterChange', function(event, slick, currentSlide, nextSlide) {
                    $(document).trigger('swiperslideChangeTransitionEnd');
                });
            }
        }
    });
    $(window).on('resize', function() {
        var viewportWidth = $(window).width();
        var $pdpProductSlickImageContainer = $('#product-images .images-container');
        if (viewportWidth > 575 && !$('.js-slick-slider').hasClass('slick-initialized') && slickSlider.find('li.grid-tile').length > 2) {
            $(slickSlider).slick(arrowConfig).on('afterChange', function(event, slick, currentSlide, nextSlide) {
                $(document).trigger('swiperslideChangeTransitionEnd');
            });
        }

        if(!layout.isMobileView()){
            if($pdpProductSlickImageContainer.hasClass('slick-initialized')) {
                $pdpProductSlickImageContainer.slick('unslick');
            }
        } else {
            slidePDPMainImage($pdpProductSlickImageContainer);
        }
    });

    if($(".js-hbanner-slick-slider .swiper-slide").length > 0) {
        $('.js-hbanner-slick-slider').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            arrows: false,
            dots: false
        });
    }
    
    if($(".plp-slick-slider .swiper-slide").length > 0) {
        $('.plp-slick-slider').slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: false
        });
    }
}

/* slick slider for color swatches */
function slideColorVariants($selector) {
    var colorSlickOptions = {
        infinite: false,
        slidesToShow: 8,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }
        ]
    }
    
    if ($selector.attr('data-pdp-variation-type')) {
        var $swatch = $selector.find('li.c-product-variations__subitem');
        var swatchWidth = $swatch.outerWidth(true);
        var swatchContainerWidth = Math.floor($selector.width());
        var slidesToShowItems = Math.floor(swatchContainerWidth/swatchWidth);
        var pdpColorSlickOptions = {
            infinite: false,
            slidesToShow: slidesToShowItems
        }
        !$selector.hasClass('slick-initialized') ? $selector.slick(pdpColorSlickOptions) : '';
    } else if($selector.parent('.js-recommendation-item')){
        var $colorList = $selector.find('li');
        var recColorSlickOptions = {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1
        }
        if($colorList.length > 3){
            !$selector.hasClass('slick-initialized') ? $selector.slick(recColorSlickOptions) : '';
        } else {
            return;
        }
    } else {
        !$selector.hasClass('slick-initialized') ? $selector.slick(colorSlickOptions) : '';
    }
}
/* slick slider for size variants */
function slideSizeVariants($selector) {
    var sizeSlickOptions = {
        infinite: false,
        slidesToShow: 9,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1
                }
            }
        ]
    }

    var pdpSizeSlickOptions = {
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        variableWidth: true
    }

    if ($selector.attr('data-pdp-variation-type')) {
        !$selector.hasClass('slick-initialized') ? $selector.slick(pdpSizeSlickOptions) : '';
    } else if($selector.parent('.js-recommendation-item')){
        var recSizeSlickOptions = {
            infinite: false,
            slidesToShow: 3,
            slidesToScroll: 1
        }
        !$selector.hasClass('slick-initialized') ? $selector.slick(recSizeSlickOptions) : '';
    } else {
        !$selector.hasClass('slick-initialized') ? $selector.slick(sizeSlickOptions) : '';
    }
}

/* slick slider for length variants */
function slideLengthVariants($selector) {
    var lengthSlickOptions = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }
    var pdpLengthSlickOptions = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1
    }

    if ($selector.attr('data-pdp-variation-type')) {
        !$selector.hasClass('slick-initialized') ? $selector.slick(pdpLengthSlickOptions) : '';
    } else if($selector.parent('.js-recommendation-item')){
        var recLengthSlickOptions = {
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1
        }
        !$selector.hasClass('slick-initialized') ? $selector.slick(recLengthSlickOptions) : '';
    } else {
        !$selector.hasClass('slick-initialized') ? $selector.slick(lengthSlickOptions) : '';
    }
}

/* slick slider for product images */
function slidePDPMainImage($selector) {
    var currentSlide,
        slidesCount,
        $progressBar = $('.c-product-details__slider-progress'),
        $sliderCount = $('.c-product-details__slider-counter');

    var updateSliderCounter = function(slick, currentIndex) {
        currentSlide = slick.slickCurrentSlide() + 1;
        slidesCount = slick.slideCount;
        $sliderCount.html(currentSlide + '<span> / ' + slidesCount + '</span>')
        $progressBar
            .css('background-size', (100 / (slidesCount/currentSlide) ) + '%')
            .attr('aria-valuenow', (100 / (slidesCount/currentSlide)) + '%');
    };

    var pdpMainImageSlickOptions = {
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    }

    $selector.on('init', function(event, slick) {
        updateSliderCounter(slick);
        slidesCount = slick.slideCount;
        $progressBar
            .css('background-size', (100 / slidesCount) + '%')
            .attr('aria-valuenow', (100 / slidesCount) + '%');
    });

    $selector.on('afterChange', function(event, slick, currentSlide) {
        updateSliderCounter(slick, currentSlide);
    });

    !$selector.hasClass('slick-initialized') ? $selector.slick(pdpMainImageSlickOptions) : '';
}

/* slick slider for top section filters */
function slidetopFiltersSection($selector, arrows = true) {
    let arrowConfig = '';
    if (arrows == true) {
        arrowConfig = {
            arrows: true,
            nextArrow: "<span role='button' tabindex='0' class='slider-arrow-right'><i class='icon-arrow-right'><span class='visually-hidden'>Next</span></i></span>",
            prevArrow: "<span role='button' tabindex='0' class='slider-arrow-left'><i class='icon-arrow-left'><span class='visually-hidden'>Prev</span></i></span>",
        };
    }
    var topFiltersSlickOptions = {};
        topFiltersSlickOptions = {
            infinite: false,
            slidesToShow: 4,
            slidesToScroll: 3,
            arrows: true,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 1025,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 1,
                        arrows: true,
                        variableWidth: true,
                        ...arrowConfig
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        arrows: true,
                        variableWidth: true,
                        ...arrowConfig
                    }
                },
                {
                    breakpoint: 9999,
                    settings: "unslick"
                }
            ]
        }
    
    !$selector.hasClass('slick-initialized') ? $selector.slick(topFiltersSlickOptions) : '';
}

module.exports = {
    slideColorVariants: slideColorVariants,
    slideSizeVariants: slideSizeVariants,
    slideLengthVariants: slideLengthVariants,
    slidePDPMainImage: slidePDPMainImage,
    slidetopFiltersSection: slidetopFiltersSection,
    init
}