import Component from '_core_ext/core/Component';
import layout from '_core_ext/layout';
import {scrollTo} from '_core_ext/util';

export default class BackToTop extends Component {
    init() {
        this.settings = {
            backToTopBottomElement: '.js-btt-bottom-element',
            footer: '.js-footer',
            shownClass: 'is--shown',
            footerPositionClass: 'is--on-footer',
            pageScrolled: 150,
            animationSpeed: 700
        };

        this.initConfig(this.settings);

        if (this.eligibleOnDevice()) {
            this.initializeCache();
            this.onWindowScroll();
            this.initializeEvents();
        }
    }

    initializeCache() {
        this.cache = {};
        this.cache.$window = $(window);
        this.cache.$document = $(document);
        this.cache.$footer = this.cache.$document.find(this.config.footer);
    }

    onWindowScroll() {
        var scrollTop = this.cache.$window.scrollTop(),
            windowInnerHeight = this.cache.$window.innerHeight(),
            scrollTrigger = (windowInnerHeight * this.config.pageScrolled) / 100,
            footerTrigger = $(document).find('.js-footer').offset(),
            affectingElement = this.cache.$document.find(this.config.backToTopBottomElement),
            bottomShown = this.$el.outerHeight() + 12,
            bottomNotShown = 8;

        this.$el.toggleClass('is--shown', scrollTop > scrollTrigger);

        if (layout.isSmallView()) {
            if (scrollTop < (footerTrigger.top - windowInnerHeight) && affectingElement.is(':visible')) {
                this.$el.css('bottom', affectingElement.outerHeight());
                bottomShown += affectingElement.outerHeight();
                bottomNotShown = affectingElement.outerHeight();
            } else {
                this.$el.css('bottom', '0');
            }
        }

        if (this.$el.hasClass('is--shown')) {
            $('div.hero-launcher-container').attr('style', 'bottom: '+bottomShown+'px !important;');
        } else {
            $('div.hero-launcher-container').attr('style', 'bottom: '+bottomNotShown+'px !important');
        }

        if (scrollTop > (footerTrigger.top - windowInnerHeight)) {
            this.$el.addClass(this.config.footerPositionClass);
            if (layout.isSmallView() && affectingElement.length > 0)
                affectingElement.addClass('d-none');
        } else {
            this.$el.removeClass(this.config.footerPositionClass);
            if (affectingElement.length > 0)
                affectingElement.removeClass('d-none');
        }
    }

    onClick() {
        scrollTo(0, this.config.animationSpeed);
    }

    initializeEvents() {
        this.eventMgr('window.scroll', this.onWindowScroll);
        this.event('click', this.onClick);
    }
}
