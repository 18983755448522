'use strict';
/* eslint-disable */
var util = require('./../util'),
    ajax = require('./../ajax'),
    cssConstants = {
        HIDDEN_CLASS: 'is--hidden',
        VISUALLY_HIDDEN_CLASS: 'visually-hidden',
        SELECTED_CLASS: 'is--selected',
        IGNORE_CLASS: 'js-ignore'
    },
    $cache = {},
    addressFields = ['firstName', 'lastName', 'address1', 'address2', 'postalCode', 'city', 'stateCode', 'countryCode', 'phone'];

const emitter = eventMgr.getEmitter('address');

function initializeCache() {
    $cache.document = $(document);
    $cache.form = $cache.document.find('.js-address');
    $cache.list = $cache.document.find('.js-checkout-address-list');
    $cache.addNewAddress = $cache.document.find('.js-add-new-address');
    $cache.addressFields = $cache.form.find('.js-address-fields');
    $cache.newAddressForm = $cache.document.find('.js-address-form:not(.js-invoice-form)');
    $cache.newBillingForm = $cache.document.find('#biling-address .js-address-form:not(.js-invoice-form)');
    $cache.addressActions = $cache.document.find('.js-show-less-addresses, .js-add-new-address span');
    $cache.miniaddresses = $cache.document.find('.js-minicheckoutaddress');
    $cache.selectedAddress = $cache.form.find('.js-selected-address-wrapper');
    $cache.showAllAddresses = $cache.form.find('.js-show-addresses');
    $cache.showLessAddresses = $cache.form.find('.js-show-less-addresses');
    $cache.existingShipping = $cache.document.find('.js-existing-shipping-address');
    $cache.countrySelect = $cache.document.find('select[name$="_country"]');
}

function toggleAddressListView(expandList) {
    $cache.showLessAddresses.toggleClass(cssConstants.HIDDEN_CLASS, !expandList);
    $cache.list.toggleClass(cssConstants.HIDDEN_CLASS, !expandList);
    $cache.showAllAddresses.toggleClass(cssConstants.HIDDEN_CLASS, expandList);
    $cache.selectedAddress.toggleClass(cssConstants.HIDDEN_CLASS, expandList);
}

function toggleNewAddressForm(showForm) {
    $cache.newAddressForm.toggleClass(cssConstants.HIDDEN_CLASS, !showForm);
    $cache.addNewAddress.toggleClass(cssConstants.HIDDEN_CLASS, showForm);
}

function handleCountryChange (e, $container, selectedValue) {
    let $this = $(this),
        $regionSelect = $this.closest('.js-address-form').find('select[name$="_states_state"]');

    if (!$cache.document) {
        $cache.document = $(document);
    }
    let url = util.appendParamToURL(Urls.getApplicableOptions, 'country', $this.val() ? $this.val().toLowerCase() : '', 'locale', pageContext.currentLocale);
    if (selectedValue) {
        url = util.appendParamToURL(url, 'selectedValue', selectedValue);
    }

    if ($regionSelect.length) {
        ajax({
            url: url,
            target: $regionSelect,
            callback: function () {
                $cache.document.trigger('address.region.updated');
                $regionSelect.selectric('refresh');
            }
        });
    }
}

/**
 * Checks if two address objects are equal
 * @param {Object} address1
 * @param {Object} address2
 * @returns {Boolean}
 */
function isEquivalentAddresses(address1, address2) {
    return address1 && address2 && addressFields.every((fieldName) => address1[fieldName] === address2[fieldName]);
}

function acceptPolicies () {
    var $acceptPolicyCheckBox = $cache.newAddressForm.find('[name$=_acceptpolicy]');
    if (!$acceptPolicyCheckBox.prop('checked')) {
        $acceptPolicyCheckBox.click();
    }
}
/**
 * @function
 * @description Selects the first address from the list of addresses
 */
function initializeEvents () {
    $cache.document
        // select address from list
        .on('change', 'input[name$="_addressList"]', function () {
            let $this = $(this),
                selectedAddress = $this.data('address');
            if (!selectedAddress) {
                return;
            }
            selectedAddress.searchaddress = $this.data('value');
            $cache.addressActions.removeClass(cssConstants.HIDDEN_CLASS);
            $cache.miniaddresses.removeClass(cssConstants.SELECTED_CLASS);
            let $miniaddress = $this.closest('.js-minicheckoutaddress');
            $cache.form.find('.js-shipping-email').val($miniaddress.find('.js-minishipping-address-email').text());
            $miniaddress.toggleClass(cssConstants.SELECTED_CLASS, $this.prop('checked'));
            $cache.selectedAddress.data('address', selectedAddress);
            $cache.selectedAddress.find('.address').html($miniaddress.find('.address').html());
            util.fillAddressFields(selectedAddress, $cache.newAddressForm);
            toggleAddressListView(false);
            toggleNewAddressForm(false);
            $('.js-shipping-address-toggle').prop('checked', false);
            // re-validate the form
            $cache.form.validate().form();
            acceptPolicies();
        })
        .on('click', '.js-address-fields-toggle', function () {
            $cache.addressFields.toggleClass(cssConstants.VISUALLY_HIDDEN_CLASS);
        })
        .on('click', '.js-add-new-address', function () {
            toggleAddressListView(!$cache.selectedAddress.data('address'));
            toggleNewAddressForm(true);
            $cache.form.validate().resetForm();
            $cache.form.find('input[name$="_addressList"]:checked').removeAttr('checked');
            $cache.miniaddresses.removeClass(cssConstants.SELECTED_CLASS);
            $cache.newAddressForm.find('input[type="text"]:not([id$="country"], [id$="firstname"], [id$="lastname"], [id$="email"]), [id$="state"]').val('');
            $cache.newAddressForm.find('input[name$="_addToAddressBook"]').prop('checked', true);
            $cache.newBillingForm.find('[id$="country"]').val('').trigger('change');
            $cache.document.trigger('selectric.reinit', [$cache.newAddressForm]);
            emitter.emit('addressform.toggle');
            $cache.newAddressForm.find('[name$=_acceptpolicy]').click();
        })
        .on('change', 'select[name$="_country"]', handleCountryChange);

    if ($cache.list.length) {
        $cache.document
            .on('addresslist.close', () => {
                toggleAddressListView(false);
                $cache.miniaddresses.removeClass(cssConstants.SELECTED_CLASS).find('input[type="radio"]').prop('checked', false);
            })
            .on('addresslist.open', () => toggleAddressListView(true))
            .on('click', '.js-show-addresses', () => {
                toggleAddressListView(true);
                emitter.emit('addresslist.show');
            })
            .on('click', '.js-show-less-addresses', () => {
                toggleAddressListView(false);
                emitter.emit('addresslist.less');
            });
    }
    $cache.selectedAddress.on('click', function() {
        let $this = $(this),
            selectedAddress = $this.data('address');
        if (!selectedAddress) {
            return;
        }
        if (isEquivalentAddresses(selectedAddress, $cache.existingShipping.data('address'))) {
            $cache.document.find('.js-shipping-address-toggle').prop('checked', true);
        }
        toggleNewAddressForm(false);
        util.fillAddressFields(selectedAddress, $cache.newAddressForm);
        acceptPolicies();
    });
}

var address = {
    init: function () {
        if (!!~pageContext.pipelineName.indexOf('COShipping')) {
            return;
        }
        initializeCache();
        initializeEvents();
    },
    handleCountryChange: handleCountryChange
};

module.exports = address;
