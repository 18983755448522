module.exports={
    "pageCategory": {
        "cart": "basket",
        "orderconfirmation": "checkoutconfirmation",
        "homepage": "home",
        "plp": "product-list"
    },
    "pageType": {
        "clp": "category",
        "plp": "category"
    },
    "category": {
        "search": "search"
    }
}