import Block from '_core_ext/core/common/Block';
export default class ShippingRegion extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.event('click', this.onClick);
    }

    onClick (el) {
        this.emitter.emit('shippingRegion.selected',this.getRegionInfo(), $(el));
    }

    getRegionInfo () {
        return {
            regionId: this.config.regionId,
            regionName: this.config.regionName,
            regionDescription: this.config.regionDescription,
            regionRestrictions: this.config.regionRestrictions
        };
    }
}