import InputGeneric from './InputGeneric';

const CHECKED_CLASS = 'm-checked';

export default class InputCheckbox extends InputGeneric {
    init () {
        super.init();
        this.setClasses();
    }
    update () {
        this.setClasses();
        super.update();
    }
    setValue (newVal = '', silently = false) {
        super.setValue(newVal, silently);
        if (silently) {
            this.setClasses();
        }
    }
    setClasses () {
        if (this.$field.prop('checked')) {
            this.$el.addClass(CHECKED_CLASS);
        } else {
            this.$el.removeClass(CHECKED_CLASS);
        }
    }
    getValue () {
        if (this.$field.prop('checked')) {
            return super.getValue();
        } else {
            return '';
        }
    }
    check () {
        this.$field.prop('checked', true);
        this.emitter.emit('change', this);
    }
    uncheck () {
        this.$field.prop('checked', false);
        this.emitter.emit('change', this);
    }
}
