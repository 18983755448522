'use strict';

var cookie = {
    /**
     * @function
     * @description get cookie by name
     * @param {String} name of cookie
     */
    get: function(name) {
        var parts = ('; ' + document.cookie).split('; ' + name + '=');
        if (parts.length === 2) {
            return parts.pop().split(';').shift();
        }
    },

    /**
     * @function
     * @description set cookie
     * @param {String} name of cookie
     * @param {String} value of cookie
     * @param {String} time when cookie expires
     */
    set: function(name, value, expires) {
        // If no expiration date is passed, use maximum
        // safe timestamp value (preventing year 2038 bug)
        expires = expires || new Date(2147483647000).toUTCString();
        var expiresDate = '; Expires=' + expires;
        document.cookie = name + '= ' + value + '; path=/' + expiresDate;
    },

    /**
     * @function
     * @description remove cookie by name
     * @param {String} name of cookie to be deleted
     */
    remove: function(name) {
        document.cookie = name + '=; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }
};

module.exports = cookie;
