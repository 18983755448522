import $ from 'jquery';
import Component from '_core_ext/core/Component';
import {scrollTo} from '_core_ext/util';
import eventMgr from '_core_ext/core/eventMgr';

export default class DotNavigation extends Component {
    init() {
        this.settings = {
            align: 'left',
            scrollSpeed: 1000
        }
        this.$navigationElements = null;
        this.clickScroll = false;
        this.sectionsArray = [];
        this.$navigationContainer = $('.js-vertical-dot-nav');

        this.initConfig(this.settings);
        if (this.eligibleOnDevice()) {
            this.$elements = this.$el.find(this.navigationElementsSelector);
            this.initEvents();
            this.createNavigationElements();
            this.addNavigationEvents();
            this.checkScrollPos();
        }
    }

    updateCachedElements() {
        this.$elements = this.$el.find(this.navigationElementsSelector);
        this.sectionsArray = [];
        this.$navigationContainer = $('.js-vertical-dot-nav');
    }

    initEvents() {
        this.eventMgr('window.scroll', this.onWindowScroll);
        this.eventMgr('window.resize', this.onWindowResize);
    }

    createNavigationElements() {
        if (this.$elements.length > 1) {
            this.$navigationContainer.empty();
            var fragment = document.createDocumentFragment();
            this.$elements.each((index, element) => {
                var navigationElement = document.createElement('div');
                var $element = $(element);

                this.sectionsArray.push({
                    name: 'section-' + index,
                    offset: $element.offset().top,
                    ref: $element
                });
                navigationElement.dataset.index = index;
                navigationElement.dataset.target = 'section-' + index;
                navigationElement.classList.add('dot');
                navigationElement.classList.add('c-product-images__nav-item');
                fragment.appendChild(navigationElement);
            });

            this.$navigationContainer.append(fragment);
            eventMgr.execute('components.update', this.$el);
        }
    }

    addNavigationEvents() {
        this.$navigationElements = this.$navigationContainer.find('.dot');
        this.event('click', this.$navigationElements, this.onNavigationElementClick);
    }

    onNavigationElementClick(element, event) {
        var elementConfig = this.sectionsArray[event.target.dataset.index];

        if (!elementConfig) {
            return;
        }

        var targetSectionOffset = elementConfig.offset;
        var $target = $(event.target);

        this.assignActiveClass($target);
        this.clickScroll = true;
        scrollTo(targetSectionOffset, this.config.scrollSpeed, this.onAnimationFinished.bind(this));
    }

    onAnimationFinished() {
        (require('_core_ext/util').debounce(() => {
            this.clickScroll = false;
        }, 400).bind(this))();
    }

    assignActiveClass(target) {
        this.$navigationElements.removeClass('active');
        target.addClass('active');
    }

    onWindowScroll() {
        if (this.clickScroll) {
            return;
        } else {
            this.checkScrollPos();
        }
    }

    navigationTopPosition() {
        return $(window).height() / 2 - this.$navigationContainer.height() / 2;
    }

    onWindowResize() {
        this.checkScrollPos();
    }

    reinit() {
        this.updateCachedElements();
        this.createNavigationElements();
        this.addNavigationEvents();
        this.checkScrollPos();
    }

    checkScrollPos() {
        var scrollPosition = $(window).scrollTop(), targetDot;

        if ((window.innerHeight + window.scrollY) > document.body.offsetHeight) {
            var targetSelector = `.dot[data-target='${this.sectionsArray[this.sectionsArray.length - 1].name}]'`;
            targetDot = this.$navigationContainer.find(targetSelector);
            this.assignActiveClass(targetDot);
        } else {
            for (var i = this.sectionsArray.length - 1; i > -1; i--){
                if (scrollPosition === 0) {
                    targetDot = this.$navigationContainer.find(`.dot[data-target='${this.sectionsArray[0].name}']`);
                    this.assignActiveClass(targetDot);
                } else if (this.sectionsArray[i].offset <= scrollPosition) {
                    targetDot = this.$navigationContainer.find(`.dot[data-target='${this.sectionsArray[i].name}']`);
                    this.assignActiveClass(targetDot);

                    return;
                }
            }
        }
    }
}
