import Block from '_core_ext/core/common/Block';
import util from '_core_ext/util';
import eventMgr from '_core_ext/core/eventMgr';
const emitter = eventMgr.getEmitter('form');

export default class Form extends Block {
    init() {
        super.init();
        this.settings = {
            errorClass: 'error',
            errorElement: 'span',
            scrollDelay: 600,
            onkeyup: false,
            ignore: '.js-ignore input, .js-ignore select, :hidden',
            onfocusout: this.onFocuseOut,
            invalidHandler: this.invalidHandler.bind(this),
            errorPlacement: this.errorPlacement,
            skipValidation: false
        };

        if (this.$el.is('form')) {
            this.initConfig(this.settings);
            if (!this.referencesLoaded) {
                this.getReferences();
            }

            this.initializeEvents();
            this.extendValidatorMethods();
            this.extendValidatorMessages();
            this.initializeValidator();
        }
    }

    beforeSubmit() {}

    onSubmit() {}

    submit(actionName) {
        this.beforeSubmit(actionName);
        this.$el.find('[name=' + actionName + ']').trigger('click');
    }

    initializeValidator() {
        this.$validator = this.$el.validate(this.config);
    }

    validate($form, event) {
        if (this.config.preventDefault) {
            event.preventDefault();
        }

        this.beforeSubmit();
        var $submitButton = this.$el.find('[type=submit]:focus');
        if ($submitButton.length > 0 && $submitButton.data('skipValidation') || this.skipValidation) {
            this.skipValidation = false;
            return true;
        } else if (!this.$el.valid()) {
            return false;
        }

        this.onSubmit();
    }

    initializeEvents() {
        this.event('submit', this.validate);

        $('[id$=country]').on('change', function () {
            $(this).closest('form').find('[id$=postal]').trigger('change');
        });

        this.$el.on('invalid-form.validate', (event, $validator) => {
            var errors = $validator.errorList;

            if (errors) {
                for (var i = 0; i < errors.length; i++) {
                    var $errorElement = $('#' + errors[i].element.id);

                    if (!$errorElement.is(':visible')) {
                        $errorElement.closest('.js-cmp-accordion');
                        emitter.emit('showError', {element: $errorElement});

                        break;
                    }
                }
            }
        });
    }

    onFocuseOut(element) {
        if (!this.checkable(element)) {
            this.element(element);
        }

        if ($(element).hasClass('js-select')) {
            if ($(element).val()) {
                $(element).closest('.selectric-wrapper').removeClass('error');
            } else {
                $(element).closest('.selectric-wrapper').addClass('error');
            }
        }
    }

    errorPlacement(error, element) {
        if (element.hasClass('js-select')) {
            error.appendTo(element.closest('.selectric-wrapper'));
            element.closest('.selectric-wrapper').addClass('error');
        } else {
            error.appendTo(element.parent());
        }
    }

    invalidHandler(form, validator) {
        var $firstVisibleEl;

        if (!validator.numberOfInvalids() && !validator.errorList.length) {
            return;
        }

        for (let i = 0; i < validator.errorList.length; i++) {
            $firstVisibleEl = $(validator.errorList[i].element);
            if (!$firstVisibleEl.isInViewport()) {
                break;
            }
        }

        if (!$firstVisibleEl.isInViewport()) {
            util.scrollTo($firstVisibleEl.offset().top - 100, this.config.scrollDelay);
        }
    }

    getReferences() {
        if (!('ui' in $)) {
            require('jquery-ui-dist/jquery-ui');
        }

        if (!('validate' in $())) {
            require('jquery-validation/dist/jquery.validate');
        }

        if (!('keyCode' in $.ui)) {
            require('jquery-ui/ui/keycode');
        }

        this.referencesLoaded = true;
    }

    get regex() {
        return {
            phone: {
                default: /^\+{0,1}[\d-\s]{2,30}$/,
                es: /^[67]{1}[\d]{8}$/
            },
            postal: {
                at: /(^[0-9]{4}$)/,
                be: /(^[0-9]{4}$)/,
                de: /(^[0-9]{5}$)/,
                nl: /(^\d{4}[ ][A-Za-z]{2}$)/,
                pl: /(^[0-9]{2}-[0-9]{3}$)/,
                pt: /(^[0-9]{4}(-[0-9]{3})?$)/,
                es: /(^[0-9]{5}$)/,
                ch: /(^[0-9]{4}$)/,
                us: /^\d{5}(-\d{4})?$/,
                ca: /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/,
                fr: /(^[0-9]{5}$)/,
                mc: /(^[0-9]{5}$)/,
                it: /(^[0-9]{5}$)/,
                jp: /^([0-9]){3}[-]([0-9]){4}$/,
                cn: /^([0-9]){6}$/,
                gb: /^[\w\d]{1}.{0,7}[\w\d]{1}$/,
                lu: /^([0-9]){4}$/,
                gr: /^([0-9]){5}$/,
                uk: new RegExp(Resources.UK_POSTAL_CODE_REG_EXP),
                bfpo: new RegExp(Resources.BFPO_NUMBER_REG_EXP)
            },
            notCC: /^(?!(([0-9 -]){13,19})).*$/,
            cardNumber: /(^([0-9]){12,19})|(^\*{8,15}[0-9]{4})$/,
            cardCvn: /(^[0-9]{3,4}$)/,
            email: new RegExp(Resources.REGEXP_EMAIL),
            password: new RegExp(Resources.REGEXP_PASSWORD),
            mobile: new RegExp(Resources.REGEXP_MOBILE),
            aviosNumber: /^[0-9]*$/,
            day: /^0[1-9]|[12][0-9]|3[01]$/,
            month: /^0[1-9]|10|11|12$/,
            name: /^((?![\u003C\u003E])(?!&lt;)(?!&gt;)(?!&amp;).)*$/
        };
    }

    getFieldConfig($el) {
        var $fieldRow = $el.closest('.js-field-row'),
            config = {};

        if (!$fieldRow.length) {
            return config;
        }

        try {
            config = JSON.parse($fieldRow.attr('data-json-config'));
        } catch (e) {
            config = {};
        }

        return config;
    }

    validateFieldByRegex (value, element, regex) {
        var isOptional = !$(element).hasClass('required') || this.$validator.optional(element);
        var config = this.getFieldConfig($(element));
        var currentRegex;

        if (isOptional && value === '') {
            return true;
        }

        currentRegex = regex || new RegExp(config.regEx, 'ig');

        if (!currentRegex) {
            return true;
        }

        return currentRegex.test($.trim(value));
    }

    validatePhone (value, el) {
        if ($(el).hasClass('correos-ap')) {
            return this.validateFieldByRegex.call(this, value, el);
        }

        var rgx = this.regex.phone.default;
        var isOptional = this.$validator.optional(el);
        var isValid = rgx.test($.trim(value));

        return isOptional || isValid;
    }

    validateAviosNumber(value, element) {
        return this.validateFieldByRegex.call(this, value, element, this.regex.aviosNumber);
    }

    validateEmail(value, element) {
        return this.validateFieldByRegex.call(this, value, element, this.regex.email);
    }

    validateBirthDateDay(value, element) {
        var errorMsgSelector = 'js-date-of-birth-error-msg';
        $(element).closest('.js-date-of-birth').find('.' + errorMsgSelector).remove();

        // get other dob fields
        var $dobContainer = $(element).closest('.js-date-of-birth');
        var $month = $dobContainer.find('.dob_month');
        var $year = $dobContainer.find('.dob_year');
        var day, month, year;

        // check with other dob fields
        if (!$month.length || !$year.length) {
            return false;
        }

        month = $month.val();
        year = $year.val();

        if (value === '') {
            return month === '' && year === '';
        }

        day = parseInt(value, 10);
        month = parseInt(month, 10);
        year = parseInt(year, 10);

        if (day > 28 && month) {
            switch (month) {
                case 2:
                    // February
                    if (day > 29 || (year && !((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0))) {
                        // Not leap year or day > 29
                        return false;
                    }
                    break;
                case 4:
                case 6:
                case 9:
                case 11:
                    // April, June, September, November
                    if (day > 30) {
                        return false;
                    }
                    break;
                default:
                    break;
            }
        }

        return this.validateFieldByRegex.call(this, value, element, this.regex.day);
    }

    validateBirthDateMonth(value, element) {
        var errorMsgSelector = 'js-date-of-birth-error-msg';
        $(element).closest('.js-date-of-birth').find('.' + errorMsgSelector).remove();

        // get other dob fields
        var $dobContainer = $(element).closest('.js-date-of-birth');
        var $day = $dobContainer.find('.dob_day');
        var $year = $dobContainer.find('.dob_year');
        var day, month, year;

        // check with other dob fields
        if (!$day.length || !$year.length) {
            return false;
        }

        day = $day.val();
        year = $year.val();

        if (value === '') {
            return day === '' && year === '';
        }

        month = parseInt(value, 10);
        day = parseInt(day, 10);
        year = parseInt(year, 10);

        switch (month) {
            case 2:
                // February
                // eslint-disable-next-line max-len
                if (day && (day > 29 || (day === 29 && year && !((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)))) {
                    // Not leap year or day > 29
                    return false;
                }
                break;
            case 4:
            case 6:
            case 9:
            case 11:
                // April, June, September, November
                if (day && day > 30) {
                    return false;
                }
                break;
            default:
                break;
        }

        return this.validateFieldByRegex.call(this, value, element, this.regex.month);
    }

    validateBirthDateYear(value, element) {
        var errorMsgSelector = 'js-date-of-birth-error-msg';
        $(element).closest('.js-date-of-birth').find('.' + errorMsgSelector).remove();

        // get other dob fields
        var $dobContainer = $(element).closest('.js-date-of-birth');
        var $day = $dobContainer.find('.dob_day');
        var $month = $dobContainer.find('.dob_month');

        // check with other dob fields
        if (!$day.length || !$month.length) {
            return false;
        }

        if (value === '') {
            return $day.val() === '' && $month.val() === '';
        }

        var year = parseInt(value, 10);

        if (isNaN(year)) {
            return false;
        }

        var minYear = 1900;
        var maxYear = new Date().getFullYear();

        if (year < minYear || year > maxYear) {
            return false;
        }

        if ($day.val() === '' || $month.val() === '') {
            // skip showing an error until all fields are filled
            return true;
        }

        var day = parseInt($day.val(), 10);
        var month = parseInt($month.val(), 10);

        // check if date is valid
        var testDate = new Date(year, month - 1, day);

        if (testDate.getMonth() + 1 !== month || testDate.getDate() !== day || testDate.getFullYear() !== year) {
            return false;
        }

        return true;
    }

    validatePassword(value, element) {
        return this.validateFieldByRegex.call(this, value, element, this.regex.password);
    }

    validatePostal(value, element) {
        var country = $(element).closest('.js-address-form').find('.country'),
            countryCode = country.val().toLowerCase();
        if (country.length === 0 || !countryCode || !this.regex.postal[countryCode]) {
            return true;
        }
        return this.validateFieldByRegex.call(this, value, element, this.regex.postal[countryCode]);
    }

    validateField(value, element) {
        return this.validateFieldByRegex.call(this, value, element);
    }

    validateOwner(value) {
        var isValid = this.regex.notCC.test($.trim(value));
        return isValid;
    }

    validateCardNumber(value) {
        var isValid = this.regex.cardNumber.test(value.replace(/[ -]/g, ''));
        return isValid;
    }

    validateCvn(value) {
        var isValid = this.regex.cardCvn.test($.trim(value));
        return isValid;
    }

    validateCCDate() {
        var isValid = true;
        var expiryMonth = parseInt($('.js-cc-month-hidden').val(), 10);
        var expiryYear = parseInt($('.js-cc-year-hidden').val(), 10);
        if (expiryMonth && expiryYear) {
            if (new Date() > new Date(expiryYear, expiryMonth)) {
                isValid = false;
            }
        }
        if (isValid) {
            $('.js-cc-month').removeClass('js-cc-date-invalid');
            $('.js-cc-year').removeClass('js-cc-date-invalid');
        } else {
            $('.js-cc-month').addClass('js-cc-date-invalid');
            $('.js-cc-year').addClass('js-cc-date-invalid');
        }
        return isValid;
    }

    validateName(value, element) {
        return this.validateFieldByRegex.call(this, value, element, this.regex.name);
    }

    isPasswordEqual(val) {
        return val === $('.js-password').val();
    }

    creditcardDateMessage(params, element) {
        var errorMsg = '';
        var $element = $(element);
        if ($element.hasClass('js-cc-month-hidden')) {
            errorMsg = Resources.VALIDATE_CREDITCARD_DATE_ERROR;
        }
        return errorMsg;
    }

    dateOfBirthMessage(params, element) {
        var errorMsgSelector = 'js-date-of-birth-error-msg';
        var errorMsgStylesSelector = 'error';
        var errorMsg = '';
        var $errorMsg = $('<span/>').addClass([errorMsgSelector, errorMsgStylesSelector].join(' ')).html(Resources.VALIDATE_DATE);

        $(element).closest('.js-date-of-birth').append($errorMsg);

        return errorMsg;
    }

    extendValidatorMethods() {
        $.validator.addMethod('email', this.validateEmail.bind(this), Resources.VALIDATE_EMAIL);
        $.validator.addMethod('postal', this.validatePostal.bind(this), Resources.INVALID_ZIP);
        $.validator.addMethod('phone', this.validatePhone.bind(this), Resources.INVALID_PHONE);
        $.validator.addMethod('owner', this.validateOwner.bind(this), Resources.INVALID_OWNER);
        $.validator.addMethod('avios', this.validateAviosNumber.bind(this), Resources.INVALID_AVIOS_NUMBER);
        $.validator.addMethod('js-cc-number', this.validateCardNumber.bind(this), Resources.INVALID_CARDNUMBER);
        $.validator.addMethod('cvn', this.validateCvn.bind(this), Resources.INVALID_CVN);
        $.validator.addMethod('password', this.validatePassword.bind(this), Resources.VALIDATE_PASSWORD);
        $.validator.addMethod('isPasswordEqual', this.isPasswordEqual.bind(this) , Resources.VALIDATE_PASSWORD_CONFIRM);
        $.validator.addMethod('dob_day', this.validateBirthDateDay.bind(this), this.dateOfBirthMessage);
        $.validator.addMethod('dob_month', this.validateBirthDateMonth.bind(this), this.dateOfBirthMessage);
        $.validator.addMethod('dob_year', this.validateBirthDateYear.bind(this), this.dateOfBirthMessage);
        $.validator.addClassRules({
            'js-passwordconfirm': {
                isPasswordEqual: true
            }
        });

        /**
         * Add credit card date validation method to jQuery validation plugin
         * Should not be expired comparing to current date
         * If selected date is less than current date plus two month
         * (this value should be manageable via site preference) then error should be shown.
         */
        $.validator.addMethod('js-creditcard-date', this.validateCCDate.bind(this), this.creditcardDateMessage);
        $.validator.addMethod('js-input-firstname', this.validateName.bind(this), Resources.INVALID_FIRST_NAME);
        $.validator.addMethod('js-input-lastname', this.validateName.bind(this), Resources.INVALID_LAST_NAME);
    }

    extendValidatorMessages() {
        $.extend($.validator.messages, {
            required: Resources.VALIDATE_REQUIRED,
            remote: Resources.VALIDATE_REMOTE,
            email: Resources.VALIDATE_EMAIL,
            url: Resources.VALIDATE_URL,
            date: Resources.VALIDATE_DATE,
            dateISO: Resources.VALIDATE_DATEISO,
            number: Resources.VALIDATE_NUMBER,
            digits: Resources.VALIDATE_DIGITS,
            creditcard: Resources.VALIDATE_CREDITCARD,
            equalTo: Resources.VALIDATE_EQUALTO,
            maxlength: $.validator.format(Resources.VALIDATE_MAXLENGTH),
            minlength: $.validator.format(Resources.VALIDATE_MINLENGTH),
            rangelength: $.validator.format(Resources.VALIDATE_RANGELENGTH),
            range: $.validator.format(Resources.VALIDATE_RANGE),
            max: $.validator.format(Resources.VALIDATE_MAX),
            min: $.validator.format(Resources.VALIDATE_MIN),
            password: Resources.VALIDATE_PASSWORD
        });

        /**
         * Add possibility to get required message from attribute in if it is else will be used default value
         */
        $.validator.messages.required = function ($1, element) {
            var config = $(element).closest('.form-row').data('jsonConfig');
            var requiredText = config && config.requiredtext;
            return requiredText || Resources.VALIDATE_REQUIRED;
        };
    }
}
