import Block from '_core_ext/core/common/Block';
var flag = false;


export default class LocationsList extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.locations = this.config.locations;

        this.event('click', '.js-show-more', this.showMoreInfo.bind(this));
        this.event('click', '.js-close-more', this.closeMoreInfo.bind(this));
        this.event('click', '.js-select-location', this.onLocationSelection.bind(this));
        this.event('click', '.js-location-pin', this.onLocationPinClick.bind(this));
        this.event('click', '.js-show-all-locations ', this.onShowAllLocationsClick.bind(this));
        if($(window).width() < 1025){
            this.event('click', '.js-show-button', this.onClickStoreTiminigs.bind(this));
        }
        else {            
            this.event('mouseover', '.js-show-button', this.onMouseOverStoreTiminigs.bind(this));
            this.event('mouseout', '.js-show-button', this.onMouseOutStoreTiminigs.bind(this));
        }
    }

    onMouseOverStoreTiminigs(el, event){
        event.preventDefault();
        $(el).siblings('.js-schedule-table').removeClass('is--hidden')
    }

    onMouseOutStoreTiminigs(el, event){
        event.preventDefault();
        $(el).siblings('.js-schedule-table').addClass('is--hidden')
    }

    onClickStoreTiminigs(el, event){
        event.preventDefault();
        if(flag == false){
            $(el).siblings('.js-schedule-table').removeClass('is--hidden');
            flag = true;
        }
        else{
            $(el).siblings('.js-schedule-table').addClass('is--hidden');
            flag = false;
        }
    }


    showMoreInfo (el, event) {
        event.preventDefault();
        $(el).addClass('is--hidden');
        $(el).siblings().removeClass('is--hidden');
    }

    closeMoreInfo (el, event) {
        event.preventDefault();
        $(el).addClass('is--hidden');
        $(el).siblings('.js-schedule-table').addClass('is--hidden');
        $(el).siblings('.js-show-more').removeClass('is--hidden');
    }

    toggle (hide) {
        this.$el.toggleClass('is--hidden', hide);
    }

    onLocationSelection (el, event) {
        event.stopPropagation();
        var $el = $(el);
        var locationId = $el.data('id');
        var locationInfo = $el.data('location-info') || this.getLocationInfo(locationId);
        this.$el.find('.js-show-more.is--hidden').removeClass('is--hidden').siblings().addClass('is--hidden');
        this.emitter.emit('locationsList.location.selected', locationId, locationInfo);
    }

    onLocationPinClick (el) {
        var locationId = $(el).data('id');
        this.emitter.emit('locationsList.location.pin.click', locationId);
    }

    onShowAllLocationsClick (el) {
        $(el).addClass('is--hidden');
        this.$el.find('.js-location-item.is--hidden').removeClass('is--hidden');
    }

    getLocationListTile (locationId) {
        return this.$el.find(`.js-locations-list .js-location-item-${locationId} .js-location-item-info`).html();
    }

    getLocationInfo (locationId) {
        return this.locations.find((location) => location.ID === locationId) || {};
    }
}