import Account from '_core_ext/components/forms/Account';

export default class Subscription extends Account {
    init() {
        super.init();
        this.event('click', '.js-gender-type-selection input', () => this.toggleGenderValidationError(false));

        this.additionalvalidators = [
            this.genderSelectionValidation.bind(this)
        ];
    }

    genderSelectionValidation() {
        var $genderSelection = this.$el.find('.js-gender-type-selection');
        var $newsletterPolicy = this.$el.find('.js-newsletter-policy');
        if (!$genderSelection.length || !$newsletterPolicy.prop('checked')) {
            return true;
        }

        var isValid = !!$genderSelection.find('input')
            .filter((i, checkbox) => checkbox.checked)
            .length;

        if (!isValid) {
            this.toggleGenderValidationError(true);
        }

        return isValid;
    }

    toggleGenderValidationError(show) {
        if (show) {
            this.$el.find('.js-gender-type-error-msg').fadeIn();
            if ($('.recaptcha-verify').length) {
                var googleRecaptcha = require('_core_ext/googleRecaptcha');
                googleRecaptcha.buildRecaptchaV2();
            }
        } else {
            this.$el.find('.js-gender-type-error-msg').fadeOut();
        }
    }
}