import Block from '_core_ext/core/common/Block';
import commonUtils from '_core_ext/components/checkout/utils/commonUtils';

export default class ShippingFormBase extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.deliveryOption = this.config.deliveryOption;
        this.deliveryForm = this.items.find((form) => {
            return form.config.type === 'deliveryForm';
        });
        this.shipByRegionEnabled = SitePreferences.SHIP_BY_REGION_ENABLED;
        this.showErrorMessage = commonUtils.showPostalCodeRestrictionsErrorMessage.bind(this);
        this.clearErrorMessage = commonUtils.clearPostalCodeRestrictionsErrorMessage.bind(this);
        this.event('click', '.js-checkout__button', this.onDeliverFormSubmit.bind(this));
        this.event('click', '.js-change-country-button', this.onChangeCountry.bind(this));
    }

    toggleDeliveryForm (hide) {
        this.deliveryForm.$el.closest('.js-delivery-form').toggleClass('is--hidden', hide);
    }

    isDeliveryFormActive () {
        return this.deliveryForm.$el.is(':visible');
    }

    isDeliveryFormValid () {
        return this.deliveryForm.$el.validate().form();
    }

    getDeliveryAddressInfo () {
        return {};
    }

    clearDeliveryForm () {
        this.deliveryForm.$el.validate().reset();
    }

    onDeliverFormSubmit (el, event) {
        event.preventDefault();
        if (!this.beforeSubmitCheck()) {
            return;
        }
        this.emitter.emit('shippingForm.on.delivery.form.submit', this.getDeliveryAddressInfo(), this.deliveryOption);
    }

    onChangeCountry (el, event) {
        event.preventDefault();
        this.emitter.emit('shippingForm.on.change.country.click');
    }

    getShopperInfo () {
        return {
            firstName: this.deliveryForm.$el.find('input[name$="_firstName"]').val(),
            lastName: this.deliveryForm.$el.find('input[name$="_lastName"]').val(),
            email: this.deliveryForm.$el.find('input[name$="_email"]').val(),
            phone: this.deliveryForm.$el.find('input[name$="_phone"]').val(),
            title: this.deliveryForm.$el.find('.js-shipping-address-title option:selected').val() || ''
        };
    }

    updateDeliveryForm () {
        throw new Error('updateDeliveryForm method must be implemented on children clases');
    }

    acceptPolicies (accept = true) {
        var $acceptPolicyCheckBox = this.deliveryForm.$el.find('[name$=_acceptpolicy]');
        var isCheckboxChecked = $acceptPolicyCheckBox.prop('checked');

        if (accept !== isCheckboxChecked) {
            $acceptPolicyCheckBox.click();
        }
    }

    getDeliveryAddressesData () {
        throw new Error('getDeliveryAddressesData method must be implemented on children classes');
    }

    beforeSubmitCheck () {
        return this.isDeliveryFormValid();
    }
}
