import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';

export default class Tracking extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.pageType = this.config.pageType;
        this.pageData = this.config.pageData;
        this.storeId = this.config.storeId;
        this.visitorData = this.config.visitorData;
        this.orderId = this.config.orderId;
        // Removed from Resource.js as per the requirement of ECOM-307 Removing Tracking URL. 
        this.OMETRIA_TRACKING_SCRIPT_URL = SitePreferences.OMETRIA_TRACKING_SCRIPT_URL;
        this.ometria = {};

        if ('ometria' in window) {
            this.initializeCmp();
        } else if (this.OMETRIA_TRACKING_SCRIPT_URL) {
            $.getScript(this.OMETRIA_TRACKING_SCRIPT_URL)
                .done(() => this.initializeCmp());
        }
    }

    initializeCmp() {
        this.ometria = window.ometria;

        this.trackPage();

        this.registerActions();
    }

    registerActions() {
        eventMgr.registerAction('Ometria.cart.changed', this.createEventAction(this.updateBasket));
        eventMgr.registerAction('Ometria.track.page', this.createEventAction(this.trackPage));
        eventMgr.registerAction('Ometria.identify.customer', this.createEventAction(this.identifyCustomer));
        eventMgr.registerAction('Ometria.add.to.cart', this.createEventAction(this.addToCart));
    }

    identifyCustomer(visitorData) {

        var {email, profileId} = visitorData;
        var visitorIdentity = email || profileId;

        if (!visitorIdentity) {
            return;
        }

        this.ometria.identify(visitorIdentity);
    }

    getBasketInfo() {
        return $('[data-ometria-basket-info]').data('ometria-basket-info');
    }

    setBasket (basketInfo) {
        if (!basketInfo || !basketInfo.exists) {
            return;
        }

        var {id, url, total, currency, productLineItems} = basketInfo;
        var basket = new this.ometria.Basket();

        basket.setId(id);
        basket.setUrl(url);
        basket.setTotal(total, currency);
        productLineItems.forEach((lineItem) => {
            var {masterProductId, variantId, qty, price} = lineItem;
            basket.addLineItem(variantId, qty, price, variantId);
        });
        this.ometria.setBasket(basket);
    }

    updateBasket() {
        var basketInfo = this.getBasketInfo();
        this.setBasket(basketInfo);
    }

    addToCart(productId) {
        this.updateBasket();
        this.ometria.trackAddToBasket(productId);
    }

    trackPage(pageData) {
        pageData = pageData || this.pageData;

        this.ometria.init(this.pageType, pageData, this.storeId);

        if (this.visitorData.isKnownVisitor) {
            this.identifyCustomer(this.visitorData);
        }

        this.updateBasket();

        if (this.orderId) {
            this.ometria.trackTransaction(this.orderId);
        }
    }

    createEventAction(action) {
        var self = this;
        return function() {
            return new Promise((res) => {
                action.apply(self, [...arguments]);
                res();
            });
        };
    }
}