/* eslint-disable linebreak-style */
'use strict';
var widgetId1;

// ReCaptcha v2 module builder
function buildRecaptchaV2() {
    $('#googleRecaptchaV2').css('display', 'block');
    widgetId1 = grecaptcha.enterprise.ready(function () {
        try {
            grecaptcha.enterprise.render('googleRecaptchaV2', {
                sitekey: $('#google-recaptcha-main').data('google-recaptchav2-key')
            });
        } catch (err) {
            console.log(err.message);
        }
    });
}

// Individual method to handle reCaptcha v2 response
function getRecaptchaV2Response() {
    return new Promise(function (resolve, reject) {
        if ($('.recaptcha-verify').length == 0 || !$('#google-recaptcha-main').data('google-recaptchav2-enabled')) {
            resolve(true);
        } else {
            if ($('#googleRecaptchaV2:visible').length < 1) {
                // buildRecaptchaV2();
            }
            $.ajax({
                url: $('#google-recaptcha-main').data('google-recaptchav2-url'),
                type: 'POST',
                dataType: 'json',
                data: {
                    token: grecaptcha.enterprise.getResponse()
                },
                success: function (responseData) {
                    if (responseData.result === true) {
                        grecaptcha.enterprise.reset();
                        $('#googleRecaptchaV2').css('display', 'none');
                        resolve(true);
                    } else {
                        grecaptcha.enterprise.reset();
                        reject(false);
                    }
                }
            });
        }
    });
}

// Handle reCaptcha behavior, calling both v2 and v3 or handling only one version.
function getRecaptchaResponse() {
    return new Promise(function (resolve, reject) {
        if ($('.recaptcha-verify').length && !$('#google-recaptcha-main').data('google-recaptchav3-enabled')) {
            if ($('#google-recaptcha-main').data('google-recaptchav2-enabled')) {
                getRecaptchaV2Response().then(
                    function (value) {
                        resolve(true);
                    },
                    function (error) {
                        reject(false);
                    }
                );
            } else {
                resolve(true);
            }
        } else if ($('.recaptcha-verify').length && $('#googleRecaptchaV2:visible').length < 1) {
            grecaptcha.enterprise.ready(function () {
                grecaptcha.enterprise.execute($('#google-recaptcha-main').data('google-recaptchav3-key'), {
                    action: 'submit'
                }).then(function (token) {
                    $.ajax({
                        url: $('#google-recaptcha-main').data('google-recaptchav3-url'),
                        method: 'POST',
                        dataType: 'json',
                        data: {
                            token: token
                        },
                        success: function (responseData) {
                            if (responseData.statusCode === 200) {
                                if (responseData.data.score < $('#google-recaptcha-main').data('google-recaptchav3-score')) {
                                    if ($('#google-recaptcha-main').data('google-recaptchav2-enabled')) {
                                        buildRecaptchaV2();
                                    } else {
                                        reject(false);
                                    }
                                } else {
                                    resolve(true);
                                }
                            } else {
                                reject(false);
                            }
                        }
                    });
                });
            });
        } else {
            getRecaptchaV2Response().then(
                function (value) {
                    resolve(true);
                },
                function (error) {
                    reject(false);
                }
            );
        }
    });
}

module.exports = {
    getRecaptchaResponse: getRecaptchaResponse,
    getRecaptchaV2Response: getRecaptchaV2Response,
    buildRecaptchaV2: buildRecaptchaV2
};
