'use strict';
/**
 * Loading the GDPR script before GTM script
 * Getting the script src URL and attributes from custom site preference
 * Preference ID:GDPRcookieScriptHeader, Group : GDPR
 * Loading 2 scripts from preference and differentiating with pipe (||) symbol
 */
function initGDPRScript() {
    var cmpScript = SitePreferences.CMP_GDPR_SCRIPT.split('||');
    for (var i=1; i<=1&&i>=0; i--) {
        var topEle = document.getElementsByTagName('script')[0];
        var script = document.createElement('script');
        if (i == 0) {
            for (let key in JSON.parse(cmpScript[i])) {
                if (JSON.parse(cmpScript[i]).hasOwnProperty(key)) {
                  let val = JSON.parse(cmpScript[i])[key];
                  script.setAttribute(key, val);
                }
            }
        } else {
            script.type= 'text/javascript';            
            script.textContent = cmpScript[i];
        }
        topEle.parentNode.insertBefore(script, topEle);

    }
}

module.exports = {
    init: function () {        
        if (!SitePreferences.CMP_GDPR_SCRIPT) {
            return;
        }
        initGDPRScript();
    }
};