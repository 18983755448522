import PickUpShippingFormBase from './PickUpBase';
import eventMgr from '_core_ext/core/eventMgr';
import util from '_core_ext/util';

export default class InstoreShippingForm extends PickUpShippingFormBase {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);

        this.event('change', '.js-select-state', this.onChangeStoreEstate.bind(this));
    }

    subscribeToChildrenEvents () {
        this.onChild('locationsPicker', 'locationsPicker.location.selected', this.onStoreSelected.bind(this));
    }

    getSelectedStoreId () {
        return this.$el.find('.js-selected-store-id').val();
    }

    onChangeStoreEstate (el) {
        if (!$(el).val()) {
            this.locationsPicker.$el.empty();
            return;
        }

        var action = this.locatorForm.$el.find('.js-find-by-state-button').attr('name');
        var formData = this.locatorForm.$el.serializeArray();
        super.handleGetLocationsPicker(action, formData, null);
    }

    onStoreSelected (storeId, storeInfo) {
        if (!storeId || !Object.keys(storeInfo).length) {
            return;
        }
        this.getStoreMiniaddress(storeId)
            .then((selectedStoreHtml) => {
                if (!selectedStoreHtml) {
                    return;
                }
                super.onLocationSelected(storeId, storeInfo, selectedStoreHtml);
                this.emitter.emit('inStoreShippingForm.store.selected', this.deliveryOption, {
                    locationId: storeId,
                    locationHtml: selectedStoreHtml
                });
            });
    }

    fillDeliveryFormWithLocationtInfo (storeId) {
        this.deliveryForm.$el.find('.js-selected-store-id').val(storeId);
    }

    getStoreMiniaddress (storeId) {
        return eventMgr.execute('get.store.mini.address', {storeId: storeId});
    }

    onFindLocationsClick (el, event) {
        event.preventDefault();
        this.findPickUpLocations();
    }

    onCurrentLocationClick (el, event) {
        event.preventDefault();
        this.findPickUpLocationsOncurrentlocation();
    }

    updateDeliveryForm (deliveryAddressInfo, shopperInfo, isCustomerLogged) {
        var {locationId, locationInfo, locationHtml} = deliveryAddressInfo;
        if (isCustomerLogged) {
            if (locationId && locationHtml) {
                this.onLocationSelected(locationId, locationInfo, locationHtml);
            }
        } else {
            if (locationId && locationHtml) {
                this.onLocationSelected(locationId, locationInfo, locationHtml);
            }
            util.fillAddressFields(shopperInfo, this.deliveryForm.$el);
        }
    }

    getDeliveryAddressesData () {
        var $deliveryForm = this.deliveryForm.$el;
        return {
            'locationId': $deliveryForm.find('.js-selected-store-id').val(),
            'locationHtml': $deliveryForm.find('.js-selected-location-info').html()
        };
    }
}