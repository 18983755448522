import Block from '_core_ext/core/common/Block';
import mapUtils from './utils';
import mapstyles from '_core_ext/configurations/google-map-styles.json';

const GOOGLE_MAPS_URL = 'https://maps.googleapis.com/maps/api/js?key=' + SitePreferences.GOOGLE_MAPS_API_KEY +
    '&libraries=places';
var google;
export default class LocationsMap extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.popupTemplateString = this.$el.find('.js-locator-marker-template').html();
        this.map = {};
        this.markersObjs = {};
        this.MapPopUp = {};

        if ('google' in window) {
            this.initializeCmp();
        } else {
            $.getScript(GOOGLE_MAPS_URL)
                .done(() => this.initializeCmp());
        }
    }

    initializeCmpEvents () {
        this.event('click', '.js-select-location', this.onLocationSelection.bind(this));
        this.event('click', '.js-close-popup', this.onClosePopUp.bind(this));
    }

    initializeCmp () {
        google = window.google;
        this.MapPopUp = require('./MapPopUp').default;
        this.renderLocations();
        this.initializeCmpEvents();
    }

    toggle (hide) {
        this.$el.find('.js-popup-bubble-content').hide();
        this.$el.find('.js-map-wrapper').toggleClass('is--hidden', hide);
    }

    renderLocations () {
        var locations = this.config.locations;
        if (!locations || !(locations instanceof Array)) {
            return;
        }

        var centerCoordinates = mapUtils.getCenter(locations);
        var popupHTML;

        this.initMap(this.config.zoom, centerCoordinates);

        for (var location of locations) {
            popupHTML = this.createPopupHTML(location);
            this.addPopupToMap(location, popupHTML);
            this.createAndAddMarkerToMap(location);
        }
    }

    initMap (zoom, center) {
        var mapDOM = this.$el.find('.js-locations-map').get(0);
        this.map = new google.maps.Map(mapDOM, {
            zoom: zoom || 10,
            center: center,
            styles: mapstyles
        });
    }

    createPopupHTML (location) {
        var $popup = $('<div></div>');
        var popupContent = this.popupTemplateString.slice(0);

        popupContent = popupContent.replace(/%LOCATIONID%/g, location.ID);
        popupContent = popupContent.replace(/%NAME%/g, location.name);
        popupContent = popupContent.replace(/%ADDRESS1%/g, location.address1);
        popupContent = popupContent.replace(/%ADDRESS2%/g, location.address2 ? location.address2 : '');
        popupContent = popupContent.replace(/%POSTAL_CODE%/g, location.postalCode);

        $popup.html(popupContent);
        $popup.find('.js-select-location').data('location-info', location);

        return $popup;
    }

    addPopupToMap (location, $popup) {
        var position = new google.maps.LatLng(location.lat, location.lng);
        var popup = new this.MapPopUp(position, $popup, location.ID);
        popup.setMap(this.map);
    }

    createAndAddMarkerToMap (location) {
        var position = new google.maps.LatLng(location.lat, location.lng);
        var marker = new google.maps.Marker({
            position: position,
            map: this.map,
            icon: this.config.markerIconUrl
        });

        marker.set('markerid', location.ID);
        this.markersObjs[location.ID] = marker;

        this.addListenerToMapMarker(marker);
    }

    addListenerToMapMarker (marker) {
        var handler = function (marker) {
            var markerId = marker.get('markerid');
            return function () {
                this.$el.find('.js-popup-bubble-content').hide();
                this.$el.find('.js-popup-' + markerId).show();

                var divHeightOfTheMap = this.$el.find('.js-map-wrapper').get(0).clientHeight;
                var offSetFromBottom = 100;
                this.map.setCenter(marker.getPosition());
                this.map.panBy(0, -(divHeightOfTheMap / 2 - offSetFromBottom));
            };
        };

        google.maps.event.addListener(marker, 'click', handler(marker).bind(this));
    }

    onLocationSelection (el, event) {
        event.stopPropagation();
        var $el = $(el);
        var locationId = $el.data('id');
        var locationInfo = $el.data('location-info');
        if (!locationId) {
            return;
        }
        this.emitter.emit('locationsMap.location.selected', locationId, locationInfo);
    }

    onClosePopUp (el, event) {
        event.stopPropagation();
        $(el).closest('.js-popup-bubble-content').hide();
    }

    showPinLocation (locationId) {
        var marker = this.markersObjs[locationId];
        google.maps.event.trigger(marker, 'click');
    }
}
