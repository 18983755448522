import FormGeneric from './../forms/FormGeneric';
import ajax from '../../ajax';

export default class CountrySelectForm extends FormGeneric {
    init() {
        super.init();

        this.countriesOptions = this.config.countriesOptions;

        this.prepareForm(this.config.cookieCountry);

        this.onChild(this.config.countryField, 'change', this.onChangeCountry);
        this.event('click', '.js-submit-btn', this.onSubmit);

        this.getById(this.config.countryField, (countryCmp) => {
            this.onChangeCountry(countryCmp, this.config.cookieCountry);
        });
    }
    prepareForm(code) {
        let countryOptions = [];
        let defaultCountryCode = this.countriesOptions[0].countryCode;

        this.countriesOptions.map((config) => {
            countryOptions.push({
                label: config.countryName,
                value: config.countryCode
            });
        });

        this.getById(this.config.countryField, (countryCmp) => {
            let countryCode = code || defaultCountryCode;

            countryCmp.setOptions(countryOptions);
            countryCmp.setValue(countryCode);

            countryCmp.getById('selectric', (childCmp) => {
                childCmp.update();
            });
        });

        this.getById(this.config.languageField, (cmp) => {
            cmp.setValue(this.config.cookieLocale);

            cmp.getById('selectric', (childCmp) => {
                childCmp.update();
            });
        });
    }
    onChangeCountry(cmp, code) {
        let countryCode = code || cmp.getSelectedOption().val();
        let countryConfig = this.countriesOptions.find((config) => config.countryCode === countryCode);
        let countryLocales = countryConfig.locales;

        this.getById(this.config.languageField, (cmp) => {
            cmp.setOptions(countryLocales.map((locale) => ({
                value: locale.id,
                label: locale.displayName
            })));

            cmp.getById('selectric', (childCmp) => {
                childCmp.update();
            });
        });

        this.toggleLanguagesBlock(countryLocales);
    }
    toggleLanguagesBlock(countryLocales) {
        if (countryLocales.length > 1) {
            this.getById(this.config.languageField, (cmp) => {
                cmp.show();

                var isCookieLocaleInCountries = countryLocales.filter((item) => {
                    return item.id === this.config.cookieLocale;
                });

                cmp.setValue(isCookieLocaleInCountries.length ? this.config.cookieLocale : countryLocales[0].id);
                cmp.getById('selectric', (childCmp) => {
                    childCmp.update();
                });
            });

            this.setSingleLanguage();
        } else {
            this.getById(this.config.languageField, (cmp) => {
                cmp.setValue(countryLocales[0].id);

                cmp.hide();
            });

            this.setSingleLanguage(countryLocales[0].displayName);
        }
    }
    setSingleLanguage(value) {
        if (!value) {
            this.getById('singleLanguage', (cmp) => {
                cmp.$el.find('.js-single-language').text('');
                cmp.hide();
            });

            return;
        }

        this.getById('singleLanguage', (cmp) => {
            cmp.$el.find('.js-single-language').text(value);
            cmp.show();
        });
    }
    /**
    * @description get external url for the locale from the config
    * @return {String|null} returns external url if exists or null if not
    */
    getExternalUrl() {
        let countryConfig;

        this.getById(this.config.countryField, (cmp) => {
            countryConfig = this.countriesOptions.find((config) => config.countryCode === cmp.getValue());
        });

        if (countryConfig && countryConfig.externalUrl) {
            return countryConfig.externalUrl;
        }

        return null;
    }
    /**
    * @description submit country selector form handler
    */
    onSubmit(el, event) {
        event.preventDefault();

        const externalUrl = this.getExternalUrl();

        if (externalUrl) {
            return window.location.assign(externalUrl);
        }

        this.getById(this.config.urlparamsField, (cmp) => {
            cmp.setValue(this.config.urlParams)
        });

        let formData = this.$el.serializeArray();

        this.getById('countrySelectSubmit', (cmp) => {
            formData.push({
                name: cmp.$el.attr('name'),
                value: cmp.$el.val()
            })
        });

        var url = this.$el.attr('action');
        const util = require('_core_ext/util');
        var contentPage;

        if ($('.qrlink-country-switcher').val() === 'true') {
            contentPage = 'qrlink';
        } else if ($('.raffle-country-switcher').val() === 'true') {
            contentPage = 'rafflecampaign';
        } else if ($('.map-country-switcher').val() === 'true') {
            contentPage = 'map';
        }
        if (contentPage) {
            url = util.appendParamToURL(url, contentPage, 'true');
        }

        ajax({
            url: url, //this.$el.attr('action'),
            type: 'POST',
            data: formData
        }).then((response) => {
            if (response && JSON.parse(response).url) {
                require('_core_ext/cookie').remove(Resources.COOKIE_VISITED_CATEGORY);
                var responseUrl = JSON.parse(response).url;

                // Remove "format=ajax" param from response url to display full page content after redirect
                var responseUrlQuery = responseUrl.split('?');
                if (responseUrlQuery.length > 1) {
                    var responseUrlParams = responseUrlQuery[1].split('&').filter(function(param) {
                        return param !== 'format=ajax';
                    });
                    responseUrlQuery[1] = responseUrlParams.join('&');
                }

                window.location.href = responseUrlQuery.join('?');
            }
        });
    }
}
