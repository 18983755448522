import Component from '../Component';
import {each} from 'jquery';

export default class Block extends Component {
    init () {
        super.init();

        if (this.config.hidden) {
            this.hide();
        } else {
            this.shown = !this.$el.hasClass('is--hidden');
        }
    }
    hide () {
        this.shown = false;
        this.$el.addClass('is--hidden');
    }
    show () {
        this.shown = true;
        this.$el.removeClass('is--hidden');
    }
    isHidden () {
        return !this.shown;
    }
    isShown () {
        return this.shown;
    }
    eachChild (fn) {
        each(this.items, (i, item) => fn(item));
    }
    addClass (className) {
        this.$el.addClass(className);
    }
    removeClass (className) {
        this.$el.removeClass(className);
    }
    onChild (childID, eventName, fn) {
        this.getById(childID, (chld) => {
            chld.on(eventName, fn.bind(this));
        });
    }
    offChild (childID, eventName, fn) {
        this.getById(childID, (chld) => {
            chld.off(eventName, fn);
        });
    }
    setHtml (val = '') {
        return this.$el.html(val);
    }
    getHeight () {
        return this.$el.height();
    }
}
