'use sctrict';

const featureDetect = require('./featureDetect');

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = true;
window.lazySizesConfig.loadMode = 3;
window.lazySizesConfig.preloadAfterLoad = true;
window.lazySizesConfig.ricTimeout = 250;
window.lazySizesConfig.expand = 700;

if (!featureDetect.hasPicture) {
    require('picturefill')();
}
require('lazysizes').init();

module.exports = {
    init: function () {}
};