'use strict';

var initialized = false,
    $cache = {},
    options = {
        tabSectionWrapper: '.js-recommendations-tabsection-wrapper, .js-tab-wrapper',
        tabSelectorDefault: '.js-tab-switch.active',
        tabSelector: '.js-tab-switch',
        contentSelector: '.tab-content',
        container: '.js-products-tabsections, .js-tab-container'
    };

/**
 * @function
 * @description Initializes the tabs
 */

function initTab() {
    let $tab = $(this).find(options.tabSelector);
    let $content = $(this).find(options.contentSelector);
    let $defaultTab = $(this).find(options.tabSelectorDefault);

    $content.hide();

    if ($defaultTab.length) {
        let index = $tab.index($defaultTab);
        $content.eq(index).show();
    } else {
        $tab.first().addClass('active').show();
        $content.first().show();
    }

    $tab.click(function () {
        var $tabWrapper = $(this).closest(options.tabSectionWrapper);
        $tabWrapper.find(options.tabSelector).removeClass('active');
        $tabWrapper.find(options.contentSelector).hide();
        $(this).addClass('active');
        var activeTab = $tabWrapper.find('.' + $(this).data('contentReference'));
        $(activeTab).fadeIn();
        $cache.document.trigger('tab.changed', [$(this)]);
        return false;
    });
}

function initAllTabs() {
    $(this)
        .find(options.tabSectionWrapper)
        .each(initTab);
}

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function initializeCache() {
    $cache.document = $(document);
}

function initializeEvents() {
    $cache.document
        .on('init.tabs', (event, selector) => {
            initAllTabs.call($(selector));
        });
}

module.exports = {
    init: function (params) {
        initializeParams(params);

        if (initialized) { return; }

        initializeCache();
        initializeEvents();
        initAllTabs.apply(options.container || document);
        initialized = true;
    }
};
