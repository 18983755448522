import NewsletterFormExt from '_core_ext/components/forms/NewsletterForm';

export default class NewsletterForm extends NewsletterFormExt {
    init() {
        super.init();

        this.event('change', '.f-subscribe-form .js-newsletter-email input[type=email]', this.emailShowIcon);
        this.event('change', '.js-notifyme-acceptlettersubscription', this.acceptNewsletter);

    }

    emailShowIcon() {
        let emailField = $('.f-subscribe-form .js-newsletter-email');
        emailField.removeClass('error valid');
        if (!$('.f-subscribe-form .js-newsletter-email input[type=email]').valid()) {
            emailField.addClass('error');
        } else {
            emailField.addClass('valid');
        }
    }
    acceptNewsletter(el, event) {

        if ($('.js-notifyme-acceptlettersubscription').is(':checked')) {
            console.log("Checked");
        }else{
            cosnole.log("unchecked");
        }
        
    }

    onSubmitSuccess(result) {
        this.$el.trigger('newsletterSubscription.success', [this.$el]);

        if (!this.config.isCustomerLogged) {
            this.fireOmetriaActions(result);
        }
        $('#header-top-banner, #header-bottom-banner, .js-header-nav-container, .header__menu-utility, .js-menu-tab-content').removeClass('d-none');
        $('#logo').removeClass('logo-space');
        this.$el.find('.js-emailform-wrapper').hide();
        this.$el.find('.js-toggle_content').hide();
        this.$el.find('.js-result_message').fadeIn();
        this.$el.find('.js-result_message').parents('.c-account__content').addClass('user-subscribed')
        this.$el.find('.js-result_message').parents('.c-account__content').find('.c-registration__title').hide();
        this.$el.get(0).reset();
    }
}