import Component from '_core_ext/core/Component';
import ajax from '_core_ext/ajax';
import page from '_core_ext/page';

export default class LoyaltyJoin extends Component {

    init() {
        this.event('click', this.onClick);
    }

    onClick(el, event) {
        event.preventDefault();
        ajax({
            type: 'POST',
            url: this.config.url,
            callback: this.onSuccessJoin
        });
    }

    onSuccessJoin() {
        page.redirect(Urls.myAccount);
    }
}