'use strict';

let ajax = require('./../../ajax'),
    progress = require('./../../../../../app_storefront_core/cartridge/js/progress'),
    util = require('./../../util');

let $cache = {},
    selectors = {
        pdpMain: '#pdpMain',
        addToCart: '#add-to-cart',
        addAllToCart: '#add-all-to-cart',
        productSetList: '#product-set-list',
        productSetItem: '.c-product-set__list-item',
        addToCartDisabled: '.c-product-details__button[disabled]',
        productSetItemSwatchLink: '.c-product-set__list-item .c-product-variations__link'
    };

function initializeCache() {
    $cache.document = $(document);
    $cache.pdpMain = $cache.document.find(selectors.pdpMain);
    $cache.addToCart = $cache.document.find(selectors.addToCart);
    $cache.addAllToCaet = $cache.document.find(selectors.addAllToCart);
    $cache.productSetList = $cache.document.find(selectors.productSetList);
}

function initializeEvents() {
    // click on swatch for product set
    $cache.productSetList.on('click', selectors.productSetItemSwatchLink, function (e) {
        e.preventDefault();
        var url = Urls.getSetItem + this.search;
        var $container = $(this).closest(selectors.productSetItem);
        var qty = $container.find('form input[name="Quantity"]').first().val();

        progress.show($cache.pdpMain);
        ajax.load({
            url: util.appendParamToURL(url, 'Quantity', isNaN(qty) ? '1' : qty),
            target: $container,
            callback: function () {
                $cache.document.trigger('product.variation.changed');
                $cache.document.trigger('productset.variation.changed');
                progress.hide();
            }
        });
    });
}

var productSet = {
    init: function () {
        initializeCache();
        initializeEvents();
    }
};

module.exports = productSet;
