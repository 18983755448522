'use strict';
// jQuery extensions

const jqueryExtHelper = require('./jqueryExtHelper');

function $pluginFallback() {
    return $.fn;
}

module.exports = function () {
    var rdisplayswap = /^(none|table(?!-c[ea]).+)/,
        cssShow = {position: 'absolute', visibility: 'hidden', display: 'block'};

    $.each([ 'height', 'width' ], (i, name) => {
        $.cssHooks[name].get = function (elem, computed, extra) {
            if (computed) {
                // certain elements can have dimension info if we invisibly show them
                // however, it must have a current display style that would benefit from this
                return rdisplayswap.test($.css(elem, 'display')) && elem.offsetWidth === 0 ?
                    $.swap(elem, cssShow, () => {
                        return jqueryExtHelper.getWidthOrHeight(elem, name, extra);
                    }) :
                    jqueryExtHelper.getWidthOrHeight(elem, name, extra);
            }
        };
    });

    // params
    // toggleClass - required
    // triggerSelector - optional. the selector for the element that triggers the event handler.
    //                   defaults to the child elements of the list.
    // eventName - optional. defaults to 'click'
    $.fn.toggledList = function (options) {
        if (!options.toggleClass) { return this; }
        var list = this;
        return list.on(options.eventName || 'click', options.triggerSelector || list.children(), function (e) {
            e.preventDefault();
            var classTarget = options.triggerSelector ? $(this).parent() : $(this);
            classTarget.toggleClass(options.toggleClass);
            // execute callback if exists
            if (options.callback) { options.callback(); }
        });
    };

    $.fn.syncHeight = function () {
        var arr = $.makeArray(this);
        arr.sort((a, b) => {
            return $(a).height() - $(b).height();
        });
        return this.height($(arr[arr.length - 1]).height());
    };

    /**
     * The event handling as first handler
     * @param {String} name
     * @param {Function} fn
     */
    $.fn.bindFirst = function (name, fn) {
        this.bindNth(name, fn, 0);
    };

    /**
     * The event handling on given specific position on handlers queue
     * @param {String} name
     * @param {Function} fn
     * @param {Number} index
     */
    $.fn.bindNth = function (name, fn, index) {
        // Bind event normally.
        this.bind(name, fn);
        // Move to nth position.
        this.changeEventOrder(name, index);
    };

    /**
     * Changing the order of event handler for current element
     * @param {String} names
     * @param {Number} newIndex
     */
    $.fn.changeEventOrder = function (names, newIndex) {
        var that = this;
        // Allow for multiple events.
        $.each(names.split(' '), (idx, name) => {
            that.each(function () {
                var handlers = $._data(this, 'events')[name.split('.')[0]];
                // Validate requested position.
                newIndex = Math.min(newIndex, handlers.length - 1);
                handlers.splice(newIndex, 0, handlers.pop());
            });
        });
    };

    /**
     * Getting the data attributes collection by the given prefix
     * @param  {String} prefix
     * @return {Array}
     */
    $.fn.dataByPrefix = function (prefix) {
        var data = this.data(),
            regex = new RegExp('^' + prefix),
            result = {};

        for (var key in data) {
            if (regex.test(key)) {
                result[key] = data[key];
            }
        }

        return result;
    };

    $.fn.isInViewport = function() {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    /** Fallback to avoid application crash in case lagacy carousel plugin executed */
    $.fn.jcarousel = $pluginFallback;
    $.fn.jcarouselControl = $pluginFallback;
    $.fn.jcarouselPagination = $pluginFallback;
    $.fn.jcarouselAutoscroll = $pluginFallback;
};