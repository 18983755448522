'use strict';

function initParcellabSnippet () {
    var parcellabElement = $('#parcellab-track-and-trace');

    if (parcellabElement.length) {
        (function (prcl) {
            if (window.ParcelLab) {
                return prcl();
            }

            function a() {
                var styles = document.createElement('link');
                styles.rel = 'stylesheet';
                styles.href = 'https://cdn.parcellab.com/css/v3/parcelLab.min.css';
                document.getElementsByTagName('head')[0].appendChild(styles);
            }
            function b(cb) {
                var script = document.createElement('script');
                script.async = true;
                script.src = 'https://cdn.parcellab.com/js/v3/parcelLab.min.js';
                (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(script);
                script.onload = cb;
            }
            a();
            b(prcl);
        })(function () { // eslint-disable-line
            var options = {};
            var pl = new ParcelLab('[id=parcellab-track-and-trace]', options); // eslint-disable-line
            pl.initialize();
            window._prcl = pl;
        });
    }
}

module.exports = {
    init: initParcellabSnippet
}