'use sctrict';

const featureDetect = require('./../../../app_storefront_core_ext/cartridge/js/featureDetect');

window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.init = true;
window.lazySizesConfig.loadMode = 2;
window.lazySizesConfig.preloadAfterLoad = false;
window.lazySizesConfig.ricTimeout = 250;
window.lazySizesConfig.expand = 700;

if (!featureDetect.hasPicture) {
    require('picturefill')();
}
require('lazysizes').init();

module.exports = {
    init: function () {}
};