import PickUpShippingFormBase from './PickUpBase';
import util from '_core_ext/util';

export default class AccessPointShippingForm extends PickUpShippingFormBase {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
    }

    subscribeToChildrenEvents () {
        this.onChild('locationsPicker', 'locationsPicker.location.selected', this.onAccessPointSelected.bind(this));
    }

    getDeliveryAddressInfo () {
        var location = this.deliveryForm.$el.find('.js-selected-access-point-location-info').prop('value');

        try {
            location = JSON.parse(location);
        } catch (error) {
            location = {};
        }

        return {
            address1: location.address1 || '',
            address2: location.address2 || '',
            stateCode: location.stateCode || '',
            postalCode: location.postalCode || '',
            city: location.city || ''
        };
    }

    onAccessPointSelected (locationId, locationInfo, selectedLocationHtml) {
        if (!locationId || !Object.keys(locationInfo).length || !selectedLocationHtml) {
            return;
        }
        super.onLocationSelected(locationId, locationInfo, selectedLocationHtml);
        this.emitter.emit('accessPointShippingForm.access.point.selected', this.deliveryOption, {
            locationId: locationId,
            locationInfo: locationInfo,
            selectedLocationHtml: selectedLocationHtml
        });
    }

    fillDeliveryFormWithLocationtInfo (locationId, locationInfo) {
        var $deliveryForm = this.deliveryForm.$el;
        $deliveryForm.find('.js-selected-location-id').val(locationId);
        $deliveryForm.find('.js-selected-access-point-location-info').val(JSON.stringify(locationInfo));
    }

    onFindLocationsClick (el, event) {
        event.preventDefault();
        this.emitter.emit('accessPointShippingForm.find.pick.up.locations');
    }

    onCurrentLocationClick (el, event) {
        event.preventDefault();
        this.emitter.emit('accessPointShippingForm.find.pick.up.locations.current.location');
    }

    updateDeliveryForm (deliveryAddressInfo, shopperInfo, isCustomerLogged) {
        var {locationId, locationInfo, locationHtml} = deliveryAddressInfo;
        var isDeliveryAddressInfoValid = locationId && Object.keys(locationInfo).length && locationHtml;
        if (isCustomerLogged) {
            if (isDeliveryAddressInfoValid) {
                this.onLocationSelected(locationId, locationInfo, locationHtml);
            }
        } else {
            if (isDeliveryAddressInfoValid) {
                this.onLocationSelected(locationId, locationInfo, locationHtml);
            }
            util.fillAddressFields(shopperInfo, this.deliveryForm.$el);
        }
    }

    getDeliveryAddressesData () {
        var $deliveryForm = this.deliveryForm.$el;
        var locationInfo = {};

        try {
            locationInfo = JSON.parse($deliveryForm.find('.js-selected-access-point-location-info').val());
        } catch (error) {
            locationInfo = {};
        }

        return {
            'locationId': $deliveryForm.find('.js-selected-location-id').val(),
            'locationInfo': locationInfo,
            'locationHtml': $deliveryForm.find('.js-selected-location-info').html()
        };
    }
}