import ShippingFormBase from './Base';
import util from '_core_ext/util';
import eventMgr from '_core_ext/core/eventMgr';
export default class HomeShippingForm extends ShippingFormBase {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.selectedAddressIdToBeEdited = '';
        this.isCustomerLogged = this.config.isCustomerLogged;
        this.addressList = null;
        this.editAddressModeEnabled = false;
        this.fallBackDeliveryInfoIfNotSelectedStoredAddress = {};
        if (this.isCustomerLogged) {

            this.getById('addressList', (addressList) => {
                this.addressList = addressList;
            });

            this.onChild('addressList', 'addressList.address.selected', this.handleAddressSelection.bind(this));
            this.onChild('addressList', 'addressList.add.new.address', this.handleAddNewAddress.bind(this));
            this.onChild('addressList', 'addressList.selected.address.click', this.handleSelectedAddressClick.bind(this));
            this.onChild('addressList', 'addressList.selected.address.edit', this.handleSelectedAddressEdit.bind(this));
        }

        this.event('click', '.js-save-address', this.onSaveEditedAddress.bind(this));
        this.event('click', '.js-cancel-edition', this.onCancelEdition.bind(this));
    }

    getDeliveryAddressInfo () {
        return {
            address1: this.deliveryForm.$el.find('input[name$="_address1"]').val(),
            address2: this.deliveryForm.$el.find('input[name$="_address2"]').val() || '',
            countryCode: this.deliveryForm.$el.find('input[id$="_country"]').val(),
            stateCode: this.deliveryForm.$el.find('select[id$="_state"]').val(),
            postalCode: this.deliveryForm.$el.find('input[name$="_postal"]').val(),
            city: this.deliveryForm.$el.find('input[name$="_city"]').val()
        };
    }

    handleAddressSelection (deliveryAddressInfo, selectedAddressInfo) {
        var $deliveryForm = this.deliveryForm.$el;
        var $deliveryFormFields = $deliveryForm.find('.js-address-form');
        $deliveryFormFields.find('.js-shipping-email').val(selectedAddressInfo.email);
        util.fillAddressFields(selectedAddressInfo, $deliveryForm);
        $deliveryFormFields.addClass('is--hidden');
        $deliveryForm.validate().form();
        this.acceptPolicies();
        this.emitter.emit('homeShippingForm.address.selected', deliveryAddressInfo, selectedAddressInfo.ID);
    }

    handleAddNewAddress () {
        var $deliveryForm = this.deliveryForm.$el;
        var $deliveryFormFields = $deliveryForm.find('.js-address-form');
        $(document).trigger('selectric.reinit', [$deliveryForm]);
        $deliveryForm.validate().resetForm();
        $deliveryFormFields.removeClass('is--hidden');
        $deliveryFormFields.find('input[name$="_addressList"]:checked').removeAttr('checked');
        $deliveryFormFields
            .find('input[type="text"]:not([id$="country"], [id$="firstname"], [id$="lastname"], [id$="email"]), [id$="state"]')
            .val('');
        $deliveryFormFields.find('input[name$="_addToAddressBook"]').prop('checked', true);
        this.acceptPolicies(false);
        this.emitter.emit('homeShippingForm.add.new.address');
    }

    handleSelectedAddressClick (selectedAddress) {
        if (!this.isCustomerLogged) {
            return;
        }
        var $deliveryFormFields = this.deliveryForm.$el.find('.js-address-form');
        $deliveryFormFields.addClass('is--hidden');
        $deliveryFormFields.find('[name$=_searchaddress]').val(selectedAddress.ID);
        util.fillAddressFields(selectedAddress, this.deliveryForm.$el);
        $deliveryFormFields.find('input[name$="_addToAddressBook"]').prop('checked', false);
        this.acceptPolicies();
    }

    handleSelectedAddressEdit (selectedAddress) {
        if (!this.isCustomerLogged) {
            return;
        }
        this.fallBackDeliveryInfoIfNotSelectedStoredAddress = Object.assign({ID: ''}, this.getDeliveryAddressInfo(),
            this.getShopperInfo());
        var $deliveryFormFields = this.deliveryForm.$el.find('.js-address-form');
        this.selectedAddressIdToBeEdited = selectedAddress.ID;
        this.activateEditAdrressMode(true);
        $deliveryFormFields.find('[name$=_searchaddress]').val('');
        $deliveryFormFields.find('input[name$="_addToAddressBook"]').prop('checked', false);
        util.fillAddressFields(selectedAddress, this.deliveryForm.$el);
        $deliveryFormFields.removeClass('is--hidden');
    }

    activateEditAdrressMode (disable) {
        if (!this.isCustomerLogged) {
            return;
        }
        var $deliveryFormFields = this.deliveryForm.$el.find('.js-address-form');
        this.addressList.toggle(disable);
        $deliveryFormFields.toggleClass('is--hidden', !disable);
        this.$el.find('.js-addresslist-edit-header').toggleClass('is--hidden', !disable);
        this.$el.find('.js-add-new-address-header').toggleClass('is--hidden', disable);
        this.$el.find('.js-edit-store-address-controls').toggleClass('is--hidden', !disable);
        $deliveryFormFields.find('.js-accept-policies').toggleClass('is--hidden', disable);
        $deliveryFormFields.find('.js-add-to-address-book').toggleClass('is--hidden', disable);
        $deliveryFormFields.find('[data-cmp=inputEmail]').toggleClass('is--hidden', disable);
        this.editAddressModeEnabled = disable;
    }

    onSaveEditedAddress (el, event) {
        event.preventDefault();
        if (!this.isDeliveryFormValid() || !this.isCustomerLogged) {
            return;
        }
        this.editStoredAddress();
    }

    editStoredAddress() {
        var newDeliveryAddressInfo = this.getDeliveryAddressInfo();
        var shopperInfo = this.getShopperInfo();
        var shippingAddressInfo = Object.assign({}, newDeliveryAddressInfo, shopperInfo);
        eventMgr.execute('update.stored.shipping.address', {
            existingAddressId: this.selectedAddressIdToBeEdited,
            shippingAddressInfo: shippingAddressInfo
        }).then((data) => {
            var $notifications = this.$el.find('.js-edit-store-address-controls .js-edit-address-notifications');
            if (!data.success) {
                $notifications.text(data.errorMsg).addClass('error');
                return;
            }
            this.activateEditAdrressMode(false);
            this.deliveryForm.$el.find('.js-address-form [name$=_searchaddress]').val(this.selectedAddressIdToBeEdited);
            this.addressList.handleStoredAddressEdition(this.selectedAddressIdToBeEdited, data.addressInfo);
            this.emitter.emit('homeShippingForm.edited.address.save', newDeliveryAddressInfo,
                this.selectedAddressIdToBeEdited);
        });
    }

    onCancelEdition (el, event) {
        event.preventDefault();
        this.cancelEdition();
    }

    cancelEdition () {
        var currentSelectedAddressInfo = this.addressList.getCurrentSelectedAddressInfo()
            ? this.addressList.getCurrentSelectedAddressInfo() : this.fallBackDeliveryInfoIfNotSelectedStoredAddress;
        util.fillAddressFields(currentSelectedAddressInfo, this.deliveryForm.$el);
        this.deliveryForm.$el.find('.js-address-form [name$=_searchaddress]').val(currentSelectedAddressInfo.ID);
        this.activateEditAdrressMode(false);
        this.addressList.handleCancelAddressEdition();
    }

    setStoredHomeAddress (addressId) {
        this.addressList.changeAddress(addressId);
        var selectedAddressInfo = this.addressList.getCurrentSelectedAddressInfo();
        var $deliveryFormFields = this.deliveryForm.$el.find('.js-address-form');
        $deliveryFormFields.find('[name$=_searchaddress]').val(addressId);
        util.fillAddressFields(selectedAddressInfo, this.deliveryForm.$el);
    }

    updateDeliveryForm (deliveryAddressInfo, shopperInfo, isCustomerLogged) {
        var {addressId, addressInfo} = deliveryAddressInfo;
        if (isCustomerLogged) {
            if (addressId) {
                this.setStoredHomeAddress(addressId);
            }
        } else {
            var deliveryFormInfo = Object.assign({}, addressInfo, shopperInfo);
            util.fillAddressFields(deliveryFormInfo, this.deliveryForm.$el);
        }
    }

    beforeSubmitCheck () {
        if (this.editAddressModeEnabled) {
            this.cancelEdition();
        }

        if (this.isCustomerLogged && !this.isDeliveryFormValid() && this.addressList) {
            var $deliverForm = this.deliveryForm.$el;
            $deliverForm.find('.js-address-form').removeClass('is--hidden');
            $deliverForm.find('.js-add-new-address-header').toggleClass('is--hidden', true);
        }

        return super.beforeSubmitCheck();
    }

    getDeliveryAddressesData () {
        return {
            'addressInfo': this.getDeliveryAddressInfo()
        };
    }
}