import FormGeneric from '_core_ext/components/forms/FormGeneric';
import ajax from '_core_ext/ajax';

export default class CaptchaForm extends FormGeneric {
    init() {
        super.init();
        this.submitButton = this.$el.find('[type$="submit"]');
    }

    onSubmit() {
        ajax({
            type: this.$el.attr('method'),
            url: this.$el.attr('action'),
            data: this.$el.serialize() + '&' + this.submitButton.attr('name') + '=' + this.submitButton.attr('value'),
            callback: () => {
                this.$el.trigger('dialog.close');
            }
        });
    }
}
