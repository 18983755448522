import Block from '_core_ext/core/common/Block';
import page from '_core_ext/page';

export default class HeaderNavigation extends Block {
    init() {
        this.active = false;
        this.activeViewMode = 'desktop';
        this.device = 'desktop';

        // Events for Mobile screens
        this.event('click', '.js-menu-link', this.onMenuLinkClick.bind(this, 'category-nav__item'));
        this.event('click', '.js-menu-link-prev', this.onMenuLinkPrevClick.bind(this, 'category-nav__item'));
        this.event('click', '.js-sub-menu-link', this.onMenuLinkClick.bind(this, 'submenu'));
        this.event('click', '.js-sub-menu-link-prev', this.onMenuLinkPrevClick.bind(this, 'submenu'));
        this.event('click', '.js-fourth-level-submenu-link', this.onFourthLevelLinkClick.bind(this));

        // Events for Desktop screens
        this.event('mouseenter', '.js-menu-tab', this.onMenuTabMouseEntered.bind(this));
        this.event('mouseleave', '.js-menu-tab', this.onMenuTabMouseLeft.bind(this));

        // Events for Tablet (Mobile + Desktop)
        this.event('touchend', '.js-menu-link', this.onTouchLinkTouchEnd.bind(this, 'category-nav__item'));
        this.event('touchend', '.js-sub-menu-link', this.onTouchLinkTouchEnd.bind(this, 'submenu'));
    }

    setViewMode(viewMode) {
        this.activeViewMode = viewMode;
    }

    setDevice(device) {
        this.device = device;
    }

    isActive() {
        return this.active;
    }

    activate() {
        this.active = true;
        this.emitter.emit('headerNavigation.state.activated');
    }

    deactivate() {
        this.active = false;
        this.$el.find('.js-touch-link').parent().removeClass('is--touched is--active');
        this.initNavigationStyles();
        this.emitter.emit('headerNavigation.state.deactivated');
    }

    toggleActiveState() {
        if (!this.isActive()) {
            // expand
            this.activate();
        } else {
            // close
            this.deactivate();
        }
    }

    onMenuLinkClick(parentSelector, el, e) {
        const IS_ACTIVE = parentSelector + '--is-active';

        let $item = $(el).parent();
        let isActive = $item.hasClass(IS_ACTIVE);

        $item.hasClass('active') ? $item.removeClass('active') : $item.addClass('active');
        $item.siblings().removeClass('active');

        if (this.activeViewMode !== 'mobile' || isActive) {
            return;
        }
        if (this.activeViewMode === 'mobile' && $item.hasClass('lastLevel')) {
            return;
        }

        e.preventDefault();
        $item.siblings().removeClass(IS_ACTIVE);
        $item.addClass(IS_ACTIVE);
        var $menuTab = $(el).parents('.js-menu-tab-content');

        if (parentSelector !== 'submenu' || $menuTab.hasClass('subnav--is-active')) {
            return;
        }

        $menuTab.siblings().removeClass('subnav--is-active');
        $menuTab.addClass('subnav--is-active');
    }

    onMenuLinkPrevClick(parentSelector, el, e) {
        const IS_ACTIVE = parentSelector + '--is-active';
        e.preventDefault();
        $(el).closest('.' + parentSelector).removeClass(IS_ACTIVE).siblings().removeClass(IS_ACTIVE);

        if (parentSelector !== 'submenu') {
            return;
        }

        $(el).parents('.js-menu-tab-content').removeClass('subnav--is-active');
    }

    onTouchLinkTouchEnd(parentSelector, el, e) {
        const IS_TOUCHED = parentSelector + '--is-touched';
        const IS_ACTIVE = parentSelector + '--is-active';

        let $item = $(el).parent();
        let isTouched = $item.hasClass(IS_TOUCHED);

        $item.siblings().removeClass(IS_TOUCHED);
        $item.siblings().removeClass(IS_ACTIVE);

        this.emitter.emit('headerNavigation.link.touched');

        if (!$item.find('.tab-content').length || this.device !== 'tablet' || this.activeViewMode !== 'desktop' || isTouched) {
            return true;
        }

        e.preventDefault();
        $item.addClass(IS_TOUCHED);
        $item.addClass(IS_ACTIVE);

        this.emitter.emit('headerNavigation.link.touched.largeView');

        var $menuTab = $(el).parents('.js-menu-tab-content');

        if (parentSelector !== 'submenu' || $menuTab.hasClass('subnav--is-active')) {
            return;
        }

        $menuTab.siblings().removeClass('subnav--is-active');
        $menuTab.addClass('subnav--is-active');
    }

    onMenuTabMouseEntered() {
        this.emitter.emit('headerNavigation.menuTab.mouseEntered');
        $('.js-overlay-nav').addClass('is--expanded');
        $('.header__inner').addClass('menu-expanded');
    }

    onMenuTabMouseLeft() {
        this.emitter.emit('headerNavigation.menuTab.mouseLeft');
        $('.js-overlay-nav').removeClass('is--expanded');
        $('.header__inner').removeClass('menu-expanded');
    }

    rebuildMenuLinks() {
        this.$el.find('.js-cat-mobile-titles').remove().prependTo(this.$el.find('.js-category-navigation'));
    }

    /**
     * @description Creates navigation styles according to menu slot config
     */
    initNavigationStyles() {
        var isHamburgerMenu = this.activeViewMode === 'mobile';
        if (window.pageContext && window.pageContext.topLevelCategory) {
            this.$el.find('.js-menu-tab').each((i, el) => {
                /**
                 * non-strict equals for number type of category ID is required
                 */
                // eslint-disable-next-line eqeqeq
                if ($(el).find('.js-menu-link').data('topcatId') == window.pageContext.topLevelCategory) {
                    $(el).addClass('category-nav__item--is-active');
                }
            });
        } else {
            this.$el.find(isHamburgerMenu ? '.js-menu-tab.js-cat-mobile-titles' : '.js-menu-tab:not(.js-cat-mobile-titles)');
        }

        this.$el.find('.js-nav-system-subnav-item').each((i, el) => {
            let $wrapper = $(el);
            let $slots = $wrapper.find('.js-menu-slot');
            let wrapperClass = '';

            $slots.each((i, el) => {
                let slotClass = $(el).data('slotcss');
                if (slotClass) {
                    wrapperClass += ` slot-${i + 1}-${slotClass}`;
                }
            });

            if (wrapperClass) {
                $wrapper.addClass(wrapperClass);
            }
        });
    }

    onFourthLevelLinkClick(el) {
        var url = $(el).find('a').attr('href');
        if (!url) {
            return;
        }
        page.redirect(url);
    }
}