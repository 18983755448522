import Block from '_core_ext/core/common/Block';
import layout from '_core_ext/layout';
import util from '_core_ext/util';

export default class Sorting extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.$html = $(document.documentElement);

        this.event('click', '.js-sort-option', this.onSortingClick.bind(this));
        this.event('click', '.js-close-sorting, .js-sorting-overlay', this.onSortingClose.bind(this));
        this.event('click', '.js-show-sorting', this.onSortingShow.bind(this));
        this.eventMgr('window.modechanged', this.onWindowModechanged.bind(this));
    }

    onSortingClick(el, event) {
        event.preventDefault();
        var searchQueryUrl = $(el).data('grid-url');
        if (layout.isMobileView()) {
            this.onSortingClose(el);
        }
        this.emitter.emit(`${this.id}.sort.rule.selected`, {url: searchQueryUrl});
    }

    onSortingClose(el) {
        $(el).closest('.js-dropdown').trigger('dropdown.close');
        this.$html.removeClass('is--sorting-open');
    }

    onSortingShow() {
        if (layout.isMobileView()) {
            this.$html.addClass('is--sorting-open');
        }
    }

    onWindowModechanged(modeData) {
        if (!modeData) {
            return;
        }

        var {mode} = modeData;
        var sortingDropDownExpanded = this.$el.find('.js-sort-dropdown').hasClass('is--expanded');
        if (mode === 'medium' || mode === 'small') {
            if (sortingDropDownExpanded) {
                this.$html.addClass('is--sorting-open');
            } else {
                this.$html.removeClass('is--sorting-open');
            }
        } else {
            $('.js-global-dropdown').trigger('dropdown.close');
            this.$html.removeClass('is--sorting-open');
        }
    }

    updateQueryUrls(paginationParams) {
        var url;
        var updatedUrl;
        this.$el.find('[data-grid-url]').each((i, el) => {
            url = $(el).data('grid-url');
            updatedUrl = util.updateOrAppendUrlParams(url, paginationParams);
            $(el).data('grid-url', updatedUrl);
        });
    }
}