import {Promise as Promise} from 'es6-promise-polyfill';
import bowser from 'bowser';

var page = require('./page'),
    utils = require('./components-utils'),
    componentsConfig = require('./components-config'),
    config = componentsConfig.configuration,
    references = componentsConfig.references,
    Component = require('./core/Component').default,
    eventMgr = require('./core/eventMgr').default,
    emitter = eventMgr.getEmitter('components'),
    componentsInited = false,
    rootCmp;

/**
 * The reference getter for the page module
 * @param {name}
 * @returns {Object}
 */
function getPage(name) {
    return name && references[name];
}

/**
 * Specific page components getter
 * @param {String} name
 * @returns {Object}
 */
function getPageComponents(name) {
    return name && config[name] && config[name].components || {};
}

/**
 * Global components configuration getter
 * @returns {Object}
 */
function getGlobalComponents() {
    return getPageComponents('global');
}

/**
 * Component reference getter
 * @param {String} page
 * @param {name} name
 * @returns {Object}
 */
function getComponent(page, name) {
    if ('undefined' === typeof name) {
        return getPage(page);
    }

    return references[name] || {};
}

function selectAndCreateComponents(Module, configuration, name, $container) {
    var $elements;

    if (Module.selector === null) {
        $elements = ($container || $(document)).find(`[data-cmp="${name}"]`);
    } else if (typeof Module.selector === 'string') {
        $elements = ($container || $(document)).find(Module.selector);
    } else {
        $elements = $(Module.selector);
    }

    $elements.each(function () {
        var $el = $(this);

        if (!$el.data('cmp-instance')) {
            var cmp = new Module(this, configuration);

            cmp.items = [];
            cmp.cmpName = name;
            $el.addClass('js-cmp-inited ' + (name ? 'js-cmp-' + name : '')).data('cmp-instance', cmp);
        }
    });
}

/**
 * Component module initization helper
 * @param {Object} module The require reference to the component module
 * @param {Object} configuration Inialization config
 * @param {jQuery Object} $container where need init modules
 * @returns {void}
 */
function initializeModule(module, configuration, name, $container, cmpOnly) {
    if (!module) {
        return;
    }
    if (typeof module.selector !== 'undefined') {
        utils.collectExecutionData(name, selectAndCreateComponents, null, [module, configuration || {}, name, $container]);
        return;
    }

    if (cmpOnly) {
        // Skip others modules
        return;
    }

    if ('function' !== typeof module.init) {
        throw new Error('[components]: The mandatory "init" method missed');
    }

    utils.collectExecutionData(name, module.init, module, [configuration || {}, name, $container]);
}

function buildComponentHierarchy() {
    function setHierarchy() {
        $('.js-cmp-inited').each(function () {
            var $this = $(this),
                cmpInstance = $this.data('cmp-instance'),
                $parents = $this.parents('.js-cmp-inited'),
                $parent;

            if ($parents.length > 0) {
                $parent = $parents.eq(0);
            } else {
                $parent = $(document);
            }
            var parentCmpInstance = $parent.data('cmp-instance');

            if (parentCmpInstance && parentCmpInstance.items && parentCmpInstance.items.push) {
                if (parentCmpInstance.items.indexOf(cmpInstance) === -1) {
                    parentCmpInstance.items.push(cmpInstance);
                }
            }
        });
    }

    function initChildAndCurrent(cmpInstance) {
        if (cmpInstance) {
            $.each(cmpInstance.items || [], (index, childCmp) => {
                initChildAndCurrent(childCmp);
            });
            if (!cmpInstance._inited && typeof cmpInstance.init === 'function') {
                utils.collectExecutionData(cmpInstance.cmpName, cmpInstance.init, cmpInstance);
                cmpInstance._inited = true;
                utils.setExecutionData(cmpInstance.cmpName);
            }
        }
    }

    utils.collectExecutionData('Components Hierarchy', setHierarchy, this);
    utils.setInitStatus('Components Hierarchy', 'initialized');
    initChildAndCurrent(rootCmp);
}

function releaseCmps(baseCmp) {
    var cmp = baseCmp || rootCmp,
        item,
        i;

    if (!(cmp && cmp.items)) {
        return;
    }

    for (i = cmp.items.length - 1; i >= 0; i--) {
        item = cmp.items[i];
        if (item) {
            releaseCmps(item);
            if (typeof item.isBindedToDom === 'function' && !item.isBindedToDom() && typeof item.destroy === 'function') {
                item.destroy();
                item._inited = false;
                cmp.items.splice(i, 1);
            }
        }
    }
}

function initComponents($container, cmpOnly) {
    var components = $.extend({}, getGlobalComponents(), getPageComponents(page.ns));

    for (var name in components) {
        if ('undefined' !== typeof components[name].enabled && !components[name].enabled) {
            continue;
        }

        try {
            initializeModule(references[name], components[name].options, name, $container, cmpOnly);
            utils.setInitStatus(name, 'initialized');
        } catch (e) {
            utils.handleExceptions(e, '"' + name + '" initialization failed');
            utils.setInitStatus(name, 'skipped');
        }
    }

    buildComponentHierarchy();
}

/**
 * Validate the component avaliability on current page
 * @param {String} name
 * @returns {void}
 */
function isComponentEnabled(name) {
    var configuration = $.extend(true, {}, getGlobalComponents(), getPageComponents(page.ns));

    return configuration[name] && ('undefined' === typeof configuration[name].enabled || !!configuration[name].enabled);
}

/**
 * Current components configuration extend method. Implements
 * the possibility to customized configuration extension on brand level
 * @param {Object} componentsConfig
 * @returns {Object} this
 */
function extendConfig(componentsConfig) {
    references = $.extend(true, {}, references, componentsConfig.references || {});
    config = $.extend(true, {}, config, componentsConfig.configuration || {});
    return this;
}

/**
 * The main initialization method for all enabled and availiable components
 * on current page
 * @returs {void}
 */
function initializeAll() {
    var currentPage = getPage(page.ns),
        $document = $(document);

    // Creation of root component
    rootCmp = new Component($document);
    $document.data('cmp-instance', rootCmp);

    if (currentPage) {
        try {
            initializeModule(currentPage, config[page.ns].options, page.ns);
            utils.setExecutionData(page.ns);
        } catch (e) {
            utils.handleExceptions(e, '"' + page.ns + '" initialization failed');
        }
    }

    initComponents();

    utils.exposeStatuses();
    componentsInited = true;
    emitter.emit('inited');
}

function updateComponents($container) {
    if (!componentsInited) {
        return;
    }
    utils.clearStatistic();
    releaseCmps(rootCmp);
    initComponents($container || $(document), true);
    utils.exposeStatuses(true);
    emitter.emit('updated');
}

$(document).ajaxStop(() => {
    if (bowser.msie) {
        setTimeout(updateComponents, 300);
    } else {
        setTimeout(updateComponents, 10);
    }
});

eventMgr.registerAction('components.update', ($container) => {
    return new Promise((res) => {
        setTimeout(() => {
            updateComponents($container);
            res();
        }, 0);
    })
})

module.exports = {
    'get': getComponent,
    'config': config,
    'initAll': initializeAll,
    'update': updateComponents,
    'isComponentEnabled': isComponentEnabled,
    'extendConfig': extendConfig
};
