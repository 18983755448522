import Block from '_core_ext/core/common/Block';
import eventMgr from '_core_ext/core/eventMgr';
import dialog from '_core_ext/dialog';
import util from '_core_ext/util';
import progress from '_core/progress';
import notifyMeServices from './utils/notifymeServices';
var googleRecaptcha = require('_core_ext/googleRecaptcha');


export default class NotifyMe extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        notifyMeServices.init();
        this.attrsToNotify = {};
        this.selectedAttrsRequiredToNotify = null;
        this.wasFormSubmitted = false;

        this.event('submit', '.js-emailme-form', this.onFormSubmit.bind(this));
        this.event('click', '.js-product-variations__link', this.onVariationSelected.bind(this));
        this.event('click', 'input[type="checkbox"].js-notifyme-acceptlettersubscription, input[type="checkbox"].js-notifyme-policies', this.onSubscriptionNotifyForm.bind(this));
    }

    show(params) {
        var formattedAttrs = {};
        var {pid, selectedVariationAttributes, loaderLocation} = params;

        for (var key in selectedVariationAttributes) {
            formattedAttrs[`notifyme_selected_attr_${key}`] = selectedVariationAttributes[key];
        }

        var urlParams = Object.assign({}, {
            pid: pid,
            page: pageContext.ns
        }, formattedAttrs);
        var url = util.appendParamsToUrl(Urls.emailMe, urlParams);
        var $dialog = this.$el;
        var self = this;

        progress.show(loaderLocation);
        dialog.open({
            url: url,
            options: {
                target: $dialog,
                closeOnOutsideClick: true,
                dialogClass: "plpNotifyMe",
                close: () => {
                    self.onDialogClose();
                }
            },
            callback: function () {
                self.initAttrsToNotify();
                self.setSelectedAttrsRequiredToNotify();
                self.wasFormSubmitted = false;
                $('.js-toggle_content.c-subscribe-form__toggle input').attr('disabled','disabled');
                self.$el.find('.js-notifyme-policies').prop('checked', true);
                self.$el.find('.js-notifyme-policies').siblings().find('a').prop('target', '_blank');
                $('.g-recaptcha').css('display', 'block');
                progress.hide();
            }
        });
    }

    onFormSubmit(el, event) {
        event.preventDefault();
        var $form = $(el);
        if (!$form.valid() || !this.allRequiredAttributesWereSelected()) {
            return;
        }
        if ($('.recaptcha-verify').length && $('.js-notifyme-acceptlettersubscription').is(':checked') === true) {
            if (!this.isCaptchaValid()) {
              googleRecaptcha.buildRecaptchaV2();
              return;
            } else {
              googleRecaptcha.getRecaptchaResponse().then(function (value) {
                if ($subscribeForm.parents("form").valid()) {
                  $subscribeForm.parents("form").submit();
                } else {
           
                  googleRecaptcha.buildRecaptchaV2();
                  return;
                }
              });
            }
        }

        this.fillEmailMeAttrsFormField();
        var $submitAction = $form.find('.js-notifyme');
        var submitName = $submitAction.attr('name');
        var submitValue = $submitAction.attr('value');
        var formData = $form.serialize() + '&' + submitName + '=' + submitValue;
        var url = $form.attr('action');

        this.submitForm({
            url: url,
            formData: formData
        }).then((response) => {
            $form.trigger('reset');
            this.showSubmitFormResponse(response);
            this.wasFormSubmitted = true;
            this.emitter.emit('notifyMe.form.submit');
        });
    }

    submitForm(params) {
        return eventMgr.execute('submit.notify.me.form', params);
    }
    
    isCaptchaValid() {
        if ($('#g-recaptcha-response-1').val() !== '' || grecaptcha.enterprise.getResponse() !== '') {
            return true;
        } else {
            if ($('#g-recaptcha-response-1').length) {
                grecaptcha.enterprise.reset();
            }
            return false;
        }
    }

    showSubmitFormResponse(response) {
        this.$el.find('.js-notifyemail-content').html(response);
        if(response !== undefined) {
            $(".plpNotifyMe .ui-dialog-titlebar-close").hide();
            this.$el.find('.js-notifyemail-content').addClass('success');

            setTimeout(function() {
                $(".plpNotifyMe .ui-dialog-titlebar-close").trigger('click');
                $('.js-hide-quickshop:not(.d-none)').trigger('click');
                $(".plpNotifyMe .ui-dialog-titlebar-close").show();
                this.$el.find('.js-notifyemail-content').removeClass('success');
            }, 4000);
        }
    }

    collectAttrsToNotify() {
        var self = this;
        var attrId;
        var $variationAttr;
        var $variationAttrValue;
        this.$el.find('.js-email-me-attrs-list .js-variation-attr').each((i, el) => {
            $variationAttr = $(el);
            attrId = $variationAttr.data('attr-id');
            self.attrsToNotify[attrId] = [];

            $variationAttr.find('.js-product-variations__subitem.selected').each((i, el) => {
                $variationAttrValue = $(el).find('a');
                self.attrsToNotify[attrId].push($variationAttrValue.data('attr-value'));
            });
        });
    }

    onVariationSelected(el, event) {
        event.preventDefault();
        var $el = $(el);
        var $attr = $el.parents('.js-variation-attr');
        var attrValue = $el.data('attr-value');
        var attrId = $attr.data('attr-id');

        if ($el.parent().hasClass('selected')) {
            this.removeAttrToNotify(attrId, attrValue);
        } else {
            this.addAttrToNotify(attrId, attrValue);
        }
        $el.parent().toggleClass('selected');
        $attr.removeClass('c-variations__item--error');
    }

    onSubscriptionNotifyForm(el, event) {
        $('.js-toggle_content.c-subscribe-form__toggle').hide();
        $('.js-toggle_content.c-subscribe-form__toggle input').attr('disabled','disabled');
        if ($('.js-notifyme-acceptlettersubscription').is(':checked') === true && $('.js-notifyme-policies').is(':checked') === true) {
            $('.js-toggle_content.c-subscribe-form__toggle').show();
            $('.js-toggle_content.c-subscribe-form__toggle input').removeAttr('disabled');
        }
        else {
            $('.js-toggle_content.c-subscribe-form__toggle').hide();
            $('.js-toggle_content.c-subscribe-form__toggle input').attr('disabled','disabled');
        }
    }

    addAttrToNotify(attrId, attrValue) {
        attrValue = String(attrValue);
        if (!(attrId in this.attrsToNotify)) {
            this.attrsToNotify[attrId] = [];
        }

        this.attrsToNotify[attrId].push(attrValue);
    }

    removeAttrToNotify(attrId, attrValue) {
        attrValue = String(attrValue);
        if (!(attrId in this.attrsToNotify)) {
            return;
        }
        this.attrsToNotify[attrId] = this.attrsToNotify[attrId].filter((attr) => attr !== attrValue);

        if (!this.attrsToNotify[attrId].length) {
            delete this.attrsToNotify[attrId];
        }
    }

    fillEmailMeAttrsFormField() {
        var selectedAttrs = JSON.stringify(this.attrsToNotify);
        this.$el.find('.js-email-me-attrs').attr('value', selectedAttrs);
    }

    initAttrsToNotify() {
        var selectedAttrs = this.$el.find('.js-email-me-attrs').attr('value');

        try {
            this.attrsToNotify = JSON.parse(selectedAttrs);
        } catch (error) {
            this.attrsToNotify = {};
        }
    }

    setSelectedAttrsRequiredToNotify() {
        this.selectedAttrsRequiredToNotify = parseInt(this.$el.find('.js-email-me-attrs').data('selected-attrs-required'), 10);
    }

    /**
    * This method verifies that the user has selected the required amount of variation attributes for a specific product
    * before the notify me form is submitted, for example, if the user has triggered the notify me modal, and if the product
    * has 3 variation attributes, color, size, and length, this method ensures that the user has selected at least one
    * variation attribute value per variation attribute. The attrsToNotify variable is an object that is built dynamically
    * each time the user selects a new variation attribute value which would like to be notified, on the other hand,
    * the selectedAttrsRequiredToNotify holds the amount of requiered variation attributes that must be selected by the user
    * before submit the notify me form, for the previous example this value would be 3.
    */
    allRequiredAttributesWereSelected() {
        var attrId;
        var $notSelectedAttrs = this.$el.find('.js-variation-attr').filter((i, el) => {
            attrId = $(el).data('attr-id');
            return !(attrId in this.attrsToNotify);
        });

        $notSelectedAttrs.addClass('c-variations__item--error');

        return Object.keys(this.attrsToNotify).length === this.selectedAttrsRequiredToNotify;
    }

    onDialogClose() {
        this.emitter.emit('notifyMe.dialog.close', this.wasFormSubmitted);
    }
}