import Block from '_core_ext/core/common/Block';

const CHILDREN_COMPONENTS_IDS = [
    'pdpVideos'
];

export default class Pdp extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.updateChildrenReferences();
        this.subscribeToChildrenEvents();
        // @TODO: After refactoring PDP to component based version replace this with child component event listener
        this.eventMgr('productVariation.changed', this.onProductVariationChanged.bind(this));
    }

    subscribeToChildrenEvents() {
        //this.onChild('childComponentID', 'event.name', this.handlerAction.bind(this));
    }

    updateChildrenReferences() {
        CHILDREN_COMPONENTS_IDS.forEach((childComponentId) => {
            this.getById(childComponentId, (childComponent) => {
                this[childComponentId] = childComponent;
            });
        });
    }

    onProductVariationChanged() {
        let src = null,
            width = null,
            height = null,
            $pdpVideoInfo = this.$el.find('.js-pdp-video-info');
        
        if ($pdpVideoInfo.length) {
            src = $pdpVideoInfo.data('videoSrc') ? $pdpVideoInfo.data('videoSrc').toString().split(',') : [];
            width = $pdpVideoInfo.data('videoWidth') ? $pdpVideoInfo.data('videoWidth').toString().split(',') : [];
            height = $pdpVideoInfo.data('videoHeight') ? $pdpVideoInfo.data('videoHeight').toString().split(',') : [];
        }
        
        this.pdpVideos.update(src.map((item, indx) => {
            return {
                src: item.trim(),
                width: width[indx] || width[0] || null,
                height: height[indx] || height[0] || null,
            };
        }));
    }
}