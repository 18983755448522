import Subscription from '_core_ext/components/forms/Subscription';
import page from '_core_ext/page';

export default class RegistrationForm extends Subscription {
    init() {
        super.init();
    }

    onSubmitSuccess(response) {
        this.$el.trigger('newsletterSubscriptionCb.success', [this.$el]);
        page.redirect(response.redirectUrl);
    }
}