import Block from '_core_ext/core/common/Block';

export default class IFrameVideo extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.loaded = false;
        this.src = null;
        this.width = null;
        this.height = null;
        this.cache = {};
        this.resizeListenerInitialized = false;

        this.initIframeVideo();
    }

    initIframeVideo() {
        this.src = null;

        if (this.config.src && this.config.src.trim()) {
            this.src = this.config.src.trim();
            var iframe = $('<iframe>');

            iframe.attr('id', 'productVideo');
            iframe.attr('frameborder', '0');
            iframe.attr('allow', 'autoplay; fullscreen; picture-in-picture');
            iframe.attr('allowFullScreen', '');
            iframe.attr('width', '640');
            iframe.attr('height', '360');

            if (!!this.config.lazyLoad) {
                if ('loading' in HTMLIFrameElement.prototype) {
                    iframe.attr('loading', 'lazy');
                    iframe.attr('src', this.src);
                } else {
                    iframe.addClass('lazyload');
                    iframe.attr('data-src', this.src);
                }
                if (!!this.config.autoScale) {
                    iframe.on('load', () => {
                        this.loaded = true;
                        iframe.attr('width', '100%');
                        iframe.attr('height', 'auto');
                        this.calcVideoSize(iframe);
                    })
                }
            } else {
                iframe.attr('src', this.src);
                this.loaded = true;
            }

            this.$el.append(iframe);

            if (this.config.width && this.config.height) {
                this.width = parseInt(this.config.width);
                this.height = parseInt(this.config.height);
                if (!!this.config.autoScale) {
                    this.calcVideoSize();
                } else {
                    iframe.attr('width', this.width);
                    this.loaded = true;
                    iframe.attr('height', 'auto');
                    this.calcVideoSize(iframe);
                }
                if (!this.resizeListenerInitialized) {
                    this.eventMgr('window.resize', this.calcVideoSize.bind(this));
                    this.resizeListenerInitialized = true;
                }
            }
        }
    }

    calcVideoSize() {
        if (this.loaded) {
            if (!this.cache.$videoFrame) {
                this.cache.$videoFrame = this.$el.find('#productVideo');
            }
            this.cache.$videoFrame.css('height', this.cache.$videoFrame.width() * (this.height/this.width) + 'px');
        }
    }

    updateSrc(params, forcedReInit) {
        let newSrc = params.src && params.src.trim() ? params.src.trim() : null;

        // check if src has been changed
        if (newSrc === this.src && !forcedReInit) {
            // nothing changed
            return;
        }

        this.reInit(params);
    }

    reInit(params) {
        this.loaded = false;
        this.cache.$videoFrame = null;
        this.config.src = params.src || null;

        // delete previous iframe element
        this.$el.find('#productVideo').remove();

        // re-init video
        this.initIframeVideo();
    }
}