/**
 * Detection of support passive event listeners
 * @link https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md#feature-detection
 */

var supportsPassiveEvents = false;
try {
    var opts = Object.defineProperty({}, 'passive', {
        get: function() {
            supportsPassiveEvents = true;
        }
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
} catch (e) {} // eslint-disable-line no-empty

/**
 * Detect if current browser has touch
 */
var isTouchDevice = 'ontouchstart' in window;

/**
 * Detect if picture element supported
 */
var hasPicture = !!window.HTMLPictureElement;

module.exports = {
    passiveEvents: supportsPassiveEvents,
    hasTouch: isTouchDevice,
    hasPicture: hasPicture
};