import eventMgr from '_core_ext/core/eventMgr';
import util from '_core_ext/util';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';
import page from '_core_ext/page';


function getShippingMethodsByRegion (params) {
    var {regionId} = params;
    var url = util.appendParamToURL(Urls.changeShippingMethodsByRegion, 'region', regionId);

    return new Promise((res, rej) => {
        progress.show(this.$el);
        ajax({
            type: 'POST',
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t get shipping methods by region.');
                }
                res(data);
            }
        });
    });
}

function selectShippingMethod (params) {
    var {deliveryAddressInfo, shippingMethodId, regionId} = params;
    var urlParams = {
        shippingMethodID: shippingMethodId,
        region: regionId
    };
    var url = util.appendParamsToUrl(Urls.selectShippingMethodsList, $.extend(deliveryAddressInfo, urlParams));

    return new Promise((res, rej) => {
        progress.show(this.$el);
        ajax.getJson({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null || !data.success) {
                    return rej('Couldn\'t select shipping method.');
                }
                res();
            }
        });
    });
}

function getCheckoutSummary () {
    return new Promise((res, rej) => {
        progress.show(this.$el);
        ajax({
            url: util.appendParamsToUrl(Urls.summaryRefreshURL, {'checkoutStep': 2}),
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t get checkout summary.');
                }
                res(data);
            }
        });
    });
}

function submitDeliveryForm (params) {
    var {formData, action, shippingMethodId, formEl} = params;
    var urlFormParams = $.param(formData);
    var data = `${action}=${action}&${urlFormParams}&shippingMethodId=${shippingMethodId}&serverRedirectAllowed=false`;
    return new Promise((res, rej) => {
        progress.show(this.$el);
        ajax({
            url: Urls.singleShipping,
            type: 'POST',
            data: data,
            callback: (response, textStatus, xhr) => {
                var responseData = JSON.parse(xhr.responseText);
                if (!responseData.success) {
                    if (responseData.redirectUrl) {
                        page.redirect(responseData.redirectUrl);
                    } else {
                        var formValidator = formEl.validate();
                        var validationErrors = {};

                        if (responseData.errors) {
                            for (var field in responseData.errors) {
                                if (formEl.find('[name=' + field + ']').length) {
                                    validationErrors[field] = responseData.errors[field].error || '';
                                }
                            }
                        }

                        formValidator.showErrors(validationErrors);
                        progress.hide(this.$el);
                    }
                } else {
                    if (responseData.redirectUrl) {
                        page.redirect(responseData.redirectUrl);
                    } else {
                        // default success action
                        page.redirect(Urls.billing);
                    }
                }
                res();
            }
        });
    });
}

function validateSelectedShippingMethod (params) {
    var {deliveryAddressInfo, deliveryOption, regionId, storeId, shippingMethodId} = params;
    var urlParams = {
        region: regionId,
        deliveryOption: deliveryOption,
        storeId: storeId || '',
        shippingMethodId: shippingMethodId
    };

    var url = util.appendParamsToUrl(Urls.validateShippingMethod, $.extend(deliveryAddressInfo, urlParams));
    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax.getJson({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t validate selected shipping method.');
                }
                res(data.isShippingMethodValid);
            }
        });
    });
}

function getAvailableShippingMethodsCostInfo (params) {
    var {deliveryAddressInfo, deliveryOption, regionId, storeId} = params;
    var urlParams = {
        region: regionId,
        deliveryOption: deliveryOption,
        storeId: storeId || ''
    };

    var url = util.appendParamsToUrl(Urls.shippingMethodsCostInfo, $.extend(deliveryAddressInfo, urlParams));
    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax.getJson({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t get available shipping methods cost info.');
                }
                res(data.availableShippingMethodsCostInfo);
            }
        });
    });
}

function getShippingForm (params) {
    var {deliveryOption} = params;
    var url = util.appendParamsToUrl(Urls.getShippingForm, {deliveryOption: deliveryOption});

    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t get shipping form.');
                }
                res(data);
            }
        });
    });
}

function updateStoredShippingAddress (params) {
    var {existingAddressId, shippingAddressInfo} = params;
    var urlParams = {
        existingAddressId: existingAddressId
    };

    var url = util.appendParamsToUrl(Urls.updateStoredShippingAddress, $.extend(shippingAddressInfo, urlParams));

    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax.getJson({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t edit shipping stored address.');
                }
                res(data);
            }
        });
    });
}

module.exports.init = function () {
    eventMgr.registerAction('get.shipping.methods.by.region', getShippingMethodsByRegion);
    eventMgr.registerAction('select.shipping.method', selectShippingMethod);
    eventMgr.registerAction('get.checkout.summary', getCheckoutSummary);
    eventMgr.registerAction('validate.selected.shipping.method', validateSelectedShippingMethod);
    eventMgr.registerAction('get.available.shipping.methods.cost.info', getAvailableShippingMethodsCostInfo);
    eventMgr.registerAction('submit.delivery.form', submitDeliveryForm);
    eventMgr.registerAction('get.shipping.form', getShippingForm);
    eventMgr.registerAction('update.stored.shipping.address', updateStoredShippingAddress);
};
