import Block from '_core_ext/core/common/Block';
import util from '_core_ext/util';
import cookie from '_core_ext/cookie';
import layout from '_core_ext/layout';
import eventMgr from '_core_ext/core/eventMgr';
import paginationUtils from '_core_ext/components/plp/utils/pagination';

export default class ProductList extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        this.loadMoreAvailable = false;
        this.sessionStorage = util.storageAvailable('sessionStorage') ? window.sessionStorage : {};
        this.productTileToNotify = null;

        this.initloadMore();

        this.event('click', '.js-load-more-button', this.onLoadMoreClick.bind(this));
        this.eventMgr('window.scroll', this.onInfiniteScroll.bind(this));
        this.eventMgr('window.modechanged', this.initloadMore.bind(this));
        this.subscribeToChildrenEvents();
        this.updateTilesIdx();
        if (layout.isMobile() || layout.isTablet()) {
            var url = window.location.href;
            if(url.includes('?')) {
                sessionStorage.updateScrollPos = 'false';
                if (sessionStorage.plpPageUrl === null) {
                    sessionStorage.plpPageUrl = window.location.href.split('?')[0];
                } else if(sessionStorage.plpPageUrl !== window.location.href.split('?')[0]) {
                    sessionStorage.scrollPos = 0;
                    sessionStorage.plpPageUrl = window.location.href.split('?')[0];
                }
                setTimeout( function(){          
                    $(window).scrollTop(sessionStorage.scrollPos || 0);
                    sessionStorage.scrollPos = $(window).scrollTop() - $('.js-header').height();
                    sessionStorage.updateScrollPos = 'true';
                }, 400);
            }
        }
    }

    /**
    * @private
    * @function
    * @description Update product list grid
    * @param {Object} loadingPlaceHolder
    * @param {Boolean} loadmore
    * @param {Boolean} removeLoadMore
    */
    updateGrid(loadingPlaceHolder, loadmore, removeLoadMore) {
        var gridUrl = loadingPlaceHolder.data('grid-url');
        var cacheKey = this.getCacheKey(gridUrl, removeLoadMore);
        this.handleGridContent(cacheKey, gridUrl, loadmore, removeLoadMore)
            .then((htmlGridSection) => {
                this.appendGridContent(htmlGridSection, gridUrl, loadingPlaceHolder);
            }).then(() => {
                var paginationParams = paginationUtils.getPaginationParams(gridUrl, true);
                this.emitter.emit('productList.grid.updated', {paginationParams});
            });
    }

    /**
    * @private
    * @function
    * @description Returns the html grid if it was previously cached, if not, performs AJAX request to get it.
    * @param {String} cacheKey
    * @param {String} gridUrl
    * @param {Boolean} loadmore
    * @param {Boolean} removeLoadMore
    */
    handleGridContent(cacheKey, gridUrl, loadmore, removeLoadMore) {
        var cachedHtmlGridSection = this.getCachedHtmlGridSection(cacheKey);

        if (cachedHtmlGridSection) {
            return Promise.resolve(cachedHtmlGridSection);
        } else {
            this.$el.find('.js-productgrid-loader').css('display','inline-block');
            if (layout.isMobile() || layout.isTablet()) {
                $(window).scrollTop(sessionStorage.scrollPos || 0);
                sessionStorage.scrollPos = $(window).scrollTop() - $('.js-header').height();
                sessionStorage.updateScrollPos = 'true';
            }
            return this.getGridSection({
                gridUrl: gridUrl,
                loadmore: loadmore,
                removeLoadMore: removeLoadMore,
                cacheKey: cacheKey
            });
        }
    }

    /**
    * @private
    * @function
    * @description Generates cache key for sessionStorage
    * @param {String} gridUrl
    * @param {Boolean} removeLoadMore
    */
    getCacheKey(gridUrl, removeLoadMore) {
        var cookieValue;
        var initialCacheKey = 'scroll-cache_' + gridUrl + '&removeLoadMore=' + removeLoadMore;
        var cookieNames = ['plp_productView', 'plp_gridView'];

        return cookieNames.reduce((cacheKey, cookieName) => {
            cookieValue = cookie.get(cookieName);
            if (cookieValue) {
                cacheKey += `&${cookieName}=${cookieValue}`;
            }
            return cacheKey;
        }, initialCacheKey);
    }

    /**
    * @private
    * @function
    * @description Returns cached html grid section response from sessionStorage
    * @param {String} cacheKey
    */
    getCachedHtmlGridSection(cacheKey) {
        return this.sessionStorage[cacheKey];
    }

    /**
    * @private
    * @function
    * @description Adss html grid section response to sessionStorage
    * @param {String} cacheKey
    * @param {HTML} htmlGridSection
    */
    addHtmlGridSectionToCache(cacheKey, htmlGridSection) {
        try {
            // put response into cache
            this.sessionStorage[cacheKey] = htmlGridSection;
        } catch (error) {
            // nothing to catch in case of out of memory of session storage
            // it will fall back to load via ajax
        }
    }

    /**
    * @private
    * @function
    * @description Adss html grid section response to productList html
    * @param {Object} loadingPlaceHolder
    * @param {HTML} htmlGridSection
    */
    fillEndlessScrollChunk(htmlGridSection, loadingPlaceHolder) {
        var tmpDOM = $('<div>').append(htmlGridSection);
        var targetResultItems = tmpDOM.find('#search-result-items');
        if (targetResultItems.length) {
            this.$el.find('#search-result-items').append(targetResultItems.html());
            targetResultItems.remove();
        }
        loadingPlaceHolder.remove();
        this.$el.append(tmpDOM.find('[data-cmp=productList]').html());
        $(document).trigger('init.all.carousels.insideselector', '[data-cmp=productList]');
        return eventMgr.execute('components.update', this.$el);
    }

    appendGridContent(htmlGridSection, gridUrl, loadingPlaceHolder) {
        this.$el.find('.js-load-more-wrapper').remove();
        return this.fillEndlessScrollChunk(htmlGridSection, loadingPlaceHolder)
            .then(() => {
                paginationUtils.setHistoryUrl(gridUrl, true);
                this.updateTilesIdx();
                var newProductTiles = this.getProductTileCmpsFromResponse(htmlGridSection);
                this.subscribeToChildrenEvents(newProductTiles);
            });
    }

    /**
    * @private
    * @function
    * @description Performs AJAX request for getting new product list grid section
    * @param {Object} params
    */
    getGridSection(params) {
        var {cacheKey, removeLoadMore, gridUrl} = params;
        return eventMgr.execute('get.grid.section', params)
            .then((htmlGridSection) => {
                cacheKey = cacheKey ? cacheKey : this.getCacheKey(gridUrl, removeLoadMore);
                this.addHtmlGridSectionToCache(cacheKey, htmlGridSection);
                return htmlGridSection;
            });
    }

    /**
    * @private
    * @function
    * @description Load More functionality
    */
    onLoadMoreClick() {
        if (!this.loadMoreAvailable) {
            return;
        }

        var loadingPlaceHolder = this.$el.find('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
        if (loadingPlaceHolder.length === 1) {
            this.updateGrid(loadingPlaceHolder, true, true);
        }
    }

    /**
    * @private
    * @function
    * @description Infinite Scroll functionality
    */
    onInfiniteScroll() {
        if(sessionStorage.updateScrollPos == 'true' && (layout.isMobile() || layout.isTablet())) {
            setTimeout(sessionStorage.scrollPos = $(window).scrollTop(), 200);
        }
        if (this.loadMoreAvailable) {
            return;
        }

        var loadingPlaceHolder = this.$el.find('.infinite-scroll-placeholder[data-loading-state="unloaded"]');
        if (loadingPlaceHolder.length === 1 && util.elementInViewport(loadingPlaceHolder.get(0), 250)) {
            this.updateGrid(loadingPlaceHolder, false, false);
        }
    }

    isLoadMoreAvailable(modeData) {
        var viewType = modeData ? modeData.mode : layout.getMode();
        var $loadMoreWrapper = this.$el.find('.js-load-more-wrapper');
        if ($loadMoreWrapper.length) {
            var screenConfig = $loadMoreWrapper.data('screens-config');
            var screenConfigCurrentPage = screenConfig[pageContext.currentPage];
            var availableScreens = screenConfigCurrentPage ? screenConfigCurrentPage.screens.split(',') : [];
            return availableScreens.indexOf(viewType) > -1;
        }
        return false;
    }

    initloadMore(modeData) {
        if (!SitePreferences.LISTING_INFINITE_SCROLL) {
            return;
        }
        this.loadMoreAvailable = this.isLoadMoreAvailable(modeData);
        this.$el.toggleClass('is-load-more-enabled', this.loadMoreAvailable);
    }

    getProductTileCmpsFromResponse(html) {
        html = $(html);
        var producTiles = [];
        var productTileId = '';
        var self = this;
        $(html).find('.product-tile').each((i, el) => {
            productTileId = $(el).data('json-config').cmpId;
            self.getById(productTileId, (cmp) => {
                producTiles.push(cmp);
            });
        });

        return producTiles;
    }

    subscribeToChildrenEvents(children) {
        children = children || this.items;
        var self = this;
        var notifyMeCb = (params) => {
            this.getById(params.productTileCmpId, (productTile) => {
                this.productTileToNotify = productTile;
            });
            this.emitter.emit('productList.notifyme', params);
        };
        var addToCartCb = (params) => this.emitter.emit('productList.product.added.to.cart', params);

        children.forEach((productTile) => {
            productTile.on('produtTile.notifyme', notifyMeCb.bind(self));
            productTile.on('produtTile.product.added.to.cart', addToCartCb.bind(self));
            productTile.on('produtTile.quickShop.hideSiblings', () => {
                this.items.forEach((ProductTile) => {
                    ProductTile.hideQuickShop();
                });
            });
        });
    }

    notifyMeFormSubmited() {
        this.productTileToNotify.notifyMeFormSubmitted();
    }

    onNotifyMeDialogClose() {
        this.productTileToNotify.onNotifyMeDialogClose();
    }

    updateTilesIdx() {
        this.items.forEach((ProductTile, idx) => {
            ProductTile.$el.data('idx', idx);
        });
    }
}