export default {
    showPostalCodeRestrictionsErrorMessage: function () {
        var $errorBlock = this.$el.find('.error-message-block');
        var $spanErrorBlock = $errorBlock.find('span');

        if ($spanErrorBlock.length && $spanErrorBlock.text()) {
            return;
        }

        var unavailabilityMessage = Resources.SHIPPING_METHOD_NOT_APPLICABLE;
        $('<span/>').addClass('error').html(unavailabilityMessage).prependTo($errorBlock.get());
    },

    clearPostalCodeRestrictionsErrorMessage: function () {
        var $errorBlock = this.$el.find('.error-message-block');
        var $spanErrorBlock = $errorBlock.find('span');

        if (!$spanErrorBlock.length && !$spanErrorBlock.text()) {
            return;
        }

        $errorBlock.html('');
    }
};