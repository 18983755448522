'use strict';
/* eslint-disable */

function initializeEvents() {
    $('.order-conf-radio-btn').find('input').change(function(){
        var id = $(this).attr('id');
        $('.order-conf-radio-btn').find('input').each(function(ele){
            var thisid = $(this).attr('id');
            if (thisid != id) {
                $(this).removeAttr('checked', false);
            }       
        });
        $('.gender-radio-'+id).attr('checked',true);
        $('#dwfrm_profile_customer_gender').val($(this).val()).trigger('change');
    });
}
var registeraccount = {
    init: function () {
        initializeEvents();
        $('#dwfrm_profile_customer_gender').val('3').trigger('change');
    }
};

module.exports = registeraccount;