import Component from '_core_ext/core/Component';

export default class UserZomm extends Component {

    init () {
        this.initUserZoomFunctionality(this.config.URL);
    }

    initUserZoomFunctionality (URL) {
        var uz = document.createElement('script');
        uz.type = 'text/javascript';
        uz.async = true;
        uz.charset = 'utf-8';
        uz.src = ('https:' === document.location.protocol ? 'https://' : 'http://') + URL;
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(uz, s);
    }
}
