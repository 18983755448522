import ViewModeBase from '../Base';

export default class GridView extends ViewModeBase {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
    }

    getViewModeUrlParams() {
        return {
            gridViewMode: this.currentViewMode
        };
    }
}