import eventMgr from '_core_ext/core/eventMgr';
import util from '_core_ext/util';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';

function getPlp (params) {
    var {url, additionalUrlParams} = params;

    if (additionalUrlParams) {
        url = util.appendParamsToUrl(url, additionalUrlParams);
    }

    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            url: url,
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t get plp.');
                }
                res(data);
                setTimeout(() => {
                    var lazyImages = [].slice.call(document.querySelectorAll(".lazyloadimg"));
                    /** Then we set up a intersection observer watching over those images and whenever any of those becomes visible on the view then replace the placeholder image with actual one, remove the non-loaded class and then unobserve for that element **/
                    let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
                        entries.forEach(function(entry) {
                            if (entry.isIntersecting) {
                                let lazyImage = entry.target;
                                lazyImage.src = lazyImage.dataset.src;
                                lazyImage.classList.remove("lazy");
                                lazyImageObserver.unobserve(lazyImage);
                            }
                        });
                    });
                    /** Now observe all the non-loaded images using the observer we have setup above **/
                    lazyImages.forEach(function(lazyImage) {
                        lazyImageObserver.observe(lazyImage);
                    });

                    if($(".plp-slick-slider .swiper-slide").length > 0) {
                        $('.plp-slick-slider').slick({
                            infinite: false,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            arrows: true,
                            dots: false,
                            prevArrow:
                                "<i class='icon-arrow-left slick-prev pull-left slick-arrow'></i>",
                            nextArrow:
                                "<i class='icon-arrow-right slick-next pull-right slick-arrow'></i>"

                        });
                    }

                }, "500");
            }
        });
    });
}

function getGridSection (params) {
    var {gridUrl, loadmore} = params;
    return new Promise((res, rej) => {
        ajax.load({
            url: util.appendParamsToUrl(gridUrl, {loadmore: loadmore}),
            callback: function (htmlGrid, textStatus) {
                if (textStatus !== 'success' && textStatus !== 'notmodified') {
                    return rej('Couldn\'t get grid section.');
                }
                res(htmlGrid);
            }
        });
    });
}

function getRefinementApplyUrl (params) {
    var {url, data} = params;
    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            url: url,
            type: 'POST',
            data: data,
            dataType: 'json',
            callback: (data) => {
                progress.hide();
                if (data === null) {
                    return rej('Couldn\'t apply refinement.');
                }
                res(data);
            }
        });
    });
}

module.exports.init = function () {
    eventMgr.registerAction('get.plp', getPlp);
    eventMgr.registerAction('get.grid.section', getGridSection);
    eventMgr.registerAction('get.refinement.apply.url', getRefinementApplyUrl);
};