import FormGeneric from '_core_ext/components/forms/FormGeneric';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';

export default class Account extends FormGeneric {
    init() {
        super.init();
        this.additionalvalidators = [];
    }

    onSubmit() {
        this.$el.find('.js-error_message').hide();

        if (!this.checkAdditionalValidators()) {
            return;
        }

        this.submitForm()
            .then((response) => {
                if (response.success) {
                    this.onSubmitSuccess(response);
                } else {
                    this.handleSubmitFailed(response);
                }
            });
    }

    submitForm() {
        progress.show(this.$el);
        return new Promise((res, rej) => {
            var $submit = this.$el.find('[type=submit]');
            var submitName = $submit.attr('name');
            var submitValue = $submit.attr('value');

            ajax.getJson({
                url: this.$el.attr('action'),
                type: 'POST',
                data: this.$el.serialize() + '&' + submitName + '=' + submitValue,
                contentType: 'application/x-www-form-urlencoded',
                callback: (data) => {
                    progress.hide();
                    if (data === null) {
                        return rej(`Couldn't submit ${this.id} form`);
                    }
                    res(data);
                }
            });
        });
    }

    handleSubmitFailed(result) {
        if (!result.errorMsgs || !result.errorMsgs.length) {
            return;
        }

        var $errorMsgsContainer = this.$el.find('.js-error_messages-container');

        if (!$errorMsgsContainer.length) {
            $errorMsgsContainer = $('<div class="js-error_messages-container"></div>');
        }
        var errorMsgsElements = result.errorMsgs
            .map((msg) => {
                return `<p class="js-error_message c-subscribe-form__error-msg">
                            ${msg}
                        </p>`;
            });

        $errorMsgsContainer.html('');
        $errorMsgsContainer.append(...errorMsgsElements);
        this.$el.append($errorMsgsContainer);
        this.$el.trigger('existingAccountError.success', [this.$el, result]);

        $errorMsgsContainer.find('.js-error_message').fadeIn();
    }

    checkAdditionalValidators() {
        if (!this.additionalvalidators.length) {
            return true;
        }

        return this.additionalvalidators
            .map((validator) => validator())
            .every((validationResult) => validationResult);
    }

    onSubmitSuccess(result) {
        if (!result.successMsg) {
            if(this.$el.parents('.c-account.account-profile-accordion').length > 0) {
                window.location.reload();
            }
            return;
        }
        var $msgContainer = this.$el.find('.js-result_message');

        if (!$msgContainer.length) {
            var container = `<p class="js-result_message c-subscribe-form__success-msg">${result.successMsg}</p>`;
            this.$el.append(container);
            $msgContainer = this.$el.find('.js-result_message');
        }

        $msgContainer.fadeIn();
        setTimeout(() => this.$el.find('.js-result_message').fadeOut(), 3000);
        if(this.$el.parents('.c-account-change_password_section_hkt').length > 0) {
            this.$el.parents('.c-account-change_password_section_hkt').find('.js-cancel-button').trigger('click');
        }
    }
}
