import DotNavigation from '_core_ext/components/common/DotNavigation';

export default class ProductMainImageNav extends DotNavigation {
    init () {
        this.navigationElementsSelector = '.js-image';
        this.initProductMainImageEvents();
        this.initialized = false;
    }

    initProductMainImageEvents() {
        $(document).on('lazyloaded', this.initSuper.bind(this));
    }

    initSuper() {
        require('_core_ext/util').throttle(() => {
            if (this.initialized) {
                this.reinit();
            } else {
                super.init();
            }
        }, 500);
    }
}
