import Component from '../../core/Component';
import $ from 'jquery';

export default class InputGeneric extends Component {
    init () {
        this.event('change', '.js-input_field', this.update);
        this.event('paste', '.js-input_field', () => {
            setTimeout(this.update.bind(this), 10);
        });

        this.$field = this.$el.find('.js-input_field');
        this.$label = this.$el.find('.js-input-label');

        this.initValue = this.getValue();
        if (!this.id && this.$field.first().attr('name')) {
            this.id = this.$field.first().attr('name');
        }

        this.shown = true;
        this.disabled = false;
        this.skipValidation = false;

        this.disableEnterSubmit();
    }
    getValue () {
        return this.$field.val();
    }
    getName () {
        if (this.shown && this.isValid()) {
            return this.$field.attr('name');
        }
    }
    setValue (newVal = '', silently = false) {
        this.$field.val(newVal);
        if (!silently) {
            this.update();
        }
    }
    isDirty () {
        return this.initValue !== this.getValue();
    }
    hide () {
        this.shown = false;
        this.$el.addClass('is--hidden');
    }
    show () {
        this.shown = true;
        this.$el.removeClass('is--hidden');
    }
    disable () {
        this.disabled = true;
        this.$field.attr('disabled', true);
    }
    enable () {
        this.disabled = false;
        this.$field.attr('disabled', false);
    }
    setReadonly () {
        this.$field
            .on('focus', function () {
                $(this).trigger('blur');
            })
            .prop('readonly', true);
    }
    isDisabled () {
        return this.disabled;
    }
    skipSubmission () {
        return false;
    }
    setSkipValidation (value) {
        this.skipValidation = !!value;
    }
    isSkipValidation () {
        return this.skipValidation;
    }
    disableEnterSubmit () {
        if (this.config.disableEnterSubmit) {
            this.event('keydown', '.js-input_field', (el, ev) => {
                if (ev && ev.keyCode === 13) {
                    ev.preventDefault();
                    return false;
                }
            });
        }
    }
    preventEmptyString () {
        if ((typeof this.$field.val()) === 'string' && !this.$field.val().match(/\S/)) {
            this.$field.val('');
        }
    }
    update () {
        this.preventEmptyString();

        if (this.isDirty()) {
            this.$el.addClass('state-dirty');
        } else {
            this.$el.removeClass('state-dirty');
        }

        this.emitter.emit('change', this);
    }
}
