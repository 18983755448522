import eventMgr from '_core_ext/core/eventMgr';
import util from '_core_ext/util';
import ajax from '_core_ext/ajax';
import progress from '_core/progress';

function checkIfCouponIsApplicableForCreditCard (params) {
    var {url, cb, data} = params;
    progress.show(this.$el);
    return new Promise((res) => {
        util.makeRequestForCheckingIfCouponIsApplicableForCreditCard(url, 'POST', () => {
            progress.hide();
            cb();
        }, data);
        res();
    });
}

function saveCustomerData (params) {
    progress.show(this.$el);
    return new Promise((res, rej) => {
        ajax({
            url: Urls.saveCustomerData,
            type: 'POST',
            data: params,
            callback: (data) => {
                if (data === null) {
                    return rej('Couldn\'t save customer data.');
                }
                res();
            }
        });
    });
}

module.exports.init = function () {
    eventMgr.registerAction('check.if.coupon.is.applicable.for.credit.card', checkIfCouponIsApplicableForCreditCard);
    eventMgr.registerAction('save.customer.data', saveCustomerData);
};
