'use strict';

/* eslint-disable */

const SCRIPT_URL = '//services.postcodeanywhere.co.uk/js/address-3.50.min.js';
var $cache = {},
    initialized = false,
    instances = {},
    fields = {
        account: ['_address_searchaddress', '_address_address1', '_address_address2',
            '_address_city', '_address_postal', '_address_stateCode', '[id$=_address_country]'],
        address: ['_addressFields_searchaddress', '_addressFields_address1', '_addressFields_address2',
            '_addressFields_city', '_addressFields_postal', '_addressFields_stateCode', '[id$=_addressFields_country]'],
        invoice: ['_findaddress', '_invoice_address1', '_invoice_address2', '_invoice_city', '_invoice_postal', '_invoice_stateCode', '[id$=_invoice_country]']
    };

function postCodeCorrection(data) {
    switch(data.CountryIso2) {
        case 'GR':
        case 'LU':
            data.PostalCode = data.PostalCode.replace(/[^0-9]/g, '');
            break;
        case 'US':
            data.PostalCode = data.PostalCode.replace(/[^0-9-]/g, '');
            break;
    }
    return data;
}

function setAddressLine1AndLine2(data){
    if(data.FormattedLine2 !== null && data.FormattedLine2 !== "" && data.FormattedLine2 !== undefined){
        $('input[name$="_addressFields_address1"]').val(data.FormattedLine1 + ',' + data.FormattedLine2);
    }

}

/**
 *  Callback which is triggered when when an address has been selected
 */
function onPopulate(data) {
    data = postCodeCorrection(data);
    setAddressLine1AndLine2(data);
    if (data.PostalCode && this.$postalFormElement.length && this.$postalFormElement.val() !== data.PostalCode) {
        this.$postalFormElement.val(data.PostalCode).change();
    }

    if (data.CountryIso2 && this.$countryFormElement.length && this.$countryFormElement.val() !== data.CountryIso2) {
        this.$countryFormElement.val(data.CountryIso2).trigger('change', [this.$addressFieldsElement, data.ProvinceCode || data.Province]);
    } else if (data.Province || data.AdminAreaName) {
    	let province = data.Province || data.AdminAreaName;
        let $option = this.$provinceFormElement.find('option:contains(' + province + ')');
        if ($option.length) {
            this.$provinceFormElement.val($option.val());
            this.$provinceFormElement.change();
        } else if (data.ProvinceCode) {
            this.$provinceFormElement.val(data.ProvinceCode).change();
        }
    } else if (data.ProvinceCode && this.$provinceFormElement.find('option[value$='+data.ProvinceCode+']').length) {
        this.$provinceFormElement.val(data.ProvinceCode).change();
    }

    if (this.$addressFieldsElement.is(':hidden')) {
        this.$addressFieldsElement.parents('.js-global-accordion:not(:hidden)').trigger('accordion.open', [0]);
    }
    this.$provinceFormElement.selectric('refresh');
    $cache.document.trigger('address.updated');
}

/**
 * Wrapper for PCA constructor
 */
function createPCAControl(type, selectedCountry) {
    let fieldNames = [
        {
            element: fields[type][0],
            field: '',
            mode: window.pca.fieldMode.SEARCH
        },
        {
            element: fields[type][1],
            field: 'Line1',
            mode: window.pca.fieldMode.POPULATE
        },
        {
            element: fields[type][2],
            field: 'Line2',
            mode: window.pca.fieldMode.POPULATE
        },
        {
            element: fields[type][3],
            field: 'City',
            mode: window.pca.fieldMode.POPULATE
        },
        {
            element: fields[type][4],
            field: 'PostalCode',
            mode: window.pca.fieldMode.POPULATE
        },
        {
            element: fields[type][5],
            field: 'Province',
            mode: window.pca.fieldMode.POPULATE
        },
        {
            element: fields[type][6],
            field: 'Country',
            mode: window.pca.fieldMode.POPULATE
        }
    ];
    return  selectedCountry ? new window.pca.Address(fieldNames, { key: SitePreferences.PCA_API_KEY, setCountryByIP: false , search: { countries: selectedCountry }})
        : new window.pca.Address(fieldNames, { key: SitePreferences.PCA_API_KEY, setCountryByIP: false });
}

/**
 * Corrects native PCA autocomplete value from 'false' to 'nope'
 */
function correctAutocompleteAttr() {
    $("input[id$='_searchaddress']").attr("autocomplete", "nope");
}

/**
* Initialize postcode anywhere object
*/
function initializePCA(type, countryCode) {
    let instance = null,
        $addressFieldsElement;

    if (type === 'invoice') {
        $addressFieldsElement = $cache.document.find('.js-invoice-form .js-address-fields');
    } else if (type === 'address' || type === 'account') {
        $addressFieldsElement = $cache.document.find('.js-address-form:not(.js-invoice-form) .js-address-fields');
    } else {
        $addressFieldsElement = $cache.document.find('.js-address-form');
    }
    let selectedCountry = countryCode ? countryCode : $addressFieldsElement.data('selectedCountry') || "";
    instances[type] = createPCAControl(type, selectedCountry);
    instance = instances[type];

    instance.$addressFieldsElement = $addressFieldsElement;
    instance.$provinceFormElement = instance.$addressFieldsElement.find('select[name$="_states_state"]');
    instance.$postalFormElement = instance.$addressFieldsElement.find('input[name$="_postal"]');
    instance.$countryFormElement = $cache.document.find('.js-address-form select.js-country-pca');
    instance.$countryFormElement.data('instanceType', type);

    instance.listen('load', function () {
        $cache.document.trigger('pca.loaded');
    });
    instance.listen('populate', onPopulate);

    //MA edit address case
    correctAutocompleteAttr();
}

/**
 * Updates countries after select
 */
function updateCountry() {
    let $this = $(this);
    if (window.pca) {
        let countryCode = $this.val(),
            type;
        if (countryCode) {
            type = $this.data('instanceType');
            if (type && instances[type]) {
                instances[type].destroy();
                initializePCA(type, countryCode);
            }
        }
    }
}

/**
 * Initialize Cache
 */
function initializeCache() {
    $cache.document = $(document);
}

/**
 * Reinitialize component
 */
function addAddressInstance() {
    if (SitePreferences.PCA_ENABLED && window.pca) {
        initializePCA('invoice');
    }
}

/**
 * Reinitialize component
 */
function reinit(type) {
    type = type && fields[type] && fields[type].length ? type : 'account';
    if (SitePreferences.PCA_ENABLED) {
        if (window.pca) {
            initializePCA(type);
        } else {
            /* eslint-disable */
            $.getScript(SCRIPT_URL, initPCA);
            /* eslint-enable */
        }
    }
}


/**
 * Initialize Events
 */
function initializeEvents() {
    $cache.document
        .on('change', '.js-country-pca:not(:hidden)', updateCountry)
        .on('address.init', (e, type) => {
            reinit(type);
        })
        .on('dialogopen', reinit)
        .on('dialog.scroll', () => {
            if ($cache.document.find('.pcaautocomplete.pcatext').is(':visible')) {
                var $elementPCA = $cache.document.find('.searchaddress'),
                    elementPCAPosition = $elementPCA.offset().top + $elementPCA.height();
                $cache.document.find('.pcaautocomplete.pcatext').css('top', elementPCAPosition);
            }
        })
        .one('pca.loaded', () => {
            //shipping and billing forms case
            correctAutocompleteAttr();

            if ($('.findaddress').length) {
                addAddressInstance();
            }
        });
}

/**
 * Initialize component
 *
 */
function initPCA() {
    initializeCache();
    initializeEvents();
    try {
        if (window.pca && $('.js-address-fields').length) {
            initializePCA('address');
        }
    } catch (error) {
        window.console.error('PCA failed due to country restriction ', error);
    }
}

module.exports = {
    init: function () {
        if (!SitePreferences.PCA_ENABLED || initialized) {
            return;
        }
        $.getScript(SCRIPT_URL, initPCA);
        initialized = true;
    },
    addAddressInstance: addAddressInstance,
    reinit: reinit
};
