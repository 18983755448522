'use strict';

import eventMgr from '_core_ext/core/eventMgr';
import billing from '_core_ext/pages/checkout/billing';

/**
 * @function Initializes on first loading
 */
function initializePage () {
    $(document).find('.form-row_field+span.error').hide();

    //if on the order review page and there are products that are not available diable the submit order button
    if ($('.order-summary-footer').length > 0) {
        if ($('.notavailable').length > 0) {
            $('.order-summary-footer .submit-order .button-fancy-large').attr('disabled', 'disabled');
        }
    }

    if (!$('.checkout-shipping').length) {
        billing.init();
    }
}

var checkout = {
    init: function () {
        initializePage();
        eventMgr.registerAction('Billing.get.billing.address.info', () => {
            return new Promise((res) => {
                res({
                    addressId: $('.js-minicheckoutaddress.is--selected input').val()
                });
            });
        });
    }
};

module.exports = checkout;