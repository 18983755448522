import FormGeneric from '_core_ext/components/forms/FormGeneric';
import ajax from '_core_ext/ajax';
import util from '_core_ext/util';

export default class MeasurementsForm extends FormGeneric {
    init() {
        super.init();

        this.config.animationDuration = 5000;

        this.listenTabSwitching();
        this.onChangeUnitType();

        this.event('click', '.js-save-form', this.onSave);
        this.event('change', '.js-input_field', util.debounce(this.validateForms, 100));
        this.event('click', '.js-unit-type', this.onChangeUnitType);
    }
    validateForms() {
        var $saveButton = this.$el.find('[type=button]');
        var success;

        if (this.$el.find('.error').length > 0) {
            $saveButton.prop('disabled', true);
            success = false;
        } else {
            $saveButton.prop('disabled', false);
            success = true;
        }

        return success;
    }
    listenTabSwitching() {
        $(document).on('tab.changed', (event, currentTab) => {
            var $currentTab = $(currentTab);
            var $tabWrapper = $currentTab.closest('.js-tab-wrapper');

            $tabWrapper.find('.js-tab-switch').each((index, tab) => {
                this.$el
                    .find(`[data-parent='${$(tab).data('containerId')}']`)
                    .attr('data-visible', false);
            });

            this.$el
                .find(`[data-parent='${$currentTab.data('containerId')}']`)
                .attr('data-visible', true);

            this.$el
                .find('.js-measurements-confirmation')
                .empty();
        });
    }
    onChangeUnitType() {
        var unitsConfig = {
            cm: 'cm',
            in: '"'
        };
        var $unitRadio = this.$el.find('.js-unit-type').filter(':checked');
        var values = $unitRadio.data('unitValues').split(',');

        var $collarSizeField = this.$el.find(`[data-type='collarSize']`);
        var $insideLegFieldLabel = this.$el.find('.js-insideleg-label');

        $collarSizeField.find('option').each((index, el) => {
            var $option = $(el);

            if (index > 0) { //skip first option in select 'Please select...'
                $option.text(values[index - 1] + unitsConfig[$unitRadio.data('name').toLowerCase()]);
            }
        });

        $collarSizeField.selectric('refresh');
        $insideLegFieldLabel.text($unitRadio.data('label'));
    }
    getActiveTabData() {
        /* eslint quotes: 0 */
        var $activeTabPage = this.$el.find(`[data-visible='true']`);
        var $inputFields = $activeTabPage.find(':input');

        return this.processTabFields($inputFields);
    }
    processTabFields($fields) {
        var result = [];
        var $checkboxes = $fields.filter((index, item) => {
            return $(item).is(':checkbox');
        });
        var $singleValueInputs = $fields.filter((index, item) => {
            // filter selectric additional inputs also
            return $(item).data('type') && ($(item).is('select') || $(item).is('input:text'));
        });
        var $radioFields = $fields.filter((index, item) => {
            return $(item).is(':radio');
        });
        var checkedCheckboxes = $checkboxes.filter((index, item) => {
            return $(item).is(':checked');
        });

        $checkboxes.each((index, el) => {
            var $el = $(el);
            var type = $el.data('type');
            var savedParameters = result.filter((obj) => {
                if (obj.name === type) {
                    return obj;
                }
            });

            // pass empty value in case of no checked checkboxes are presented
            if (!checkedCheckboxes.length) {
                if (!savedParameters.length) {
                    result.push({
                        name: type,
                        value: null
                    });
                }

                return;
            } else {
                if ($el.is(':checked')) {
                    if (result.length) {
                        var paramValue = $el.val();

                        if (savedParameters.length) {
                            savedParameters[0].value.push($el.val()); //push to values if we already have key in object
                        } else {
                            result.push({
                                name: type,
                                value: [paramValue]
                            });
                        }
                    } else {
                        result.push({
                            name: type,
                            value: [$el.val()]
                        });
                    }
                }
            }
        });

        $singleValueInputs.each((index, el) => {
            var $el = $(el);

            result.push({
                name: $el.data('type'),
                value: $el.val()
            });
        });

        $radioFields.each((index, el) => {
            var $el = $(el);

            if ($(el).is(':checked')) {
                result.push({
                    name: $el.data('type'),
                    value: $el.val()
                });
            }
        });

        return result;
    }
    onSave() {
        if (this.validateForms()) {
            ajax({
                url: this.$el.attr('action'),
                type: 'POST',
                data: [{
                    name: 'measurements',
                    value: JSON.stringify(this.getActiveTabData())
                }]
            }).then((response) => {
                var responseObj = JSON.parse(response);

                if (responseObj && responseObj.success) {
                    this.showConfirmationMessage(responseObj.message)
                }
            });
        }
    }
    showConfirmationMessage(message) {
        var $successMessageContainer = this.$el.find('.js-measurements-confirmation');

        $successMessageContainer.text(message);
        $successMessageContainer.show();
        $successMessageContainer.fadeOut(this.config.animationDuration);
    }
}