'use strict';

/* eslint-disable */

const PhotoSwipe = require('photoswipe/dist/photoswipe');
const PhotoSwipeUI_Default = require('photoswipe/dist/photoswipe-ui-default');

//TODO configure preset and remove it from here
//https://babeljs.io/docs/en/babel-preset-env
//Added to support Symbol in Edge.
require('babel-polyfill');

let layout = require('./../../../../../app_storefront_core_ext/cartridge/js/layout'),
    globalGallery = null,
    util = require('./../../../../../app_storefront_core_ext/cartridge/js/util'),
    initialized = false,
    $cache = {},
    options = {
        initOnDevices: ['small', 'medium', 'large', 'extra-large'],
        barsSize: {
            top: 0,
            bottom: 'auto'
        },
        initialZoomLevel: 'fit',
        secondaryZoomLevel: 3,
        maxZoomLevel: 1,
        zoomEl: false,
        maxSpreadZoom: 2,
        fullscreenEl: true,
        allowPanToNext: true,
        pinchToClose: true,
        history: false,
        focus: false,
        closeOnScroll: false,
        showAnimationDuration: 0,
        hideAnimationDuration: 0,
        spacing: 0,
        closeElClasses: []
    };

/**
 * @description parse container data (url, title, size ...) from DOM elements
 *
 * @param {DOMNode} el, images container
 * @returns {Array} items, list of picture elements in found in container
 */
function parseThumbnailElements (el) {
    let thumbElements = el.querySelectorAll('.component-picture'),
        numNodes = thumbElements.length,
        items = [],
        pictureEl,
        item;

    for (let i = 0; i < numNodes; i++) {
        pictureEl = thumbElements[i];

        // include only element nodes
        if (pictureEl.nodeType !== 1) {
            continue;
        }

        let [linkEl] = pictureEl.getElementsByTagName('img');

        if (layout.isLargeView() && linkEl.dataset.zoomDesktop) {
            var desktopConfig = util.jsonParse(linkEl.dataset.zoomDesktop);
            item = {
                src: desktopConfig.srcZoom,
                w: parseInt(desktopConfig.width, 10),
                h: parseInt(desktopConfig.height, 10)
            };
        } else if (layout.isMediumView() && linkEl.dataset.zoomTablet) {
            var tabletConfig = util.jsonParse(linkEl.dataset.zoomTablet);
            item = {
                src: tabletConfig.srcZoom,
                w: parseInt(tabletConfig.width, 10),
                h: parseInt(tabletConfig.height, 10)
            };
        } else if (layout.isSmallView() && linkEl.dataset.zoomMobile) {
            var mobileConfig = util.jsonParse(linkEl.dataset.zoomMobile);
            item = {
                src: mobileConfig.srcZoom,
                w: parseInt(mobileConfig.width, 10),
                h: parseInt(mobileConfig.height, 10)
            };
        } else {
            item = {
                src: linkEl.getAttribute('data-zoom-src'),
                w: parseInt(linkEl.getAttribute('data-width'), 10),
                h: parseInt(linkEl.getAttribute('data-height'), 10)
            };
        }


        if (linkEl.children.length > 0) {
            item.msrc = linkEl.children[0].getAttribute('src');
        }

        item.el = pictureEl; // save link to element for getThumbBoundsFn
        items.push(item);
    }

    return items;
}

/**
 * @description for set zoom level
 *
 * @param {Object} zoomInstance, instants of zoom
 */
function zoomEvents(zoomInstance) {
    zoomInstance.listen('close', () => {
        $cache.body.removeClass('no-scroll scroll-down');
        $('.photoSwipe_wrapper').remove();
        if($('.pswp').hasClass('pswp--open')) {
            setTimeout(function() {
                $('#header').css('pointer-events', 'auto');
            }, 500);
        }
    });

    zoomInstance.listen('beforeChange', function(){
        $('div.photoSwipe_innerthumbs img.stl-icon').remove();
        var counter = $('.pswp__counter').text();
        var splittedCounter = counter.split('/');
        var splittedValues = (splittedCounter[0]*100)/splittedCounter[1];
        $('.progress .bar').css('width', splittedValues + "%");
        var photoSwipeThumb = $('div.photoSwipe_innerthumbs img');
        var thumbIndex = parseInt(splittedCounter[0] - 1);
        if (photoSwipeThumb && $(photoSwipeThumb[thumbIndex]).length > 0) {
            photoSwipeThumb.removeClass("svifaded");
            $(photoSwipeThumb[thumbIndex]).addClass('svifaded');
        }
    })
}

/**
 * @description opens zoom popup
 *
 * @param {DOMNode} galleryElement, clicked image DOMNode
 * @param {Object} zoomOptions, options for current images container
 */
function openPhotoSwipe (galleryElement, zoomOptions) {
    let [pswpElement] = document.getElementsByClassName(galleryElement.dataset.pswptemplate),
        zoomInstance,
        items;
    items = parseThumbnailElements(galleryElement);

    if (isNaN(zoomOptions.index)) {
        return;
    }

    //disable navigation arrows, where there is only one image
    if (items.length < 2) {
        zoomOptions.arrowEl = false;
    }

    zoomInstance = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, zoomOptions);
    globalGallery = zoomInstance;
    $('body').append('<div class="photoSwipe_wrapper"><div class="photoSwipe_innerthumbs"></div></div>');
    $('.images-container img').clone().appendTo("div.photoSwipe_innerthumbs");
    zoomInstance.init();
    $("div.photoSwipe_innerthumbs img").eq(zoomInstance.getCurrentIndex()).addClass('svifaded');
    var counter = $('.pswp__counter').text();
    var splittedCounter = counter.split('/');
    var splittedValues = (splittedCounter[0]*100)/splittedCounter[1];
    $('.progress .bar').css('width', splittedValues + "%");
    zoomEvents(zoomInstance);
}

/**
 * @description image click handler
 *
 * @param {Event} e, click event
 * @param {Object} zoomOptions
 */
function onThumbnailsClick (e, zoomOptions) {
    e = e || window.event;
    e.preventDefault ? e.preventDefault() : e.returnValue = false;

    let eTarget = e.target || e.srcElement;

    // find nearest parent element
    let closest = function closest(el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // find root element of slide
    let clickedListItem = closest(eTarget, function(el) {
        return (el.tagName && el.tagName.toUpperCase() === 'PICTURE');
    });

    if (!clickedListItem) {
        return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    let clickedGallery = clickedListItem.closest('.images-container'),
        childNodes = clickedListItem.closest('.images-container').querySelectorAll('.component-picture'),
        numChildNodes = childNodes.length,
        nodeIndex = 0,
        index;

    for (let i = 0; i < numChildNodes; i++) {
        if (childNodes[i].nodeType !== 1) {
            continue;
        }

        if (childNodes[i] === clickedListItem) {
            index = nodeIndex;
            break;
        }
        nodeIndex++;
    }

    if (index >= 0) {
        zoomOptions.index = parseInt(index, 10);
        openPhotoSwipe(clickedGallery, zoomOptions);
    }

    $cache.body.addClass('no-scroll');

    return false;
}

function initializeZoom(zoomContainer) {
    let zoomOptions = $.extend({}, options, zoomContainer.dataset.options || {});

    if (zoomOptions['initOnDevices'].indexOf(layout.getMode()) === -1) {
        return;
    }

    zoomOptions.galleryUID = zoomContainer.getAttribute('data-pswp-uid');

    var isAndroid = / Android/i.test(navigator.userAgent.toLowerCase());
    var isIOS =   / iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent.toLowerCase());

    if(isIOS) {
        $(zoomContainer).on('click', (event) => {
            onThumbnailsClick(event, zoomOptions);
            $('#header').css('pointer-events', 'none');
        });
        // window could be replaced with another desired element
        window.addEventListener('gestureend', function(event) {
            onThumbnailsClick(event, zoomOptions);
            $('#header').css('pointer-events', 'none');
        });

    } else if(isAndroid) {
        $(zoomContainer).on('click', (event) => {
            onThumbnailsClick(event, zoomOptions);
            $('#header').css('pointer-events', 'none');
        });
        // window could be replaced with another desired element
        window.addEventListener('touchstart', function(event) {
            if (event.touches.length > 1) {
                onThumbnailsClick(event, zoomOptions);
                $('#header').css('pointer-events', 'none');
            }
        });
    } else {
        $(zoomContainer).on('click', (event) => {
            onThumbnailsClick(event, zoomOptions);
            $('#header').css('pointer-events', 'none');
        });
    }
}

function initializeAll() {
    let $zoomItems = $('.js-zoom');

    $zoomItems.each((index, item) => {
        item.setAttribute('data-pswp-uid', index + 1);
        initializeZoom(item);
    });
}

function initializeCache() {
    $cache.document = $(document);
    $cache.body = $('body');
}

function initializeEvents() {
    $cache.document
        .on('images.loaded', () => {
            initializeAll();
        })
        .on('touchend', '#zoom-close-button, #zoom-prev-button, #zoom-next-button', function() {
            this.click();
        });
}

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function handleClickEventOnThumbnails(){
    $('body').on('click', 'div.photoSwipe_innerthumbs img', function (e) {
        $('div.photoSwipe_innerthumbs img').removeClass("svifaded");
        $(this).addClass('svifaded');
        globalGallery.goTo($("div.photoSwipe_innerthumbs img").index($(this)));
      });
}

module.exports = {
    init: function(params) {
        initializeParams(params);

        if (initialized) { return; }

        initializeCache();
        initializeEvents();
        initializeAll();
        handleClickEventOnThumbnails();
        initialized = true;
    }
};
