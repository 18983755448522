'use strict';
/* eslint-disable */
/**
 * @description Creates product recommendation carousel using jQuery jcarousel plugin
 **/

var ajax = require('./../../ajax'),
    util = require('./../../util'),
    $cache = {},
    selectors = {
        recommendationSection: '.js-recommendation-section'
    };

var recommendationsEndPoint = {
    'category-recommendation': Urls.getCategoriesRecommendations,
    'default': Urls.getRecommendationTiles
};

function addPageSpecificValuesToUrl(url, zone, $container) {
    switch (zone) {
    case 'pdp':
        url = util.appendParamsToUrl(url, {
            'productID': pageContext.currentProductID,
            'recommendationType': $container.data('recommendations-type')
        });
        break;
    case 'category-recommendation':
        url = util.appendParamsToUrl(url, {
            'categoriesIDs' : $container.data('categories-ids'),
            'recommendationType' : $container.data('recommendation-type'),
            'carouselConfiguration' : JSON.stringify($container.data('carousel-configuration'))
        });
        break;
    default:
        url = util.appendParamsToUrl(url, {
            'recommendedIDs': $container.data('recommended-products')
        });
    }

    return decodeURIComponent(url);
}

function initializeCache() {
    $cache.document = $(document);
    $cache.recommendationSections = $cache.document.find(selectors.recommendationSection);
}

function getRecommendationTiles(index, recommendationSection) {
    var $recommendationSection = $(recommendationSection),
        zone = $recommendationSection.data('zone'),
        viewType = $recommendationSection.data('viewType'),
        displayCTA = $recommendationSection.data('display-cta'),
        hidePrice = $recommendationSection.data('hidePrice'),
        templateType = $recommendationSection.data('templateType'),
        maxRecommendations = $recommendationSection.data('max-recommendations'),
        tileIdPrefix = $recommendationSection.data('tileIdPrefix'),
        reqUrl = recommendationsEndPoint[zone] || recommendationsEndPoint['default'],
        $target = $recommendationSection.data('target') ?  $recommendationSection.find($recommendationSection.data('target')) :  $recommendationSection.find("ul"),
        $wrapperTarget = $recommendationSection.data('wrapper-target') ?  $recommendationSection.closest($recommendationSection.data('wrapper-target')) :  '',
        recommendationJSON = {},
        skipAttraqtSTL = true;

    if(SitePreferences.ATTRAQT_ENABLED && SitePreferences.ATTRAQT_RECOMMENDATION_CONFIG != null 
        && SitePreferences.ATTRAQT_RECOMMENDATION_CONFIG.length > 0 && zone === 'pdp'){
        try {
            recommendationJSON = JSON.parse(SitePreferences.ATTRAQT_RECOMMENDATION_CONFIG);
            var shopTheLookConfigs = recommendationJSON.ShopTheLook;
            if(shopTheLookConfigs != null){
                var enableAttraqtShopTheLook = shopTheLookConfigs.enableAttraqtShopTheLook;
                if(enableAttraqtShopTheLook != null && enableAttraqtShopTheLook === 'true'){
                    skipAttraqtSTL = false;
                }
            }
        } catch (error) {
            recommendationJSON = {};
        }
        
        if(!skipAttraqtSTL){
            return;
        }
    }

    if (zone) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'zone': zone
        });
    }

    if (maxRecommendations) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'maxNumber': maxRecommendations
        });
    }

    if (viewType) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'viewType': viewType
        });
    }

    if (tileIdPrefix) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'tileIdPrefix': tileIdPrefix
        });
    }

    if (templateType) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'templateType': templateType
        });
    }

    if (displayCTA) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'displayCTA': displayCTA
        });
    }

    if (hidePrice) {
        reqUrl = util.appendParamsToUrl(reqUrl, {
            'hidePrice': hidePrice
        });
    }

    reqUrl = addPageSpecificValuesToUrl(reqUrl, zone, $recommendationSection);

    ajax.load({
        url: reqUrl,
        callback: function(data) {
            if (!data) {
                return false;
            }
            $cache.document.trigger('recommendations.loaded', $wrapperTarget);
            $cache.document.trigger('init.tabs', $wrapperTarget);
        },
        target: $target
    });
}

function initializeRecommendations() {
    $cache.recommendationSections.each(getRecommendationTiles);

    if (!$cache.recommendationSections.length) {
        $cache.document.trigger('recommendations.notfound');
    }

    $cache.document.on('cart.updated search.nohit', function (event, selector) {
        $(selector).find(selectors.recommendationSection).each(getRecommendationTiles);
    });
}

var recommendations = {
    init: function() {
        initializeCache();
        initializeRecommendations();
    }
};

module.exports = recommendations;