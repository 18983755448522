import Block from '_core_ext/core/common/Block';
import layout from '_core_ext/layout';
import util from '_core_ext/util';
import eventMgr from '_core_ext/core/eventMgr';
import tileServices from './utils/services';
import slickSlider from '../../../components/common/SlickSlider';
const picturefill = require('picturefill');

export default class ProductTile extends Block {
    init() {
        super.init();
        this.settings = {};
        this.initConfig(this.settings);
        tileServices.init();

        this.quickShopEnabled = this.config.quickShopEnabled;
        this.quickShopTimeout = null;
        this.quickShopLastOpened = false;

        if ($(window).width() > 1024) {
            if ($('.c-refinements__content-inner').hasClass('largeDevices')){
                const newscroll = document.querySelector('.largeDevices');
                let isDown = false;
                let startX;
                let scrollLeft;
    
                newscroll.addEventListener('mousedown', (e) => {
                    let rect = newscroll.getBoundingClientRect();
                    isDown = true;
                    newscroll.classList.add('active');
                    // Get initial mouse position
                    startX = e.pageX - rect.left;
                    // Get initial scroll position in pixels from left
                    scrollLeft = newscroll.scrollLeft;
                });
    
                newscroll.addEventListener('mouseleave', () => {
                    isDown = false;
                    newscroll.dataset.dragging = false;
                    newscroll.classList.remove('active');
                });
    
                newscroll.addEventListener('mouseup', () => {
                    isDown = false;
                    newscroll.dataset.dragging = false;
                    newscroll.classList.remove('active');
                });
    
                newscroll.addEventListener('mousemove', (e) => {
                    if (!isDown) return;
                    let rect = newscroll.getBoundingClientRect();
                    e.preventDefault();
                    newscroll.dataset.dragging = true;
                    // Get new mouse position
                    const x = e.pageX - rect.left;
                    // Get distance mouse has moved (new mouse position minus initial mouse position)
                    const walk = (x - startX);
                    // Update scroll position of slider from left (amount mouse has moved minus initial scroll position)
                    newscroll.scrollLeft = scrollLeft - walk;
                });
            }
        }

        if ($(window).width() > 767) {
            if($('.top-refinement-bar .c-refinements__content-inner').find('.c-refinements__subitem').length > 4) {
                slickSlider.slidetopFiltersSection($('.js-plp-slick-slider'), true);
            }
        }else{
            slickSlider.slidetopFiltersSection($('.js-plp-slick-slider'), true);
        }
        
        this.event('click', '.swatch-list .swatch:not(.add-bag)', this.onClickSwatch.bind(this));
        this.event('click', '.swatch-list .swatch.add-bag', this.onClickSwatchAdd.bind(this));

        if ($(window).width() > 992) {
            this.event('mouseenter', '.swatch-list .swatch.js-color-swatch', this.onMouseEnterSwatch.bind(this));
            this.event('mouseleave', '.swatch-list li', this.onSwatchListMouseLeave.bind(this));
        }

        if (this.quickShopEnabled) {
            this.updateQuickShopReference();

            this.event('click', '.js-show-quickshop', util.debounce(
                this.showQuickShop.bind(this, true), 400,
                {
                    leading: true,
                    trailing: false
                }));
            if ($(window).width() > 992) {
                this.event('mouseleave','.c-product-tile__wrapper', this.hideQuickShop.bind(this));
                this.event('mouseenter', '.js-product-tile-image', this.showQuickShopWithDelay.bind(this, 10));
            }
            
            this.subscribeToQuickShopEvents();
        }

        if ($(window).innerWidth() <= 1199) {
            $('.addbag-container').removeClass('d-none');
            $('.product-tile .js-product-tile-image').on('mouseleave touchmove', function(){
                $(this).find('.c-quick-shop').addClass('d-none');
            });
            $(window).scroll(function() {
                $('.c-quick-shop').addClass('quickshowClass').show();
                clearTimeout($.data(this, 'scrollTimer'));
                $.data(this, 'scrollTimer', setTimeout(function() {
                    $('.c-quick-shop').removeClass('quickshowClass');
                    $('.addbag-container').removeClass('d-none');
                    $('.c-product-result__content .size, .c-product-result__content .length').addClass('d-none');
                    $('.js-quick-shop-content .size, .js-quick-shop-content .length').addClass('d-none');
                    $('.c-quick-shop').removeClass('d-none');
                }, 250));
            });
        }
    }

    /**
    * @description updates picture srcset attributes
    *
    * @param {Object} data
    */

    updateSRCSET(data) {
        if (!data) {
            return;
        }

        this.$el.find('.js-srcset-mobile').attr('srcset', data.srcMobile);
        this.$el.find('.js-srcset-tablet').attr('srcset', data.srcTablet);
        this.$el.find('.js-srcset-desktop').attr('srcset', data.srcDesktop);
    }

    /**
    * @description updates image attributes and reinits picturefill polifil
    *
    * @param {jQuery} $tile
    * @param {Object} data
    */

    updateAttributes($element, data) {
        if (!data || !$element) {
            return;
        }

        $element.attr({
            srcset: data.src,
            alt: data.alt,
            title: data.title
        });
        picturefill({
            reevaluate: true,
            elements: [$element[0]]
        });
    }

    onSwatchListMouseLeave() {
        var $tile = this.$el;
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var data = $thumb.data('current');
        var $swatch = $tile.find('.swatch-list').find('.swatch.js-selected');
        var colorName = $swatch.parent('li').data('color-value');
        $tile.find('.selected-color-name').text(colorName);

        this.updateSRCSET(data);
        this.updateAttributes($thumb, data);
    }

    onClickSwatch(el, event) {
        event.preventDefault();
        if ($(window).innerWidth() <= 1199) {
            $('.js-product-tile').find('.size, .length').addClass('d-none');
        }
        var $swatch = $(el);
        var tileUrl = $swatch.data('tile-url');
        if (!tileUrl) {
            return;
        }
        $swatch.closest('.product-tile').find('a.thumb-link').removeClass('video-link');
        $swatch.closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $swatch.addClass('selected');
        let isColorSwatch = $swatch.hasClass('js-color-swatch');

        this.updateTile(tileUrl, isColorSwatch);
    }

    onClickSwatchAdd(el, event) {
        event.preventDefault();
        this.$el.find('.size').length ? this.$el.find('.size').removeClass('d-none') : this.$el.find('.length').removeClass('d-none');
        this.$el.find('.addbag-container').addClass('d-none');
        this.$el.find('.thumb-link').removeClass('video-link');
    }

    handleClickSwatchPictureFill($swatch) {
        if ($swatch.hasClass('selected')) {
            return;
        }

        var $tile = this.$el;
        $swatch.closest('.swatch-list').find('.swatch.selected').removeClass('selected');
        $swatch.addClass('selected');
        $tile.find('.thumb-link').attr('href', $swatch.attr('href'));
        $tile.find('name-link').attr('href', $swatch.attr('href'));

        var data = $swatch.children('img').filter(':first').data('thumb');
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title,
            srcMobile: data.srcMobile,
            srcTablet: data.srcTablet,
            srcDesktop: data.srcDesktop
        };
        $thumb.attr(currentAttrs);
        this.updateSRCSET(data);
        $thumb.data('current', currentAttrs);

        picturefill({
            reevaluate: true,
            elements: [$thumb[0]]
        });
    }

    onMouseEnterSwatch(el) {
        var $swatch = $(el);
        // get current thumb details
        var $tile = this.$el;
        var $tileImage = $tile.find('.product-image .thumb-link').eq(0);
        var $thumb = $tile.find('.product-image .thumb-link img').eq(0);
        var data = $swatch.children('img').filter(':first').data('thumb');
        var colorName = $swatch.parent('li').data('color-value');
        $tile.find('.selected-color-name').text(colorName);
        var current = $thumb.data('current');

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data('current', {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title,
                srcMobile: $tileImage.find('.js-srcset-mobile').data('srcset') ? $tileImage.find('.js-srcset-mobile').data('srcset') : $tileImage.find('.js-srcset-mobile').attr('srcset'),
                srcTablet: $tileImage.find('.js-srcset-tablet').data('srcset') ? $tileImage.find('.js-srcset-tablet').data('srcset') : $tileImage.find('.js-srcset-tablet').attr('srcset'),
                srcDesktop: $tileImage.find('.js-srcset-desktop').data('srcset') ? $tileImage.find('.js-srcset-desktop').data('srcset') : $tileImage.find('.js-srcset-desktop').attr('srcset')
            });
            picturefill({
                reevaluate: true,
                elements: [$thumb[0]]
            });
        }

        this.updateSRCSET(data);
        this.updateAttributes($thumb, data);
    }

    showQuickShopWithDelay(milliseconds, el, event) {
        this.quickShopTimeout = setTimeout(this.showQuickShop.bind(this, false, el, event), milliseconds);
    }

    clearTimer() {
        if (this.quickShopTimeout) {
            clearTimeout(this.quickShopTimeout);
        }
    }

    showQuickShop(siblingQuickShopHide, el, event) {
        event.preventDefault();
        var $currentEl = this.$el;
        var recommendationCarouselQS = $('.recommendation-carousel');
        if ($(event.target).parents('.js-slider-button').length 
            || $(event.target).hasClass('js-slider-button')) {
            return;
        }
        if (siblingQuickShopHide) {
            this.quickShopLastOpened = true;
            this.emitter.emit('produtTile.quickShop.hideSiblings');
        }
        this.quickShop.show();
        $('.c-quick-shop__bag.d-none').removeClass('d-none');
        $currentEl.find('.c-quick-shop__bag').addClass('d-none');
        $currentEl.find('.c-quick-shop__close').removeClass('d-none');
        if(window.innerWidth < 768 && recommendationCarouselQS.length){
            if($currentEl.find('.color-swatches:visible').length) {
                slickSlider.slideColorVariants($currentEl.find('.js-color-swatches > ul'));
            } else if($currentEl.find('.size:visible').length) {
                slickSlider.slideSizeVariants($currentEl.find('.size > ul'));
            } else if($currentEl.find('.length:visible').length) {
                slickSlider.slideLengthVariants($currentEl.find('.length > ul'));
            }
        }
    }

    hideQuickShop(leaveLastOpened) {
        if (leaveLastOpened) {
            if (this.quickShopLastOpened) {
                this.quickShopLastOpened = false;
                return;
            }
        }
        this.clearTimer();
        var $currentEl = this.$el;
        $currentEl.find('.color-swatches').removeClass('d-none');
        $currentEl.find('.size, .length').addClass('d-none');
        $currentEl.find('.c-quick-shop__close').addClass('d-none');
        this.quickShop.hide();
    }

    /**
    * @param {Object} data - contains additional data that will be passed to event listeners
    */
    onProductAddedToCart(data) {
        var recommCarousel = this.$el.parents().hasClass('recommendations-carousel');
        var recentlyViewedCarousel = this.$el.parents().hasClass('recently-viewed-carousel');
        var quickshopEl = this.$el.find('.c-product-tile__wrapper .c-quick-shop');
        if(recommCarousel || recentlyViewedCarousel){
            this.$el.trigger('quickShop.addedToCartCarousel', [data, $(quickshopEl)]);
        } else {
            this.$el.trigger('quickShop.addedToCart', [data, $(quickshopEl)]);
        }

        if (this.quickShopEnabled && !layout.isSmallView()) {
            return;
        }

        this.emitter.emit('produtTile.product.added.to.cart');
    }

    onNotifyMe(params) {
        params = Object.assign({}, params, {productTileCmpId: this.id});
        this.emitter.emit('produtTile.notifyme', params);
    }

    notifyMeFormSubmitted() {
        if (!this.quickShopEnabled) {
            return;
        }
        this.quickShop.resetQuickShop();
    }

    onNotifyMeDialogClose() {
        if (!this.quickShopEnabled) {
            return;
        }
        this.quickShop.onNotifyMeDialogClose();
    }

    subscribeToQuickShopEvents() {
        this.onChild('quickShop', 'quickShop.product.added.to.cart', this.onProductAddedToCart.bind(this));
        this.onChild('quickShop', 'quickShop.notify.me', this.onNotifyMe.bind(this));
    }

    updateQuickShopReference() {
        this.getById('quickShop', (quickShop) => {
            this.quickShop = quickShop;
        });
    }

    getTileContent(tileUrl) {
        return eventMgr.execute('get.product.tile.content', {
            url: tileUrl,
            loaderLocation: this.$el.parent()
        });
    }

    updateTileContent(tileHtml, isColorSwatch) {
        this.$el.html(tileHtml);
        if (!isColorSwatch) {
            this.$el.find('.size').length ? this.$el.find('.size').removeClass('d-none') : this.$el.find('.length').removeClass('d-none');
            this.$el.find('.addbag-container').addClass('d-none');
        }
        $(document).trigger('init.all.carousels.insideselector', this.$el);
        return eventMgr.execute('components.update', this.$el);
    }
 
    handleQuickShopUpdate() {
        this.quickShopTimeout = null;
        this.quickShopLastOpened = false;
        this.updateQuickShopReference();
        this.subscribeToQuickShopEvents();
        if ($(window).innerWidth() <= 1199)
            this.quickShop.show();
    }

    updateTile(tileUrl, isColorSwatch) {
        this.getTileContent(tileUrl)
            .then((res) => this.updateTileContent(res, isColorSwatch))
            .then(this.handleQuickShopUpdate.bind(this));
    }
}