'use strict';

let layout = require('./layout'),
    $cache = {},
    initialized = false,
    options = {
        overlayTarget: 'body',
        overlaySource: null,
        overlayTargetClass: ''
    };

function initializeParams(params) {
    options = $.extend(true, {}, options, params);
}

function doWindowPopUp($element, event) {
    var url = $element.attr('href');
    var attrRel = $element.attr('rel') || '';
    attrRel = attrRel.split(',');
    var popupType = attrRel[0] || 'standard',
        popupWidth = attrRel[1] || '780',
        popupHeight = attrRel[2] || '580',
        tools = '',
        newWindow;

    event.preventDefault();

    if (popupType === 'fullscreen') {
        popupWidth = screen.availWidth;
        popupHeight = screen.availHeight;
    }

    if (popupType === 'standard') {
        tools = 'resizable,toolbar=yes,location=yes,scrollbars=yes,menubar=yes,width=' +
        popupWidth + ',height=' + popupHeight + ',top=0,left=0';
    }

    if (popupType === 'console' || popupType === 'fullscreen') {
        tools = 'resizable,toolbar=no,location=no,scrollbars=yes,width=' +
        popupWidth + ',height=' + popupHeight + ',left=0,top=0';
    }

    newWindow = window.open(url, 'newWin', tools);
    newWindow.focus();
}

/**
 * @description Opens dialog popup using URL from clicked element data attribute
 *
 * @param {jQuery} $element, element on storefront, dialog action trigger
 * @param {Event} event, click event
 * @returns {Void}
 */
function openDialog($element, event) {
    const dialog = require('./dialog');

    event.preventDefault();

    dialog.open({
        url: $element.data('url'),
        options: $element.data('options') || {}
    });
}

function initializeOverlayClickEvent(overlayOptions) {
    var $target = $(overlayOptions.overlayTarget);
    var $source = $(overlayOptions.overlaySource);
    var customTargetClass = overlayOptions.overlayTargetClass;

    if ($source.length) {
        var $cloned = $source.clone(false);

        $cloned.addClass(customTargetClass);
        $target.append($cloned);
        $(document).trigger('overlay.loaded', [$cloned, $source]);

        $cloned.on('overlay.close', () => {
            $cache.document.trigger('overlay.closed');
            $cloned.remove();
        });

        $cloned.find('.js-close').on('click', () => {
            $cloned.remove();
        });
    }
}

function overlayHandler(index, item) {
    var $element = $(item),
        overlayOptions = {};

    if ($element.data('overlayOptions')) {
        overlayOptions = $.extend(true, {}, options, $element.data('overlayOptions'));
    }

    $element.on('click', function(event) {
        if (overlayOptions.openInWindow) {
            doWindowPopUp($element, event);
        } else if (overlayOptions.dialog) {
            openDialog($element, event);
        } else {
            initializeOverlayClickEvent.apply(this, [overlayOptions]);
        }
    });
}

function getSourceSelector() {
    let selector = '.js-overlay ';

    if (layout.isSmallView() || layout.isMediumView()) {
        selector += '.js-small-overlay';
    } else {
        selector += '.js-large-overlay';
    }

    return selector;
}

function initializeOverlayEvents(container) {
    var $overlayElements = (container || $cache.document).find(getSourceSelector());
    $overlayElements.each(overlayHandler);
}

function initializeCache() {
    $cache.document = $(document);
    $cache.body = $('body');
}

function initializeEvents() {
    $cache.document.on('search.updated', () => {
        initializeCache();
        initializeOverlayEvents();
    });
    initializeOverlayEvents();
}

var overlay = {
    init: function(params) {
        if (initialized) {
            return;
        }

        initializeParams(params);
        initializeCache();
        initializeEvents();
        initialized = true;
    }
};

module.exports = overlay;
