import Account from '_core_ext/components/forms/Account';

export default class ChangePasswordForm extends Account {
    init() {
        super.init();
    }

    onSubmitSuccess(response) {
        super.onSubmitSuccess(response);
        this.$el.get(0).reset();
    }
}