'use strict';

const layout = require('./layout');

var $cache = {},
    initialized = false,
    options = {
        backgroundContainer: '.js-set-background'
    };


function setBackground() {
    let backgroundUrl = $cache.backgroundContainer
        .data(layout.isSmallView() ? 'mobileBackground' : 'desktopBackground');

    if (backgroundUrl) {
        $cache.backgroundContainer.css('background-image', 'url(' + backgroundUrl + ')');
        $cache.backgroundContainer.css({ 'background-repeat': 'no-repeat', 'background-size': 'cover', 'background-position': 'center' });
    }
}

function initializeCache() {
    $cache.document = $(document);
    $cache.backgroundContainer = $cache.document.find(options.backgroundContainer);
}

function initializeParams(params) {
    $.extend(true, options, params);
}

function initializeEvents() {
    if ($cache.backgroundContainer.data('set-background')) {
        setBackground();
    }
}

module.exports = {
    init: function (params) {
        if (initialized) {
            return;
        }

        initializeParams(params);
        initializeCache();
        initializeEvents();

        initialized = true;
    }
};
